import { eachDayOfInterval, format } from "date-fns";
import { OutagesByRange } from "shared/types/Camera";
import { CriticalPath } from "shared/types/CriticalPath";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { ProcessClass } from "shared/types/ProcessClass";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { calculateDelta } from "shared/views/critical_path/criticalPath";
import processDurationService from "@/services/processDurationService";
import projectProgressService from "@/services/projectProgressService";
import {
  QueryValueAggregation,
  QueryValueFormattingRule,
  QueryValueFormattingRuleOperator,
  QueryValueMetric,
  QueryValueReportConfig,
  QueryValueReportFilters,
  QueryValueReportSummaryPlanAndProcessesValue,
  QueryValueReportSummaryProcessesValue,
  QueryValueReportSummarySingleValue,
  QueryValueReportSummaryPlanValue,
} from "@/types/reports/PlotQueryValue";

export const precisionByMetric: Record<QueryValueMetric, number> = {
  working_hours: 1,
  unit_value: 2,
  utilization: 1,
  productive_days: 1,
  active_days: 1,
  inactive_days: 1,
  delta_planner: 1,
  project_progress: 1,
};

export const defaultFilters = {
  location: [],
  processes: {
    mode: "component",
    components: [],
    single_processes: [],
  },
  unit_value_type: null,
  daterange: {
    type: "last_week",
    start_date: null,
    end_date: null,
  },
};

export const metrics: QueryValueMetric[] = [
  "project_progress",
  "delta_planner",
  "working_hours",
  "unit_value",
  "utilization",
  "productive_days",
  "active_days",
  "inactive_days",
];

export const aggregationsByMetric: Record<QueryValueMetric, QueryValueAggregation[]> = {
  working_hours: ["sum"],
  unit_value: ["average", "median", "latest"],
  utilization: ["latest"],
  productive_days: ["latest"],
  active_days: ["latest"],
  inactive_days: ["latest"],
  delta_planner: ["latest"],
  project_progress: ["latest"],
};

export const hideAggregationForMetric: QueryValueMetric[] = ["delta_planner", "project_progress"];

export const filterKeysByMetric: Record<QueryValueMetric, (keyof QueryValueReportFilters)[]> = {
  working_hours: ["location", "processes", "daterange"],
  unit_value: ["location", "unit_value_type"],
  utilization: ["location", "processes", "daterange"],
  productive_days: ["location", "processes", "daterange"],
  active_days: ["location", "processes", "daterange"],
  inactive_days: ["location", "processes", "daterange"],
  delta_planner: [],
  project_progress: [],
};

export const previousPeriodForByMetric: Record<QueryValueMetric, QueryValueAggregation[]> = {
  working_hours: ["sum"],
  unit_value: ["latest"],
  utilization: ["latest"],
  productive_days: ["latest"],
  active_days: ["latest"],
  inactive_days: ["latest"],
  delta_planner: [],
  project_progress: [],
};

type TranslateFn = (key: string) => string;

const getWorkingDaysUnit = (
  t: TranslateFn,
  filters: QueryValueReportFilters,
  config: QueryValueReportConfig,
  full?: boolean,
) =>
  full
    ? ` ${t("analytics.reports.query_value_units.working_days_full")}`
    : t("analytics.reports.query_value_units.working_days");

export const valueUnitByMetric: Record<
  QueryValueMetric,
  (
    t: TranslateFn,
    filters: QueryValueReportFilters,
    config: QueryValueReportConfig,
    full?: boolean,
  ) => string
> = {
  working_hours: (t: TranslateFn) => t("analytics.reports.query_value_units.hours"),
  unit_value: (t: TranslateFn, filters: QueryValueReportFilters) =>
    `${t("unit_values.unit_value_unit")}/${
      filters.unit_value_type ? t(`unit_values.types.${filters.unit_value_type}.unit`) : "?"
    }`,
  utilization: () => "%",
  productive_days: getWorkingDaysUnit,
  active_days: getWorkingDaysUnit,
  inactive_days: getWorkingDaysUnit,
  delta_planner: getWorkingDaysUnit,
  project_progress: () => "%",
};

const isFormattingRuleMatching = (rule: QueryValueFormattingRule, value: number) => {
  const ruleValue = rule.value;
  if (ruleValue === null) {
    return false;
  }
  const operators: Record<QueryValueFormattingRuleOperator, () => boolean> = {
    equal: () => value === ruleValue,
    greater: () => value > ruleValue,
    greater_or_equal: () => value >= ruleValue,
    lesser: () => value < ruleValue,
    lesser_or_equal: () => value <= ruleValue,
  };
  return operators[rule.operator]();
};

export const findMatchingFormattingRule = (
  rules: QueryValueFormattingRule[],
  data: QueryValueReportSummarySingleValue,
): QueryValueFormattingRule | undefined => {
  if (data.value === null) {
    return undefined;
  }
  return rules.find((rule) => isFormattingRuleMatching(rule, data.value as number));
};

export const calculatePreviousPeriodChange = (
  value: number | null,
  previous_period_value: number | null,
) => {
  if (
    value === null ||
    previous_period_value === null ||
    !Number.isFinite(value) ||
    !Number.isFinite(previous_period_value)
  ) {
    return null;
  }
  const difference = value - previous_period_value;
  return previous_period_value !== 0 ? difference / previous_period_value : null;
};

const createEmptySingleValue = (): QueryValueReportSummarySingleValue => ({
  type: "single_value",
  value: null,
  previous_period_value: null,
  context: {
    start_date: null,
    end_date: null,
    previous_period_start_date: null,
    previous_period_end_date: null,
  },
});

const calculateUtilizationMetric = (
  locale: string,
  reportSummary: QueryValueReportSummaryProcessesValue,
  projectDurationSettings: ProjectDurationSettings,
): QueryValueReportSummarySingleValue => {
  const value =
    processDurationService.calculateUtilization(reportSummary.processes, projectDurationSettings) ??
    null;
  const previousPeriodValue =
    processDurationService.calculateUtilization(
      reportSummary.previous_period_processes,
      projectDurationSettings,
    ) ?? null;
  return {
    type: "single_value",
    value,
    previous_period_value: previousPeriodValue,
    context: reportSummary.context,
  };
};

const calculateProductiveDaysMetric = (
  locale: string,
  reportSummary: QueryValueReportSummaryProcessesValue,
  projectDurationSettings: ProjectDurationSettings,
  outages: OutagesByRange,
  processClasses: ProcessClass[],
  { hasWorkingHoursFeature }: { hasWorkingHoursFeature: boolean },
): QueryValueReportSummarySingleValue => {
  const valueAnalysis = processDurationService.calculateProcessesAnalysis(
    reportSummary.processes,
    processClasses,
    projectDurationSettings,
    outages,
    {
      hasWorkingHoursFeature,
    },
  );
  const value =
    valueAnalysis.length === 1
      ? valueAnalysis[0].productiveDays
      : valueAnalysis.find((entry) => entry.name === "total")?.productiveDays ?? null;

  const valueAnalysisPreviousPeriod = processDurationService.calculateProcessesAnalysis(
    reportSummary.previous_period_processes,
    processClasses,
    projectDurationSettings,
    outages,
    {
      hasWorkingHoursFeature,
    },
  );

  const previousPeriodValue =
    valueAnalysisPreviousPeriod.length === 1
      ? valueAnalysisPreviousPeriod[0].productiveDays
      : valueAnalysisPreviousPeriod.find((entry) => entry.name === "total")?.productiveDays ?? null;

  return {
    type: "single_value",
    value,
    previous_period_value: previousPeriodValue,
    context: reportSummary.context,
  };
};

const formatNumber = (value: number, precision: number, locale: string) =>
  value.toLocaleString(locale, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

const formatValueTextWithTotalDays = (
  value: number | null,
  total: number | null,
  metric: QueryValueMetric,
  locale: string,
) => {
  if (value === null || total === null) {
    return undefined;
  }
  return `${formatNumber(value, precisionByMetric[metric], locale)} / ${formatNumber(
    total,
    precisionByMetric[metric],
    locale,
  )}`
    .replaceAll(`.${"0".repeat(precisionByMetric[metric])}`, "")
    .replaceAll(`,${"0".repeat(precisionByMetric[metric])}`, "");
};

const calculateActiveDaysMetric = (
  locale: string,
  reportSummary: QueryValueReportSummaryProcessesValue,
  projectDurationSettings: ProjectDurationSettings,
  outages: OutagesByRange,
): QueryValueReportSummarySingleValue => {
  const valueDateInterval =
    reportSummary.context.start_date && reportSummary.context.end_date
      ? eachDayOfInterval({
          start: reportSummary.context.start_date,
          end: reportSummary.context.end_date,
        })
      : undefined;
  const value = processDurationService.calculateProcessDetailSummary(
    reportSummary.processes,
    projectDurationSettings,
    outages,
    valueDateInterval,
  ).active;
  const previousPeriodValue =
    reportSummary.context.previous_period_start_date &&
    reportSummary.context.previous_period_end_date &&
    processDurationService.calculateProcessDetailSummary(
      reportSummary.previous_period_processes,
      projectDurationSettings,
      outages,
      eachDayOfInterval({
        start: reportSummary.context.previous_period_start_date,
        end: reportSummary.context.previous_period_end_date,
      }),
    ).active;
  const totalDuration = processDurationService.getTotalDuration(
    processDurationService.calculateProcessDetailSummary(
      reportSummary.processes,
      projectDurationSettings,
      outages,
      valueDateInterval,
    ),
  );
  return {
    type: "single_value",
    value,
    value_text: formatValueTextWithTotalDays(value, totalDuration, "active_days", locale),
    previous_period_value: previousPeriodValue,
    context: reportSummary.context,
  };
};

const calculateInactiveDaysMetric = (
  locale: string,
  reportSummary: QueryValueReportSummaryProcessesValue,
  projectDurationSettings: ProjectDurationSettings,
  outages: OutagesByRange,
): QueryValueReportSummarySingleValue => {
  const valueDateInterval =
    reportSummary.context.start_date && reportSummary.context.end_date
      ? eachDayOfInterval({
          start: reportSummary.context.start_date,
          end: reportSummary.context.end_date,
        })
      : undefined;
  const value = processDurationService.calculateProcessDetailSummary(
    reportSummary.processes,
    projectDurationSettings,
    outages,
    valueDateInterval,
  ).inactive;
  const previousPeriodValue =
    reportSummary.context.previous_period_start_date &&
    reportSummary.context.previous_period_end_date &&
    processDurationService.calculateProcessDetailSummary(
      reportSummary.previous_period_processes,
      projectDurationSettings,
      outages,
      eachDayOfInterval({
        start: reportSummary.context.previous_period_start_date,
        end: reportSummary.context.previous_period_end_date,
      }),
    ).inactive;
  const totalDuration = processDurationService.getTotalDuration(
    processDurationService.calculateProcessDetailSummary(
      reportSummary.processes,
      projectDurationSettings,
      outages,
      valueDateInterval,
    ),
  );
  return {
    type: "single_value",
    value,
    value_text: formatValueTextWithTotalDays(value, totalDuration, "inactive_days", locale),
    previous_period_value: previousPeriodValue,
    context: reportSummary.context,
  };
};

const processesValueMetricCalculators: Partial<
  Record<
    QueryValueMetric,
    (
      locale: string,
      reportSummary: QueryValueReportSummaryProcessesValue,
      projectDurationSettings: ProjectDurationSettings,
      outages: OutagesByRange,
      processClasses: ProcessClass[],
      options: { hasWorkingHoursFeature: boolean },
    ) => QueryValueReportSummarySingleValue
  >
> = {
  utilization: calculateUtilizationMetric,
  productive_days: calculateProductiveDaysMetric,
  active_days: calculateActiveDaysMetric,
  inactive_days: calculateInactiveDaysMetric,
};

export const calculateProcessesValueMetric = (
  locale: string,
  metric: QueryValueMetric,
  reportSummary: QueryValueReportSummaryProcessesValue,
  projectDurationSettings: ProjectDurationSettings | undefined,
  outagesByRange: OutagesByRange | undefined,
  processClasses: ProcessClass[],
  options: { hasWorkingHoursFeature: boolean },
) => {
  if (!projectDurationSettings || !outagesByRange) {
    return createEmptySingleValue();
  }

  const calculator = processesValueMetricCalculators[metric];
  if (!calculator) {
    throw new Error(`Missing query value metric processes calculator for "${metric}"`);
  }

  return calculator(
    locale,
    reportSummary,
    projectDurationSettings,
    outagesByRange,
    processClasses,
    options,
  );
};

const calculateProjectProgress = (
  reportSummary: QueryValueReportSummaryPlanAndProcessesValue,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
): QueryValueReportSummarySingleValue => {
  const value = projectProgressService.calculateProjectProgressPercentage(
    reportSummary.plan,
    reportSummary.processes,
    projectDurationSettings,
    hierarchyTags,
  );
  return {
    type: "single_value",
    value: value !== null ? value * 100 : null,
    previous_period_value: null,
    context: reportSummary.context,
  };
};

const planAndProcessesValueMetricCalculators: Partial<
  Record<
    QueryValueMetric,
    (
      reportSummary: QueryValueReportSummaryPlanAndProcessesValue,
      projectDurationSettings: ProjectDurationSettings,
      hierarchyTags: HierarchyTagStore[],
    ) => QueryValueReportSummarySingleValue
  >
> = {
  project_progress: calculateProjectProgress,
};

export const calculatePlanAndProcessesValueMetric = (
  metric: QueryValueMetric,
  reportSummary: QueryValueReportSummaryPlanAndProcessesValue,
  projectDurationSettings: ProjectDurationSettings | undefined,
  hierarchyTags: HierarchyTagStore[],
) => {
  if (!projectDurationSettings) {
    return createEmptySingleValue();
  }

  const calculator = planAndProcessesValueMetricCalculators[metric];
  if (!calculator) {
    throw new Error(`Missing query value metric plan and processes calculator for "${metric}"`);
  }

  return calculator(reportSummary, projectDurationSettings, hierarchyTags);
};

const calculateDeltaPlanner = (
  reportSummary: QueryValueReportSummaryPlanValue,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
  criticalPath: CriticalPath,
): QueryValueReportSummarySingleValue => {
  const delta = calculateDelta(
    criticalPath,
    reportSummary.plan,
    hierarchyTags,
    projectDurationSettings,
  );
  return {
    type: "single_value",
    value: delta,
    previous_period_value: null,
    context: reportSummary.context,
  };
};

const planValueMetricCalculators: Partial<
  Record<
    QueryValueMetric,
    (
      reportSummary: QueryValueReportSummaryPlanValue,
      projectDurationSettings: ProjectDurationSettings,
      hierarchyTags: HierarchyTagStore[],
      criticalPath: CriticalPath,
    ) => QueryValueReportSummarySingleValue
  >
> = {
  delta_planner: calculateDeltaPlanner,
};

export const calculatePlanValueMetric = (
  metric: QueryValueMetric,
  reportSummary: QueryValueReportSummaryPlanValue,
  projectDurationSettings: ProjectDurationSettings | undefined,
  hierarchyTags: HierarchyTagStore[],
  criticalPath: CriticalPath | undefined,
) => {
  if (!projectDurationSettings || !criticalPath) {
    return createEmptySingleValue();
  }

  const calculator = planValueMetricCalculators[metric];
  if (!calculator) {
    throw new Error(`Missing query value metric plan calculator for "${metric}"`);
  }

  return calculator(reportSummary, projectDurationSettings, hierarchyTags, criticalPath);
};

export const createProcessTableLinkQuery = (
  filters: QueryValueReportFilters,
  reportSummary: QueryValueReportSummarySingleValue | undefined,
) => {
  const processIds =
    filters.processes.mode === "component"
      ? filters.processes.components.flatMap((item) => item.processes)
      : filters.processes.single_processes;

  const dateFormat = "yyyy-MM-dd";
  const dates =
    reportSummary?.context.start_date && reportSummary?.context.end_date
      ? [
          format(reportSummary.context.start_date, dateFormat),
          format(reportSummary.context.end_date, dateFormat),
        ]
      : [];

  return {
    processes: [...new Set(processIds)].join(",") || undefined,
    date: dates.join(",") || undefined,
    location: filters.location.join(",") || undefined,
  };
};
