<template>
  <Popover class="relative" v-slot="{ open }">
    <PopoverButton v-if="!defaultOpen" class="w-full outline-none">
      <slot>
        <template
          :class="[
            'w-full group inline-flex items-center justify-between py-1.5 px-3 bg-white rounded-md text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500 sm:text-sm text-xs sm:leading-6  text-gray-400',
            open ? 'ring-2 ring-yellow-500' : '',
          ]"
        >
          <span
            >{{ placeholder
            }}<span class="ml-0.5" v-if="selected.length > 0">({{ selected.length }})</span></span
          >
          <ChevronUpDownIcon
            class="ml-2 h-5 w-5 transition duration-150 ease-in-out"
            aria-hidden="true"
          />
        </template>
      </slot>
    </PopoverButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        focus
        class="absolute z-50 min-w-max h-max w-max sm:px-0 lg:max-w-5xl overflow-y-scroll xl:overflow-y-hidden PopoverPanelContent"
        :class="[
          !defaultOpen &&
            'md:right-0 lg:left-0 lg:-translate-x-1/4 lg:right-auto md:-translate-x-w-max mt-3',
          popoverClass,
        ]"
        v-slot="{ close }"
        :static="defaultOpen"
      >
        <div
          class="bg-gray-50 border border-gray-200 shadow rounded-lg p-4 mb-4"
          ref="popoverElement"
        >
          <div class="mb-4 relative xl:w-1/2 2xl:mr-3">
            <input
              ref="searchLocationInputElement"
              type="text"
              class="min-w-[280px] w-full px-3 py-1 text-sm rounded-md border border-gray-300 focus:ring-yellow-500 focus:border-yellow-500"
              :placeholder="t('buttons.search')"
              v-model="searchValue"
            />
            <XMarkIcon
              v-if="searchValue"
              class="w-4 absolute items-center bottom-0 right-1 top-1.5 text-gray-500"
              @click="resetSearchBar"
            />
          </div>
          <div
            :class="[
              {
                'grid-cols-1': groups.length === 1,
                'grid-cols-2': groups.length === 2,
                'xl:grid-cols-3': groups.length === 3,
                'lg:grid-cols-2 xl:grid-cols-4': groups.length >= 4,
              },
            ]"
            class="sm:grid lg:grid-flow-auto gap-2 items-start"
          >
            <div
              class="overflow-y-auto pl-4 pr-2 lg:max-w-[280px] lg:w-[280px] shadow bg-white h-96 sm:max-w-1/2"
              v-for="group in groups"
              :key="group.type"
            >
              <div class="">
                <div class="flex px-3 items-center justify-between sticky top-0 bg-white z-50">
                  <p class="font-bold py-1">{{ t(`analytics.reports.${group.type}`) }}</p>
                  <button
                    v-if="internalSelectedByType[group.type].size > 0"
                    @click="clearSelectedInGroup(group.type)"
                    class="text-gray-500 text-xs hover:underline"
                    type="button"
                  >
                    {{ $t("buttons.clear") }}
                  </button>
                </div>
                <div
                  class="py-2 px-3 rounded-md w-full flex items-center hover:bg-yellow-200"
                  @click="
                    handleAllOptionsChange(group.type);
                    checkedAllByType[group.type] ? emit('userInput') : '';
                  "
                >
                  <div
                    v-if="
                      internalSelectedByType[group.type].size > 0 && !checkedAllByType[group.type]
                    "
                    class="h-4 w-4 rounded bg-yellow-600 text-white ring-1 ring-yellow text-center leading-4 text-sm"
                  >
                    -
                  </div>
                  <input
                    type="checkbox"
                    :id="`checkbox_tag_all_${group.type}`"
                    :checked="checkedAllByType[group.type]"
                    v-else
                    class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500"
                  />
                  <label :for="`checkbox_tag_all_${group.type}`" class="ml-3 text-sm" @click.prevent
                    >{{ t("analytics.planner.all") }} <span>({{ group.tags.length }})</span></label
                  >
                </div>
                <template v-for="tag in group.tags" :key="tag._id">
                  <div
                    class="cursor-pointer py-2 px-3 rounded-md w-full flex items-center hover:bg-yellow-200"
                    @click="
                      handleOptionChange(tag._id);
                      internalSelected.has(tag._id) ? emit('userInput') : '';
                    "
                  >
                    <input
                      type="checkbox"
                      :id="`checkbox_tag_${tag._id}`"
                      :value="tag._id"
                      :checked="internalSelected.has(tag._id)"
                      class="cursor-pointer h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
                    />
                    <label
                      :for="`checkbox_tag_${tag._id}`"
                      class="cursor-pointer ml-3 text-sm"
                      @click.prevent
                      >{{ tag.name }}</label
                    >
                  </div>
                </template>
              </div>
            </div>
          </div>
          <MainButton
            :label="t('buttons.apply')"
            class="sm:mr-0 !w-full"
            type="button"
            color="yellow"
            @click="
              emitChange();
              close();
            "
            v-if="changeOnSubmit"
          />
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import MainButton from "@/components/other/MainButton.vue";

const props = defineProps<{
  hierarchyTags: HierarchyTagStore[];
  selected: string[];
  placeholder?: string;
  defaultOpen?: boolean;
  changeOnSubmit?: boolean;
  popoverClass?: string;
}>();

const emit = defineEmits<{
  (eventName: "change", payload: string[]): void;
  (eventName: "userInput"): void;
}>();

const tagTypes: HierarchyType[] = ["building", "level", "section", "component"];

const { t } = useI18n();

const internalSelected = ref<Set<string>>(new Set(props.selected));
const searchValue = ref("");
const searchLocationInputElement = ref<HTMLElement | null>(null);
const popoverElement = ref<HTMLDivElement | null>(null);

const tagsById = computed(() =>
  props.hierarchyTags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, HierarchyTagStore>),
);

const groupedTags = computed(() => {
  const initialValue = tagTypes.reduce((acc, type) => {
    acc[type] = [];
    return acc;
  }, {} as Record<HierarchyType, HierarchyTagStore[]>);
  return props.hierarchyTags.reduce((acc, tag) => {
    acc[tag.type].push(tag);
    return acc;
  }, initialValue);
});

const groups = computed(() => {
  const sortedGroups = Object.entries(groupedTags.value)
    .filter(([_type, tags]) => tags.length > 0)
    .map(([type, tags]) => ({
      type: type as HierarchyType,
      tags: (tags as HierarchyTagStore[]).filter(
        (tag) =>
          tag.name &&
          (!searchValue.value || tag.name.toLowerCase().includes(searchValue.value.toLowerCase())),
      ),
    }));
  sortedGroups.sort((a, b) => tagTypes.indexOf(a.type) - tagTypes.indexOf(b.type));
  return sortedGroups;
});

const internalSelectedByType = computed(() =>
  tagTypes.reduce((acc, type) => {
    acc[type] = new Set(
      [...internalSelected.value].filter((tagId) => tagsById.value[tagId]?.type === type),
    );
    return acc;
  }, {} as Record<HierarchyType, Set<string>>),
);

const checkedAllByType = computed(() =>
  tagTypes.reduce((acc, type) => {
    acc[type] = internalSelectedByType.value[type].size === groupedTags.value[type].length;
    return acc;
  }, {} as Record<HierarchyType, boolean>),
);

const resetSearchBar = () => {
  searchValue.value = "";
};

const emitChange = () => emit("change", [...internalSelected.value]);

const emitChangeImmediatelyIfEnabled = () => {
  if (!props.changeOnSubmit) {
    emitChange();
  }
};

const handleAllOptionsChange = (type: HierarchyType) => {
  const deleteAll = checkedAllByType.value[type];
  groupedTags.value[type].forEach((tag) => {
    deleteAll ? internalSelected.value.delete(tag._id) : internalSelected.value.add(tag._id);
  });
  emitChangeImmediatelyIfEnabled();
};

const handleOptionChange = (tagId: string) => {
  if (internalSelected.value.has(tagId)) {
    internalSelected.value.delete(tagId);
  } else {
    internalSelected.value.add(tagId);
  }
  emitChangeImmediatelyIfEnabled();
};

const clearSelectedInGroup = (type: HierarchyType) => {
  [...internalSelectedByType.value[type]].forEach((tagId) => internalSelected.value.delete(tagId));
  emitChangeImmediatelyIfEnabled();
};

watch(
  () => props.selected,
  () => {
    internalSelected.value = new Set(props.selected);
  },
);

watch(popoverElement, () => {
  if (popoverElement.value) {
    searchLocationInputElement.value?.focus();
  } else {
    resetSearchBar();
  }
});
</script>
