<template>
  <div class="border-b border-gray-200 bg-gray-50 sticky top-0 z-10">
    <div class="flex justify-between items-center py-2 sm:py-3 mx-6">
      <div>
        <SelectList
          :minWidth="220"
          :options="projectStatusOptions"
          :defaultSelected="'all'"
          @update:selected="selectedProjectStatus = $event"
        ></SelectList>
      </div>
      <button
        @click="openAddProjectModal = true"
        type="button"
        class="hidden sm:flex items-center gap-1 rounded bg-yellow-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
      >
        <PlusIcon class="h-5 w-5" />
        {{ $t(`dashboard.home.add_project`) }}
      </button>
    </div>
  </div>
  <div class="pt-2 grid grid-cols-1 px-6 pb-10 lg:grid-cols-2 2xl:grid-cols-3 gap-6 xl:gap-6">
    <div v-for="(project, index) in filteredProjects" :key="index">
      <ProjectCard
        :id="'project-link-' + index"
        :project="project"
        :thumbnailUrls="thumbnailUrlsByProject[project._id]"
        :weather="weatherByProject[project._id]"
        :extendedData="extendedDataByProject[project._id]"
      />
    </div>
  </div>
  <AddProjectModal
    :open="openAddProjectModal"
    @close="openAddProjectModal = false"
  ></AddProjectModal>
</template>

<script lang="ts" setup>
import { PlusIcon } from "@heroicons/vue/24/outline";
import mobile from "is-mobile";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SelectList from "shared/components/other/OaiListbox.vue";
import { ProjectExtendedData, SimplifiedProject } from "shared/types/Project";
import { WeatherItem } from "shared/types/Weather";
import {
  useExtendedDataForProjects,
  useThumbnailUrls,
  useThumbnailUrlsAll,
  useWeatherForProjects,
} from "@/composables/project";
import AddProjectModal from "@/views/dashboard/components/AddProjectModal.vue";
import ProjectCard from "@/views/dashboard/components/ProjectCard.vue";

const store = useStore();
const { projectThumbnailUrls } = useThumbnailUrls();
const { projectThumbnailUrlsAll } = useThumbnailUrlsAll();
const { weatherForProjects } = useWeatherForProjects();
const { extendedDataForProjects } = useExtendedDataForProjects();

const { t } = useI18n();
const isMobileDevice = mobile();

const projects = store.state.projects as SimplifiedProject[] | undefined;
const selectedProjectStatus = ref<"active" | "completed" | "all">("all");
const openAddProjectModal = ref(false);

const projectStatusOptions = [
  { value: "active", name: t("project.status.active") },
  { value: "completed", name: t("project.status.completed") },
  { value: "all", name: t(`dashboard.home.all_projects`) },
];

const filteredProjects = computed(() =>
  selectedProjectStatus.value === "all"
    ? projects
    : projects?.filter((project) => project.status === selectedProjectStatus.value),
);

const thumbnailUrlsByProject = computed(() => {
  if (projectThumbnailUrlsAll.value.length === 0 || isMobileDevice) {
    return projectThumbnailUrls.value.reduce((acc, projectThumbnailUrl) => {
      acc[projectThumbnailUrl._id] = [projectThumbnailUrl.thumbnail_url];
      return acc;
    }, {} as Record<string, string[]>);
  } else {
    return projectThumbnailUrlsAll.value.reduce((acc, item) => {
      acc[item._id] = item.thumbnail_urls.map((urlItem) => urlItem.thumbnail_url);
      return acc;
    }, {} as Record<string, string[]>);
  }
});

const weatherByProject = computed(() =>
  weatherForProjects.value.reduce((acc, item) => {
    acc[item._id] = item.weather;
    return acc;
  }, {} as Record<string, WeatherItem>),
);
const extendedDataByProject = computed(() =>
  extendedDataForProjects.value.reduce((acc, extendedData) => {
    acc[extendedData._id] = extendedData;
    return acc;
  }, {} as Record<string, ProjectExtendedData>),
);
</script>
