import { format, isDate } from "date-fns";
import { shallowRef } from "vue";
import { useI18n } from "vue-i18n";
import { HierarchyType } from "shared/types/HierarchyTag";
import { ShortenedProcessWithTags } from "shared/types/Process";
import processDurationService from "@/services/processDurationService";
import {
  filterColumnDateField,
  filterProcessField,
  filterProcessIds,
} from "@/views/process_table/components/utils";
import { Column, Data } from "@/views/process_table/types";
import LocationTag from "./LocationTag.vue";

const msToTime = (duration: number) => {
  const hours = Math.floor(duration / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

export const usePlainPresets = () => {
  const { t } = useI18n();

  const locationTag = shallowRef(LocationTag);

  const columns: Column[] = [
    {
      label: "",
      field: "process_ids",
      size: 0,
      filters: [
        {
          type: "process_ids",
          filterFn: filterProcessIds,
          filterValue: [] as string[],
          placeholder: "",
        },
      ],
      class: "hidden",
    },
    {
      label: t("analytics.processes.process"),
      field: "process",
      size: 3,
      filters: [
        {
          type: "process",
          filterFn: filterProcessField,
          filterValue: { processes: [], processTypes: [] } as Record<
            "processes" | "processTypes",
            string[]
          >,
          placeholder: t("analytics.processes.placeholder_process_type"),
        },
      ],
    },
    {
      label: t("analytics.processes.location"),
      field: "location",
      size: 5,
      filters: [
        {
          type: "location",
          filterFn: () => true,
          filterValue: [] as string[],
          placeholder: t("analytics.processes.placeholder_hierarchy_tag"),
        },
      ],
      renderComponent: locationTag,
      toggleSort: (a: unknown, b: unknown) => {
        const firstEl = a as Record<string, string>;
        const secondEl = b as Record<string, string>;
        return firstEl[Object.keys(firstEl)[0]].localeCompare(secondEl[Object.keys(secondEl)[0]]);
      },
    },

    {
      label: t("analytics.processes.date"),
      field: "date",
      size: 2,
      render: (value) => (isDate(value) ? format(value as Date, "dd.MM.yyyy") : "--"),
      filters: [
        {
          type: "date",
          filterFn: (v, i) => filterColumnDateField(v, i, "date"),
          filterValue: [],
          placeholder: "Filter",
        },
      ],
      sortDirection: "asc",
    },
    {
      label: t("analytics.processes.duration"),
      field: "duration",
      render: (value) => ((value as number) > 0 ? msToTime(value as number) : "--"),
      filterDisabled: true,
    },
    {
      label: t("analytics.processes.working_hours"),
      field: "working_hours",
      render: (value) => ((value as number) > 0 ? `${value}` : "--"),
      filterDisabled: true,
    },
  ];

  const getSummary = (data: Data[]) => {
    let duration = 0;
    let working_hours = 0;

    data.forEach((item) => {
      duration += item.duration as number;
      working_hours += item.working_hours as number;
    });
    const roundedDuration = (duration / (1000 * 60 * 60)).toFixed(2);

    return {
      process: `${data.length} ${t("analytics.processes.item", data.length)}`,
      duration: `Σ ${roundedDuration} ${t("time.hour", { count: working_hours })}`,
      working_hours: `Σ ${working_hours.toFixed(2)} ${t("time.hour", { count: working_hours })}`,
    };
  };

  const dataMapper = (processes: ShortenedProcessWithTags[]) =>
    processes.map((process) => {
      const tags: Record<HierarchyType, string> = {
        building:
          process.planner_item_mapping.building_name ||
          process.section_mask_mapping.building_name ||
          "",
        level:
          process.planner_item_mapping.level_name || process.section_mask_mapping.level_name || "",
        section:
          process.planner_item_mapping.section_name ||
          process.section_mask_mapping.section_name ||
          "",
        component: process.planner_item_mapping.component_name || "",
      };
      const tagIds: Record<HierarchyType, string | null> = {
        building:
          process.planner_item_mapping.building_id || process.section_mask_mapping.building_id,
        level: process.planner_item_mapping.level_id || process.section_mask_mapping.level_id,
        section: process.planner_item_mapping.section_id || process.section_mask_mapping.section_id,
        component: process.planner_item_mapping.component_id || "",
      };
      const tagOrder = {
        building: 1,
        level: 2,
        section: 3,
        component: 4,
      };
      const location = {} as Record<string, string>;
      for (const tag in tagOrder) {
        if (Object.prototype.hasOwnProperty.call(tags, tag)) {
          location[tag] = tags[tag as keyof typeof tags];
        }
      }
      return {
        ...process,
        ...tags,
        process: t(`process_classes.${process.encoded_label}`),
        date: process.start_time,
        duration: processDurationService.calculateDurationSum([process]),
        working_hours: processDurationService.calculateWorkHoursSum([process], true),
        location: location,
        tagIds,
      };
    });

  return {
    columns,
    dataMapper,
    getSummary,
  };
};
