<template>
  <div @keyup.esc="handleEsc">
    <div
      class="fixed inset-0 bg-gray-500/75"
      style="z-index: 9999"
      @click="emit('closed')"
      @touchstart="emit('closed')"
    />
    <div
      class="fixed inset-0 flex justify-center mt-24"
      style="z-index: 10000"
      @click="emit('closed')"
    >
      <div
        v-if="!showPageSelector"
        class="flex gap-3 flex-col text-gray-100 w-5/6 lg:w-3/4 2xl:w-1/2 h-min bg-oaiGray-400 rounded-lg"
        @click.stop
      >
        <div class="relative flex items-center px-3 pt-3">
          <input
            type="text"
            ref="searchInput"
            autofocus
            autocomplete="off"
            class="px-3 py-2 w-full truncate rounded border text-xs md:text-base border-gray-300 focus:border-yellow-500 focus:outline-offset-0 placeholder-gray-300 bg-transparent removeRing"
            :placeholder="
              hasPermission('app_admin')
                ? $t('partials.sidebar.search_project_admin')
                : $t('partials.sidebar.search_project_user')
            "
            v-model="searchValue"
            @keydown="handleInputKeyDown"
          />
          <XMarkIcon
            v-if="searchValue"
            @click="searchValue = ''"
            class="absolute cursor-pointer text-gray-100 hover:text-gray-100 w-4 h-4 right-4"
          />
        </div>

        <div class="overflow-auto h-min" style="max-height: 400px">
          <div
            :id="`quick-search-project-${project._id}`"
            v-for="project in projects"
            :key="project._id"
            class="flex gap-1 cursor-pointer px-3 py-1.5 text-sm hover:bg-oaiGray-300"
            :class="selectedProject?._id === project._id ? ['bg-oaiGray-300'] : []"
            @click="handleProjectClick($event, project)"
          >
            <div class="flex gap-3 items-center flex-1 min-w-0 justify-between md:justify-start">
              <div class="truncate">{{ project.name }}</div>
              <template v-if="hasPermission('app_admin')">
                <div class="text-gray-400 truncate">{{ project.customer_name }}</div>
                <div class="text-gray-400">{{ project.site_id }}</div>
                <Squares2X2Icon
                  class="text-gray-400 w-4 h-4 cursor-context-menu flex-shrink-0"
                  @click="handleOpenMenuClick($event, project)"
                />
              </template>
            </div>
            <div class="gap-1 items-center hidden lg:flex">
              <div class="text-gray-300 font-mono text-xs lowercase" style="width: 200px">
                {{ format(project.planned_start, "dd.MM.yyyy") }}
                {{
                  project.planned_end
                    ? `- ${format(project.planned_end, "dd.MM.yyyy")}`
                    : `- ${$t("calendar.today")}`
                }}
              </div>
              <div
                :class="[
                  project.status === 'active' ? 'bg-green-500' : 'bg-gray',
                  'px-1.5 py-0.5 text-center rounded-md text-sm',
                ]"
                style="min-width: 110px"
              >
                {{ $t(`admin.general_project_settings.project_status.${project.status}`) }}
              </div>
            </div>
          </div>
          <div v-if="projects.length === 0" class="px-3 py-1.5 text-sm">
            {{ $t("partials.sidebar.search_not_found") }}
          </div>
        </div>
      </div>
      <div
        v-if="showPageSelector && selectedProject"
        ref="pageContainerRef"
        class="flex gap-1 flex-col text-gray-100 pageContainer select-none border border-transparent rounded-md bg-oaiGray focus:outline-none h-min max-w-5/6 lg:min-w-96 lg:max-w-auto"
        @click.stop
        @keydown="handlePageKeyDown"
        :tabindex="-1"
      >
        <div class="overflow-auto">
          <div class="p-3 font-semibold text-white truncate">
            {{ selectedProject?.name }}
          </div>
          <div
            v-for="(page, index) in pages"
            :key="page.path"
            class="flex gap-1 justify-between cursor-pointer px-3 py-1.5 text-sm"
            :class="[
              selectedPage?.path === page.path
                ? 'bg-gray-600 hover:bg-gray-500'
                : 'bg-oaiGray-400 hover:bg-gray-600',
              index === pages.length - 1 ? 'border-t border-gray-500' : '',
            ]"
            @click="goToSelectedProject(page)"
          >
            <div class="truncate capitalize">{{ page.label }}</div>
            <div class="flex-self-end font-mono">[{{ page.key }}]</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon, Squares2X2Icon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import FuzzySearch from "fuzzy-search";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useHasPermission } from "shared/composables/project";
import { Project } from "shared/types/Project";
import textService from "@/services/textService";

type Page = {
  label: string;
  path: string;
  key: string;
};

const backPage: Page = {
  label: "back",
  path: "back",
  key: "ESC",
};

const searchValue = ref("");
const selectedProject = ref<Project | null>(null);
const searchInput = ref<HTMLInputElement | null>(null);
const pageContainerRef = ref<HTMLDivElement | null>(null);
const showPageSelector = ref(false);
const selectedPage = ref<Page | null>(null);

const store = useStore();
const router = useRouter();

const emit = defineEmits(["closed"]);
const props = defineProps<{ currentSelectedProject: string }>();

const normalizedProjects = computed(
  () =>
    store.state.projects.map((project: Project) => ({
      ...project,
      normalized_name: textService.normalize(project.name),
      normalized_customer_name: textService.normalize(project.customer_name),
      normalized_site_id: textService.normalize(project.site_id),
    })) as Project[],
);

const projects = computed(() => {
  if (!searchValue.value) {
    return normalizedProjects.value;
  }

  const searchItems = textService
    .normalize(searchValue.value)
    .toLowerCase()
    .split(" ")
    .map((item) => item.trim())
    .filter((item) => item);

  return searchItems.reduce((acc, search) => {
    const searcher = new FuzzySearch(
      acc,
      ["normalized_name", "normalized_customer_name", "normalized_site_id"],
      {
        caseSensitive: false,
        sort: false,
      },
    );
    return searcher.search(search);
  }, normalizedProjects.value);
});

const pages = computed(() => {
  if (!selectedProject.value) {
    return [];
  }
  const { customer_name, site_id } = selectedProject.value;
  return [
    {
      label: "Dashboard",
      path: "/dashboard",
      key: "B",
    },
    useHasPermission("daily_report_user", customer_name, site_id) && {
      label: "Daily Report",
      path: "/daily_report/overview",
      key: "D",
    },
    useHasPermission("planner_user", customer_name, site_id) && {
      label: "Planner",
      path: "/planner",
      key: "T",
    },
    useHasPermission("processes_user", customer_name, site_id) && {
      label: "Process Gantt",
      path: "/processes",
      key: "P",
    },
    useHasPermission("processes_user", customer_name, site_id) && {
      label: "Process Table",
      path: "/processes/table",
      key: "A",
    },
    useHasPermission("processes_user", customer_name, site_id) &&
      selectedProject.value.features.includes("working_hours") &&
      selectedProject.value.process_groups.includes("default") && {
        label: "Unit Values",
        path: "/unit_values_editor",
        key: "U",
      },
    useHasPermission("processes_user", customer_name, site_id) && {
      label: "Reports",
      path: "/reports",
      key: "R",
    },
    useHasPermission("site_activity_user", customer_name, site_id) && {
      label: "Site activity",
      path: "/labour/site_activity",
      key: "H",
    },
    useHasPermission("project_admin", customer_name, site_id) && {
      label: "Settings",
      path: "/settings",
      key: "S",
    },
    backPage,
  ].filter((item) => item) as Page[];
});

watch([projects, () => props.currentSelectedProject], () => {
  const currentProjectIndex = projects.value.findIndex(
    (item) => item.name === props.currentSelectedProject,
  );
  if (currentProjectIndex !== -1) {
    selectProject(projects.value[currentProjectIndex]);
  } else if (projects.value.length > 0) {
    selectProject(projects.value[0]);
  }
});

onMounted(() => {
  searchValue.value = "";
  setTimeout(() => {
    searchInput.value?.focus();
    const currentProjectIndex = projects.value.findIndex(
      (item) => item.name === props.currentSelectedProject,
    );
    if (currentProjectIndex !== -1) {
      selectProject(projects.value[currentProjectIndex]);
    } else if (projects.value.length > 0) {
      selectProject(projects.value[0]);
    }
  }, 50);
});

const selectProject = (project: Project) => {
  selectedProject.value = project;
  if (project) {
    document.getElementById(`quick-search-project-${project._id}`)?.scrollIntoView({
      block: "center",
    });
  }
};

const setShowPageSelector = (show: boolean) => {
  showPageSelector.value = show;
  if (show) {
    nextTick(() => {
      // this has to be in nextTick, otherwise useHasPermission doesn't work in the pages computed property
      selectedPage.value = pages.value[0];
      pageContainerRef.value?.focus();
    });
  } else {
    selectedPage.value = null;
    nextTick(() => {
      searchInput.value?.focus();
      // reselect project, so it's scrolled into view
      if (selectedProject.value) {
        selectProject(selectedProject.value);
      }
    });
  }
};

const handleProjectClick = (event: MouseEvent, project: Project) => {
  selectedProject.value = project;
  if (event.shiftKey) {
    setShowPageSelector(true);
  } else {
    goToSelectedProject(null);
  }
};

const goToProject = (project: Project, page: Page | null) => {
  const pagePath = page?.path || "/dashboard";
  router.push(`/${project.customer_name}/${project.site_id}${pagePath}`);
  emit("closed");
};

const moveProjectUp = () => {
  const project =
    (selectedProject.value &&
      projects.value[projects.value.findIndex((p) => p._id === selectedProject.value?._id) - 1]) ||
    projects.value[projects.value.length - 1];
  selectProject(project);
};

const moveProjectDown = () => {
  const project =
    (selectedProject.value &&
      projects.value[projects.value.findIndex((p) => p._id === selectedProject.value?._id) + 1]) ||
    projects.value[0];
  selectProject(project);
};

const goToSelectedProject = (page: Page | null) => {
  if (page?.path === backPage.path) {
    setShowPageSelector(false);
    return;
  }
  if (selectedProject.value) {
    goToProject(selectedProject.value, page);
  }
};

const movePageUp = () => {
  selectedPage.value =
    (selectedPage.value &&
      pages.value[pages.value.findIndex((p) => p.path === selectedPage.value?.path) - 1]) ||
    pages.value[pages.value.length - 1];
};

const movePageDown = () => {
  selectedPage.value =
    (selectedPage.value &&
      pages.value[pages.value.findIndex((p) => p.path === selectedPage.value?.path) + 1]) ||
    pages.value[0];
};

const handleInputKeyDown = (event: KeyboardEvent) => {
  const handledKeys = ["ArrowDown", "ArrowUp", "Enter"];
  if (handledKeys.includes(event.key)) {
    event.stopPropagation();
    event.preventDefault();

    if (event.key === "ArrowUp") {
      moveProjectUp();
    }
    if (event.key === "ArrowDown") {
      moveProjectDown();
    }
    if (event.key === "Enter") {
      if (event.shiftKey) {
        setShowPageSelector(true);
      } else {
        goToSelectedProject(null);
      }
    }

    return false;
  }
};

const handlePageKeyDown = (event: KeyboardEvent) => {
  const handledKeys = ["ArrowDown", "ArrowUp", "Enter"];
  if (handledKeys.includes(event.key)) {
    event.stopPropagation();
    event.preventDefault();

    if (event.key === "ArrowUp") {
      movePageUp();
    }
    if (event.key === "ArrowDown") {
      movePageDown();
    }
    if (event.key === "Enter") {
      goToSelectedProject(selectedPage.value);
    }
  } else {
    const shortcutPage = pages.value.find(
      (page) => page.key.toLowerCase() === event.key.toLowerCase(),
    );
    if (shortcutPage) {
      event.stopPropagation();
      event.preventDefault();

      goToSelectedProject(shortcutPage);
    }
  }
};

const handleEsc = () => {
  if (showPageSelector.value) {
    setShowPageSelector(false);
  } else {
    emit("closed");
  }
};

const handleOpenMenuClick = (event: Event, project: Project) => {
  event.stopPropagation();
  selectProject(project);
  setShowPageSelector(true);
};
</script>

<style scoped>
.projectContainer {
  width: 100%;
  max-width: none;
}

.pageContainer {
  width: 100%;
  max-width: none;
}

@media (min-width: 1024px) {
  .projectContainer {
    width: 50%;
  }

  .pageContainer {
    width: min-content;
  }
}

input {
  @apply ring-transparent !important;
}
</style>
