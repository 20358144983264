<template>
  <BaseWidget
    :isLoading="areReportsLoading || isReportSummaryLoading || isMounting"
    :showZoomIcon="true"
    :useWhiteIcons="useWhiteIcons"
    @trackInteraction="emit('trackInteraction', report?.type)"
  >
    <ReportPlot
      v-if="report"
      :report="report"
      :data="reportSummary"
      :title="report.name"
      :hidePopover="true"
      :width="width"
      :height="height"
      :hideLegend="true"
      :spacingX="16"
      :spacingY="12"
      :dashboardGridSize="dashboardGridSize"
      :isOnTheDashboard="true"
      @updateUseWhiteIcons="useWhiteIcons = $event"
      @widgetRouteClicked="emit('widgetRouteClicked', report.type)"
    />

    <template #menuItems>
      <MenuItem v-slot="{ active }" @click="handleEditReportClick">
        <div
          :class="[
            active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
            'px-4 py-2 text-sm cursor-pointer truncate flex items-center gap-2',
          ]"
        >
          <PencilIcon class="w-5 h-5" />
          {{ $t("analytics.reports.edit_report") }}
        </div>
      </MenuItem>
    </template>
    <template #modals>
      <EditReportModal
        v-if="editedReport"
        :report="editedReport"
        @close="editedReport = null"
        @done="editedReport = null"
      />
    </template>
    <template #zoomedView="{ height }">
      <ReportPlot
        v-if="report"
        :report="report"
        :data="reportSummary"
        :title="report.name"
        :hidePopover="true"
        :width="width"
        :height="height"
        :hideLegend="true"
        :spacingX="16"
        :spacingY="12"
        :dashboardGridSize="dashboardGridSize"
      />
    </template>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { MenuItem } from "@headlessui/vue";
import { PencilIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref, watch } from "vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useTrackEvent } from "shared/composables/tracking";
import logger from "shared/services/logger";
import { useReports } from "@/composables/report";
import ReportRepository from "@/repositories/ReportRepository";
import { DashboardGridSize } from "@/types/Dashboard";
import { Report, ReportSummary } from "@/types/Report";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import EditReportModal from "@/views/reports/components/EditReportModal.vue";
import ReportPlot from "@/views/reports/components/ReportPlot.vue";

const props = defineProps<{
  arg: string;
  width: number;
  height: number;
  dashboardGridSize: DashboardGridSize;
}>();

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const emit = defineEmits(["widgetRouteClicked", "trackInteraction"]);

const { reports, isLoading: areReportsLoading } = useReports();

const reportSummary = ref<ReportSummary | undefined>(undefined);
const isMounting = ref(true);
const isReportSummaryLoading = ref(false);
const editedReport = ref<Report | null>(null);
const useWhiteIcons = ref(false);

const report = computed(() => reports.value.find(({ _id }) => _id === props.arg));

const loadReportSummary = () => {
  if (!report.value || isReportSummaryLoading.value) {
    return;
  }
  isReportSummaryLoading.value = true;
  ReportRepository.loadReportSummary(currentCustomerName, currentSiteId, report.value)
    .then((data) => {
      reportSummary.value = data;
    })
    .catch((error) => {
      logger.error(error);
    })
    .finally(() => {
      isReportSummaryLoading.value = false;
    });
};

const trackEvent = useTrackEvent();

const handleEditReportClick = () => {
  if (!report.value) {
    return;
  }
  editedReport.value = report.value;
  trackEvent("dashboard_widget_report-edit_click", { type: report.value.type });
};

watch(report, () => {
  loadReportSummary();
});

onMounted(() => {
  isMounting.value = false;
  loadReportSummary();
});
</script>
