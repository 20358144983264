<template>
  <div class="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" :src="logoMultipleNoPaddingSrc" alt="Workflow" />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
        <form @submit.prevent="newPasswordSubmit" class="space-y-6" action="#" method="POST">
          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              {{ $t("authentication.reset_password.new_password") }}
            </label>
            <div class="mt-1 relative">
              <input
                id="password"
                name="password"
                :type="showPassword ? 'text' : 'password'"
                required=""
                v-model="newPassword"
                class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 pl-3 pr-9 py-2 placeholder-gray-400 shadow-sm focus:border-oaiGray-100 focus:ring-oaiGray-100 sm:text-sm"
              />
              <EyeIcon
                class="w-5 h-5 absolute top-2 right-3 text-gray-600 cursor-pointer"
                @click="showPassword = true"
                v-if="!showPassword"
              />
              <EyeSlashIcon
                class="w-5 h-5 absolute top-2 right-3 text-gray-600 cursor-pointer"
                @click="showPassword = false"
                v-if="showPassword"
              />
            </div>
          </div>

          <div class="pt-2">
            <button
              type="submit"
              class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
            >
              {{ $t("authentication.reset_password.change_password") }}
            </button>
          </div>
          <div class="text-center text-sm text-yellow-900">
            <router-link to="/log-in" class="font-sm hover:text-yellow-700">
              {{ $t("authentication.reset_password.back_to_login") }}
            </router-link>
          </div>
          <div class="pt-4" v-show="invalidInput">
            <div class="rounded-md bg-red-50 p-4">
              <div class="flex">
                <div class="shrink-0">
                  <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    {{ this.errorMessageInvalidInput }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { EyeIcon, EyeSlashIcon, XCircleIcon } from "@heroicons/vue/24/solid";
import { Auth } from "aws-amplify";
import logoMultipleNoPaddingSrc from "shared/assets/imgs/logo/logo-multiple-no-padding.svg";
import logger from "shared/services/logger";
import Footer from "@/views/auth/components/Footer";

export default {
  name: "ResetPassword",
  components: {
    EyeSlashIcon,
    EyeIcon,
    XCircleIcon,
    Footer,
  },
  methods: {
    async newPasswordSubmit() {
      this.invalidInput = false;

      const email = this.$route.query.email;
      const resetCode = atob(this.$route.query.code);

      try {
        await Auth.forgotPasswordSubmit(email, resetCode, this.newPassword);
        this.$router.push("/log-in");
      } catch (error) {
        switch (error.message) {
          case "Invalid verification code provided, please try again.":
            this.errorMessageInvalidInput = this.$t(
              "authentication.reset_password.error_invalid_code",
            );
            this.invalidInput = !this.invalidInput;
            break;
          case "Invalid code provided, please request a code again.":
            this.errorMessageInvalidInput = this.$t(
              "authentication.reset_password.error_invalid_code",
            );
            this.invalidInput = !this.invalidInput;
            break;
          case "Password does not conform to policy: Password not long enough":
            this.errorMessageInvalidInput = this.$t(
              "authentication.reset_password.error_password_length",
            );
            this.invalidInput = !this.invalidInput;
            break;
          case "Username/client id combination not found.":
            this.errorMessageInvalidInput = this.$t(
              "authentication.reset_password.error_user_not_existing",
            );
            this.invalidInput = !this.invalidInput;
            break;
          case "Attempt limit exceeded, please try after some time.":
            this.errorMessageInvalidInput = this.$t(
              "authentication.reset_password.error_limit_exceeded",
            );
            this.invalidInput = !this.invalidInput;
            break;
          default:
            logger.error("Unable to reset password", error);
            this.errorMessageInvalidInput = this.$t("authentication.reset_password.error_unknown");
            this.invalidInput = !this.invalidInput;
            break;
        }
      }
    },
  },
  data() {
    return {
      errorMessageInvalidInput: "",
      invalidInput: false,
      newPassword: "",
      showPassword: false,
      logoMultipleNoPaddingSrc,
    };
  },
};
</script>
