<template>
  <div class="text-left flex flex-col">
    <div v-if="isLoading" class="flex items-center justify-center flex-1 h-full">
      <LoadingSpinner />
    </div>
    <div
      v-if="
        !isLoading &&
        (plannerItemsWithChildren.length > 0 || hasLocationFilter) &&
        (!isCompleted || showEvenWhenCompleted)
      "
      class="relative h-full flex flex-col"
      :class="simplified ? '' : 'pt-3'"
    >
      <div class="flex gap-1 items-center absolute top-1 left-[0px]" v-if="!simplified">
        <div class="text-xs whitespace-nowrap">
          {{ t("analytics.reports.query_value_progress.progress_for_date") }}:
        </div>
        <VueDatePicker
          input-class-name="!text-xs"
          v-model="selectedDate"
          @update:model-value="handleSelectedDateChange"
          :enable-time-picker="false"
          format="dd.MM.yyyy"
          :locale="locale"
          auto-apply
          reverse-years
          class="w-[150px]"
          :clearable="false"
        />
        <LocationFilter
          class="ml-3 text-left"
          :style="{ lineHeight: '0' }"
          :hierarchyTags="hierarchyTags"
          :selected="locationFilters"
          popoverClass="leading-normal"
          @change="handleLocationFilterChange"
        >
          <FunnelIcon v-if="!hasLocationFilter" class="h-5 w-5 text-gray-500" />
          <FunnelIconSolid v-else class="h-5 w-5 text-yellow-400" />
        </LocationFilter>
        <FontAwesomeIcon
          icon="fa-solid fa-file-excel"
          class="ml-3 w-5 h-5 text-green cursor-pointer"
          @click="handleExportExportClick"
        />
      </div>
      <div
        class="flex gap-2 text-xs px-4 overflow-auto"
        :class="simplified ? '' : 'mb-1 pb-3 border-b'"
        :style="{ scrollbarGutter: 'stable' }"
      >
        <div class="flex-1" v-if="!simplified" />
        <OaiTooltip
          cls="w-[80px] flex items-center justify-center whitespace-nowrap"
          v-if="!simplified"
        >
          {{ formatPercentage(totalPercentage)
          }}{{ plannedProgress !== null ? `/ ${formatPercentage(plannedProgress)}` : "" }}
          <template #tooltip>
            <div class="text-xs flex flex-col gap-2">
              <div>
                {{ formatNumber(totalProgress.finishedWorkingDays) }}
                /
                {{ formatNumber(totalProgress.totalWorkingDays) }}
                {{ t("analytics.planner.working_days_label") }}
                ({{
                  t("analytics.reports.query_value_progress.finished_working_hours_parent_tooltip")
                }})
              </div>
              <div v-if="plannedProgress !== null">
                {{ t("analytics.planner.actual_progress") }}:
                {{ formatPercentage(totalPercentage) }}
              </div>
              <div v-if="plannedProgress !== null">
                {{ t("analytics.planner.planned_progress") }}:
                {{ formatPercentage(plannedProgress) }}
              </div>
            </div>
          </template>
        </OaiTooltip>
        <div class="w-[80px] flex items-center justify-end" v-if="!simplified">
          <OaiTooltip cls="cursor-pointer">
            {{ t("analytics.reports.query_value_progress.weight") }}
            <template #tooltip>
              <div>
                {{ t("analytics.reports.query_value_progress.weight_tooltip") }}
              </div>
            </template>
          </OaiTooltip>
        </div>
      </div>
      <div
        :style="{ scrollbarGutter: 'stable' }"
        class="overflow-auto -ml-2 pr-2 flex-1"
        v-if="projectDurationSettings"
      >
        <ProjectProgressItem
          :key="plannerItem._id"
          v-for="plannerItem in plannerItemsWithChildren"
          :plannerItem="plannerItem"
          :plannerItemProgressById="plannerItemProgressById"
          :hierarchyTags="hierarchyTags"
          :actualEventsBySourceId="actualEventsBySourceId"
          :plannedEventsByPlannerId="plannedEventsByPlannerId"
          :simplified="simplified"
          :processesBySourceId="processesBySourceId"
          :level="0"
          :parentDefaultOpen="true"
          :childrenCountByLevel="childrenCountByLevel"
          :tagsByPlannerItemId="tagsByPlannerItemId"
          :projectDurationSettings="projectDurationSettings"
          :totalProgress="totalProgress"
        />
      </div>
    </div>
    <div
      v-if="
        !isLoading && plannerItemsWithChildren.length === 0 && !hasLocationFilter && !isCompleted
      "
      class="py-2 text-sm truncate h-full flex flex-col items-center justify-center text-center"
    >
      {{ t("dashboard.project.planner_card.no_current_procedures") }}
    </div>
    <div
      v-if="!isLoading && isCompleted && !showEvenWhenCompleted"
      class="text-sm flex flex-col items-center justify-center gap-1 select-none flex-1 text-center"
    >
      <CheckCircleIcon class="h-16 w-16 text-green-400" v-if="!hideCheckIconWhenCompleted" />
      <span>{{ $t("analytics.planner.project_completed_message") }}</span>
      <RouterLink
        :to="{
          name: 'PlannerV2',
          params: { customer_name: currentCustomerName, site_id: currentSiteId },
        }"
        class="text-gray-600 underline"
      >
        {{ $t("analytics.planner.to_planner_link") }}
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { CheckCircleIcon, FunnelIcon } from "@heroicons/vue/24/outline";
import { FunnelIcon as FunnelIconSolid } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { startOfDay } from "date-fns";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useFilterByLocation } from "shared/composables/hierarchyTags";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import {
  ActualEvent,
  PlanConfig,
  PlannedEvent,
  PlannerItem,
  PlannerItemWithChildren,
} from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import LocationFilter from "@/components/process_filters/LocationFilter.vue";
import projectProgressService from "@/services/projectProgressService";
import { PlannerItemProgress } from "@/types/Plan";
import ProjectProgressItem from "@/views/planner/components/ProjectProgressItem.vue";
import exportProgressToExcel from "@/views/planner/progressExcelExport";

const props = defineProps<{
  planConfig: PlanConfig;
  processes: ShortenedProcessWithTags[];
  projectDurationSettings: ProjectDurationSettings;
  hierarchyTags: HierarchyTagStore[];
  simplified?: boolean;
  showOnlyActive?: boolean;
  hideCheckIconWhenCompleted?: boolean;
  showEvenWhenCompleted?: boolean;
}>();

const precision = 1;
const { locale, t } = useI18n();

const selectedDate = ref(startOfDay(new Date()));
const dateChangingTimer = ref<ReturnType<typeof setTimeout> | null>(null);
const locationFilters = ref<string[]>([]);

const createIsMatchingLocationFn = useFilterByLocation(computed(() => props.hierarchyTags));

const formatNumber = (number: number) =>
  number.toLocaleString(locale.value, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    useGrouping: false,
  });

const formatPercentage = (percentage: number | null) =>
  percentage !== null ? `${formatNumber(percentage * 100)}%` : "-";

const createPlannerItemsWithChildren = (plannerItems: PlannerItem[]): PlannerItemWithChildren[] => {
  const roots = [] as PlannerItemWithChildren[];
  const plannerItemsById = props.planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = { ...plannerItem, children: [] };
    return acc;
  }, {} as Record<string, PlannerItemWithChildren>);
  plannerItems.forEach(({ _id }) => {
    const plannerItem = plannerItemsById[_id];
    if (plannerItem.parent_id) {
      const parent = plannerItemsById[plannerItem.parent_id];
      if (parent) {
        parent.children?.push(plannerItem);
      }
    } else {
      roots.push(plannerItem);
    }
  });
  return roots;
};

const tagsByPlannerItemId = computed(() => {
  const types: HierarchyType[] = ["building", "level", "section", "component"];

  const hierarchyTagsBySourceId = props.hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      acc[`${tag.type}_${sourceId}`] = tag;
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  return props.planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = types
      .map((type) => hierarchyTagsBySourceId[`${type}_${plannerItem.source_id}`])
      .filter((tag) => tag) as HierarchyTagStore[];
    return acc;
  }, {} as Record<string, HierarchyTagStore[]>);
});

const tagIdsByPlannerItemId = computed(() =>
  Object.entries(tagsByPlannerItemId.value).reduce((acc, [plannerItemId, tags]) => {
    acc[plannerItemId] = tags.reduce((tagAcc, tag) => {
      tagAcc[tag.type] = tag._id;
      return tagAcc;
    }, {} as Record<HierarchyType, string>);
    return acc;
  }, {} as Record<string, Record<HierarchyType, string>>),
);

const isMatchingLocation = computed(() => createIsMatchingLocationFn(locationFilters.value));

const isPlannerItemVisible = (plannerItem: PlannerItem) => {
  if (props.showOnlyActive) {
    const plannerItemProgress = plannerItemProgressById.value[plannerItem._id];
    return (
      plannerItemProgress &&
      plannerItemProgress.total_working_days !== plannerItemProgress.finished_working_days &&
      plannerItemProgress.total_working_days !== 0 &&
      plannerItemProgress.finished_working_days !== 0
    );
  }

  return isMatchingLocation.value(tagIdsByPlannerItemId.value[plannerItem._id]);
};

const filterPlannerItems = (plannerItems: PlannerItem[]): PlannerItem[] => {
  const result = [] as PlannerItem[];

  const plannerItemsByParentId = plannerItems.reduce((acc, plannerItem) => {
    if (plannerItem.parent_id) {
      if (!acc[plannerItem.parent_id]) {
        acc[plannerItem.parent_id] = [];
      }
      acc[plannerItem.parent_id].push(plannerItem);
    }
    return acc;
  }, {} as Record<string, PlannerItem[]>);

  const traverse = (plannerItems: PlannerItem[]): boolean => {
    let visible = false;
    for (const plannerItem of plannerItems) {
      const childPlannerItems = plannerItemsByParentId[plannerItem._id] || [];

      if (childPlannerItems.length === 0) {
        if (isPlannerItemVisible(plannerItem)) {
          result.push(plannerItem);
          visible = true;
        }
      } else {
        const childVisible = traverse(childPlannerItems);
        if (childVisible) {
          result.push(plannerItem);
          visible = true;
        }
      }
    }
    return visible;
  };

  traverse(plannerItems.filter((plannerItem) => !plannerItem.parent_id));

  return result;
};

const filteredPlanConfig = computed(() => {
  const plannerItemsToProcess = projectProgressService.getPlannerItemsToProcess(
    props.planConfig,
    props.hierarchyTags,
  );
  const finalPlannerItemsToProcess = filterPlannerItems(plannerItemsToProcess);
  return {
    ...props.planConfig,
    planner_items: finalPlannerItemsToProcess,
  };
});

const plannerItemsWithChildren = computed(() =>
  createPlannerItemsWithChildren(filteredPlanConfig.value.planner_items),
);

const childrenCountByLevel = computed(() => {
  const result: Record<number, number> = {};
  let plannerItems: PlannerItemWithChildren[] = plannerItemsWithChildren.value;
  let level = 0;
  while (plannerItems.length > 0) {
    const allChildren: PlannerItemWithChildren[] = [];
    for (const plannerItem of plannerItems) {
      for (const child of plannerItem.children || []) {
        allChildren.push(child);
      }
    }
    result[level] = allChildren.length;
    level += 1;
    plannerItems = allChildren;
  }
  return result;
});

const plannerItemProgresses = computed(() =>
  projectProgressService.calculateProjectProgressItems(
    // It's a bit tricky here.
    // When props.showOnlyActive is true, filteredPlanConfig USES the value of plannerItemProgresses to filter.
    // This means, here we can't use filteredPlanConfig for that case, because that would create a circular dependency.
    props.showOnlyActive ? props.planConfig : filteredPlanConfig.value,
    props.processes,
    props.projectDurationSettings,
    props.hierarchyTags,
    selectedDate.value,
  ),
);

const plannerItemProgressById = computed(() =>
  plannerItemProgresses.value.reduce((acc, plannerItemProgress) => {
    acc[plannerItemProgress._id] = plannerItemProgress;
    return acc;
  }, {} as Record<string, PlannerItemProgress>),
);

const totalProgress = computed(() =>
  projectProgressService.calculateProjectProgress(plannerItemProgresses.value),
);

const totalPercentage = computed(() =>
  projectProgressService.calculatePercentage(
    totalProgress.value.finishedWorkingDays,
    totalProgress.value.totalWorkingDays,
  ),
);

const actualEventsBySourceId = computed(() =>
  props.planConfig.actual_events.reduce((acc, actualEvent) => {
    acc[actualEvent.source_id] = actualEvent;
    return acc;
  }, {} as Record<string, ActualEvent | undefined>),
);

const plannedEventsByPlannerId = computed(() =>
  props.planConfig.planned_events.reduce((acc, plannedEvent) => {
    acc[plannedEvent.planner_item_id] = plannedEvent;
    return acc;
  }, {} as Record<string, PlannedEvent>),
);

const isLoading = computed(() => !!dateChangingTimer.value);

const processesBySourceId = computed(() => {
  const sortedProcesses = props.processes.slice();
  sortedProcesses.sort((a, b) => a.start_time.getTime() - b.start_time.getTime());
  return sortedProcesses.reduce((acc, process) => {
    const key = process.planner_item_mapping?.source_id;
    if (key) {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(process);
    }
    return acc;
  }, {} as Record<string, ShortenedProcessWithTags[]>);
});

const plannedProgress = computed(() => {
  const plannerItemsById = filteredPlanConfig.value.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem>);
  const planWithoutActuals: PlanConfig = {
    ...filteredPlanConfig.value,
    actual_events: filteredPlanConfig.value.planned_events
      .filter((plannedEvent) => plannerItemsById[plannedEvent.planner_item_id])
      .map((plannedEvent) => ({
        _id: `actual_${plannedEvent._id}`,
        source_id: plannerItemsById[plannedEvent.planner_item_id].source_id,
        start: plannedEvent.start,
        end: plannedEvent.end,
        customer_name: plannedEvent.customer_name,
        site_id: plannedEvent.site_id,
        created: plannedEvent.created || "",
        created_by: plannedEvent.created_by || "",
        last_updated: plannedEvent.created || "",
        last_updated_by: plannedEvent.created_by || "",
      })),
  };
  const processes = undefined;
  return projectProgressService.calculateProjectProgressPercentage(
    planWithoutActuals,
    processes,
    props.projectDurationSettings,
    props.hierarchyTags,
    selectedDate.value,
  );
});

const isCompleted = computed(
  () =>
    plannerItemProgresses.value.length > 0 &&
    plannerItemProgresses.value.every(
      (plannerItemProgress) =>
        plannerItemProgress.finished_working_days === plannerItemProgress.total_working_days &&
        plannerItemProgress.total_working_days !== 0,
    ),
);

const hasLocationFilter = computed(() => locationFilters.value.length > 0);

const handleSelectedDateChange = () => {
  if (dateChangingTimer.value) {
    clearTimeout(dateChangingTimer.value);
  }
  dateChangingTimer.value = setTimeout(() => {
    dateChangingTimer.value = null;
  }, 500);
};

const handleLocationFilterChange = (payload: string[]) => {
  locationFilters.value = payload;
};

const handleExportExportClick = () => {
  exportProgressToExcel(
    plannerItemsWithChildren.value,
    plannerItemProgresses.value,
    props.hierarchyTags,
    props.planConfig,
    props.projectDurationSettings,
  );
};
</script>
