const normalize = (text: string) =>
  text
    .replaceAll("²", "2")
    .replaceAll("³", "3")
    .replaceAll("ß", "ss")
    .replaceAll("ä", "ae")
    .replaceAll("ü", "ue")
    .replaceAll("ö", "oe")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

const toFileName = (text: string) => text.toLowerCase().split(" ").join("_");

const toNormalizedFileName = (text: string) => toFileName(normalize(text));

export default {
  normalize,
  toFileName,
  toNormalizedFileName,
};
