<template>
  <div
    @click="editMode ? $emit('addHighlight') : ''"
    :class="[
      {
        'shadow-lg mix-blend-normal bg-blend-color-dodge': editMode,
        'border-yellow': highlightSection === 'notes',
      },
      'my-4 bg-white shadow sm:rounded-lg md:px-5 px-1 py-6 border',
    ]"
  >
    <div class="my-2 md:mb-4">
      <div class="flex items-center justify-between px-2">
        <h3 class="md:text-lg leading-4 font-semibold text-gray-900">
          {{ $t(`report.section_title.notes`) }}
        </h3>
        <div class="flex items-center" v-if="!approved">
          <button
            class="flex items-center text-orange text-sm"
            @click="
              copyDataOpen = true;
              searchDate = yesterday;
            "
          >
            <CalendarIcon class="h-4 pr-1" />
            <span class="md:text-sm text-xs hover:underline">{{
              $t("report.general.old_data_label")
            }}</span>
          </button>
          <PencilIcon
            v-if="!editMode"
            class="w-4 h-4 text-oaiGray-300 cursor-pointer ml-3"
            @click="activeEditMode"
          />
        </div>
        <CopySectionData
          :open="copyDataOpen"
          :date="searchDate"
          @closeModal="copyDataOpen = false"
          @copySectionData="copySectionData($event), (copyDataOpen = false)"
        />
      </div>
      <div v-if="approved && note.length === 0">
        <p class="md:text-center pl-2 pt-2 md:p-0 md:text-base text-sm">
          {{ $t("report.general.section_empty") }}
        </p>
      </div>
    </div>

    <div :class="[editMode ? 'editInput' : 'overviewInput']">
      <textarea
        rows="3"
        name="note"
        v-if="editMode"
        :value="note"
        @input="$emit('updateSectionData', ($event.target as HTMLInputElement).value, 'notes')"
        :placeholder="$t('report.general.notes_placeholder')"
        :class="{ 'oai-inputs': editMode }"
      />
      <div v-if="!editMode">
        <p :class="{ 'bg-gray-100 p-2 rounded text-sm': note }">
          {{ note }}
        </p>
      </div>
    </div>
    <div :class="['flex justify-center', { 'mt-5': editMode }]">
      <button
        type="button"
        v-if="!editMode && note.length === 0 && !approved"
        @click="activeEditMode"
        class="relative inline-flex items-center p-2 shadow-sm border-2 border-yellow rounded-full text-yellow-700 hover:bg-yellow-700 hover:text-white focus:outline-none"
      >
        <PlusIcon class="w-6" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { PlusIcon, PencilIcon, CalendarIcon } from "@heroicons/vue/24/solid";
import { format, subDays, getISODay, parseISO } from "date-fns";
import { defineComponent, PropType } from "vue";
import { useTrackEvent } from "shared/composables/tracking";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import CopySectionData from "@/views/daily_report/components/SectionCopyData.vue";

export default defineComponent({
  props: {
    note: {
      type: String as PropType<string>,
      required: true,
    },
    editMode: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    highlightSection: {
      type: String as PropType<string>,
      required: true,
    },
    approved: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    validationError: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  emits: ["addHighlight", "activeEditMode", "updateSectionData"],
  components: {
    PlusIcon,
    PencilIcon,
    CalendarIcon,
    CopySectionData,
  },
  data() {
    return {
      copyDataOpen: false,
      date: this.$route.params.date as string,
      searchDate: "",
      error: false,
      errorMessage: "",
    };
  },

  setup() {
    const trackEvent = useTrackEvent();
    return {
      trackEvent,
    };
  },
  computed: {
    yesterday() {
      const dateObj = parseISO(this.date);
      if (getISODay(dateObj) === 1) {
        return format(subDays(dateObj, 2), "yyyy-MM-dd");
      }
      return format(subDays(dateObj, 1), "yyyy-MM-dd");
    },
  },
  methods: {
    activeEditMode() {
      this.$emit("activeEditMode");
      this.$emit("addHighlight");
    },

    copySectionData(date: string) {
      this.error = false;
      this.errorMessage = "";
      const { customer_name, site_id } = this.$route.params;
      this.trackEvent("dcr_copy-from_apply");
      return DailyReportRepository.getSectionValueCopy(
        "notes",
        customer_name as string,
        site_id as string,
        date,
      )
        .then((response) => {
          if (response.data === "") {
            this.showToast("warning", this.$t("err.saved_values"));
          }
          this.$emit("updateSectionData", response.data);
        })
        .catch(() => {
          this.showToast("warning", this.$t("err.saved_values"));
        })
        .finally(() => this.$emit("activeEditMode"));
    },
  },
});
</script>
