<template>
  <Sidebar>
    <GalleryContent />
  </Sidebar>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { useTrackEvent } from "shared/composables/tracking";
import Sidebar from "@/components/layout/Sidebar.vue";
import GalleryContent from "@/views/camera/components/GalleryContent.vue";

const trackEvent = useTrackEvent();

onMounted(() => {
  trackEvent("camera_gallery_view");
});
</script>
