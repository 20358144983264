<template>
  <div
    class="h-full w-full rounded-xl flex items-center justify-center px-1 text-xs relative border border-gray-600"
    :class="[backgroundColor, selected ? 'shadow-lg' : 'shadow-md']"
    :style="{ filter: dimmed && !selected && !highlighted ? 'opacity(0.3)' : '' }"
  >
    <div
      v-if="highlightedYellow || highlightedBlue || highlightedPurple"
      class="absolute -top-[20px] -left-[17px] -right-[17px] -bottom-[20px] -z-[10] rounded-lg border flex justify-center items-end text-xs"
      :class="
        highlightedYellow
          ? 'bg-yellow-100 border-yellow-400 text-yellow-800'
          : highlightedBlue
          ? 'bg-blue-200 border-blue-400 text-blue-800'
          : 'bg-purple-100 border-purple-400 text-purple-800'
      "
      :style="{ fontSize: '8px' }"
    >
      {{
        highlightedYellow
          ? t("analytics.critical_path.time_critical_component_label")
          : highlightedBlue
          ? t("analytics.critical_path.latest_planned_component_label")
          : t("analytics.critical_path.projected_end_component_label")
      }}
    </div>
    <Handle
      type="source"
      :position="Position.Top"
      v-if="criticalPathNode.level_id !== layout.levels[0]?.id"
    />
    <OaiTooltip cls="truncate" :simple="false">
      <span
        class="bg-gray-400 text-white px-1 py-0.5 rounded-md font-mono"
        v-if="tagLetters && isAdmin"
      >
        {{ tagLetters }}
      </span>
      {{ criticalPathNode.name }}
      <template #tooltip>
        <CriticalPathGraphNodeHover
          :criticalPathNode="criticalPathNode"
          :context="context"
          :differenceForPathsByNode="differenceForPathsByNode"
          :differenceForNode="differenceForNode"
          :differenceForPathsByNotFinishedNode="differenceForPathsByNotFinishedNode"
        />
      </template>
    </OaiTooltip>
    <Handle
      type="target"
      :position="Position.Bottom"
      v-if="criticalPathNode.level_id !== layout.levels[layout.levels.length - 1]?.id"
    />
    <OaiTooltip
      v-if="criticalPathNode.level_splits.length > 0"
      cls="absolute bottom-0 right-0 translate-x-1/2 translate-y-1/2 bg-gray-400 px-2 text-xs py-0.5 rounded-full text-white"
    >
      {{ criticalPathNode.level_splits.length }}
      <template #tooltip>
        <div>
          <div v-for="split in criticalPathNode.level_splits" :key="split.id">
            &bull; {{ split.name }}
          </div>
        </div>
      </template>
    </OaiTooltip>
    <CriticalPathGraphNodeDelay
      :criticalPathNode="criticalPathNode"
      :context="context"
      :selected="selected"
      :differenceForPathsByNode="differenceForPathsByNode"
      :differenceForNode="differenceForNode"
    />
  </div>
</template>

<script lang="ts" setup>
import { Handle, Position } from "@vue-flow/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useHasPermission } from "shared/composables/project";
import {
  CriticalPathLayout,
  CriticalPathContext,
  CriticalPathDifferenceForPath,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import CriticalPathGraphNodeDelay from "shared/views/critical_path/components/CriticalPathGraphNodeDelay.vue";
import CriticalPathGraphNodeHover from "shared/views/critical_path/components/CriticalPathGraphNodeHover.vue";
import { calculateWorkingDaysDifferenceWithEvents } from "../criticalPathDifference";

const props = defineProps<{
  criticalPathNode: CriticalPathNodeEx;
  context: CriticalPathContext;
  layout: CriticalPathLayout;
  dimmed: boolean;
  selected: boolean;
  highlightedYellow: boolean;
  highlightedBlue: boolean;
  highlightedPurple: boolean;
  differenceForPathsByNode: Record<string, CriticalPathDifferenceForPath>;
  differenceForPathsByNotFinishedNode: Record<string, CriticalPathDifferenceForPath>;
}>();

const { t } = useI18n();

const isAdmin = useHasPermission(["app_admin", "pct_admin"]);

const backgroundColor = computed(() => {
  if (props.selected) {
    const selectedStateClasses: Record<CriticalPathNodeEx["state"], string> = {
      not_started: "bg-gray-200",
      in_progress: "bg-yellow-600 text-white",
      finished: "bg-green-400 text-white",
    };
    return selectedStateClasses[props.criticalPathNode.state];
  }

  const classes: Record<CriticalPathNodeEx["state"], string> = {
    not_started: "bg-white",
    in_progress: "bg-yellow-400 text-white",
    finished: "bg-green-200 text-white",
  };
  return classes[props.criticalPathNode.state];
});

const tagLetters = computed(() => {
  const letters = props.criticalPathNode.tags.map(
    (tagId) => props.context.criticalPathTagLetterById[tagId],
  );
  letters.sort();
  return letters.join(",");
});

const differenceForNode = computed(() =>
  calculateWorkingDaysDifferenceWithEvents(props.criticalPathNode, props.context),
);

const highlighted = computed(
  () => props.highlightedPurple || props.highlightedBlue || props.highlightedYellow,
);
</script>
