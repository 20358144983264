import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-between px-3" }
const _hoisted_2 = { class: "md:text-lg leading-4 font-semibold text-gray-900" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "mt-4 divide-y" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = { class: "absolute right-0 top-0 pr-2 pt-2 z-10" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["href", "data-pswp-width", "data-pswp-height"]
const _hoisted_9 = ["src", "width", "height"]
const _hoisted_10 = {
  key: 0,
  class: "flex justify-between border border-gray-200 py-1 px-2 sm:text-sm text-xs rounded-b-md"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_14 = { class: "mt-4 flex justify-center" }
const _hoisted_15 = {
  key: 1,
  class: "md:text-center pt-2 pl-3 md:p-0 md:text-base text-sm"
}
const _hoisted_16 = { class: "py-6 grid lg:grid-cols-2 divide-x" }
const _hoisted_17 = { class: "grid-cols-1 p-4" }
const _hoisted_18 = { class: "text-lg leading-4 font-semibold text-gray-900" }
const _hoisted_19 = {
  key: 0,
  class: "flex items-center justify-center h-full"
}
const _hoisted_20 = {
  key: 1,
  class: "flex items-center justify-center h-full"
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "flex justify-between py-1 lg:px-2 px-1 lg:text-sm text-xs flex-wrap" }
const _hoisted_24 = {
  key: 1,
  class: "col-span-3 p-2 text-center rounded border-2 border-gray-200 my-2 text-xs sm:text-base flex items-center justify-center h-32"
}
const _hoisted_25 = { class: "grid-cols-1 p-4" }
const _hoisted_26 = { class: "mt-6 sm:mt-4 sm:flex sm:flex-row-reverse w-full mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PencilIcon = _resolveComponent("PencilIcon")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!
  const _component_masonry_wall = _resolveComponent("masonry-wall")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_GalleryImageUpload = _resolveComponent("GalleryImageUpload")!
  const _component_BasicModal = _resolveComponent("BasicModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editMode ? _ctx.$emit('addHighlight') : '')),
      class: _normalizeClass([
      { 'shadow-lg mix-blend-normal bg-blend-color-dodge': _ctx.editMode },
      'my-4 bg-white shadow sm:rounded-lg md:px-5 px-1 py-6 border',
      { 'border-yellow': _ctx.highlightSection === 'gallery' },
    ])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(`report.section_title.gallery`)), 1),
        (!_ctx.approved)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (!_ctx.editMode)
                ? (_openBlock(), _createBlock(_component_PencilIcon, {
                    key: 0,
                    class: "w-4 h-4 text-oaiGray-300 cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeEditMode()))
                  }))
                : (_openBlock(), _createBlock(_component_PlusIcon, {
                    key: 1,
                    class: "w-5 h-5 text-orange cursor-pointer",
                    onClick: _ctx.openModal
                  }, null, 8, ["onClick"]))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_masonry_wall, {
          items: _ctx.images,
          gap: 16,
          "ssr-columns": 1,
          "column-width": 300,
          class: "gallery-images"
        }, {
          default: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_ctx.editMode)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "rounded-md bg-gray-50 p-1 text-gray-500 hover:text-red-700",
                      onClick: ($event: any) => (_ctx.$emit('removeGalleryImage', item.id))
                    }, [
                      _createVNode(_component_TrashIcon, {
                        class: "lg:h-5 h-4",
                        "aria-hidden": "true"
                      })
                    ], 8, _hoisted_7))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("a", {
                href: item.url,
                target: "_blank",
                rel: "noreferrer",
                "data-pswp-width": item.width,
                "data-pswp-height": item.height,
                style: _normalizeStyle({ aspectRatio: item.width / item.height })
              }, [
                _createElementVNode("img", {
                  src: item.url,
                  alt: "img",
                  width: item.width,
                  height: item.height,
                  class: "rounded-t-md w-full object-cover",
                  style: _normalizeStyle({ aspectRatio: item.width / item.height })
                }, null, 12, _hoisted_9)
              ], 12, _hoisted_8)
            ]),
            (item?.name && !(_ctx.editMode && item.source === 'custom'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("span", null, _toDisplayString(item?.name), 1),
                  (item.timestamp)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.formatDate(item.timestamp)), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (item.source == 'custom' && _ctx.editMode)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": ($event: any) => ((item.name) = $event),
                    type: "text",
                    class: "w-full border-2 border-t-0 border-yellow-300 focus:border-yellow-500 focus:ring-0 focus:border-t-0 sm:text-sm text-xs",
                    placeholder: _ctx.$t('report.sections.gallery.add_note')
                  }, null, 8, _hoisted_13), [
                    [_vModelText, item.name]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["items"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        (!_ctx.approved && (_ctx.editMode || _ctx.images.length === 0))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args))),
              class: "relative inline-flex items-center p-2 shadow-sm border-2 border-yellow rounded-full text-yellow-700 hover:bg-yellow-700 hover:text-white focus:outline-none"
            }, [
              _createVNode(_component_PlusIcon, { class: "w-6" })
            ]))
          : _createCommentVNode("", true),
        (_ctx.approved && _ctx.images.length === 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.$t("report.general.section_empty")), 1))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _createVNode(_component_BasicModal, {
      open: _ctx.open,
      onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closeModal())),
      customClass: "md:w-9/12 h-5/6 overflow-visible"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("h3", _hoisted_18, _toDisplayString(_ctx.$t("report.sections.gallery.oai_images_title")), 1),
            (_ctx.loadingImages)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_component_LoadingSpinner)
                ]))
              : (_ctx.streams.length === 0 && !_ctx.loadingImages)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.$t("err.error_streams_not_found")), 1))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.sortedStreams, (stream, streamIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: streamIndex,
                      class: "grid xl:grid-cols-3 sm:grid-cols-2 gap-4 mx-auto mt-4 mb-6"
                    }, [
                      (_ctx.galleryMap[stream.camera_id])
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.galleryMap[stream.camera_id], (image, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "border rounded-md",
                              onClick: ($event: any) => (_ctx.toggleImageSelection(image))
                            }, [
                              _createElementVNode("div", {
                                class: _normalizeClass([
                  'relative rounded-md  aspect-w-4 aspect-h-3 bg-gray-200',
                  { 'border-2 border-yellow-500': image?.selected },
                ])
                              }, [
                                _createElementVNode("img", {
                                  src: image?.url,
                                  alt: "Image"
                                }, null, 8, _hoisted_22),
                                _createVNode(_component_CheckCircleIcon, {
                                  class: _normalizeClass([
                    !image?.selected ? 'invisible' : '',
                    'h-5 w-5 top-2 left-2 text-yellow-500 absolute',
                  ]),
                                  "aria-hidden": "true"
                                }, null, 8, ["class"]),
                                _createElementVNode("span", {
                                  class: _normalizeClass([
                    image?.selected ? 'border-yellow-500' : 'border-transparent',
                    'pointer-events-none absolute rounded-lg border',
                  ]),
                                  "aria-hidden": "true"
                                }, null, 2)
                              ], 2),
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("span", null, _toDisplayString(image?.name), 1),
                                _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(image.timestamp)), 1)
                              ])
                            ], 8, _hoisted_21))
                          }), 128))
                        : (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.$t("report.sections.gallery.no_images")) + " " + _toDisplayString(stream.name), 1))
                    ]))
                  }), 128))
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_GalleryImageUpload, {
              onAddCustom: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addCustom($event))),
              customImages: _ctx.customImages
            }, null, 8, ["customImages"])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("button", {
            type: "button",
            class: "inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateGalleryImages()))
          }, _toDisplayString(_ctx.$t("buttons.add_images")), 1),
          _createElementVNode("button", {
            type: "button",
            class: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.open = false)),
            ref: "cancelButtonRef"
          }, _toDisplayString(_ctx.$t("buttons.cancel")), 513)
        ])
      ]),
      _: 1
    }, 8, ["open"])
  ], 64))
}