<template>
  <div
    class="flex justify-center items-center group cursor-[col-resize]"
    :style="`position: absolute; bottom: 0; left: ${currentX}px; width: ${width}px; transform: translateX(-50%); z-index: 999999; height: ${
      tableContentHeight ? tableContentHeight + 41 + 'px' : '100%'
    }; top: ${tableContentHeight ? -41 + 'px' : 0}`"
    ref="slider"
  >
    <div class="h-full w-1 transition group-hover:bg-gray-400" />
  </div>
</template>

<script lang="ts" setup>
import { useDraggable } from "@vueuse/core/index";
import { PropType, Ref, ref } from "vue";
import { TableResizeSliderEvent } from "@/types/Tables";

const props = defineProps({
  containerRef: {
    type: Function as PropType<() => Ref<HTMLDivElement | null>>,
    required: true,
  },
  currentX: {
    type: Number,
    required: true,
  },
  width: {
    type: Number,
    required: true,
  },
  tableContentHeight: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(["moved", "started", "ended"]);

const slider = ref<HTMLElement | null>(null);
const initialX = ref(0);

const setBodyUserSelect = (value: string) => {
  window.document.body.style.userSelect = value;
  // for Safari
  window.document.body.style["webkitUserSelect"] = value;
};

useDraggable(slider, {
  axis: "x",
  containerElement: props.containerRef(),
  initialValue: () => ({ x: props.currentX, y: 0 }),
  onStart: (position) => {
    initialX.value = position.x;
    setBodyUserSelect("none");
    emit("started");
  },
  onEnd: () => {
    setBodyUserSelect("auto");
    emit("ended");
  },
  onMove: (position) => {
    const container = props.containerRef().value;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const x = position.x - containerRect.x + initialX.value;
      const event: TableResizeSliderEvent = {
        x,
        containerRect,
      };
      emit("moved", event);
    }
  },
});
</script>
