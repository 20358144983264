<template><slot /></template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps<{
  gridMargin: number;
  columnWidth: number;
  rowHeight: number;
  show: boolean;
}>();

const display = computed(() => (props.show ? "block" : "none"));

const width = computed(() => `calc(100% - ${2 * props.gridMargin}px`);
const height = computed(() => width.value);

const margin = computed(() => `${props.gridMargin}px`);

const backgroundSize = computed(
  () => `${props.columnWidth + props.gridMargin}px ${props.rowHeight + props.gridMargin}px`,
);
const backgroundImage = computed(() => {
  const columnRatio = (props.columnWidth / (props.gridMargin + props.columnWidth)) * 100;
  const rowRatio = (props.rowHeight / (props.gridMargin + props.rowHeight)) * 100;
  const backgroundColor = "#f9fafb";
  return `linear-gradient(to bottom, transparent ${rowRatio}%, ${backgroundColor} ${rowRatio}%), linear-gradient(to right, #e5e7eb ${columnRatio}%, ${backgroundColor} ${columnRatio}%)`;
});
</script>

<style>
.vgl-layout::before {
  display: v-bind("display");
  position: absolute;
  width: v-bind("width");
  height: v-bind("height");
  margin: v-bind("margin");
  content: "";
  background-image: v-bind("backgroundImage");
  background-repeat: repeat;
  background-size: v-bind("backgroundSize");
}
</style>
