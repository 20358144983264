<template>
  <BaseWidget
    :isLoading="isLoading"
    @delete="emit('delete')"
    @trackInteraction="emit('trackInteraction')"
    @trackZoomClicked="trackEvent('progress_detail_click', { origin: 'planner_widget' })"
    hideDragHandle
    :showZoomIcon="true"
  >
    <div class="px-4 py-3 h-full flex flex-col">
      <div class="mb-3 mr-4 flex items-center gap-2">
        <DashboardDragHandle class="shrink-0" />
        <RouterLink
          :to="{
            name: 'PlannerV2',
            params: {
              customer_name: currentCustomerName,
              site_id: currentSiteId,
            },
          }"
          class="font-semibold text-gray-900 truncate hover:text-yellow-800 pr-4"
          :class="width > normalWidgetWidth ? 'text-lg' : 'text-base'"
          @click.prevent="emit('widgetRouteClicked')"
        >
          {{ $t("dashboard.project.planner_card.current_procedures") }}
        </RouterLink>
      </div>
      <ProjectProgressTable
        class="flex-1 overflow-hidden"
        v-if="planConfig && shortenedProcessesWithTags && projectDurationSettings"
        :planConfig="planConfig"
        :processes="shortenedProcessesWithTags"
        :projectDurationSettings="projectDurationSettings"
        :hierarchyTags="hierarchyTags"
        :simplified="true"
        :showOnlyActive="true"
        :hideCheckIconWhenCompleted="width < normalWidgetWidth"
      />
    </div>
    <template #zoomedView>
      <ProjectProgressTable
        class="h-full"
        v-if="planConfig && shortenedProcessesWithTags && projectDurationSettings"
        :planConfig="planConfig"
        :processes="shortenedProcessesWithTags"
        :projectDurationSettings="projectDurationSettings"
        :hierarchyTags="hierarchyTags"
        :showEvenWhenCompleted="true"
      />
    </template>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { usePlanConfig } from "shared/composables/planner";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import DashboardDragHandle from "@/views/dashboard/componentsV2/DashboardDragHandle.vue";
import { normalWidgetWidth } from "@/views/dashboard/composables";
import ProjectProgressTable from "@/views/planner/components/ProjectProgressTable.vue";

defineProps<{ width: number; height: number }>();
const emit = defineEmits(["delete", "widgetRouteClicked", "trackInteraction"]);

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const trackEvent = useTrackEvent();

const { planConfig, isLoading: isPlanConfigLoading } = usePlanConfig();
const { shortenedProcessesWithTags, isLoading: areShortenedProcessesWithTagsLoading } =
  useShortenedProcessesWithTags();
const { projectDurationSettings, isProjectDurationSettingsLoading } = useProjectDurationSettings();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();

const isLoading = computed(
  () =>
    isPlanConfigLoading.value ||
    areShortenedProcessesWithTagsLoading.value ||
    isProjectDurationSettingsLoading.value ||
    areHierarchyTagsLoading.value,
);
</script>
