<template>
  <div class="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" :src="logoMultipleNoPaddingSrc" alt="Workflow" />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
        <Form
          @submit="handleSubmitLogIn"
          :initialValues="{ email: '', password: '' }"
          :validationSchema="loginSchema"
          class="flex flex-col gap-4 w-full"
        >
          <InputField name="email" :label="$t('authentication.login.email')" />
          <PasswordField name="password" :label="$t('authentication.login.password')" />
          <div class="rounded-md bg-red-50 p-4 my-2" v-if="passwordAlert">
            <div class="flex">
              <div class="shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  {{ $t("authentication.login.wrong_email_or_password") }}
                </h3>
              </div>
            </div>
          </div>
          <div class="text-sm text-yellow-900 text-right">
            <router-link to="/forgot-password" class="font-sm hover:text-yellow-700">
              {{ $t("authentication.login.forgot_password") }}
            </router-link>
          </div>

          <button
            type="submit"
            :disabled="isLoading"
            class="my-4 focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
          >
            <div v-if="isLoading">
              <LoadingSpinner size="w-5 h-5" color="white" />
            </div>
            <div v-else>{{ $t("authentication.login.sign_in") }}</div>
          </button>
        </Form>

        <div class="flex items-center gap-3 text-sm text-gray-400">
          <div class="border-t flex-1" />
          {{ $t("authentication.login.or") }}
          <div class="border-t flex-1" />
        </div>

        <div class="rounded-md bg-red-50 p-4" v-if="socialPasswordAlert">
          <div class="flex">
            <div class="shrink-0">
              <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                {{ $t("authentication.login.social_error") }}
              </h3>
            </div>
          </div>
        </div>

        <button
          class="border mt-4 px-5 py-2 rounded-lg flex gap-3 w-full justify-center"
          type="button"
          style="font-family: Montserrat-SemiBold, system-ui"
          @click="triggerMicrosoftLogin"
        >
          <img
            alt="microsoft logo"
            src="data:image/svg+xml,%3csvg%20width='21'%20height='21'%20viewBox='0%200%2021%2021'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20x='11.375'%20y='11.375'%20width='8.75'%20height='8.75'%20fill='%23FEBA08'/%3e%3crect%20x='0.875'%20y='11.375'%20width='8.75'%20height='8.75'%20fill='%2305A6F0'/%3e%3crect%20x='11.375'%20y='0.875'%20width='8.75'%20height='8.75'%20fill='%2380BC06'/%3e%3crect%20x='0.875'%20y='0.875'%20width='8.75'%20height='8.75'%20fill='%23F25325'/%3e%3c/svg%3e"
          /><span>{{ $t("authentication.login.sign_in_with_microsoft") }}</span>
        </button>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script lang="ts" setup>
import { XCircleIcon } from "@heroicons/vue/24/solid";
import { AxiosError } from "axios";
import { Form, SubmissionHandler } from "vee-validate";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import * as yup from "yup";
import logoMultipleNoPaddingSrc from "shared/assets/imgs/logo/logo-multiple-no-padding.svg";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useTrackEvent } from "shared/composables/tracking";
import logger from "shared/services/logger";
import InputField from "@/components/forms/InputField.vue";
import PasswordField from "@/components/forms/PasswordField.vue";
import {
  useSignIn,
  useMicrosoftSignIn,
  useLoadUserData,
  useGetReturnUrl,
} from "@/composables/auth";
import Footer from "@/views/auth/components/Footer.vue";

const { t } = useI18n();

const passwordAlert = ref(false);
const socialPasswordAlert = ref(false);
const isLoading = ref(false);
const router = useRouter();
const trackEvent = useTrackEvent();

const signIn = useSignIn();
const microsoftSignIn = useMicrosoftSignIn();
const loadUserData = useLoadUserData();
const getReturnUrl = useGetReturnUrl();

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email(t("authentication.validation.validation_email_invalid"))
    .required(t("authentication.validation.validation_field_required")),

  password: yup.string().required(t("authentication.validation.validation_field_required")),
});

const handleSubmitLogIn: SubmissionHandler = async (genericObject) => {
  isLoading.value = true;
  passwordAlert.value = false;

  try {
    await signIn(genericObject.email, genericObject.password);
    const returnUrl = getReturnUrl();
    router.push(returnUrl);
  } catch (error) {
    if (error instanceof AxiosError) {
      const is401 = error?.response?.status === 401;
      const is403 = error?.response?.status === 403;
      if (
        error.message !== "Incorrect username or password." &&
        error.message !== "Network error" &&
        !is403 &&
        !is401
      ) {
        logger.error("Unable to sign in", error);
      }
    }

    passwordAlert.value = true;
  } finally {
    isLoading.value = false;
  }
};

const triggerMicrosoftLogin = async () => {
  isLoading.value = true;
  passwordAlert.value = false;
  socialPasswordAlert.value = false;
  trackEvent("user_sso_microsoft_click");

  try {
    await microsoftSignIn();
    await loadUserData();
    trackEvent("user_sso_microsoft_success");
    router.push(getReturnUrl());
  } catch (error) {
    if (error !== "No current user") {
      logger.error(error);
    }
    if (error instanceof AxiosError) {
      if (
        error?.message !== "user_cancelled: User cancelled the flow." &&
        !error?.message.includes("interaction_in_progress")
      ) {
        logger.error(error);
      }
    }
    socialPasswordAlert.value = true;
  } finally {
    isLoading.value = false;
  }
};
</script>
