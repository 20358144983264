<template>
  <Sidebar>
    <div class="flex flex-col gap-4" style="height: 100vh">
      <PageHeader
        ><div class="pl-4 md:pl-6">
          {{ $t("analytics.processes.process_data_gantt") }}
        </div>

        <template #content>
          <div
            class="flex min-h-9 items-center right-0 gap-2 justify-between lg:w-auto pr-3 lg:pr-3"
            v-if="!noData"
          >
            <div class="flex items-center">
              <button
                @click="zoomOut()"
                type="button"
                class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-500 hover:text-yellow focus:z-10"
              >
                <MinusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <input
                type="range"
                min="0"
                max="7"
                class="h-4 w-30 rounded bg-yellow-200 focus:bg-gray-300 hidden sm:block"
                :value="presetIndex"
                @input="handleRangeChange"
              />
              <button
                @click="zoomIn()"
                type="button"
                class="relative -ml-px inline-flex items-center rounded-r-md px-2 py-2 text-gray-500 hover:text-yellow focus:z-10"
              >
                <PlusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>

            <Menu as="div" class="inline-block text-left leading-none relative">
              <MenuButton class="flex items-center rounded-full text-gray-500 hover:text-gray-600">
                <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-5 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray/5 focus:outline-none"
                >
                  <div class="py-1 divide-y">
                    <MenuItem as="template" v-slot="{ active }">
                      <Menu
                        as="div"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'extraMenuItem',
                        ]"
                      >
                        <MenuButton
                          class="flex items-center w-full rounded-full gap-2"
                          @click="exportPdf()"
                        >
                          <FontAwesomeIcon icon="fa-solid fa-file-pdf" class="h-4 text-red" />
                          <span>{{ t("buttons.export_plain") }} PDF</span>
                        </MenuButton>
                      </Menu>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <button
                        @click="schedulerInstance && toggleOutages(schedulerInstance)"
                        type="button"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'extraMenuItem',
                        ]"
                      >
                        <input
                          type="checkbox"
                          :checked="areOutagesShown"
                          class="h-4 w-4 rounded border-gray-300 text-orange-400 focus:bg-gray-50 pointer-events-none mr-2"
                        />
                        <span> {{ $t("analytics.planner.outages") }} </span>
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <button
                        @click="isShortcutsModalOpen = true"
                        type="button"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'extraMenuItem',
                        ]"
                      >
                        <CodeBracketSquareIcon class="h-4 w-4 mr-2" />
                        <span> {{ $t("analytics.processes.shortcuts.title") }} </span>
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </template>
      </PageHeader>
      <NoDataYet v-if="noData" desc="analytics.processes.no_data" />
      <div class="2xl:flex lg:justify-between items-center" v-show="!noData">
        <div class="lg:flex md:px-6 px-4">
          <SelectList
            :options="displayTypes"
            :defaultSelected="displayType"
            @update:selected="handleDisplayTypeChange"
            class="w-full lg:w-60 z-20"
          />
          <div v-if="displayType !== 'process'" class="lg:flex lg:items-center lg:ml-5 gap-5">
            <ProcessTypesFilter
              class="xl:w-72 lg:w-60 mt-2 lg:mt-0"
              multiple
              @update:selected="handleProcessFilter"
              @userInput="trackEvent('process-gantt_filter_apply', { type: 'process' })"
              :selected="{
                processTypes: Object.entries(filterProcesses.processTypes)
                  .filter(([, value]) => value)
                  .map(([key]) => key),
                processes: Object.entries(filterProcesses.processes)
                  .filter(([, value]) => value)
                  .map(([key]) => key),
              }"
              :groups="[
                {
                  name: t('analytics.processes.process_group'),
                  field: 'processTypes',
                  options: processTypes,
                },
                {
                  name: t('analytics.processes.processes'),
                  field: 'processes',
                  options: Object.entries(filterProcesses.processes).map(([key]) => ({
                    name: t(`process_classes.${key}`),
                    value: key,
                  })),
                },
              ]"
              :dependencies="{
                processTypes: {
                  to: 'processes',
                  defineDependencies: (options: string[], value: string) => {
                    return options.filter((item) => {
                      return getProcessFromProcessId[item].processElement === value;
                    });
                  },
                },
              }"
              placeholder="analytics.processes.placeholder_process_type"
            />
            <LocationFilter
              class="xl:w-72 lg:w-60 mt-2 lg:mt-0"
              @change="handleLocationFilterChange"
              @userInput="trackEvent('process-gantt_filter_apply', { type: 'location' })"
              :selected="filterResources"
              :hierarchyTags="hierarchyTagsForTagGroups"
              :placeholder="t('analytics.processes.placeholder_hierarchy_tag')"
              :useNameAsId="true"
            />
            <p
              class="underline text-sm text-gray-600 cursor-pointer"
              @click="clearFiltersInternalAndInScheduler"
              v-if="
                getSchedulerInstanceFilterValue() ||
                Object.values(filterProcesses).some((subFilters) =>
                  Object.values(subFilters).some((value) => value),
                ) ||
                filterResources.length > 0
              "
            >
              {{ t("analytics.processes.clear_filters") }}
            </p>
          </div>
        </div>
        <ProcessColorsLegend v-show="!noData" />
      </div>

      <div
        class="flex gap-2 flex-1 overflow-hidden process-gantt"
        :class="{ 'gantt-event-selected': selectedEvents.length > 0 }"
      >
        <BryntumSchedulerPro
          :key="displayType"
          v-show="!noData"
          v-bind="{ ...schedulerProConfig, ...schedulerSelectionConfig, ...schedulerTooltipConfig }"
          ref="schedulerProRef"
          :resources="schedulerResources"
          :events="schedulerEvents"
          @eventClick="handleEventClick"
          @eventSelectionChange="handleEventSelection"
          @timeAxisChange="updatePresetIndex"
        />
        <div v-if="loading" class="absolute inset-0 bg-white opacity-60" style="z-index: 9998" />
        <div
          v-if="loading"
          class="absolute inset-0 flex items-center justify-center"
          style="z-index: 9999"
        >
          <LoadingSection :loading="loading" />
        </div>
        <ProcessSideBar
          v-show="!noData"
          :open="isProcessSideBarOpen"
          @closed="isProcessSideBarOpen = false"
          :processes="processSideBarProcesses"
          :resourceFullName="processSideBarResourceFullName"
          @processUpdate="handleProcessUpdate"
        />
        <ProcessesAnalysis
          v-if="showProcessesAnalysis"
          :processes="selectedEvents.flatMap((e) => e.processes)"
          :start="processesStartDate"
          :end="processesEndDate"
          @close="handleCloseProcessesAnalysis"
        />
        <ShortcutsModal :open="isShortcutsModalOpen" @close="isShortcutsModalOpen = false" />
      </div>
    </div>
  </Sidebar>
</template>

<script lang="tsx" setup>
import {
  EventModel,
  Column,
  SchedulerEventModel,
  SchedulerPro,
  Store,
  ViewPreset,
  ViewPresetConfig,
} from "@bryntum/schedulerpro";
import { BryntumSchedulerPro } from "@bryntum/schedulerpro-vue-3";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  EllipsisVerticalIcon,
  MinusIcon,
  PlusIcon,
  CodeBracketSquareIcon,
} from "@heroicons/vue/24/outline";
import { addDays, addWeeks, format, subWeeks } from "date-fns";
import { computed, nextTick, onMounted, Ref, ref, watch } from "vue";
import { onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import SelectList from "shared/components/other/OaiListbox.vue";
import { useFilterByLocation, useHierarchyTags } from "shared/composables/hierarchyTags";
import { useHolidaysInScheduler, useNonWorkingDaysInScheduler } from "shared/composables/planner";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import SchedulerProMixins from "shared/mixins/SchedulerProMixins";
import { apiClient } from "shared/repositories/clients";
import logger from "shared/services/logger";
import { SimplifiedPlannerProcess } from "shared/types/Plan";
import { ShortenedProcess } from "shared/types/Process";
import { ProcessClass } from "shared/types/ProcessClass";
import { SchedulerProBaseEvent } from "shared/types/SchedulerPro";
import { Stream } from "shared/types/Stream";
import NoDataYet from "@/components/layout/NoDataYet.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import LocationFilter from "@/components/process_filters/LocationFilter.vue";
import ProcessTypesFilter from "@/components/process_filters/ProcessTypesFilter.vue";
import { useOutages } from "@/composables/process";
import { useProcessClasses } from "@/composables/process";
import { useStreams } from "@/composables/stream";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import { getConfig } from "@/services/config";
import { TagGroupedProcess } from "@/types/Process";
import ProcessSideBar from "@/views/planner/components/ProcessSideBar.vue";
import ProcessColorsLegend from "@/views/process_gantt/components/ProcessColorsLegend.vue";
import ShortcutsModal from "@/views/process_gantt/components/ShortcutsModal.vue";
import {
  getSchedulerTooltipConfig,
  getSchedulerProConfig,
  ProcessEventModel,
  ProcessResourceModel,
  useSchedulerSelection,
} from "./SchedulerProConfig";
import ProcessesAnalysis from "./components/ProcessesAnalysis.vue";

const { t } = useI18n();
const { query } = useRoute();
const router = useRouter();
const route = useRoute();
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const processClasses = useProcessClasses();

const { streams } = useStreams();

const methods = SchedulerProMixins.methods as NonNullable<typeof SchedulerProMixins.methods>;
const noData = ref(false) as Ref<boolean>;
const isOperationLoading = ref(false);
let schedulerEvents = [] as ProcessEventModel[];
const schedulerResources = ref<(ProcessResourceModel & Record<string, unknown>)[]>([]);
const filterProcesses = ref({
  processTypes: {} as Record<string, boolean>,
  processes: {} as Record<string, boolean>,
});
const processesStartDate = ref<Date>(new Date());
const processesEndDate = ref<Date>(new Date(0));
const isShortcutsModalOpen = ref(false);

const displayTypes = [
  { name: t("analytics.processes.component_view"), value: "component" },
  { name: t("analytics.processes.processes_view"), value: "process" },
  { name: t("analytics.processes.section_view"), value: "section" },
  { name: t("analytics.processes.level_view"), value: "level" },
];

const schedulerProConfig = computed(() => getSchedulerProConfig());

const getHolidayFn = computed(() => createGetHolidayFn());

const {
  lightenProcessEventColors,
  schedulerSelectionConfig,
  mountSelection,
  unmountSelection,
  removeSelectionBox,
  updateSelectionBoxPosition,
} = useSchedulerSelection();
const schedulerTooltipConfig = getSchedulerTooltipConfig(t);
const trackEvent = useTrackEvent();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const createIsMatchingLocationFn = useFilterByLocation(hierarchyTags);

const loading = computed(() => isOperationLoading.value || areHierarchyTagsLoading.value);

let schedulerInstance: SchedulerPro;
const schedulerProRef = ref<{ instance: { value: SchedulerPro } }>(
  {} as { instance: { value: SchedulerPro } },
);

const openProcessFromUrl = () => {
  setTimeout(() => {
    const event = schedulerInstance.eventStore.allRecords.find((event) =>
      event
        .getData("processes")
        .find((process: ShortenedProcess) => process._id === (query.processId as string)),
    ) as EventModel;
    if (event) {
      schedulerInstance.scrollResourceEventIntoView(event.resource, event, { block: "center" });
      openProcessSidebar(event);
    }
  }, 200);
};

onMounted(() => {
  const dataFromQueryStrings = getQueryStringsOnMounted();

  loadProcesses().then(() => {
    if (query.processId) {
      openProcessFromUrl();
    } else {
      if (dataFromQueryStrings.view) {
        displayType.value = dataFromQueryStrings.view as DisplayType;
      } else {
        trackEvent("process-gantt_view", { type: displayType.value });
      }
      if (
        dataFromQueryStrings?.processes.length > 0 ||
        dataFromQueryStrings?.processTypes.length > 0
      ) {
        handleProcessFilter({
          processTypes: dataFromQueryStrings?.processTypes,
          processes: dataFromQueryStrings?.processes,
        });
      }
      if (dataFromQueryStrings.location.length > 0) {
        handleResourcesFilter(dataFromQueryStrings.location);
      }
    }
  });

  setTimeout(() => {
    if (schedulerInstance) {
      mountSelection(() => schedulerInstance, trackEvent);
      refreshHolidaysInScheduler(schedulerInstance);
      refreshNonWorkingDaysInScheduler(schedulerInstance);
    }
  }, 100);
});

onUnmounted(() => {
  unmountSelection(() => schedulerInstance);
});

watch(schedulerProRef, (newValue) => {
  if (newValue?.instance?.value) {
    schedulerInstance = newValue.instance.value;
  }
});

const streamsByCameraId = computed(() =>
  streams.value.reduce((acc, stream) => {
    acc[stream.camera_id] = stream;
    return acc;
  }, {} as Record<string, Stream>),
);

const createProcessEventsForResource = (resourceId: string, processes: ShortenedProcess[]) =>
  methods
    .createProcessEventsForResource(
      schedulerInstance,
      resourceId,
      processes as SimplifiedPlannerProcess[],
    )
    .map((event) => ({
      ...event,
      stream: streamsByCameraId.value[processes[0].camera_id],
    }));

const createProcessEventWithName = (
  resourceId: string,
  processes: ShortenedProcess[],
  name: string,
) =>
  createProcessEventsForResource(resourceId, processes).map((event) => ({
    ...event,
    name,
  }));

const hierarchyTagsForTagGroups = computed(() => {
  if (displayType.value === "section") {
    return hierarchyTags.value.filter((tag) => tag.type !== "level");
  }
  if (displayType.value === "level") {
    return hierarchyTags.value.filter((tag) => tag.type !== "section");
  }
  return hierarchyTags.value;
});

const processTypes = ref<{ name: string; value: string }[]>([]);
const processes = ref<TagGroupedProcess[]>([]);

const resetView = async (scrollState: object | null = null) => {
  if (!noData.value) {
    const ID = 2;
    const { timeRangeStore } = schedulerInstance.project;
    const projectStartTimeRange = timeRangeStore.getById(ID);
    const name = `${t(`time.start_gantt`)} ${format(processesStartDate.value, "dd.MM.yyyy")}`;
    if (projectStartTimeRange) {
      projectStartTimeRange.set("name", name);
      projectStartTimeRange.set("startDate", processesStartDate.value);
    } else {
      timeRangeStore.add({
        id: ID,
        name,
        startDate: processesStartDate.value,
      });
    }
  }

  if (scrollState) {
    await methods.restoreView(schedulerInstance, presetIndex.value, scrollState);
  } else {
    methods.resetZoomLevel(schedulerInstance, schedulerProConfig.value.viewPreset as string);
  }
  await methods.resetTimespan(schedulerInstance);
  scrollToMostRecentProcess(schedulerInstance);
};

watch(processes, async (_, oldValue: TagGroupedProcess[] | null) => {
  let lastDate = new Date(0);
  let startDate = new Date();

  if (processes.value?.length) {
    const eventElements = new Set<string>();

    processes.value.forEach((item) => {
      const processEvents = createProcessEventWithName(
        item._id,
        item.processes,
        getTagName(item.tags),
      );

      processEvents.forEach((event) => {
        eventElements.add(event.processElement);
      });

      item.tags = Object.fromEntries(
        Object.entries({
          building: item.tags.building,
          level: item.tags.level,
          section: item.tags.section,
          component: item.tags.component,
        }).filter(([, value]) => value),
      ) as typeof item.tags;

      item.processes.forEach((process) => {
        if (process.end_time > lastDate) {
          lastDate = process.end_time;
        }
        if (process.start_time < startDate) {
          startDate = process.start_time;
        }
      });
    });

    const processCategories = getProcessCategories();
    const mappedProcessTypes: { name: string; value: string }[] = Array.from(eventElements).map(
      (element) => ({
        name: t(`process_classes.${element}`),
        value: element,
      }),
    );

    filterProcesses.value = {
      processTypes: Object.fromEntries(mappedProcessTypes.map(({ value }) => [value, false])),
      processes: Object.fromEntries(processCategories.map(({ value }) => [value, false])),
    };

    filterResources.value = [];

    processTypes.value = mappedProcessTypes;
    processesStartDate.value = startDate;
    processesEndDate.value = lastDate;
  }

  handleDisplayTypeChange(displayType.value, false);

  const scrollState = schedulerInstance.storeScroll();

  nextTick(() => resetView(oldValue?.length ? scrollState : null));
});

const presetIndex = ref(0);
const levelOrder = ref<Record<string, number>>({});
const updatePresetIndex = () => {
  const viewPresetName = (schedulerInstance.viewPreset as ViewPreset)?.id;

  if (!viewPresetName) {
    return;
  }

  presetIndex.value = [...(schedulerInstance.presets as ViewPresetConfig[])].findIndex(
    (preset) => preset.id === viewPresetName,
  );

  nextTick(() => {
    updateSelectionBoxPosition(schedulerInstance, selectedEventsInArea.value);
  });
};

const loadProcesses = async () => {
  isOperationLoading.value = true;

  try {
    const response = await OpsProcessesRepository.loadTagGroupedProcesses(customerName, siteId);
    noData.value = response.data.length === 0;
    processes.value = response.data;
    levelOrder.value = response.levelOrders;
  } catch (e) {
    logger.error(e);
    noData.value = true;
  } finally {
    isOperationLoading.value = false;
  }
};

const handleRangeChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const newPresetsValue = parseInt(target.value, 10);

  if (newPresetsValue > presetIndex.value) {
    zoomIn();
  } else if (newPresetsValue < presetIndex.value) {
    zoomOut();
  }
};

const zoomIn = () => {
  schedulerInstance.zoomIn();
};

const zoomOut = () => {
  if (presetIndex.value > 0) {
    schedulerInstance.zoomOut();
  }
};

const hideColumn = (column: string) => {
  const columns = schedulerProConfig.value.columns as Partial<Column>[];
  columns.forEach((item) => {
    item.hidden = item.field === column;
  });

  schedulerInstance.setConfig({ columns });
};

const isProcessSideBarOpen = ref(false);
const processSideBarResourceFullName = ref("");
const processSideBarProcesses = ref<(SimplifiedPlannerProcess & { name?: string })[]>([]);

const openProcessSidebar = (eventRecord: EventModel) => {
  processSideBarProcesses.value = eventRecord.getData("processes");
  processSideBarResourceFullName.value = methods.getResourceFullName(eventRecord.resource);
  isProcessSideBarOpen.value = true;
};

const handleProcessUpdate = async (
  process: SimplifiedPlannerProcess & {
    name?: string;
    section_mask_mapping: Record<string, string>;
    planner_item_mapping: Record<string, string>;
  },
) => {
  await loadProcesses();

  nextTick(() => {
    const event = schedulerInstance.eventStore.allRecords.find((event) =>
      event.getData("processes").find((p: ShortenedProcess) => p._id === process._id),
    ) as EventModel;

    if (event) {
      schedulerInstance.scrollResourceEventIntoView(event.resource, event, { block: "center" });

      openProcessSidebar(event);
    }
  });
};

const handleEventClick = (e: SchedulerProBaseEvent & { event: PointerEvent }) => {
  const isCtrlClicked = e.event.ctrlKey || e.event.metaKey;

  if (isCtrlClicked) {
    trackEvent("process-gantt_selector-cmd_apply");

    const isEventSelected = selectedEvents.value.some((event) => event.id === e.eventRecord.id);

    if (isEventSelected) {
      const selection = selectedEvents.value.filter((event) => event.id !== e.eventRecord.id);
      schedulerInstance.trigger("eventSelectionChange", {
        selection: selection,
        deselected: [e.eventRecord],
        selected: [],
        action: "deselect",
      });
      schedulerInstance.deselectEvent(e.eventRecord);
    } else {
      schedulerInstance.trigger("eventSelectionChange", {
        selection: [...selectedEvents.value, e.eventRecord],
        selected: [e.eventRecord],
        deselected: [],
        action: "select",
      });
      schedulerInstance.selectEvent(e.eventRecord);
    }

    return;
  }

  if (e.eventRecord.getData("type")?.startsWith("process_")) {
    trackEvent("process-gantt_process_click");
    openProcessSidebar(e.eventRecord);
  }
};

const createGetHolidayFn = () => {
  const getProjectDurationSettings = () => projectDurationSettings.value;
  return (date: Date) => {
    const durationSettings = getProjectDurationSettings();
    if (!durationSettings) {
      return undefined;
    }
    return durationSettings.non_working_days.find((non_working_day) => {
      const start = non_working_day.start_date;
      const end = addDays(non_working_day.end_date, 1);
      return date >= start && date < end;
    });
  };
};

const getProcessFromProcessId = processClasses.value.reduce(
  (acc, item) => ({ ...acc, [item.encodedLabel]: item }),
  {} as Record<string, ProcessClass>,
);

const getProcessCategories = () => {
  const categories = new Set<number>();
  processes.value.forEach((process) => {
    process.processes.forEach((item) => {
      categories.add(item.encoded_label);
    });
  });

  return Array.from(categories)
    .sort((a, b) => {
      const aName = getProcessFromProcessId[a].processElement;
      const bName = getProcessFromProcessId[b].processElement;
      return aName.localeCompare(bName);
    })
    .map((category) => ({
      name: t(`process_classes.${category}`),
      value: category,
    }));
};

const handleProcessFilter = (
  value: Record<"processTypes" | "processes", string[] | Record<string, boolean>>,
  events?: ProcessEventModel[],
) => {
  let filteredEvents = events || schedulerEvents;

  const valuesList = Object.fromEntries(
    Object.entries(value).map(([key, value]) => [
      key,
      Array.isArray(value) ? value : Object.entries(value).filter(([, value]) => value),
    ]),
  ) as Record<keyof typeof value, string[]>;
  const filterProcessEntries = Object.entries(filterProcesses.value.processes).filter(
    ([, value]) => value,
  );
  const filterProcessTypesEntries = Object.entries(filterProcesses.value.processTypes).filter(
    ([, value]) => value,
  );
  if (valuesList.processes.length !== filterProcessEntries.length) {
    let diff: string;
    // remove filter
    if (valuesList.processes.length > filterProcessEntries.length) {
      diff = valuesList.processes.filter((item) => !filterProcesses.value.processes[item])[0];
      const processElement = getProcessFromProcessId[diff].processElement;
      if (!(value.processTypes as string[]).includes(processElement)) {
        (value.processTypes as string[]).push(processElement);
      }
    } else {
      const mappedProcesses = Object.fromEntries(valuesList.processes.map((item) => [item, true]));
      diff = filterProcessEntries
        .filter((item) => !mappedProcesses[item[0]])
        .map((item) => item[0])[0];
      const processElement = getProcessFromProcessId[diff].processElement;
      const allItemsUnselected = processClasses.value
        .filter(
          (item) =>
            item.processElement === processElement &&
            filterProcesses.value.processes[item.encodedLabel] !== undefined,
        )
        .some(
          (item) =>
            filterProcesses.value.processes[item.encodedLabel] &&
            item.encodedLabel.toString() !== diff,
        );
      if (!allItemsUnselected) {
        (value.processTypes as string[]).splice(
          (value.processTypes as string[]).indexOf(processElement),
          1,
        );
      }
    }
  } else if (valuesList.processTypes.length !== filterProcessTypesEntries.length) {
    // remove filter
    if (valuesList.processTypes.length > filterProcessTypesEntries.length) {
      const diff = valuesList.processTypes.find(
        (item) => !filterProcessTypesEntries.map((item) => item[0]).includes(item),
      );
      processClasses.value.forEach((item) => {
        if (item.processElement === diff && !filterProcesses.value.processes[item.encodedLabel]) {
          (value.processes as string[]).push(item.encodedLabel.toString());
        }
      });
      // add filter
    } else {
      const mappedProcessTypes = Object.fromEntries(
        valuesList.processTypes.map((item) => [item, true]),
      );
      const diff = filterProcessTypesEntries
        .filter((item) => !mappedProcessTypes[item[0]])
        .map((item) => item[0])[0];
      value.processes = (value.processes as string[]).filter(
        (item) => getProcessFromProcessId[item].processElement !== diff,
      );
    }
  }

  if (value.processes) {
    filteredEvents = filterProcessClasses(value.processes, events);
  }
  if (value.processTypes) {
    filteredEvents = filterProcessTypes(value.processTypes, filteredEvents);
  }

  nextTick(() => (schedulerInstance.eventStore.data = filteredEvents));
};

const filterResources = ref<string[]>([]);

const handleResourcesFilter = (selectedTagIds: string[], events?: ProcessEventModel[]) => {
  if (!schedulerInstance || !schedulerInstance.resourceStore || !schedulerInstance.eventStore) {
    return;
  }
  filterResources.value = selectedTagIds;
  const isMatchingLocation = createIsMatchingLocationFn(selectedTagIds);

  const matchingResourceIds: string[] = schedulerResources.value
    .filter((res) => {
      const processTagIds = (res.component_ids || {}) as NonNullable<
        ProcessResourceModel["component_ids"]
      >;
      return isMatchingLocation(processTagIds) && res.name;
    })
    .map((res) => res.id?.toString() as string);
  const matchingResourceIdsSet = new Set(matchingResourceIds);

  schedulerInstance.resourceStore.clearFilters();

  nextTick(() => {
    if (events) {
      schedulerInstance.eventStore.data = events.filter((event) =>
        matchingResourceIdsSet.has(event.resourceId?.toString() as string),
      );
    }
    schedulerInstance.resourceStore.filter((record: ProcessResourceModel) =>
      matchingResourceIdsSet.has(record.id?.toString() as string),
    );
  });
};

const handleLocationFilterChange = (payload: string[]) => {
  handleResourcesFilter(payload);
};

const filterProcessTypes = (
  value: string[] | Record<string, boolean>,
  events = schedulerEvents,
) => {
  let mappedValues = value as Record<string, boolean>;
  if (Array.isArray(value)) {
    mappedValues = Object.fromEntries(value.map((element) => [element, true]));
  }

  let filteredEvents = events;
  if (Object.keys(mappedValues).length) {
    filteredEvents = events.filter((event) => {
      return mappedValues[event.processElement];
    });
  }

  filterProcesses.value.processTypes = Object.fromEntries(
    Object.entries(filterProcesses.value.processTypes).map(([element]) => [
      element,
      !!mappedValues[element],
    ]),
  );

  return filteredEvents;
};

const filterProcessClasses = (
  value: string[] | Record<string, boolean>,
  events = schedulerEvents,
) => {
  let mappedValues = value as Record<string, boolean>;
  if (Array.isArray(value)) {
    mappedValues = Object.fromEntries(value.map((element) => [element, true]));
  }

  let filteredEvents = events;
  if (Object.keys(mappedValues).length) {
    filteredEvents = events
      .map((event) => {
        const processes = event.processes.filter((process) => mappedValues[process.encoded_label]);
        return {
          ...event,
          processes,
        };
      })
      .filter((event) => event.processes.length);
  }

  filterProcesses.value.processes = Object.fromEntries(
    Object.entries(filterProcesses.value.processes).map(([element]) => [
      element,
      !!mappedValues[element],
    ]),
  );

  return filteredEvents;
};

// the only way, how it is possible to clean search filter
const cleanSearchFilter = async () => {
  const input = document.querySelector(".plannerColFilter input") as HTMLInputElement;
  input.value = "";
  input.dispatchEvent(new Event("input"));

  await (schedulerInstance.store as Store).clearFilters();
};

const clearFilters = () => {
  Object.values(filterProcesses.value).forEach((filter) => {
    Object.keys(filter).forEach((key) => {
      filter[key] = false;
    });
  });

  filterResources.value = [];
  setTimeout(() => {
    router.replace({
      query: {
        ...route.query,
        processes: undefined,
        processTypes: undefined,
        location: undefined,
      },
    });
  }, 100);
};

const clearFiltersInternalAndInScheduler = async () => {
  await cleanSearchFilter();
  clearFilters();
  schedulerInstance.eventStore.data = schedulerEvents;
};

const getTagName = (tags: Partial<TagGroupedProcess["tags"]>) => {
  return [tags.building, tags.level, tags.section, tags.component].filter(Boolean).join(" | ");
};

type DisplayType = "process" | "component" | "section" | "level";

const displayType = ref<DisplayType>((route.query.view as DisplayType) || displayTypes[0].value);

const handleDisplayTypeChange = (
  value: "process" | "component" | "section" | "level",
  doClearFilters = true,
) => {
  displayType.value = value;
  areOutagesShown.value = false;
  handleCloseProcessesAnalysis();

  if (doClearFilters) {
    clearFilters();
  }

  const displayFns: Record<typeof value, () => void> = {
    process: displayByProcesses,
    component: displayByTags,
    section: displayBySection,
    level: displayByLevel,
  };

  displayFns[value]();

  nextTick(() => {
    resetView();
    router.replace({
      query: {
        ...route.query,
        view: displayType.value,
      },
    });
  });
  trackEvent("process-gantt_view", { type: value });
};

const displayByLevel = () => {
  const resources: ProcessResourceModel[] = [];
  const eventGroups: (TagGroupedProcess["processes"][number] & {
    name: string;
    tags: TagGroupedProcess["tags"];
  })[][] = [];
  const events: ProcessEventModel[] = [];
  const pointers = {} as Record<string, Record<string, number>>;
  let index = 0;

  const processesV2 = structuredClone(processes.value);
  processesV2.sort((recordA, recordB) => {
    return (
      (levelOrder.value[recordA?.tags?.level ?? ""] || 0) -
      (levelOrder.value[recordB?.tags?.level ?? ""] || 0)
    );
  });

  processesV2.forEach((process) => {
    if (!pointers[process.tags.level]) {
      pointers[process.tags.level] = {};
    }

    process.processes.forEach((subProcess) => {
      let currentIndex = pointers[process.tags.level][process.tags.component];
      if (currentIndex === undefined) {
        currentIndex = index++;
        pointers[process.tags.level][process.tags.component] = currentIndex;
        eventGroups.push([]);
        const tags = {
          ...(process.tags.building ? { building: process.tags.building } : {}),
          level: process.tags.level,
          component: process.tags.component,
        };

        resources.push({
          index: currentIndex,
          id: currentIndex,
          cls: "",
          name: getTagName(tags),
          component: tags as TagGroupedProcess["tags"],
          component_ids: process.tag_ids,
          getHolidayFn: getHolidayFn.value,
        });
      }
      eventGroups[currentIndex].push({
        ...subProcess,
        name: getTagName(process.tags),
        tags: process.tags,
      });
    });
  });

  let isOdd = false;
  Object.values(pointers).forEach((levels) => {
    const elementsEntries = Object.entries(levels).sort(([a], [b]) => a.localeCompare(b));
    elementsEntries.forEach(([, index]) => {
      resources[index].cls = isOdd ? "bg-[#f7f7f7] colored-row" : "";
    });

    isOdd = !isOdd;
  });

  eventGroups.forEach((processes, i) => {
    const eventsList = createProcessEventsForResource(i.toString(), processes);

    events.push(...eventsList);
  });

  hideColumn("process");
  schedulerResources.value = resources;
  schedulerEvents = events;
  handleProcessFilter(filterProcesses.value, events);
  handleResourcesFilter(filterResources.value, events);
};

const displayByProcesses = () => {
  const resources: ProcessResourceModel[] = [];
  const events: ProcessEventModel[] = [];
  const groups: Record<string, (ProcessResourceModel & { tags: TagGroupedProcess["tags"] })[]> = {};

  processes.value.forEach((process) => {
    process.processes.forEach((item) => {
      if (!groups[item.encoded_label]) {
        groups[item.encoded_label] = [];
      }

      const modifiedItem = { ...item, tags: process.tags };
      groups[item.encoded_label].push(modifiedItem);
    });
  });

  Object.entries(groups).forEach(([label, processes]) => {
    const getTagsFromProcessId = processes.reduce(
      (acc, item) => ({ ...acc, [item.id as string]: item.tags }),
      {} as Record<string, TagGroupedProcess["tags"]>,
    );

    const resourceEvents = createProcessEventsForResource(
      label,
      processes as unknown as ShortenedProcess[],
    ).map((event) => ({
      ...event,
      processes: event.processes.map((process) => ({
        ...process,
        name: getTagName(getTagsFromProcessId[(process as unknown as { id: string }).id]),
      })),
    }));

    resources.push({
      id: label,
      cls: "",
      process: t(`process_classes.${label}`),
      name: t(`process_classes.${label}`),
      processElement: resourceEvents[0].processElement,
      getHolidayFn: getHolidayFn.value,
    });

    events.push(...resourceEvents);
  });
  resources.sort((a, b) => (b.processElement || "").localeCompare(a.processElement || ""));
  resources.forEach((resource, i) => (resource.index = i + 1));

  hideColumn("component");
  schedulerResources.value = resources;
  schedulerEvents = events;

  nextTick(() => {
    schedulerInstance.eventStore.data = events.filter((event) => {
      return !filterProcesses.value.processTypes[event.processElement];
    });
  });
};

const displayByTags = () => {
  const resources: ProcessResourceModel[] = [];

  const events = processes.value.flatMap((item) => {
    const modifiedProcesses = item.processes.map((process) => ({ ...process, tags: item.tags }));
    return createProcessEventWithName(item._id, modifiedProcesses, getTagName(item.tags));
  });

  processes.value.forEach((process, i) => {
    resources.push({
      id: process._id,
      component: process.tags,
      component_ids: process.tag_ids,
      index: i,
      cls: "",
      name: getTagName(process.tags),
      getHolidayFn: getHolidayFn.value,
    });
  });

  hideColumn("process");
  schedulerResources.value = resources;
  schedulerEvents = events;
  handleProcessFilter(filterProcesses.value, events);
  handleResourcesFilter(filterResources.value, events);
};

const displayBySection = () => {
  const resources: ProcessResourceModel[] = [];
  const eventGroups: (TagGroupedProcess["processes"][number] & {
    name: string;
    tags: TagGroupedProcess["tags"];
  })[][] = [];
  const events: ProcessEventModel[] = [];
  const pointers = {} as Record<string, Record<string, Record<string, number>>>;
  let index = 0;
  const processesV2: TagGroupedProcess[] = structuredClone(processes.value);
  processesV2.sort((recordA: TagGroupedProcess, recordB: TagGroupedProcess) => {
    const buildingA = recordA?.tags?.building;
    const buildingB = recordB?.tags?.building;
    const sectionA = recordA?.tags?.section;
    const sectionB = recordB?.tags?.section;
    if (buildingA === buildingB) {
      return sectionA && sectionB ? sectionA.localeCompare(sectionB) : 0;
    }
    return buildingA && buildingB ? buildingA.localeCompare(buildingB) : 0;
  });

  processesV2.forEach((process) => {
    if (!pointers[process.tags.building]) {
      pointers[process.tags.building] = {};
    }
    if (!pointers[process.tags.building][process.tags.section]) {
      pointers[process.tags.building][process.tags.section] = {};
    }
    process.processes.forEach((subProcess) => {
      let currentIndex =
        pointers[process.tags.building][process.tags.section][process.tags.component];
      if (currentIndex === undefined) {
        currentIndex = index++;
        pointers[process.tags.building][process.tags.section][process.tags.component] =
          currentIndex;
        eventGroups.push([]);
        const tags = {
          ...(process.tags.building ? { building: process.tags.building } : {}),
          section: process.tags.section,
          component: process.tags.component,
        };

        resources.push({
          index: currentIndex,
          id: currentIndex,
          cls: "",
          name: getTagName(tags),
          component: tags as TagGroupedProcess["tags"],
          component_ids: process.tag_ids,
          getHolidayFn: getHolidayFn.value,
        });
      }

      eventGroups[currentIndex].push({
        ...subProcess,
        name: getTagName(process.tags),
        tags: process.tags,
      });
    });
  });

  let isOdd = false;
  Object.values(pointers).forEach((sections) => {
    Object.values(sections).forEach((elements) => {
      const elementsEntries = Object.entries(elements).sort(([a], [b]) => a.localeCompare(b));
      elementsEntries.forEach(([, index]) => {
        resources[index].cls = isOdd ? "bg-[#f7f7f7] colored-row" : "";
      });

      isOdd = !isOdd;
    });
  });

  eventGroups.forEach((processes, i) => {
    const eventsList = createProcessEventsForResource(i.toString(), processes);

    events.push(...eventsList);
  });
  hideColumn("process");
  schedulerResources.value = resources;
  schedulerEvents = events;

  handleProcessFilter(filterProcesses.value, events);
  handleResourcesFilter(filterResources.value, events);

  if (events) {
    schedulerInstance.eventStore.data = events;
  } else {
    schedulerInstance.eventStore.data = [];
  }

  schedulerInstance.resourceStore.sort(
    (recordA: ProcessResourceModel, recordB: ProcessResourceModel) => {
      const indexA = recordA?.index ?? 0;
      const indexB = recordB?.index ?? 0;
      return indexA - indexB;
    },
  );
};

const exportPdf = () => {
  isOperationLoading.value = true;
  const config = getConfig();
  trackEvent("process_gantt_export-pdf-click");

  setTimeout(async () => {
    try {
      await methods.resetView(schedulerInstance, schedulerProConfig.value.viewPreset as string);

      const { startDate, endDate } = schedulerInstance.eventStore.getTotalTimeSpan() as {
        startDate: Date;
        endDate: Date;
      };

      schedulerInstance.features.pdfExport.setConfig({
        scheduleRange: "daterange",
        rangeStart: subWeeks(startDate, 1),
        rangeEnd: addWeeks(endDate, 1),
        exportServer: `${config.API_BASE}planner-v2/plans/${customerName}/${siteId}/export-pdf`,
        fetchOptions: {
          headers: {
            "Content-Type": "application/json",
            Authorization: apiClient.defaults.headers.common["Authorization"],
          },
        },
      });

      const { response } = (await schedulerInstance.features.pdfExport.export({
        columns: (schedulerInstance.columns as Column[]).map((column) =>
          !column.hidden ? (column.id as string) : "",
        ),
      })) as { response: { status: number } };

      if (response.status !== 200) {
        throw new Error("Unsuccessful response code");
      }
    } catch (error) {
      logger.error("Unable to generate PDF", error as Error);
    } finally {
      isOperationLoading.value = false;
    }
  }, 100);
};

const showProcessesAnalysis = ref(false);
const selectedEvents = ref<(SchedulerEventModel & { processes: ShortenedProcess[] })[]>([]);
const selectedEventsInArea = ref<SchedulerEventModel[]>([]);

const handleEventSelection = (e: {
  action: string;
  deselected: SchedulerEventModel[];
  selected: SchedulerEventModel[];
  selection: (SchedulerEventModel & { processes: ShortenedProcess[] })[];
}) => {
  if (e.action === "update") {
    schedulerInstance.selectEvents(selectedEvents.value);
    return;
  }

  if (!schedulerInstance.features.eventDragSelect.disabled) {
    if (e.action === "select") {
      selectedEventsInArea.value = [...selectedEventsInArea.value, ...e.selected];
    }

    if (e.action === "clear") {
      selectedEventsInArea.value = [];
    }
  }

  if (e.action === "deselect") {
    selectedEventsInArea.value = selectedEventsInArea.value.filter((event) => {
      return !e.deselected.some((deselected) => deselected.id === event.id);
    });
  }

  if (selectedEvents.value?.length === 0 && e.selection.length === 0) {
    return;
  }

  if (e.selection.length === 0) {
    handleCloseProcessesAnalysis();
    return;
  }

  showProcessesAnalysis.value = true;
  selectedEvents.value = e.selection;
};

const handleCloseProcessesAnalysis = () => {
  showProcessesAnalysis.value = false;
  selectedEvents.value = [];
  schedulerInstance.selectedEvents = [];
  removeSelectionBox();
};

const { projectDurationSettings } = useProjectDurationSettings();
const refreshHolidaysInScheduler = useHolidaysInScheduler();
const refreshNonWorkingDaysInScheduler = useNonWorkingDaysInScheduler();

const scrollToMostRecentProcess = (scheduler: SchedulerPro) => {
  setTimeout(() => {
    let maxEndTimeEvent = null as EventModel | null;
    let maxEndTime = null as Date | null;

    scheduler?.eventStore?.allRecords.forEach((event) => {
      const processes = event.getData("processes");
      if (processes && processes.length > 0) {
        if (
          schedulerInstance.resourceStore.records.find((record) => {
            return String(record.getData("id")) === String(event.getData("resourceId"));
          })
        ) {
          processes.forEach((process: ShortenedProcess) => {
            const processEndTime = new Date(process.end_time);
            if (maxEndTime === null || processEndTime > maxEndTime) {
              maxEndTime = processEndTime;
              maxEndTimeEvent = event as EventModel;
            }
          });
        }
      }
    });
    if (maxEndTimeEvent) {
      scheduler.scrollEventIntoView(maxEndTimeEvent, {
        block: "center",
      });
    }
  }, 500);
};

watch(
  () => projectDurationSettings.value,
  () => {
    if (schedulerInstance) {
      refreshHolidaysInScheduler(schedulerInstance);
      refreshNonWorkingDaysInScheduler(schedulerInstance);
    }
  },
);

const getQueryStringsOnMounted = () => {
  return {
    processTypes: route.query.processTypes ? (route.query.processTypes as string).split(",") : [],
    processes: route.query.processes ? (route.query.processes as string).split(",") : [],
    location: route.query.location ? (route.query.location as string).split(",") : [],
    view: route.query.view ? (route.query.view as string) : "",
  };
};

const getSchedulerInstanceFilterValue = () =>
  (schedulerInstance as unknown as Record<string, Record<string, unknown>>)?.$filter?.value;

watch(
  () => filterProcesses.value,
  () => {
    router.replace({
      query: {
        ...route.query,
        processes:
          Object.entries(filterProcesses.value.processes)
            .filter(([, value]) => value)
            .map(([key]) => parseInt(key))
            .join(",") || undefined,
        processTypes:
          Object.entries(filterProcesses.value.processTypes)
            .filter(([, value]) => value)
            .map(([key]) => key)
            .join(",") || undefined,
      },
    });
  },
  { deep: true },
);
watch(
  () => filterResources.value,
  () => {
    router.replace({
      query: {
        ...route.query,
        location: filterResources.value.join(",") || undefined,
      },
    });
  },
  { deep: true },
);

const { toggleOutages, areOutagesShown } = useOutages();
</script>

<style>
.process-gantt
  .b-gridbase
  .b-timeline-subgrid
  .colored-row.b-grid-row.b-selected:not(.b-group-row) {
  @apply bg-[#f7f7f7];
}

.process-gantt.gantt-event-selected .plannerProcessEvent_prefab:not(.b-sch-event-selected) {
  background: v-bind("lightenProcessEventColors.prefab") !important;
}

.process-gantt.gantt-event-selected .plannerProcessEvent_support:not(.b-sch-event-selected) {
  background: v-bind("lightenProcessEventColors.support") !important;
}

.process-gantt.gantt-event-selected .plannerProcessEvent_reinforce:not(.b-sch-event-selected) {
  background: v-bind("lightenProcessEventColors.reinforce") !important;
}

.process-gantt.gantt-event-selected .plannerProcessEvent_concrete:not(.b-sch-event-selected) {
  background: v-bind("lightenProcessEventColors.concrete") !important;
}

.process-gantt.gantt-event-selected .plannerProcessEvent_sheath:not(.b-sch-event-selected) {
  background: v-bind("lightenProcessEventColors.sheath") !important;
}

.process-gantt.gantt-event-selected .plannerProcessEvent_brickwork:not(.b-sch-event-selected) {
  background: v-bind("lightenProcessEventColors.brickwork") !important;
}

.process-gantt.gantt-event-selected .plannerProcessEvent_safety:not(.b-sch-event-selected) {
  background: v-bind("lightenProcessEventColors.safety") !important;
}

.process-gantt.gantt-event-selected .plannerProcessEvent_transport:not(.b-sch-event-selected) {
  background: v-bind("lightenProcessEventColors.transport") !important;
}

.process-gantt .b-dragselect-rect {
  background-color: #feac3112 !important;
  border: 1px dotted #feac31ba !important;
}
</style>
