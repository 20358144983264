<template>
  <Sidebar>
    <CameraItemLayout :noData="noData">
      <template #contentLeft>
        <div class="col-span-6">
          <div class="lg:max-w-screen-md 2xl:max-w-screen-2xl pr-2 lg:mr-6">
            <OculaiVideoPlayer
              :loading="loading"
              :src="timelapseUrl"
              :noSrcMessage="$t('err.no_data_yet')"
            />
            <div class="2xl:pt-4 mt-2 flex justify-end" v-if="!loading">
              <button type="button" @click="trackEvent('camera_project-timelapse_download_click')">
                <a :href="timelapseUrl">
                  <ArrowDownTrayIcon class="h-6 w-6 hover:text-yellow" />
                </a>
              </button>
            </div>
          </div>
        </div>
      </template>
    </CameraItemLayout>
  </Sidebar>
</template>

<script lang="ts" setup>
import { ArrowDownTrayIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useTrackEvent } from "shared/composables/tracking";
import logger from "shared/services/logger";
import Sidebar from "@/components/layout/Sidebar.vue";
import OculaiVideoPlayer from "@/components/other/OculaiVideoPlayer.vue";
import CameraRepository from "@/repositories/CameraRepository";
import CameraItemLayout from "@/views/camera/components/CameraItemLayout.vue";

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const { params } = useRoute();

const timelapseUrl = ref("");
const loading = ref(false);
const noData = ref(false);

const trackEvent = useTrackEvent();

const getTimelapseUrl = () => {
  loading.value = true;

  return CameraRepository.loadProjectTimelapse(
    currentCustomerName,
    currentSiteId,
    params?.camera_id as string,
  )
    .then((data) => {
      if (data) {
        timelapseUrl.value = data?.presigned_url_timelapse;
      } else {
        noData.value = true;
      }
    })
    .catch((error) => {
      timelapseUrl.value = "";
      logger.error(error);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  trackEvent("camera_project-timelapse_view");
  getTimelapseUrl();
});
</script>
