<template>
  <Modal :open="open" @close="emits('close')">
    <template #content>
      <div class="pb-4">
        <p class="text-2xl font-bold text-center mb-4">
          {{ t("analytics.processes.shortcuts.title") }}
        </p>

        <div class="pr-14">
          <table class="border-separate border-spacing-x-12 border-spacing-y-1">
            <tbody>
              <tr>
                <td>{{ t("analytics.processes.shortcuts.draw_area") }}</td>
                <td class="text-end">
                  <kbd>Shift</kbd> + <kbd>{{ t("analytics.processes.shortcuts.draw") }}</kbd>
                </td>
              </tr>
              <tr>
                <td>
                  {{ t("analytics.processes.shortcuts.select_multiple") }}
                  <span class="text-gray-500 text-sm">
                    ({{ t("analytics.processes.shortcuts.event") }})</span
                  >
                </td>
                <td class="text-end">
                  <kbd>{{ isMacos ? "Cmd" : "Ctrl" }}</kbd> +
                  <kbd>{{ t("analytics.processes.shortcuts.click") }}</kbd>
                </td>
              </tr>
              <tr>
                <td>
                  {{ t("analytics.processes.shortcuts.open_context_menu") }}
                  <span class="text-gray-500 text-sm">
                    ({{ t("analytics.processes.shortcuts.resource") }})</span
                  >
                </td>
                <td class="text-end">
                  <kbd>{{ t("analytics.processes.shortcuts.right_click") }}</kbd>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import Modal from "shared/components/modals/Modal.vue";

const { t } = useI18n();

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const isMacos = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

const emits = defineEmits(["close"]);
</script>

<style scoped>
kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}
</style>
