import { useQuery } from "@tanstack/vue-query";
import { computed, Ref } from "vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import HierarchyRepository from "shared/repositories/HierarchyRepository";
import logger from "shared/services/logger";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";

export const useAllHierarchyTags = () => {
  const { data, isLoading } = useQuery<HierarchyTagStore[]>({
    queryKey: ["hierarchy-tags-all"],
    queryFn: () => HierarchyRepository.loadAllHierarchyData(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load all hierarchy tags");
      return false;
    },
  });

  return { hierarchyTags: data, areHierarchyTagsLoading: isLoading };
};

export const useHierarchyTags = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const { data, isLoading } = useQuery<HierarchyTagStore[]>({
    queryKey: ["hierarchy-tags", customerName, siteId],
    queryFn: () => HierarchyRepository.loadHierarchyTags(customerName, siteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const hierarchyTags = computed(
    () => data.value?.slice().sort((a, b) => a.number - b.number) || [],
  );

  return { hierarchyTags, isLoading };
};

export const useFilterByLocation = (hierarchyTags: Ref<HierarchyTagStore[]>) => {
  const tagsById = computed(() =>
    hierarchyTags.value.reduce((acc, tag) => {
      acc[tag._id] = tag;
      return acc;
    }, {} as Record<string, HierarchyTagStore>),
  );
  return (filterTagIds: string[]) => {
    const filteredTypes = new Set<HierarchyType>(
      filterTagIds
        .map((tagId) => tagsById.value[tagId]?.type)
        .filter((type) => type) as HierarchyType[],
    );
    const filterTagIdsSet = new Set(filterTagIds);

    return (entity: Record<HierarchyType, string>) =>
      Object.entries(entity).every(
        ([key, tagId]) => !filteredTypes.has(key as HierarchyType) || filterTagIdsSet.has(tagId),
      );
  };
};
