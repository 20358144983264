<template>
  <Sidebar>
    <CameraItemLayout :noData="noData">
      <template #contentLeft>
        <div class="loadingCard is-loading" v-if="!noData && loading">
          <div
            class="image group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-50 overflow-hidden"
          />
        </div>
        <div class="col-span-6" v-if="!noData && !loading">
          <ImagesComparison
            @noData="noData = true"
            :camera="cameraId"
            @beforeChanged="beforeDate = $event"
            @afterChanged="afterDate = $event"
          />
        </div>
      </template>
    </CameraItemLayout>
  </Sidebar>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useTrackEvent } from "shared/composables/tracking";
import Sidebar from "@/components/layout/Sidebar.vue";
import ImagesComparison from "@/components/other/ImagesComparison.vue";
import CameraItemLayout from "@/views/camera/components/CameraItemLayout.vue";

const { params } = useRoute();
const cameraId = params?.camera_id as string;

const beforeDate = ref("");
const afterDate = ref("");
const loading = ref(false);
const noData = ref(false);

const trackEvent = useTrackEvent();

onMounted(() => {
  trackEvent("camera_compare_view");
});
</script>
