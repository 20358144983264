<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select id="tabs" name="tabs" class="oai-inputs" @change="changeTab($event.target.value)">
        <option v-for="(tab, index) in tabs" :key="tab.name" :value="index" :selected="tab.current">
          {{ $t(`report.weather.${tab.name}`) }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="tab.name"
          :class="[
            tab.current
              ? 'text-bold bg-gray-200 text-gray-900'
              : 'text-gray-500 hover:text-gray-700',
            index === 0 ? 'rounded-l-lg' : '',
            index === tabs.length - 1 ? 'rounded-r-lg' : '',
            'group relative min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium hover:bg-gray-300 focus:z-10',
            validationErrors.includes(tab.name) && !tab.current ? 'bg-red-100' : '',
          ]"
          :aria-current="tab.current ? 'page' : undefined"
          @click="changeTab(index)"
        >
          <span>{{ $t(`report.weather.${tab.name}`) }}</span>
          <span
            aria-hidden="true"
            :class="[tab.current ? '' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']"
          />
        </div>
      </nav>
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: "WeatherRules",
  emits: ["tabChanged"],
  props: ["validationErrors"],
  data() {
    return {
      tabs: [
        { name: "cold_temperature", current: true },
        { name: "hot_temperature", current: false },
        { name: "precipitation", current: false },
        { name: "wind", current: false },
      ],
    };
  },
  methods: {
    changeTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.current = i === index;
      });
      this.$emit("tabChanged", this.tabs[index].name);
    },
  },
};
</script>
