<template>
  <Modal :open="open" @close="emits('close')" customCls="h-fit max-w-[90vw] l:w-[120vh] min-w-96">
    <template #content>
      <h4 class="text-lg font-bold mb-6 text-center">
        {{
          ["choose_issue", "report_issue"].includes(currentStep)
            ? t("issue_report.report_process_issue")
            : issueOptions.find((issue) => issue.value === issueType)?.title
        }}
      </h4>

      <div v-if="currentStep === 'choose_issue'">
        <div class="mb-4">
          <SelectList
            :options="issueOptions"
            :defaultSelected="issueType"
            :placeholder="`-- ${t('issue_report.select_issue')} --`"
            @update:selected="issueType = $event"
          />
        </div>

        <div class="flex justify-end mt-6">
          <MainButton
            v-if="issueType === 'process_not_exist'"
            @click="submitIssueReport"
            color="yellow"
            :isDisabled="isSubmitting"
          >
            {{ t("buttons.submit") }}
            <LoadingSpinner cls="h-4 w-4 ml-3 text-white" v-if="isSubmitting" />
          </MainButton>

          <button
            v-else
            :disabled="!issueType"
            type="button"
            @click="
              currentStep = issueOptions.find((issue) => issue.value === issueType)?.customerResolve
                ? 'resolve_issue'
                : 'report_issue'
            "
            class="inline-flex items-center md:px-4 md:py-1.5 py-1 px-1.5 rounded-md font-bold border shadow transition-colors duration-150 text-yellow border-yellow hover:bg-yellow hover:text-white bg-gray-50"
            :class="[
              'inline-flex items-center md:px-4 md:py-1.5 py-1 px-1.5 rounded-md font-bold border shadow transition-colors duration-150',
              issueType
                ? 'text-yellow border-yellow hover:bg-yellow hover:text-white bg-gray-50'
                : 'border-gray-100 text-gray-300 bg-gray-100 cursor-not-allowed', // Gray when disabled
            ]"
          >
            <ArrowLongRightIcon class="shrink-0 h-6 transition-transform duration-100" />
          </button>
        </div>
      </div>

      <div v-if="currentStep === 'resolve_issue'">
        <div v-if="issueType === 'process_class'">
          <Listbox as="div" v-model="newProcessClass">
            <div class="relative">
              <ListboxButton
                class="relative rounded-md py-1.5 pl-3 pr-10 text-xs text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full bg-white',"
              >
                <span class="block truncate">{{ t(`process_classes.${newProcessClass}`) }}</span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions
                  class="absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-yellow-500/5 focus:outline-none sm:text-sm w-full"
                >
                  <ListboxOption
                    as="template"
                    v-for="processClass in processClasses"
                    :key="processClass.encodedLabel"
                    :value="processClass.encodedLabel"
                    v-slot="{ active, selected }"
                  >
                    <li
                      :class="[
                        (active || selected) && 'bg-yellow-100',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 flex gap-2',
                      ]"
                    >
                      <span class="block truncate font-normal">
                        {{ t(`process_classes.${processClass.encodedLabel}`) }}
                      </span>
                      <span
                        class="bg-green-200 text-gray-50 px-2 py-1 ml-1 rounded text-xs"
                        v-if="mappableEncodedLabels?.includes(processClass.encodedLabel)"
                      >
                        {{ t("issue_report.process_mappable") }}
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>

          <p v-if="!isSelectedProcessClassMappable" class="text-xs text-yellow-600 mt-1">
            {{ t("issue_report.non_mappable_process_warning") }}
          </p>
        </div>
        <div v-if="issueType === 'level'">
          <SelectList
            :label="t('issue_report.new_level')"
            :options="levelTags"
            @update:selected="newLevelTag = $event"
            :defaultSelected="levelTags.find((tag) => tag.name === process?.tags?.level)?.value"
          />
        </div>
        <div v-if="issueType === 'time_intervals'">
          <table class="border-spacing-x-2">
            <thead>
              <tr>
                <th class="text-left text-xs pr-4 py-1">{{ t("issue_report.start") }}</th>
                <th class="text-left text-xs pr-4 py-1">{{ t("issue_report.end") }}</th>
                <th class="text-left text-xs pr-4 py-1"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(interval, index) in newWorkIntervals" :key="index">
                <td class="pr-4 py-1">
                  <input type="time" class="oai-inputs" v-model="interval.start_time" />
                </td>
                <td class="pr-4 py-1">
                  <input type="time" class="oai-inputs" v-model="interval.end_time" />
                </td>
                <td v-if="index === 0" class="pr-4 py-1">
                  <PlusIcon class="w-4 h-4 text-gray-300 cursor-pointer" @click="addWorkInterval" />
                </td>
                <td v-else class="pr-4 py-1">
                  <XMarkIcon
                    class="w-4 h-4 text-gray-300 cursor-pointer"
                    @click="deleteWorkInterval(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <p v-if="!isWorkIntervalsValid && allIntervalsProvided" class="text-xs text-red-600 mt-1">
            {{ t("issue_report.intervals_error") }}
          </p>
        </div>

        <p
          class="text-xs text-gray-600 mt-6 underline cursor-pointer"
          @click="currentStep = 'report_issue'"
        >
          {{ t("issue_report.report_to_oculai") }}
        </p>

        <div class="flex justify-between mt-4">
          <button
            type="button"
            @click="currentStep = 'choose_issue'"
            class="inline-flex items-center md:px-4 md:py-1.5 py-1 px-1.5 rounded-md font-bold border shadow transition-colors duration-150 text-yellow border-yellow hover:bg-yellow hover:text-white bg-gray-50"
          >
            <ArrowLongRightIcon class="shrink-0 h-6 transition-transform duration-100 rotate-180" />
          </button>

          <MainButton
            :isDisabled="
              isSubmitting ||
              !issueType ||
              (issueType === 'time_intervals' && !isWorkIntervalsValid)
            "
            @click="selfResolveIssue"
            color="yellow"
          >
            {{ t("issue_report.resolve") }}
            <LoadingSpinner cls="h-4 w-4 ml-3 text-white" v-if="isSubmitting" />
          </MainButton>
        </div>
      </div>

      <div v-if="currentStep === 'report_issue'">
        <div>
          <span class="text-sm text-gray-900 font-semibold ml-1">
            {{ t("issue_report.issue_description") }}
          </span>
          <textarea rows="3" v-model="issueDescription" class="oai-inputs" />

          <div class="flex justify-between mt-6">
            <button
              type="button"
              @click="currentStep = 'choose_issue'"
              class="inline-flex items-center md:px-4 md:py-1.5 py-1 px-1.5 rounded-md font-bold border shadow transition-colors duration-150 text-yellow border-yellow hover:bg-yellow hover:text-white bg-gray-50"
            >
              <ArrowLongRightIcon
                class="shrink-0 h-6 transition-transform duration-100 rotate-180"
              />
            </button>

            <MainButton
              :isDisabled="!issueType || isSubmitting"
              @click="submitIssueReport"
              color="yellow"
            >
              {{ t("buttons.submit") }}
              <LoadingSpinner cls="h-4 w-4 ml-3 text-white" v-if="isSubmitting" />
            </MainButton>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { XMarkIcon, PlusIcon, ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import { ArrowLongRightIcon } from "@heroicons/vue/24/solid";
import { format, parse } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { computed, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import SelectList from "shared/components/other/OaiListbox.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import { SimplifiedPlannerProcess, SimplifiedPlannerProcessWorkInterval } from "shared/types/Plan";
import { EncodedLabel } from "shared/types/ProcessClass";
import MainButton from "@/components/other/MainButton.vue";
import { useProcessClasses } from "@/composables/process";
import IssueReportRepository from "@/repositories/IssueReportRepository";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";

type ModifiedWorkingInterval = {
  start_time: string;
  end_time: string;
  workforce: { validated_count: number | null };
};

const { t } = useI18n();

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  process: {
    type: Object as PropType<SimplifiedPlannerProcess>,
    required: false,
  },
});

const processClasses = useProcessClasses();
const { hierarchyTags } = useHierarchyTags();
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const toast = useCustomToast();

const currentStep = ref<"choose_issue" | "resolve_issue" | "report_issue">("choose_issue");
const issueType = ref<string>();
const issueDescription = ref("");
const newProcessClass = ref<EncodedLabel>();
const newWorkIntervals = ref<ModifiedWorkingInterval[]>([]);
const newLevelTag = ref<string>();
const mappableEncodedLabels = ref<EncodedLabel[]>([]);
const isSubmitting = ref(false);

const levelTags = computed(() =>
  hierarchyTags.value
    .filter((tag) => tag.type === "level")
    .map((tag) => ({ name: tag.name, value: tag._id })),
);

const allIntervalsProvided = computed(() => {
  return newWorkIntervals.value.every((interval) => interval.start_time && interval.end_time);
});

const isWorkIntervalsValid = computed(() => {
  const now = new Date();
  const intervals = newWorkIntervals.value
    .map((interval) => ({
      start: parse(interval.start_time, "HH:mm", now),
      end: parse(interval.end_time, "HH:mm", now),
    }))
    .sort((a, b) => a.start.getTime() - b.start.getTime());

  return intervals.every((interval, index) => {
    return (
      interval.start.getTime() < interval.end.getTime() &&
      (index === 0 || interval.start.getTime() > intervals[index - 1].end.getTime())
    );
  });
});

const isSelectedProcessClassMappable = computed(() => {
  if (!newProcessClass.value) {
    return false;
  }

  return mappableEncodedLabels.value.includes(newProcessClass.value);
});

const issueOptions = computed(() => [
  {
    value: "process_class",
    name: t("issue_report.issue_process_class"),
    title: t("issue_report.update_process_class"),
    customerResolve: true,
  },
  {
    value: "time_intervals",
    name: t("issue_report.issue_time_interval"),
    title: t("issue_report.update_time_interval"),
    customerResolve: true,
  },
  ...(props.process?.tags?.level
    ? [
        {
          value: "level",
          name: t("issue_report.issue_level"),
          title: t("issue_report.update_level"),
          customerResolve: true,
        },
      ]
    : []),
  {
    value: "people_count",
    name: t("issue_report.issue_people_count"),
    title: t("issue_report.report_process_issue"),
    customerResolve: false,
  },
  {
    value: "location_section",
    name: t("issue_report.issue_location"),
    title: t("issue_report.report_process_issue"),
    customerResolve: false,
  },
  {
    value: "process_not_exist",
    name: t("issue_report.issue_process_not_exist"),
    customerResolve: false,
  },
]);

const formatToTime = (date: Date) => {
  return format(date, "HH:mm");
};

const remapWorkIntervals = (workIntervals: SimplifiedPlannerProcessWorkInterval[]) => {
  return workIntervals.map((interval) => ({
    ...interval,
    start_time: formatToTime(interval.start_time),
    end_time: formatToTime(interval.end_time),
  }));
};

watch(
  () => [props.process, props.open],
  ([p, isOpen]) => {
    if (!p) {
      return;
    }
    const process = p as SimplifiedPlannerProcess;
    issueType.value = undefined;
    issueDescription.value = "";
    currentStep.value = "choose_issue";
    newProcessClass.value = process.encoded_label;
    newWorkIntervals.value = remapWorkIntervals(process.work_intervals);
    newLevelTag.value = levelTags.value.find((tag) => tag.name === process?.tags?.level)?.value;

    if (isOpen) {
      OpsProcessesRepository.loadMappableEncodedLabels(process._id).then((labels) => {
        mappableEncodedLabels.value = labels;
      });
    }
  },
  { immediate: true, deep: true },
);

const deleteWorkInterval = (index: number) => {
  newWorkIntervals.value.splice(index, 1);
};

const addWorkInterval = () => {
  newWorkIntervals.value.push({
    start_time: "",
    end_time: "",
    workforce: { validated_count: null },
  });
};

const emits = defineEmits(["close", "processUpdate"]);

const selfResolveIssue = async () => {
  if (!issueType.value) {
    return;
  }

  isSubmitting.value = true;

  if (issueType.value === "process_class") {
    emits("processUpdate", await resolveWrongProcessClass());
  } else if (issueType.value === "level") {
    emits("processUpdate", await resolveWrongLevel());
  } else if (issueType.value === "time_intervals") {
    emits("processUpdate", await resolveWrongTimeIntervals());
  }

  isSubmitting.value = false;

  emits("close");
};

const resolveWrongProcessClass = async () => {
  const process = props.process;
  if (!newProcessClass.value || !process) {
    return;
  }

  try {
    const updatedProcess = await IssueReportRepository.resolveProcessIssue(
      customerName,
      siteId,
      props.process._id,
      {
        encoded_label: newProcessClass.value,
      },
    );

    const issueName = issueOptions.value.find((issue) => issue.value === issueType.value)?.name;
    const oldProcess = processClasses.value.find((processClass) => {
      return processClass.encodedLabel === process.encoded_label;
    });
    const newProcess = processClasses.value.find((processClass) => {
      return processClass.encodedLabel === newProcessClass.value;
    });
    const resolveMessage = `Issue type: ${issueName}\n\nProcess class changes from ${oldProcess?.decodedLabel} to ${newProcess?.decodedLabel}`;

    await createSelfResolvedIssueReport(resolveMessage);

    toast.success(t("issue_report.process_updated"));

    return {
      ...updatedProcess,
      planner_item_mapping: process.planner_item_mapping,
      old_id: props.process._id,
    };
  } catch (_) {
    toast.error(t("issue_report.issue_failed_to_resolve"));
  }
};

const resolveWrongLevel = async () => {
  if (!newLevelTag.value || !props.process) {
    return;
  }

  try {
    const updatedProcess = await IssueReportRepository.resolveProcessIssue(
      customerName,
      siteId,
      props.process._id,
      {
        section_mask_mapping: { level_id: newLevelTag.value, id: null },
      },
    );

    const mappingResults = await IssueReportRepository.remapProcessToSectionMasks(
      customerName,
      siteId,
      updatedProcess._id,
    );

    const newLevelName = hierarchyTags.value.find((tag) => tag._id === newLevelTag.value)?.name;
    const issueTypeName = issueOptions.value.find((issue) => issue.value === issueType.value)?.name;

    let resolveMessage = `Issue type: ${issueTypeName}\n\nLevel changes from ${props.process?.tags?.level} to ${newLevelName}`;

    if (mappingResults.level_to_mask_mapping_not_applicable.length) {
      toast.warning(t("issue_report.level_to_mask_mapping_not_applicable"));

      resolveMessage += `\n\n❗❗❗ New level is not applicable to section mask. Please check the section mask mapping. ❗❗❗`;
    } else {
      toast.success(t("issue_report.process_updated"));
    }

    await createSelfResolvedIssueReport(resolveMessage);

    if (mappingResults.level_to_mask_mapping_changes.length) {
      const mappedProcess = mappingResults.level_to_mask_mapping_changes[0];
      const componentTag = hierarchyTags.value.find((tag) =>
        tag.source_ids.includes(mappedProcess.planner_item_mapping.source_id!),
      );
      const plannerItemMapping = {
        ...mappedProcess.planner_item_mapping,
        component_name: componentTag?.name,
      };
      return {
        ...mappingResults.level_to_mask_mapping_changes[0],
        old_id: props.process._id,
        planner_item_mapping: plannerItemMapping,
      };
    }

    return { ...updatedProcess, old_id: props.process._id };
  } catch (_) {
    toast.error(t("issue_report.issue_failed_to_resolve"));
  }
};

const resolveWrongTimeIntervals = async () => {
  const process = props.process;
  if (!isWorkIntervalsValid.value || !process) {
    return;
  }

  try {
    const updatedProcess = await IssueReportRepository.resolveProcessIssue(
      customerName,
      siteId,
      process._id,
      {
        work_intervals: newWorkIntervals.value.map((interval) => {
          const date = new Date(process.date);
          return {
            ...interval,
            start_time: fromZonedTime(parse(interval.start_time, "HH:mm", date), "UTC")
              .toISOString()
              .replace("Z", "+00:00"),
            end_time: fromZonedTime(parse(interval.end_time, "HH:mm", date), "UTC")
              .toISOString()
              .replace("Z", "+00:00"),
          };
        }),
      },
    );

    const issueName = issueOptions.value.find((issue) => issue.value === issueType.value)?.name;
    const resolveMessage = `Issue type: ${issueName}\n\nTime intervals changes from ${props.process.work_intervals
      .map(
        (interval) => `${formatToTime(interval.start_time)} - ${formatToTime(interval.end_time)}`,
      )
      .join(", ")} to ${newWorkIntervals.value
      .map((interval) => `${interval.start_time} - ${interval.end_time}`)
      .join(", ")}`;

    await createSelfResolvedIssueReport(resolveMessage);

    toast.success(t("issue_report.process_updated"));

    return {
      ...updatedProcess,
      planner_item_mapping: process.planner_item_mapping,
      old_id: props.process._id,
    };
  } catch (_) {
    toast.error(t("issue_report.issue_failed_to_resolve"));
  }
};

const createSelfResolvedIssueReport = async (message: string) => {
  if (!props.process) {
    return;
  }

  await IssueReportRepository.createSelfResolvedIssueReport(customerName, siteId, {
    type: "process",
    entry_id: props.process._id,
    report_message: "",
    resolve_message: message,
  });
};

const submitIssueReport = async () => {
  if (!issueType.value || !props.process) {
    return;
  }

  const issueName = issueOptions.value.find((issue) => issue.value === issueType.value)?.name;
  let message = `Issue type: ${issueName}`;
  if (issueDescription.value) {
    message += `\n\nIssue description: ${issueDescription.value}`;
  }

  isSubmitting.value = true;

  try {
    await IssueReportRepository.createIssueReport(customerName, siteId, {
      type: "process",
      entry_id: props.process._id,
      report_message: message,
    });

    toast.success(t("issue_report.issue_reported"));
  } catch (_) {
    toast.error(t("issue_report.issue_failed_to_report"));
  }

  isSubmitting.value = false;

  emits("close");
};
</script>
