<template>
  <div :style="fullWindow4to3RatioDimensionStyle">
    <LiveBaseImage
      :cameraId="route.params.camera_id as string"
      :showControls="false"
      :isPublic="true"
    />
  </div>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import LiveBaseImage from "@/components/live_video/LiveBaseImage.vue";
import { useFullWindow4to3RatioDimensionStyle } from "@/composables/screen";

const route = useRoute();
const fullWindow4to3RatioDimensionStyle = useFullWindow4to3RatioDimensionStyle();
</script>
