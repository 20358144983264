import { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { apiClient } from "shared/repositories/clients";
import { UnitValue, UnitValueAggregate, UnitValueType } from "@/types/UnitValue";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const mapUnitValue = (unitValue: UnitValue<string>): UnitValue => ({
  ...unitValue,
  actual_start: unitValue.actual_start ? parseUtcDate(unitValue.actual_start) : null,
  actual_end: unitValue.actual_end ? parseUtcDate(unitValue.actual_end) : null,
});

const loadUnitValues = (customerName: string, siteId: string): Promise<UnitValue[]> =>
  apiClient
    .get<void, AxiosResponse<UnitValue<string>[]>>(`/unit_values/${customerName}/${siteId}`)
    .then((response) => response.data.map((unitValue) => mapUnitValue(unitValue)));

const loadUnitValuesTypes = (customerName: string, siteId: string) =>
  apiClient
    .get<void, AxiosResponse<UnitValueType[]>>(`/unit_values/${customerName}/${siteId}/types`)
    .then((response) => response.data);

const mapUnitValueToUpdate = (unitValue: UnitValue) => ({
  quantity: unitValue.quantity,
  type: unitValue.type,
  type_order: unitValue.type_order,
  tags: unitValue.tags,
  type_duration_factor: unitValue.type_duration_factor,
  type_workforce_delta: unitValue.type_workforce_delta,
  type_value_delta: unitValue.type_value_delta,
  custom_adjusted_duration: unitValue.custom_adjusted_duration,
  custom_adjusted_workforce: unitValue.custom_adjusted_workforce,
  custom_adjusted_value: unitValue.custom_adjusted_value,
  oai_adjusted_workforce: unitValue.oai_adjusted_workforce,
  ignored: unitValue.ignored,
  approved: unitValue.approved,
});

const saveUnitValues = (
  customerName: string,
  siteId: string,
  unitValues: UnitValue[],
): Promise<UnitValue[]> =>
  apiClient
    .patch<void, AxiosResponse<UnitValue<string>[]>>(`/unit_values/${customerName}/${siteId}`, {
      unit_values: unitValues.map((unitValue) => mapUnitValueToUpdate(unitValue)),
    })
    .then((response) => response.data.map((unitValue) => mapUnitValue(unitValue)));

const calculateUnitValueFields = (
  customerName: string,
  siteId: string,
  unitValues: UnitValue[],
): Promise<UnitValue[]> =>
  apiClient
    .post<void, AxiosResponse<UnitValue<string>[]>>(
      `/unit_values/${customerName}/${siteId}/calculate-fields`,
      {
        unit_values: unitValues.map((unitValue) => mapUnitValueToUpdate(unitValue)),
      },
    )
    .then((response) => response.data.map((unitValue) => mapUnitValue(unitValue)));

const loadUnitValueAggregates = (): Promise<UnitValueAggregate[]> =>
  apiClient
    .get<void, AxiosResponse<UnitValueAggregate[]>>(`/unit_values/aggregates`)
    .then((response) => response.data);

export default {
  loadUnitValues,
  saveUnitValues,
  calculateUnitValueFields,
  loadUnitValueAggregates,
  loadUnitValuesTypes,
};
