<template>
  <div>
    <svg>
      <defs>
        <marker
          id="oaiArrowClosed"
          viewBox="-10 -10 20 20"
          markerWidth="14"
          markerHeight="14"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
        >
          <polyline
            stroke-linecap="round"
            stroke-linejoin="round"
            points="-12,-7 0,0 -12,7 -12,-7"
            style="stroke: #6b7280; fill: #6b7280; stroke-width: 1"
          ></polyline>
        </marker>
      </defs>
    </svg>
    <svg>
      <defs>
        <marker
          id="oaiArrowClosedSelected"
          viewBox="-10 -10 20 20"
          markerWidth="14"
          markerHeight="14"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
        >
          <polyline
            stroke-linecap="round"
            stroke-linejoin="round"
            points="-12,-7 0,0 -12,7 -12,-7"
            style="stroke: #f87171; fill: #f87171; stroke-width: 1"
          ></polyline>
        </marker>
      </defs>
    </svg>
    <svg>
      <defs>
        <marker
          id="oaiArrowClosedDimmed"
          viewBox="-10 -10 20 20"
          markerWidth="14"
          markerHeight="14"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
        >
          <polyline
            stroke-linecap="round"
            stroke-linejoin="round"
            points="-12,-7 0,0 -12,7 -12,-7"
            style="stroke: #efefef; fill: #efefef; stroke-width: 1"
          ></polyline>
        </marker>
      </defs>
    </svg>
  </div>
</template>

<script lang="ts" setup></script>
