<template>
  <BaseWidget :isLoading="true" v-if="isLoading" />
  <template v-else>
    <LiveStreamWidget
      v-bind="$props"
      v-if="isLive"
      @delete="$emit('delete')"
      @widgetRouteClicked="emit('widgetRouteClicked')"
      @trackInteraction="emit('trackInteraction')"
    />
    <ArchivedStreamWidget
      v-bind="$props"
      v-else
      @delete="$emit('delete')"
      @widgetRouteClicked="emit('widgetRouteClicked')"
    />
  </template>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useStreams } from "@/composables/stream";
import { Dashboard, DashboardGridSize } from "@/types/Dashboard";
import ArchivedStreamWidget from "@/views/dashboard/componentsV2/ArchivedStreamWidget.vue";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import LiveStreamWidget from "@/views/dashboard/componentsV2/LiveStreamWidget.vue";

const props = defineProps<{
  arg: string;
  width: number;
  height: number;
  dashboardGridSize: DashboardGridSize;
  dashboard?: Dashboard;
}>();
const emit = defineEmits(["delete", "widgetRouteClicked", "trackInteraction"]);

const { streams, isLoading } = useStreams();

const isLive = computed(() => {
  const stream = streams.value.find(({ camera_id }) => camera_id == props.arg);
  return !!stream?.aws_stream_id;
});
</script>

<style>
.oaiStreamWidgetContainer .vgl-item__resizer {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="rgb(255, 255, 255)" stroke-width="2" viewBox="0 0 16 16"><path d="m9.5 14.5 5-5M4 14.5 14.5 4"></path></svg>');
}
</style>
