import {
  EventStore,
  SchedulerEventModel,
  Model,
  SchedulerPro,
  EventModel,
  RecurringTimeSpansMixin,
  RecurringTimeSpan,
  Store,
  ResourceModel,
  TimeSpan,
  DependencyModel,
  ResourceModelConfig,
  EventModelConfig,
  SchedulerAssignmentModel,
} from "@bryntum/schedulerpro";
import { DomClassList, DateHelper } from "@bryntum/schedulerpro";
import { BryntumSchedulerProProps } from "@bryntum/schedulerpro-react";
// Using the react package here, because it has more up-to-date types
// See https://forum.bryntum.com/viewtopic.php?t=24760 and https://github.com/bryntum/support/issues/6753
import { addMilliseconds, format } from "date-fns";
import { set, intervalToDuration } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import getProcessClasses from "../../constants/ProcessClasses";
import i18n from "../../i18n";
import { PlannerMode, SimplifiedPlannerProcess } from "../../types/Plan";
import { SchedulerProDropEvent } from "../../types/SchedulerPro";
import { customPresets } from "./SchedulerProPresets";

const { t, localeText } = i18n.global;

export class OculaiTimeRangeStore extends RecurringTimeSpansMixin(Store) {
  static get defaultConfig() {
    return {
      modelClass: RecurringTimeSpan(TimeSpan),
    };
  }
}

export class OculaiResourceModel extends ResourceModel {
  constructor(config: Partial<ResourceModelConfig>) {
    super(config);
    this.set("sourceId", this.getData("sourceId") || getOculaiSourceId());
    this.set("comments", this.getData("comments") || []);
  }

  static get fields() {
    return [
      { name: "sourceId", type: "string" },
      { name: "trackingEnabled", type: "boolean", defaultValue: true },
      { name: "index", type: "number" },
    ];
  }
}

export class VisitorAndTrackerEventModel extends EventModel {
  static get fields() {
    return [
      { name: "type", type: "string", defaultValue: "actual" },
      { name: "active", type: "boolean", defaultValue: true },
    ];
  }
}

export class RevisionEventModel extends EventModel {
  constructor(config: Partial<EventModelConfig>) {
    super(config);
  }

  static get fields() {
    return [
      { name: "type", type: "string", defaultValue: "planned" },
      { name: "active", type: "boolean" },
    ];
  }
}

export function getOculaiSourceId() {
  return "oculai-" + uuidv4();
}

export function getEventType(mode: PlannerMode) {
  return mode === "tracker" ? "actual" : "planned";
}

export function isModeCompatible(record: SchedulerEventModel, mode: PlannerMode) {
  return (
    (record.getData("type") === "planned" && mode === "revision") ||
    (record.getData("type") === "actual" && mode === "tracker")
  );
}

export function isParentResource(record: Model) {
  return (
    getChildrenLength(record) > 0 && checkTrackingEnabledInAnyChild(record.children as Model[])
  );
}

export function checkTrackingEnabledInAnyChild(nodes: Model[]): boolean {
  for (const node of nodes) {
    if (node.getData("trackingEnabled")) {
      return true;
    }
    if ("children" in node) {
      const childResult = checkTrackingEnabledInAnyChild(node.children as Model[]);
      if (childResult) {
        return true;
      }
    }
  }
  return false;
}

export function hasEventForMode(
  mode: PlannerMode,
  resourceId: string | number,
  eventStore: EventStore,
) {
  const eventsForMode = eventStore
    .getEventsForResource(resourceId)
    .filter((obj) => isModeCompatible(obj, mode));

  return eventsForMode.length > 0;
}

export function shiftChildrenEvents(event: SchedulerProDropEvent, eventType: string) {
  if (event.eventRecords.length > 0) {
    const queue: Model[] = [];
    let skipFirstNode = true;
    queue.push(event.resourceRecord);

    while (queue.length > 0) {
      const currentNode = queue.shift() as Model;

      if (!skipFirstNode) {
        const childrenEventsAll = event.source.eventStore.getEventsForResource(currentNode.id);
        const childEventActual = childrenEventsAll.find(
          (eventItem) => eventItem.getData("type") === eventType,
        );

        if (childEventActual) {
          childEventActual.startDate = addMilliseconds(
            childEventActual.startDate as Date,
            event.context.timeDiff,
          );
          childEventActual.endDate = addMilliseconds(
            childEventActual.endDate as Date,
            event.context.timeDiff,
          );
        }
      } else {
        skipFirstNode = false;
      }

      for (let i = 0; i < getChildrenLength(currentNode); i++) {
        queue.push((currentNode.children as Model[])[i]);
      }
    }
  }
}

export function getChildrenLength(record: Model) {
  return "children" in record ? (record.children as Model[]).length : 0;
}

export function resetLag(schedulerPro: SchedulerPro, eventId: string | number) {
  (schedulerPro.dependencyStore.allRecords as DependencyModel[])
    .filter(({ to, lag }) => to === eventId && lag !== 0)
    .forEach((dependency) => dependency.set("lag", 0));
}

export function setDefaultStartAndEnd(
  schedulerPro: SchedulerPro,
  record: EventModel,
  now: Date,
  originalStart = null as Date | null,
  originalEnd = null as Date | null,
) {
  const isMilestone = record.isMilestone;
  if (record.getData("type") === "actual" && (record.endDate > now || record.startDate > now)) {
    if (originalStart && originalEnd) {
      record.startDate = originalStart;
      record.endDate = originalEnd;
    } else {
      // resize case
      record.endDate = now;
    }
  } else {
    const defaultStartTime = 7;
    const defaultEndTime = 17;

    // Set start date to current full hour - 1 if start date === today
    const startHour =
      (record.startDate as Date).getDate() === now.getDate() &&
      now.getHours() < defaultStartTime &&
      record.getData("type") === "actual"
        ? now.getHours() - 1
        : defaultStartTime;
    record.startDate = set(new Date(record.startDate), {
      hours: startHour,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    // Set end date to current full hour if end date === today
    const potentialEndHour =
      new Date(record.endDate).getDate() === now.getDate() &&
      now.getHours() < defaultEndTime &&
      record.getData("type") === "actual"
        ? now.getHours()
        : 17;
    const endHour = isMilestone ? startHour : potentialEndHour;
    record.endDate = set(new Date(record.endDate), {
      hours: endHour,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
  }
}

export async function editPlannedEvent(
  schedulerPro: SchedulerPro,
  resourceId: string,
): Promise<void> {
  const events = schedulerPro.eventStore.getEventsForResource(resourceId);
  const plannedEvent = events.find(
    (eventModel: SchedulerEventModel) => eventModel.getData("type") === "planned",
  );
  if (plannedEvent) {
    await schedulerPro.editEvent(plannedEvent);
  }
}

export async function showInfoMenuItem(schedulerPro: SchedulerPro, resourceId: string) {
  const events = schedulerPro.eventStore.getEventsForResource(resourceId);
  const event =
    events.find((item) => item.getData("type") === "actual") ||
    events.find((item) => item.getData("type") === "planned");

  if (event) {
    if (event.getData("type") === "actual") {
      if (typeof schedulerPro.onEventDblClick === "function") {
        schedulerPro.onEventDblClick({
          source: schedulerPro,
          eventRecord: event,
          assignmentRecord: {} as unknown as SchedulerAssignmentModel,
          event: new MouseEvent("dblclick"),
        });
      }
    } else {
      return await schedulerPro.editEvent(event);
    }
  }
}

function generateTooltipContent(events: EventModel[], trackingEnabled: boolean) {
  const encodedLabelsToDecodedLabel = getProcessClasses().reduce((acc, processClass) => {
    acc[processClass.encodedLabel] = processClass.decodedLabel;
    return acc;
  }, {} as Record<string, string>);

  const plannedEvent: EventModel | undefined = events.find(
    (event) => event.getData("type") === "planned",
  );
  const actualEvent: EventModel | undefined = events.find(
    (event) => event.getData("type") === "actual",
  );
  const processEvents: EventModel[] = events.filter((event) =>
    event.getData("type")?.startsWith("process_"),
  );
  if (processEvents.length > 0) {
    const processes = processEvents.reduce((acc, event) => {
      (event.getData("processes") as SimplifiedPlannerProcess[])?.forEach((process) =>
        acc.push(process),
      );
      return acc;
    }, [] as SimplifiedPlannerProcess[]);
    return `
      <div class="flex flex-col gap-3 mt-2">
        <span class="text-sm plannedBg rounded px-1.5 py-0.5 w-min">${format(
          processes[0].start_time,
          "dd.MM.yyyy",
        )}</span>
        ${processes
          .map((process) => {
            const duration = intervalToDuration({
              start: process.start_time,
              end: process.end_time,
            });
            const durationFormattedHours = (duration.hours || 0).toString().padStart(2, "0");
            const durationFormattedMinutes = (duration.minutes || 0).toString().padStart(2, "0");
            return `
            <div class="flex flex-col gap-1 text-sm">
              <span>
                ${
                  t
                    ? t(`process_classes.${process.encoded_label}`)
                    : encodedLabelsToDecodedLabel[process.encoded_label]
                }
              </span>
              <span style="font-weight: 300;" class="text-xs">
                ${format(process.start_time, "HH:mm")} - ${format(process.end_time, "HH:mm")}
                (${durationFormattedHours}:${durationFormattedMinutes}h)
              </span>
            </div>
          `;
          })
          .join("")}
      </div>
    `;
  }

  let html = "";
  if (plannedEvent) {
    const start = plannedEvent.getData("startDate");
    const end = plannedEvent.getData("endDate");
    const durationLabel =
      plannedEvent.getData("durationLabelFn") &&
      plannedEvent.getData("durationLabelFn")(plannedEvent, { excludeDisturbances: true });

    html += `
            <div class="flex gap-3 justify-between items-center text-sm mt-2">
                <span class="plannedBg rounded-lg px-1.5 py-0.5 text-xs">${t(
                  "analytics.planner.target_period",
                )}</span>
                <span style="font-weight: 300;">
                    ${
                      start.getTime() === end.getTime()
                        ? format(start, "dd.MM.yyyy")
                        : format(start, "dd.MM.yyyy") + " - " + format(end, "dd.MM.yyyy")
                    }
                    ${durationLabel ? `(${durationLabel})` : ""}
                </span>
            </div>`;
  }
  if (trackingEnabled) {
    if (actualEvent) {
      const start = actualEvent.getData("startDate");
      const end = actualEvent.getData("endDate");
      const isActive = actualEvent.getData("active");
      const durationLabel =
        actualEvent.getData("durationLabelFn") &&
        actualEvent.getData("durationLabelFn")(actualEvent);
      html += `
            <div class="flex gap-3 justify-between items-center text-sm mt-2">
                <span class="bg-green text-white rounded-lg px-1.5 py-0.5 text-xs">${t(
                  "analytics.planner.actual_period",
                )}</span>
                <span style="font-weight: 300;">
                    ${
                      start.getTime() === end.getTime()
                        ? format(start, "dd.MM.yyyy")
                        : format(start, "dd.MM.yyyy") +
                          " - " +
                          (isActive ? t("analytics.planner.present") : format(end, "dd.MM.yyyy"))
                    }
                    ${durationLabel ? `(${durationLabel})` : ""}
                </span>
            </div>`;
    } else {
      html += `<div class="flex gap-3 justify-between items-center text-sm mt-2">
                <span class="bg-green text-white rounded-lg px-1.5 py-0.5 text-xs">${t(
                  "analytics.planner.actual_period",
                )}</span>
                <span style="font-weight: 300;">${t(
                  "analytics.planner.actual_not_started",
                )}</span></div>`;
    }
  }
  return html;
}

const generateHolidayTooltip = (holidayInfo?: {
  name: string;
  start_date: Date;
  end_date: Date;
  type: string;
}) => {
  if (holidayInfo) {
    const timeRange =
      holidayInfo.start_date.getTime() === holidayInfo.end_date.getTime()
        ? format(holidayInfo.start_date, "dd.MM.yyyy")
        : `${format(holidayInfo.start_date, "dd.MM.yyyy")}-${format(
            holidayInfo.end_date,
            "dd.MM.yyyy",
          )}`;
    return `
      <div class="flex gap-2 text-sm mt-4 px-1 py-0.5 rounded w-min whitespace-nowrap" style="${
        holidayInfo.type === "disturbance"
          ? "background-color: #f5e3be; color: #9c7018"
          : "background-color: #bfdbfe4c; color: #1d4ed8"
      }">
        ${holidayInfo.name} (${timeRange})
      </div>
    `;
  }
  return "";
};

const generateOutagesTooltip = (
  outages: { start_time: Date; end_time: Date; camera_name: string }[] | null,
) => {
  if (!outages || outages.length === 0) {
    return "";
  }
  return `
    <div class="flex flex-col mt-8">
    <h4 class="border-b pb-1 text-base flex gap-2 justify-between items-center">
      ${t("analytics.planner.outages")}
    </h4>
    <div class="flex gap-2 flex-wrap mt-2" style="max-width: 400px">
          ${outages
            .map(
              (outage) => `
            <div class="flex text-sm text-white px-1 py-0.5 rounded w-min whitespace-nowrap bg-red-300">
              <span>${outage.camera_name}:&nbsp;</span>
              <span>${format(outage.start_time, "HH:mm")}-${format(outage.end_time, "HH:mm")}</span>
            </div>
          `,
            )
            .join("")}
    </div>
  `;
};

export const baseSchedulerProConfig = (mode: PlannerMode) => ({
  weekStartDay: 1,
  readOnly: true,
  autoAdjustTimeAxis: true,
  presets: customPresets(),
  viewPreset: "customMonthYear",
  displayDateFormat: "DD.MM.YYYY HH:mm",
  barMargin: 0,
  resourceMargin: 5,
  rowHeight: 25,
  eventLayout: {
    type: "stack",
    weights: {
      planned: 100,
      actual: 200,
      process_prefab: 300,
      process_support: 400,
      process_reinforce: 500,
      process_concrete: 600,
      process_sheath: 700,
    },
    groupBy: "type",
  },
  treeFeature: true,
  stickyEventsFeature: false,
  regionResizeFeature: {
    showSplitterButtons: false,
  },
  columns: [
    {
      text: "#",
      field: "index",
      width: 30,
      filterable: false,
      renderer({ value, cellElement }: { value: unknown; cellElement: HTMLElement }) {
        cellElement.classList.add("plannerPlannerItemNumber");
        return value;
      },
    },
    {
      text: t("analytics.planner.name_column_label"),
      field: "name",
      width: 300,
      indentSize: 1,
      type: "tree",
      cls: "plannerColFilter",
      filterable: {
        filterField: {
          placeholder: t("general.search_placeholder"),
        },
      },
      renderer({
        row,
        value,
        record,
        cellElement,
      }: {
        row: { eachElement: (fn: (el: HTMLElement) => void) => void };
        value: unknown;
        record: Model;
        cellElement: HTMLElement;
      }) {
        cellElement.classList.add("plannerPlannerItemName");
        row.eachElement((el: HTMLElement) => {
          el.style.background = !record.getData("trackingEnabled") ? "#f2f5f7" : "#ffffff";
        });
        if ((record.children as Model[])?.length === 0) {
          cellElement.classList.add("plannerLeafPlannerItem");
        }
        if (record.getData("comments").length > 0) {
          cellElement.classList.add("plannerItemWithComments");
        }
        return value;
      },
    },
  ],

  eventTooltipFeature: {
    template: (data: { startDate: Date; endDate: Date; eventRecord: EventModel }) => {
      const events = data.eventRecord.getData("type")?.startsWith("process_")
        ? [data.eventRecord]
        : (
            data.eventRecord.eventStore.getEventsForResource(
              data.eventRecord.resource.id,
            ) as EventModel[]
          ).filter((event) => !event.getData("type")?.startsWith("process_"));
      let html = `<h4 class="border-b pb-1 text-base">${data.eventRecord.resource.name}</h4>`;
      const trackingEnabled = data.eventRecord.resource.getData("trackingEnabled");
      html += generateTooltipContent(events, trackingEnabled);
      return html;
    },
  },

  scheduleTooltipFeature: {
    generateTipContent(context: { date: Date; resourceRecord: ResourceModel }) {
      const holidayInfo =
        context.resourceRecord.getData("isHolidayFn") &&
        context.resourceRecord.getData("isHolidayFn")(context.date);
      const outages =
        context.resourceRecord.getData("getOutagesFn") &&
        context.resourceRecord.getData("getOutagesFn")(context.date);
      const events = (context.resourceRecord.events as EventModel[]).filter(
        (event) => !event.getData("type")?.startsWith("process_"),
      );
      let html = `<div class="flex flex-col"><h4 class="border-b pb-1 text-base flex gap-2 justify-between items-center">
        <span>${context.resourceRecord.name}</span><span class="text-xs">${format(
        context.date,
        "dd.MM.yyyy",
      )}</span></h4>`;
      const trackingEnabled = context.resourceRecord.getData("trackingEnabled");
      html += generateTooltipContent(events, trackingEnabled);
      html += generateHolidayTooltip(holidayInfo);
      html += generateOutagesTooltip(outages);
      html += "</div> ";
      return html;
    },
  },
  timeRangesFeature: {
    showCurrentTimeLine: {
      name: DateHelper.format(new Date(), "DD.MM.YYYY"),
      disabled: false,
    },
  },
  sortFeature: false,
  cellEditFeature: false,
  cellMenuFeature: {
    disabled: true,
  },
  scheduleMenuFeature: {
    disabled: true,
  },
  eventMenuFeature: {
    disabled: true,
  },
  headerZoomFeature: true,
  dependenciesFeature: {
    allowCreate: false,
    showTooltip: false,
    showCreationTooltip: false,
    clickWidth: 10,
    renderer(event: {
      dependencyRecord: DependencyModel;
      domConfig: { d: string };
      fromBox: { x: number; width: number };
      toBox: { x: number; width: number };
    }) {
      // replace H (horizontal line) with new value, when only one is present and the line is not going to a milestone
      // e.g. "M5710.119047619048,449.5 h118.2481398809523 v28.5"
      if (
        !(event.dependencyRecord.toEvent as EventModel | null)?.isMilestone &&
        event.domConfig.d.match(/h/gi)?.length === 1
      ) {
        const gap = event.toBox.x - (event.fromBox.x + event.fromBox.width);
        const newH = gap + event.toBox.width * 0.05;
        event.domConfig.d = event.domConfig.d.replace(/h\d+(\.\d+)?/i, `h${newH}`);
      }
    },
  },
  eventEditFeature: false,
  eventDragCreateFeature: false,
  rowCopyPasteFeature: false,
  rowReorderFeature: false,
  nonWorkingTimeFeature: true,
  eventNonWorkingTimeFeature: {
    disabled: true,
  },
  panFeature: {
    pan: true,
    eventDragCreate: false,
  },
  project: {
    eventModelClass: VisitorAndTrackerEventModel,
    resourceModelClass: OculaiResourceModel,
    timeRangeStore: new OculaiTimeRangeStore(),
    onDataReady(
      this: SchedulerPro,
      event: {
        isInitialCommit: boolean;
      },
    ) {
      if (event.isInitialCommit) {
        this.project.stm.enable();
      }
    },
    stm: {
      autoRecord: true,
    },
  },
  milestoneLayoutMode: "estimate",
  milestoneAlign: "center",
  resourceTimeRangesFeature: {
    disabled: false,
  },
  eventRenderer: (({ eventRecord, resourceRecord, renderData }) => {
    const renderClass = renderData.cls as DomClassList;

    if (eventRecord.getData("type")?.startsWith("process_")) {
      renderClass.add(
        `plannerProcessEvent plannerProcessEvent_${eventRecord.getData("processElement")}`,
      );
      eventRecord.draggable = false;
      eventRecord.resizable = false;
      return;
    }

    const editable = isModeCompatible(eventRecord, mode);
    const isParent = isParentResource(resourceRecord);
    const isActiveActualEvent =
      eventRecord.getData("type") === "actual" && eventRecord.getData("active");
    const isParentAndTrackerMode = isParent && mode === "tracker";

    eventRecord.draggable = editable && !isParentAndTrackerMode && !isActiveActualEvent;
    eventRecord.resizable = editable && !isParentAndTrackerMode && !isActiveActualEvent;

    if (isParent && !isActiveActualEvent) {
      renderClass.add("plannerParentEvent");
    }
    let activeExtraContent = "";
    if (isActiveActualEvent) {
      renderClass.add(
        isParent
          ? "plannerActiveActualParentEvent"
          : `plannerActiveActualEvent plannerActiveActualEvent-${localeText}`,
      );
      if (!isParent) {
        activeExtraContent = `<span class="plannerActiveActualEventClip"></span></span><span class='plannerActiveActualEventLabel'>● <span>    ${t(
          "general.active",
        )}</span></span>`;
      }
    }

    renderClass.add(
      eventRecord.getData("type") === "actual" ? "plannerActualEvent" : "plannerPlannedEvent",
    );

    if (mode === "revision") {
      renderClass.add("plannerEventInRevisionMode");
    }

    if (eventRecord.isMilestone) {
      renderClass.add("plannerMilestoneEvent");
    }
    return activeExtraContent;
  }) as BryntumSchedulerProProps["eventRenderer"],
});
