<template>
  <Modal :open="true" @close="handleClose" customCls="w-full lg:w-2/3 m-2">
    <template #content>
      <div>
        <div v-if="!isLoading" class="flex flex-col gap-2">
          <ReportFormWithPlot
            :hierarchyTags="hierarchyTags"
            :report="editedReport"
            @change="handleReportChange"
          />
          <div class="justify-between flex-1 flex items-center">
            <button
              @click="handleDeleteReportClick"
              type="button"
              :disabled="isDeleteReportLoading"
              class="flex items-center justify-center rounded-md px-4 py-2 md:text-sm text-xs shadow-sm bg-red-400 text-white disabled:bg-gray-300"
            >
              {{ t("analytics.reports.remove_report") }}
            </button>
            <MainButton
              :label="t('buttons.save')"
              type="button"
              color="yellow"
              :style="{ margin: 0 }"
              @click="handleUpdateClick"
              :isDisabled="isReportUpdating"
            >
              <LoadingSpinner size="w-5 h-5 mr-2" v-if="isReportUpdating" />
            </MainButton>
          </div>
        </div>
        <div v-else class="flex items-center justify-center h-[400px]">
          <LoadingSpinner />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useConfirmationModal, useConfirmationModalIsOpen } from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import MainButton from "@/components/other/MainButton.vue";
import { useDeleteReport, useUpdateReport } from "@/composables/report";
import { Report, ReportToCreateOrUpdate } from "@/types/Report";
import ReportFormWithPlot from "@/views/reports/components/ReportFormWithPlot.vue";

const props = defineProps<{ report: Report }>();
const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "done", report: Report): void;
}>();

const { t } = useI18n();

const trackEvent = useTrackEvent();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const { updateReport, isLoading: isReportUpdating } = useUpdateReport();
const { deleteReport, isLoading: isDeleteReportLoading } = useDeleteReport();

const showConfirmationModal = useConfirmationModal();
const isConfirmationModalOpen = useConfirmationModalIsOpen();

const editedReport = ref<Report>({ ...props.report });

const isLoading = computed(() => areHierarchyTagsLoading.value);

const handleReportChange = (change: Partial<Report>) => {
  editedReport.value = { ...editedReport.value, ...change };
};

const handleClose = () => {
  if (isConfirmationModalOpen.value) {
    return;
  }
  emit("close");
};

const handleUpdateClick = () => {
  if (isReportUpdating.value) {
    return;
  }
  const reportToUpdate: ReportToCreateOrUpdate = {
    _id: editedReport.value._id,
    type: editedReport.value.type,
    name: editedReport.value.name || props.report.name,
    filters: editedReport.value.filters,
    config: editedReport.value.config,
    aggregation: editedReport.value.aggregation,
  };
  updateReport(reportToUpdate)
    .then((updatedReport) => {
      emit("done", updatedReport);
    })
    .catch(() => undefined);
};

const handleDeleteReportClick = () => {
  if (!editedReport.value || isDeleteReportLoading.value) {
    return;
  }
  const reportId = editedReport.value._id;
  showConfirmationModal({
    confirmAction: t("analytics.reports.delete_confirmation.confirm"),
    cancelAction: t("analytics.reports.delete_confirmation.cancel"),
    message: t("analytics.reports.delete_confirmation.message", {
      name: editedReport.value.name,
    }),
    header: t("analytics.reports.delete_confirmation.header"),
  }).then((confirmed) => {
    if (confirmed) {
      trackEvent("dashboard_widget_report_delete", { type: editedReport?.value?.type });
      deleteReport(reportId).catch(() => undefined);
    }
  });
};

watch(
  () => props.report,
  () => {
    editedReport.value = { ...props.report };
  },
);
</script>
