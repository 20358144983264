<template>
  <div>
    <div>
      <h3 class="lg:text-lg leading-4 font-semibold text-gray-900">
        {{ $t("report.sections.gallery.custom_images_title") }}
      </h3>
    </div>

    <input
      type="file"
      @change="handleFileUpload"
      multiple
      enctype="multipart/form-data"
      accept=".jpg,.jpeg,.png,.heic, .heif, image/jpeg"
      ref="imgInput"
      id="img-imgInput"
      class="hide-file-input p-o absolute h-1 w-1 overflow-hidden whitespace-nowrap border-0"
    />
    <div class="grid lg:grid-cols-3 sm:grid-cols-2 gap-3 mt-6">
      <div v-for="(image, index) in images" :key="index">
        <div class="relative flex items-center justify-center">
          <img :src="image.url" alt="Uploaded Image" />
          <div class="absolute right-0 top-0 pr-2 pt-2">
            <button
              type="button"
              class="rounded-md bg-gray-50 p-1 text-gray-500 hover:text-red-700"
              @click="deleteImage(index)"
            >
              <span class="sr-only">Close</span>
              <TrashIcon class="lg:h-5 h-4" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <div
          :class="[
            'bg-gray-200 rounded-md aspect-h-3 aspect-w-4 flex items-center justify-center object-contain',
            { 'bg-red': isDragging },
          ]"
          @click="openFileInput"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop.prevent="drop"
        >
          <div v-if="loading" class="flex items-center justify-center">
            <svg
              class="w-6 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <button class="mx-auto w-max" v-else>
            <PlusIcon class="xl:w-10 xl:h-10 w-5 h-5 hover:text-yellow" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TrashIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { defineComponent } from "vue";
import logger from "shared/services/logger";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import { ReportImageEntry } from "@/types/DailyReport";

export default defineComponent({
  name: "GalleryImageUpload",
  props: ["savedImages", "customImages"],
  emits: ["addCustom"],
  components: {
    TrashIcon,
    PlusIcon,
  },

  data() {
    return {
      images: this.customImages as ReportImageEntry[],
      isDragging: false as boolean,
      allowedExtensions: [".jpg", ".jpeg", ".png", ".heic", ".heif"],
      loading: false as boolean,
    };
  },

  methods: {
    async handleFileUpload(event: Event) {
      const target = event.target as HTMLInputElement;
      const files = target.files as FileList;
      let filesToUpload = Array.from(files);

      const handleImageLoad = (imageUrl: string) => {
        this.saveCustomImage(imageUrl);
      };

      const handleFileRead = () => {
        return (event: ProgressEvent<FileReader>) => {
          const target = event.target as FileReader;
          const imageUrl = target.result as string;
          handleImageLoad(imageUrl);
        };
      };

      const heic2any = (await import("heic2any")).default;

      for (const file of filesToUpload) {
        if (file.type === "image/heif" || file.type === "image/heic") {
          this.loading = true;
          try {
            const convertedBlob = await heic2any({
              blob: file,
              toType: "image/jpeg",
            });
            const reader = new FileReader();
            reader.onload = handleFileRead();
            reader.readAsDataURL(convertedBlob as Blob);
          } catch (error) {
            logger.error(error);
          }
        } else {
          const reader = new FileReader();
          reader.onload = handleFileRead();
          reader.readAsDataURL(file);
          filesToUpload = [];
        }
      }
    },
    deleteImage(index: number) {
      this.images.splice(index, 1);
    },
    openFileInput() {
      const input = this.$refs.imgInput as HTMLInputElement;
      input.click();
    },
    dragover(e: Event) {
      this.isDragging = true;
      e.preventDefault();
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e: DragEvent) {
      e.preventDefault();
      this.isDragging = false;
      const file = e.dataTransfer?.files[0] as File;
      if (this.checkFileExtension(file)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const target = e.target as FileReader;
          const imageUrl = target.result as string;
          this.saveCustomImage(imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    checkFileExtension(file: File) {
      return this.allowedExtensions.some(
        (extension) => file?.name?.toLowerCase().endsWith(extension) || file?.type === "image/heic", // Check for HEIC file type
      );
    },

    saveCustomImage(url: string) {
      this.loading = true;
      const { customer_name, site_id } = this.$route.params;

      return DailyReportRepository.uploadDailyReportCustomImage(
        customer_name as string,
        site_id as string,
        url,
      )
        .then((data) => {
          const singleImg = {
            url: data.url,
            id: data.id,
            source: "custom",
            camera_id: "",
            name: "",
            timestamp: "",
            width: data.width,
            height: data.height,
          } as ReportImageEntry;
          this.images.push(singleImg);
          this.$emit("addCustom", this.images);
        })
        .catch((error) => {
          logger.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
