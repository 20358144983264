<template>
  <div class="pt-8 pb-6" v-show="weatherSummary && !error">
    <h3 class="text-md 2xl:text-md leading-6 font-medium text-gray-900">
      {{ $t("camera.components.weather_container.title") }}
    </h3>
    <p class="text-xs py-2" v-if="!error && !loading">
      {{ currentDate }}{{ weatherMeta.location_name }}
    </p>
    <div v-if="loading" :class="{ 'mt-2': loadWeather }">
      <div class="loadingCard is-loading">
        <div
          class="mt-4 group block w-full aspect-w-10 aspect-h-1 rounded-lg bg-gray-50 overflow-hidden"
        />
      </div>
    </div>

    <div v-if="!error && !loading">
      <div
        v-if="weatherToggle"
        class="grid grid-cols-3 gap-4 items-center bg-white border-t border-gray-200"
      >
        <div class="flex items-center">
          <img
            class="h-12 w-12 xl:h-16 xl:w-16 rounded-full"
            :src="getImgUrl(weatherSummary.icon_local)"
            alt=""
          />
          <div class="px-2 py-4 sm:text-xs whitespace-nowrap">
            <p class="2xl:text-lg text-gray-600">
              {{ Math.round(weatherSummary.avg_temperature) }}°C
            </p>
          </div>
        </div>
        <div>
          <p class="text-xs text-thin">Min: {{ weatherSummary.min_temperature }} °C</p>
          <p class="text-xs text-thin">Max: {{ weatherSummary.max_temperature }} °C</p>
        </div>

        <div class="px-2 py-4 text-xs whitespace-nowrap text-gray-500">
          {{ weatherSummary.wind }}
          <p class="px-2 text-xs whitespace-nowrap text-thin text-gray-600">
            <font-awesome-icon icon="fa-solid fa-droplet" />
            {{ formatNumber(weatherSummary.sum_rain) }}mm
          </p>
          <p class="px-2 text-xs whitespace-nowrap text-thin text-gray-600">
            <font-awesome-icon icon="fa-solid fa-wind" />
            {{ weatherSummary.max_wind }}
            {{ $t("dashboard.project.weather_card.kmh") }}
          </p>
        </div>
      </div>
      <div v-else>
        <nav class="pt-2 max-h-44 2xl:max-h-96 overflow-y-auto sm:overflow-x-hidden">
          <div class="-my-2 sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-4 lg:px-4">
              <div class="shadow overflow-hidden sm:rounded-lg">
                <table class="min-w-full">
                  <tbody class="bg-white divide-y divide-gray-200 overflow-auto">
                    <tr v-for="(item, index) in weatherItems" :key="index">
                      <td
                        class="pr-2 sm:pl-6 py-2 whitespace-nowrap text-gray-900"
                        v-if="item.icon_local"
                      >
                        <div class="flex items-center">
                          <img
                            class="h-6 w-6 2xl:h-12 2xl:w-12 rounded-full"
                            :src="getImgUrl(item.icon_local)"
                            alt=""
                          />
                          <p class="text-base 2xl:text-lg text-gray-600 px-2">
                            {{ item.temperature }}°C
                          </p>
                        </div>
                      </td>
                      <td class="px-2 py-4 text-xs text-gray-500">
                        <div class="ml-2 flex 3xl:block justify-items-end flex-wrap">
                          <p class="px-2 whitespace-nowrap text-thin text-gray-600">
                            <font-awesome-icon icon="fa-solid fa-droplet" />
                            {{ formatNumber(item.rain) }}mm
                          </p>
                          <p class="px-2 whitespace-nowrap text-thin text-gray-600">
                            <font-awesome-icon icon="fa-solid fa-wind" />
                            {{ item.wind }}
                            {{ $t("dashboard.project.weather_card.kmh") }}
                          </p>
                        </div>
                      </td>
                      <td class="px-2 py-4 text-xs whitespace-nowrap font-medium text-thin">
                        <font-awesome-icon icon="fa-regular fa-clock" />
                        {{ formatTime(item.timestamp, "HH:mm") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div>
        <button @click="weatherToggle = !weatherToggle">
          <span class="text-xs 2xl:text-sm pt-2 underline">{{
            weatherToggle
              ? $t("camera.components.weather_container.view_more")
              : $t("camera.components.weather_container.view_less")
          }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faDroplet, faWind } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dayjs from "dayjs";
import logger from "shared/services/logger";
import WeatherRepository from "@/repositories/WeatherRepository";

library.add(faDroplet, faWind, faClock);

export default {
  name: "WeatherContainer",
  props: ["loadMain", "date"],
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      weatherToggle: true,
      weatherItems: [],
      weatherMeta: {},
      weatherSummary: {},
      currentDate: "",
      location: "",
      iconLocalLink: "",
      loadWeather: false,
      errorMessage: "",
      noWeatherData: true,
      error: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.query_weather(this.date);
    });
  },

  computed: {
    loading() {
      return this.loadMain || this.loadWeather;
    },
  },

  watch: {
    date(newValue) {
      this.query_weather(newValue);
    },
  },
  methods: {
    getImgUrl(icon) {
      try {
        if (!icon) {
          return null;
        }
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        return require("@/assets/imgs/weather_icons/" + icon + ".png");
      } catch (error) {
        logger.error(error);
        return null;
      }
    },
    formatTime(value, pattern) {
      return dayjs(value).format(pattern);
    },

    //Formatting decimals
    formatNumber(number) {
      return number ? (Number.isInteger(number) ? number.toString() : number.toFixed(2)) : 0;
    },
    //Query weather data
    query_weather(queryDate) {
      if (!queryDate) {
        return;
      }
      this.loadWeather = true;

      return WeatherRepository.getWeatherForDate(
        this.currentCustomerName,
        this.currentSiteId,
        queryDate,
      )
        .then((data) => {
          if (data) {
            this.noWeatherData = false;
            this.weatherItems = data.items;
            this.weatherMeta = data.meta;
            this.weatherSummary = data.weather_summary;
            this.currentDate = "(" + this.formatTime(queryDate, "DD.MM.YYYY") + ")" + " ";
          } else {
            this.error = true;
            this.errorMessage = this.$t("err.no_weather_data");
          }
        })
        .catch(() => {
          this.error = true;
          this.errorMessage = this.$t("err.no_weather_data");
        })
        .finally(() => {
          this.loadWeather = false;
          this.$emit("requestDone");
        });
    },
  },
};
</script>
