import { computed, Ref } from "vue";

export const useDifferenceTextClass = (difference: Ref<number | null>) =>
  computed(() => {
    if (difference.value === null) {
      return undefined;
    }
    if (difference.value === 0) {
      return "text-gray-600";
    }
    return difference.value > 0 ? "text-green-400" : "text-red-400";
  });

export const useDifferenceTextWithBackgroundClass = (difference: Ref<number | null>) =>
  computed(() => {
    if (difference.value === null) {
      return undefined;
    }
    if (difference.value === 0) {
      return "bg-gray-400 text-white";
    }
    return difference.value > 0 ? "bg-green-50 text-green-600" : "bg-red-200 text-red-800";
  });
