import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    class: _normalizeClass([
      'focus:outline-none relative w-full sm:w-auto inline-flex items-center justify-center rounded-md border mt-2 focus:ring-2 sm:mt-0 px-4 py-2 md:text-sm text-xs shadow-sm font-medium',
      {
        'border-gray-300 border-solid bg-white text-gray-700 hover:bg-gray-50': _ctx.bordered,
        'text-white': _ctx.color,
      },
      _ctx.isDisabled ? 'bg-gray-300' : `bg-${_ctx.color}-600 hover:bg-${_ctx.color}-700 focus:ring-transparent`,
      _ctx.customClass,
    ]),
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked')))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
  ], 10, _hoisted_1))
}