<template>
  <div
    class="border-b border-r border-gray-300 group relative bg-gray-50 px-3 pt-3 pb-1 text-gray-500"
    style="min-height: 7rem"
  >
    <div class="flex items-center justify-between">
      <div
        :datetime="date"
        :class="[
          isToday
            ? 'flex items-center justify-center border-b border-orange font-semibold sm:rounded-full sm:bg-orange-400 sm:text-white'
            : '',
          'h-6 w-6',
        ]"
      >
        {{ formatDateToDay(date) }}
      </div>
      <div class="flex items-center">
        <OaiTooltip v-if="isProjectEnd || isProjectStart" position="top">
          <FlagIcon class="mt-2r right-0 w-5 text-blue-700" />
          <template #tooltip>
            <div class="text-center text-xs">
              <p>
                {{
                  `${
                    isProjectStart
                      ? $t("report.planned_project_start")
                      : $t("report.planned_project_end")
                  } (${formatDate(date)})`
                }}
              </p>
            </div>
          </template>
        </OaiTooltip>
        <OaiTooltip v-if="data.report.status == 'APPROVED'" position="top">
          <p class="text-lg text-red-700">
            <CheckCircleIcon class="mt-2r right-0 w-5 text-green-400" />
          </p>
          <template #tooltip>
            <div class="text-center text-xs">
              <p>
                {{ $t("report.general.approved_at") }}:
                {{ formatIsoTimestamp(data.report.updated) }}
              </p>
              <p>{{ $t("report.general.by") + ": " + data.report.updated_by }}</p>
            </div>
          </template>
        </OaiTooltip>
        <div v-if="hasReport">
          <Menu as="div" class="inline-block text-left leading-none relative">
            <MenuButton class="flex items-center rounded-full text-gray-500">
              <EllipsisVerticalIcon class="mt-2r right-0 w-5 text-gray-500" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-5 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray/5 focus:outline-none"
              >
                <div class="py-1 divide-y">
                  <MenuItem v-slot="{ active }" @click="$emit('deleteReport')">
                    <a
                      href="#"
                      :class="[
                        active ? 'text-red-700' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      ]"
                    >
                      <TrashIcon :class="[active ? 'text-red-700' : 'text-gray-700', 'w-4 h-4']" />
                      <span class="ml-2 mt-0.5">
                        {{ $t("report.delete_report") }}
                      </span>
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <router-link
      :to="hasReport ? { name: 'SingleDailyReport', params: { date: `${date}` } } : ''"
      v-if="data.report.status !== 'NOT_AVAILABLE'"
      class="cursor-pointer group my-1.5 flex h-6 w-full items-center justify-center border-l-4 border-gray-300 bg-gray-100 px-3 text-gray-500 hover:bg-yellow-100"
    >
      <div
        v-if="data.report.site_duration.start_time && data.report.site_duration.end_time"
        class="flex w-full justify-center"
      >
        <div :datetime="data.report.site_duration.start_time" class="mr-2 hidden xl:block">
          {{ formatTime(data.report.site_duration.start_time) }}
        </div>
        <span> - </span>
        <div :datetime="data.report.site_duration.end_time" class="ml-2 hidden xl:block">
          {{ formatTime(data.report.site_duration.end_time) }}
        </div>
      </div>
    </router-link>
    <div v-else class="flex w-full items-center justify-center">
      <button
        type="button"
        @click="$emit('initializeReport')"
        class="cursor-pointer relative inline-flex items-center p-2 border-yellow rounded-full text-yellow-700 hover:bg-yellow-500 hover:text-white focus:outline-none"
      >
        <PlusIcon class="w-5" />
      </button>
    </div>

    <div class="flex flex-wrap items-center justify-center">
      <div
        v-if="holidaysForDate.length > 0"
        class="hidden lg:flex flex-wrap my-1 justify-center px-3 text-gray-500"
      >
        <template v-for="holiday in holidaysForDate" :key="holiday.name">
          <div
            :title="holiday.name"
            class="m-0.5 inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-blue-700 list-group-item"
            :style="
              holiday.type === 'disturbance'
                ? 'background-color: #f5e3be; color: #9c7018'
                : 'background-color: #bfdbfe4c'
            "
          >
            {{ holiday.name.length > 20 ? `${holiday.name.substring(0, 20)}...` : holiday.name }}
          </div>
        </template>
      </div>
      <OaiTooltip simple v-if="data.weather_warnings.length !== 0" position="top">
        <p class="text-red" @click.stop.prevent>
          <font-awesome-icon icon="fa-solid fa-cloud-bolt" class="h-5" />
        </p>
        <template #tooltip>
          <div class="text-center text-xs" @click.stop.prevent>
            <template v-for="warning in data.weather_warnings" :key="warning.value">
              <p class="leading-1 pl-2">
                {{ $t(`report.weather.${warning.type}`) }}:
                <span v-if="warning.type == 'wind'"
                  >{{ warning.value }} {{ $t(`report.weather.${warning.type}_type`) }}
                </span>
                <span v-if="warning.type == 'cold_temperature'"
                  >{{ `${warning.min_value} ` }} {{ $t(`report.weather.${warning.type}_type`) }} ({{
                    $t(`report.weather.min`)
                  }})</span
                >
                <span v-if="warning.type == 'hot_temperature'"
                  >{{ `${warning.max_value} ` }} {{ $t(`report.weather.${warning.type}_type`) }} ({{
                    $t(`report.weather.max`)
                  }})</span
                >
                <span v-if="warning.type == 'precipitation'">
                  <span
                    v-if="
                      warning.rule.time_period == '7/7-14' &&
                      warning.rule.duration_operator == 'ENTIRE_TIME_PERIOD'
                    "
                  >
                    {{ `${warning.value} ${warning.rule.volume_unit} (07:00 - 14:00)` }}</span
                  >
                  <span v-if="warning.rule.time_period == '24/7-7'">
                    {{ `${warning.value} ${warning.rule.volume_unit} (24h)` }}</span
                  >
                  <span
                    v-if="
                      warning.rule.duration_operator == '>=' && warning.rule.volume_unit == 'mm'
                    "
                  >
                    {{ `${warning.value} ${warning.rule.volume_unit}` }} ({{
                      formatTime(warning.start_time)
                    }}-{{ formatTime(warning.end_time) }})</span
                  >
                  <span
                    v-if="
                      warning.rule.duration_operator == '>=' && warning.rule.volume_unit == 'mm/h'
                    "
                  >
                    {{ `>= ${warning.rule.volume_value} ${warning.rule.volume_unit}` }} ({{
                      formatTime(warning.start_time)
                    }}-{{ formatTime(warning.end_time) }})</span
                  >
                </span>
              </p>
            </template>
          </div>
        </template>
      </OaiTooltip>
      <OaiTooltip position="top" v-if="data.report.special_events.length">
        <p>
          <ExclamationCircleIcon class="h-6 text-yellow" />
        </p>
        <template #tooltip>
          <ul>
            <li
              class="mx-0.5 my-0.5 truncate rounded px-1 text-xs"
              v-for="event in data.report.special_events"
              :key="event.special_events_type.value"
            >
              {{
                event.special_events_type.mode === "default"
                  ? $t(
                      `report.sections.special_events.special_events_type_entries.${event.special_events_type.value}`,
                    )
                  : event.special_events_type.value
              }}
            </li>
          </ul>
        </template>
      </OaiTooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  PlusIcon,
  FlagIcon,
  EllipsisVerticalIcon,
  TrashIcon,
} from "@heroicons/vue/24/solid";
import { format, parseISO, isToday, parse, isSameDay, isWithinInterval, getDate } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { defineComponent, PropType } from "vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useCurrentProject } from "@/composables/project";
import { OverviewItem } from "@/types/DailyReport";

const parseUtcDate = (dateText: string) => toZonedTime(parseISO(dateText), "UTC");

library.add(faCloudBolt);
export default defineComponent({
  setup() {
    const project = useCurrentProject();
    const { projectDurationSettings } = useProjectDurationSettings();

    return { projectDurationSettings, project };
  },
  props: {
    date: {
      type: String as PropType<string>,
      required: true,
    },
    data: {
      type: Object as PropType<OverviewItem["data"]>,
      required: true,
    },
  },
  emits: ["initializeReport", "deleteReport"],
  components: {
    OaiTooltip,
    ExclamationCircleIcon,
    CheckCircleIcon,
    EllipsisVerticalIcon,
    PlusIcon,
    FlagIcon,
    TrashIcon,
    FontAwesomeIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },

  computed: {
    hasReport() {
      return this.data.report.status !== "NOT_AVAILABLE";
    },
    isToday() {
      return isToday(parse(this.date, "yyyy-MM-dd", new Date()));
    },
    isProjectStart() {
      return isSameDay(parse(this.date, "yyyy-MM-dd", new Date()), this.project.planned_start);
    },
    isProjectEnd() {
      return (
        this.project.planned_end &&
        isSameDay(parse(this.date, "yyyy-MM-dd", new Date()), this.project.planned_end)
      );
    },
    holidaysForDate() {
      return this.projectDurationSettings
        ? this.projectDurationSettings.non_working_days.filter(
            (item: { start_date: Date; end_date: Date }) =>
              isWithinInterval(parse(this.date, "yyyy-MM-dd", new Date()), {
                start: item.start_date,
                end: item.end_date,
              }),
          )
        : [];
    },
  },

  methods: {
    formatTime(dateString: string) {
      return format(parseUtcDate(dateString), "HH:mm");
    },
    formatIsoTimestamp(timestamp: string) {
      return format(parseISO(timestamp), "dd.MM.yyyy HH:mm:ss");
    },
    formatDate(date: string) {
      return format(parseISO(date), "dd.MM.yyyy");
    },
    formatDateToDay(date: string) {
      return getDate(parseISO(date));
    },
  },
});
</script>
