<template>
  <div class="flex flex-col gap-2">
    <label class="flex items-center cursor-pointer w-fit">
      <div
        v-if="!showPlannedEnabled"
        class="h-4 w-4 inline-block rounded border border-gray-300 bg-gray-100 cursor-not-allowed"
      />
      <input
        v-else
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500 cursor-pointer"
        v-model="showPlanned"
        v-bind="showPlannedProps"
        @change="handleConfigChange"
      />
      <span :class="['ml-2', !showPlannedEnabled && 'text-gray-400']">{{
        t("analytics.reports.show_planned")
      }}</span>
    </label>
    <label class="flex items-center cursor-pointer w-fit">
      <input
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500 cursor-pointer"
        v-model="showActiveDays"
        v-bind="showActiveDaysProps"
        @change="handleConfigChange"
      />
      <span class="ml-2">{{ t("working_day.active_days", 2) }}</span>
    </label>
    <label class="flex items-center cursor-pointer w-fit">
      <input
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500 cursor-pointer"
        v-model="showInactiveDays"
        v-bind="showInactiveDaysProps"
        @change="handleConfigChange"
      />
      <span class="ml-2">{{ t("working_day.inactive_days", 2) }}</span>
    </label>
    <label class="flex items-center cursor-pointer w-fit">
      <input
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500 cursor-pointer"
        v-model="showOutages"
        v-bind="showOutagesProps"
        @change="handleConfigChange"
      />
      <span class="ml-2">{{ t("working_day.outage") }}</span>
    </label>
    <label class="flex items-center cursor-pointer w-fit">
      <div
        v-if="!useHasProjectFeature('working_hours')"
        class="h-4 w-4 inline-block rounded border border-gray-300 bg-gray-100 cursor-not-allowed"
      />
      <input
        v-else
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500 cursor-pointer"
        v-model="showUtilization"
        v-bind="showUtilizationProps"
        @change="handleConfigChange"
      />
      <span :class="['mx-2', !useHasProjectFeature('working_hours') && 'text-gray-400']">{{
        t("analytics.reports.show_utilization")
      }}</span>
      <OaiTooltip position="top" v-if="!useHasProjectFeature('working_hours')">
        <LockClosedIcon class="h-4 w-4" />
        <template #tooltip>
          <div class="text-sm max-w-64 lg:max-w-96 text-wrap">
            {{ $t("paywall.working_hours") }}
          </div>
        </template>
      </OaiTooltip>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { LockClosedIcon } from "@heroicons/vue/24/outline";
import { useForm } from "vee-validate";
import { computed, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import {
  CycleTimesAggregation,
  CycleTimesReportConfig,
  CycleTimesReportFilters,
} from "@/types/reports/PlotCycleTimes";

const props = defineProps<{
  config: CycleTimesReportConfig;
  filters: CycleTimesReportFilters;
  aggregation: CycleTimesAggregation;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "configChange", config: CycleTimesReportConfig): void }>();

const { t } = useI18n();
const processClasses = useProcessClasses();

const initialValues = computed(() => ({
  showPlanned: props.config.show_planned,
  showOutages: props.config.show_outages,
  showUtilization: props.config.show_utilization,
  showActiveDays: props.config.show_active_days,
  showInactiveDays: props.config.show_inactive_days,
}));

const showPlannedEnabled = ref(false);

const { defineField } = useForm({
  initialValues: initialValues.value,
});

const [showPlanned, showPlannedProps] = defineField("showPlanned");
const [showUtilization, showUtilizationProps] = defineField("showUtilization");
const [showOutages, showOutagesProps] = defineField("showOutages");
const [showActiveDays, showActiveDaysProps] = defineField("showActiveDays");
const [showInactiveDays, showInactiveDaysProps] = defineField("showInactiveDays");

watch(
  [() => props.config, () => props.filters],
  () => {
    const reportProcessFilter = props.filters.processes;

    showPlanned.value = props.config.show_planned;
    showUtilization.value = props.config.show_utilization;
    showOutages.value = props.config.show_outages;
    showActiveDays.value = props.config.show_active_days;
    showInactiveDays.value = props.config.show_inactive_days;

    let shouldShowPlannedChart = false;

    if (
      reportProcessFilter.mode === "single_process" &&
      (!reportProcessFilter.single_processes.length ||
        reportProcessFilter.single_processes.length === processClasses.value.length)
    ) {
      shouldShowPlannedChart = true;
    } else if (reportProcessFilter.mode === "component") {
      const isAggregatedBySection = props.aggregation === "section";
      const isAggregatedByPreset =
        props.hierarchyTags.filter((tag) => {
          const componentToCompare = reportProcessFilter.components.find((component) => {
            return component.id === tag._id;
          });

          if (!componentToCompare) {
            return false;
          }

          const isAllProcessesSelected = (tag.attached_processes || []).every((process) => {
            return componentToCompare.processes.includes(process);
          });

          return tag.type === "component" && isAllProcessesSelected;
        }).length === 1;

      const noProcessSelected = !reportProcessFilter.components.some((component) => {
        return component.processes.length;
      });

      shouldShowPlannedChart = (isAggregatedBySection && isAggregatedByPreset) || noProcessSelected;
    }

    showPlannedEnabled.value = shouldShowPlannedChart;
  },
  { immediate: true },
);

const handleConfigChange = () => {
  const config: CycleTimesReportConfig = {
    show_planned: showPlanned.value,
    show_outages: showOutages.value,
    show_utilization: showUtilization.value,
    show_active_days: showActiveDays.value,
    show_inactive_days: showInactiveDays.value,
  };
  emit("configChange", config);
};
</script>
