<template>
  <OculaiVideoPlayer
    :loading="loading"
    :src="videoUrl"
    :hls="true"
    :addRightSpaceToControlBar="addRightSpaceToControlBar"
  />
</template>

<script lang="ts" setup>
import { onMounted, PropType, ref } from "vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import logger from "shared/services/logger";
import OculaiVideoPlayer from "@/components/other/OculaiVideoPlayer.vue";
import StreamRepository from "@/repositories/StreamRepository";

const videoUrl = ref<string | null>(null);
const loading = ref(false);

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const emit = defineEmits(["videoUrlError"]);

const props = defineProps({
  cameraId: {
    type: String,
    required: true,
  },
  noSrcMessage: {
    type: String as PropType<string | null>,
    required: false,
  },
  addRightSpaceToControlBar: {
    type: Boolean,
    required: false,
  },
});

onMounted(() => {
  loading.value = true;
  StreamRepository.loadLiveUrl(currentCustomerName, currentSiteId, props.cameraId)
    .then((url) => {
      videoUrl.value = url;
    })
    .catch((error) => {
      videoUrl.value = null;
      logger.error(error);
    })
    .finally(() => {
      loading.value = false;
      if (!videoUrl.value) {
        emit("videoUrlError");
      }
    });
});
</script>
