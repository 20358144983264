<template>
  <div class="flex flex-col w-full" v-if="!isLoading">
    <h1 class="text-xl font-bold text-gray-900 px-1">
      {{ $t("admin.project_settings.tab_project_participants") }}
    </h1>
    <div class="flex flex-col py-6 w-full" v-if="projectUsers.length">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden settingsCard p-0">
            <div class="divide-y divide-gray-200 sm:block">
              <div class="bg-gray-50 sm:grid-cols-12 sm:gap-2 items-center hidden sm:grid">
                <div
                  class="px-3 py-3 col-span-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ $t("admin.project_participants.table_user") }}
                </div>
                <div
                  class="px-3 py-3 col-span-8 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ $t("admin.project_participants.table_permissions") }}
                </div>
              </div>
              <div class="divide-y divide-gray-200 bg-white">
                <div
                  v-for="projectUser in projectUsers"
                  :key="projectUser._id"
                  class="grid sm:grid-cols-12 items-center xl:gap-4 gap-2 py-4"
                >
                  <div
                    @dblclick="openAdminUpdateUserPropertiesModal(projectUser)"
                    class="px-3 col-span-3 flex flex-col 2xl:text-sm text-xs break-all font-medium text-gray-900"
                  >
                    <div v-if="projectUser.user_status === 'confirmed'">{{ projectUser.name }}</div>
                    <div
                      v-else
                      class="inline-flex items-center gap-x-1.5 rounded-full bg-orange-100 px-2 py-1 text-xs font-medium text-orange-600 max-w-max"
                    >
                      <svg class="h-1.5 w-1.5 fill-orange-400" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx="3" cy="3" r="3" />
                      </svg>
                      {{ $t("admin.project_participants.pending_invite") }}
                    </div>
                    <small class="truncate text-gray-500"> {{ projectUser.email }}</small>
                    <small class="truncate text-gray-400">
                      {{
                        projectUser.role
                          ? $t(`admin.project_participants.user_roles.${projectUser.role}`) +
                            (getProjectCompanyTypeForUser(projectUser)
                              ? ` (${$t(
                                  `admin.project_participants.company_types.${getProjectCompanyTypeForUser(
                                    projectUser,
                                  )}`,
                                )})`
                              : "")
                          : getProjectCompanyTypeForUser(projectUser)
                          ? `(${$t(
                              `admin.project_participants.company_types.${getProjectCompanyTypeForUser(
                                projectUser,
                              )}`,
                            )})`
                          : ""
                      }}
                    </small>
                  </div>
                  <div
                    class="flex flex-wrap col-span-8 items-center whitespace-nowrap gap-1 px-3 text-sm text-gray-500"
                  >
                    <div
                      v-for="permission in getSortedFeaturePermissionsForUser(projectUser)"
                      :key="permission"
                    >
                      <div
                        v-if="permission !== 'project_base'"
                        class="inline-flex rounded-full px-2 text-xs font-semibold leading-5"
                        :class="
                          permission in permissionLabelsColors
                            ? permissionLabelsColors[permission as 'project_admin' | 'all_camera_features_user' | 'live_camera_feature_user']
                            : 'bg-yellow-300 text-yellow-800'
                        "
                      >
                        {{ $t(`permissions.${permission}`) }}
                      </div>
                    </div>
                  </div>
                  <div class="px-2 text-xs flex justify-self-end text-gray-500">
                    <div class="mr-3 text-gray-400 cursor-pointer hover:text-gray-500">
                      <PencilIcon
                        @click.prevent="
                          isEditProjectPermissionModalOpen = true;
                          selectedUser = projectUser;
                        "
                        id="feature-add-button"
                        class="h-4"
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      @click="
                        isDeleteUserModalOpen = true;
                        selectedUser = projectUser;
                      "
                      class="block text-gray-400 cursor-pointer hover:text-gray-500"
                    >
                      <TrashIcon class="h-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="{ 'mt-6': !projectUsers.length }">
      <MainButton
        color="yellow"
        :label="$t('admin.project_participants.add_participants')"
        @clicked="isInviteUsersModalOpen = true"
      >
        <PlusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      </MainButton>
    </div>
  </div>
  <LoadingSection v-else :loading="isLoading" />

  <Modal
    :open="isInviteUsersModalOpen"
    @close="isInviteUsersModalOpen = false"
    custom-cls="sm:w-full lg:w-[650px]"
  >
    <template #title>
      {{ $t("admin.project_participants.modal_invite_user.invite_users") }}
      <hr class="mt-2" />
    </template>

    <template #content>
      <Form
        @submit="handleSubmitInviteUsers"
        :initialValues="{ users: [{ email: '', company_type: '' }] }"
        :validationSchema="inviteSchema"
        class="flex flex-col gap-2 w-full"
        v-slot="{ errors }"
      >
        <FieldArray name="users" v-slot="{ fields, push, remove }">
          <div v-for="(field, idx) in fields" :key="idx">
            <div class="flex items-center gap-4">
              <InputField
                :name="`users[${idx}].email`"
                :label="idx === 0 ? $t('admin.project_participants.email') : ''"
                class="w-[250px]"
                :class="{
                  'mb-[19px]': errors[`users[${idx}].company_type`],
                }"
              />
              <SelectListField
                :name="`users[${idx}].company_type`"
                :label="idx === 0 ? $t('admin.project_participants.company_type') : ''"
                :options="companyTypesList"
                :minWidth="250"
                :placeholder="`--
              ${$t('form.select_option')} --`"
                :class="{
                  'mb-[19px]': errors[`users[${idx}].email`],
                }"
              />
              <button
                v-if="fields.length > 1"
                type="button"
                :class="{
                  'mt-5': idx === 0,
                  'mb-[19px]':
                    errors[`users[${idx}].email`] || errors[`users[${idx}].company_type`],
                }"
              >
                <XMarkIcon class="h-5 text-red-900 hover:text-red" @click="remove(idx)" />
              </button>
            </div>
          </div>
          <div>
            <button
              type="button"
              class="inline-flex items-center justify-center text-xs mt-3"
              @click="push({ email: '', company_type: '' })"
            >
              <PlusIcon class="h-4 w-4" aria-hidden="true" />
              {{ $t("admin.project_participants.modal_invite_user.add_user") }}
            </button>
          </div>
        </FieldArray>
        <div
          class="flex items-center gap-2 rounded-md self-end w-min bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 disabled:bg-gray-300 disabled:cursor-default"
        >
          <button type="submit">
            {{ $t("admin.project_participants.modal_invite_user.invite") }}
          </button>
        </div>
      </Form>
    </template>
  </Modal>
  <Modal
    :open="isDeleteUserModalOpen"
    @close="isDeleteUserModalOpen = false"
    custom-cls="sm:w-full sm:max-w-lg"
  >
    <template #title>
      <div v-if="selectedUser">
        <h3 class="pt-2 text-lg font-medium leading-6 text-gray-900">
          {{ selectedUser.name }}
        </h3>
        <small class="truncate text-gray-400"> {{ selectedUser.email }} </small>
      </div>
    </template>
    <template #content>
      <div class="sm:pt-4 pt-2 sm:border-t sm:border-gray-200">
        <div class="">
          <button
            type="button"
            class="inline-flex items-center w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm"
            @click="
              removeUserFromProject();
              isDeleteUserModalOpen = false;
            "
          >
            <MinusIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            {{ $t("admin.project_participants.modal_delete_user.delete_from_project") }}
          </button>
        </div>
        <div class="pt-4" v-if="hasPermission('app_admin')">
          <button
            type="submit"
            class="inline-flex items-center w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-xs sm:text-base font-medium text-white shadow-sm hover:bg-red-600 sm:col-start-2 sm:text-sm"
            @click="
              deleteUserPermanently();
              isDeleteUserModalOpen = false;
            "
          >
            <XMarkIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            {{ $t("admin.project_participants.modal_delete_user.delete_permanently") }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
  <Modal
    :open="isEditProjectPermissionModalOpen"
    v-if="selectedUser"
    @close="isEditProjectPermissionModalOpen = false"
    custom-cls="sm:w-full sm:max-w-lg"
  >
    <template #title>
      <div>
        <h3 class="pt-2 text-lg font-medium leading-6 text-gray-900">
          {{ selectedUser.name }}
        </h3>
        <small class="truncate text-gray-400"> {{ selectedUser.email }} </small>
      </div>
    </template>
    <template #content>
      <div>
        <Form
          @submit="handleUpdateProjectPermissions"
          :initialValues="initializePermissionsForUser(selectedUser)"
          :validationSchema="projectPermissionSchema"
          class="flex flex-col gap-2 w-full"
        >
          <SelectListField
            name="company_type"
            :label="$t('admin.project_participants.company_type')"
            :options="companyTypesList"
            :minWidth="250"
            :placeholder="`--
              ${$t('form.select_option')} --`"
          />
          <hr class="my-4" />
          <FieldArray name="groups" v-slot="{ fields, update }">
            <div
              v-for="(field, idx) in fields as FieldEntry<PermissionBoxEntry>[]"
              :key="idx"
              class="py-1"
            >
              <div v-if="field.value.type === 'live_camera_feature_user'" class="mb-4">
                <hr />
              </div>
              <CheckboxField
                v-if="field.value.type !== 'project_base'"
                :name="`groups[${idx}].enabled`"
                :label="$t(`permissions.${field.value.type}`)"
                @change="
                  onCheckboxChange(
                    update,
                    field.value.type,
                    fields as FieldEntry<PermissionBoxEntry>[],
                  )
                "
              />
            </div>
          </FieldArray>

          <div class="pt-4 flex items-center justify-center">
            <button
              type="submit"
              class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              <ArrowPathIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              {{ $t("admin.project_participants.modal_permission.update") }}
            </button>
          </div>
        </Form>
      </div>
    </template>
  </Modal>
  <Modal
    :open="isAdminUpdateUserPropertiesModalOpen"
    v-if="selectedUser"
    @close="isAdminUpdateUserPropertiesModalOpen = false"
    custom-cls="sm:w-full sm:max-w-lg"
  >
    <template #title>
      <p class="text-red-500 text-xs font-normal">(Admin only)</p>
      <div>
        <h3 class="pt-2 text-lg font-medium leading-6 text-gray-900">
          {{ selectedUser.name }}
        </h3>
        <small class="truncate text-gray-400"> {{ selectedUser.email }} </small>
      </div>
    </template>
    <template #content>
      <div>
        <Form
          @submit="handleAdminUpdateUserProperties"
          :initialValues="initializeUserProperties(selectedUser)"
          :validationSchema="adminUpdatePropertiesSchema"
          class="flex flex-col gap-2 w-full"
        >
          <InputField name="name" :label="$t('authentication.sign_up.name')" />
          <InputField name="company" :label="$t('authentication.sign_up.company')" />
          <SelectListField
            name="role"
            :label="$t('authentication.sign_up.role')"
            :options="roleTypesList"
            :minWidth="250"
            :placeholder="`--
              ${$t('form.select_option')} --`"
          />

          <div class="pt-4 flex items-center justify-center">
            <button
              type="submit"
              class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              <ArrowPathIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              {{ $t("admin.project_participants.modal_permission.update") }}
            </button>
          </div>
        </Form>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import {
  PlusIcon,
  MinusIcon,
  XMarkIcon,
  PencilIcon,
  TrashIcon,
  ArrowPathIcon,
} from "@heroicons/vue/24/solid";
import { Form, SubmissionHandler, FieldArray, FieldEntry } from "vee-validate";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useHasPermission } from "shared/composables/project";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { ProjectFeature } from "shared/types/Project";
import CheckboxField from "@/components/forms/CheckboxField.vue";
import InputField from "@/components/forms/InputField.vue";
import SelectListField from "@/components/forms/SelectListField.vue";
import MainButton from "@/components/other/MainButton.vue";
import { useCurrentProject } from "@/composables/project";
import {
  useUsersForProject,
  useUpdateUserProjectPermission,
  useBulkInviteUsers,
  useDeleteUser,
  useAdminUpdateUserProperties,
} from "@/composables/user";
import { companyTypes, userRoles } from "@/constants/user";
import { User, UserProject, UserProjectPermissionGroup, UserCompanyType } from "@/types/User";

const { t } = useI18n();
const hasAdminPermission = useHasPermission("app_admin");

const permissionsOrdered = [
  "project_admin",
  "all_camera_features_user",
  "planner_user",
  "processes_user",
  "site_activity_user",
  "daily_report_user",
  "live_camera_feature_user",
  "project_base",
] as UserProjectPermissionGroup[];

const permissionsToFeature = {
  all_camera_features_user: "camera",
  live_camera_feature_user: "camera",
  planner_user: "planner",
  processes_user: "processes",
  daily_report_user: "daily_report",
  site_activity_user: "site_activity",
} as Record<UserProjectPermissionGroup, ProjectFeature>;

const companyTypesList = companyTypes.map((type) => ({
  value: type,
  name: t(`admin.project_participants.company_types.${type}`),
}));

const roleTypesList = userRoles.map((role) => ({
  value: role,
  name: t(`admin.project_participants.user_roles.${role}`),
}));

const permissionLabelsColors = {
  project_admin: "bg-green-100 text-green-800",
  all_camera_features_user: "bg-gray-300 text-gray-800",
  live_camera_feature_user: "bg-orange-200 text-yellow-800",
};

const currentProject = useCurrentProject();
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const { users: projectUsers, isLoading } = useUsersForProject();
const { updateUserProjectPermission } = useUpdateUserProjectPermission();
const { adminUpdateUserProperties } = useAdminUpdateUserProperties();
const { bulkInviteUsers } = useBulkInviteUsers();
const { deleteUser } = useDeleteUser();

const selectedUser = ref<User | null>(null);

const isEditProjectPermissionModalOpen = ref(false);
const isDeleteUserModalOpen = ref(false);
const isInviteUsersModalOpen = ref(false);
const isAdminUpdateUserPropertiesModalOpen = ref(false);

const inviteSchema = yup.object().shape({
  users: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .email(t("admin.project_participants.modal_invite_user.error_mail_invalid"))
        .required(t("admin.project_participants.modal_invite_user.error_mail_required")),
      company_type: yup.string().required(t("authentication.validation.validation_field_required")),
    }),
  ),
});

const projectPermissionSchema = yup.object().shape({
  company_type: yup.string().required(t("authentication.validation.validation_field_required")),
  groups: yup.array().of(
    yup.object().shape({
      enabled: yup.boolean().required(),
    }),
  ),
});

const adminUpdatePropertiesSchema = yup.object().shape({
  name: yup.string().required(t("authentication.validation.validation_field_required")),
  company: yup.string().required(t("authentication.validation.validation_field_required")),
  role: yup.string().required(t("authentication.validation.validation_field_required")),
});

type UserToInvite = { email: string; company_type: UserCompanyType };

const handleSubmitInviteUsers: SubmissionHandler = async (genericObject) => {
  const usersToInvite = genericObject.users
    .map((item: UserToInvite) => ({
      email: item.email.replace(/\s/g, "").toLowerCase(), // sanitize
      company_type: item.company_type,
    }))
    .filter(
      (user: UserToInvite) =>
        !projectUsers.value.some((projectUser) => projectUser.email === user.email),
    );

  if (usersToInvite.length > 0) {
    bulkInviteUsers(usersToInvite);
  }
  isInviteUsersModalOpen.value = false;
};

const getSortedFeaturePermissionsForUser = (user: User) => {
  const project = user.projects.find(
    (project: UserProject) => project.customer_name === customerName && project.site_id === siteId,
  );
  if (!project) {
    return [];
  }
  return project.groups
    .slice()
    .sort((a, b) => permissionsOrdered.indexOf(a) - permissionsOrdered.indexOf(b));
};

const getProjectCompanyTypeForUser = (user: User) => {
  const project = user.projects.find(
    (project: UserProject) => project.customer_name === customerName && project.site_id === siteId,
  );
  if (!project) {
    return null;
  }
  return project.company_type;
};

const initializePermissionsForUser = (user: User) => {
  const currentFeaturesSelected = getSortedFeaturePermissionsForUser(user);
  const projectFeatures = Object.values(currentProject.features);

  const selectedPermissions = permissionsOrdered
    .filter(
      (permission) =>
        permission === "project_admin" ||
        permission === "project_base" ||
        projectFeatures.includes(permissionsToFeature[permission]),
    )
    .map((item) => {
      return {
        type: item,
        enabled: currentFeaturesSelected.some((feat) => feat === item),
      };
    });

  const project = user.projects.find(
    (project: UserProject) => project.customer_name === customerName && project.site_id === siteId,
  );

  return {
    groups: selectedPermissions,
    company_type: (project && project.company_type) || "",
  };
};

const initializeUserProperties = (user: User) => {
  return {
    name: user.name,
    company: user.company,
    role: user.role || "",
  };
};

type PermissionBoxEntry = {
  type: UserProjectPermissionGroup;
  enabled: boolean;
};

const onCheckboxChange = (
  update: (idx: number, value: PermissionBoxEntry) => void,
  typeClicked: string,
  fields: { value: PermissionBoxEntry }[],
) => {
  for (let i = 0; i < fields.length; i++) {
    if (
      typeClicked === "live_camera_feature_user" &&
      !["live_camera_feature_user", "project_base"].includes(fields[i].value.type)
    ) {
      update(i, { type: fields[i].value.type, enabled: false });
    }
    if (
      typeClicked !== "live_camera_feature_user" &&
      fields[i].value.type === "live_camera_feature_user"
    ) {
      update(i, { type: fields[i].value.type, enabled: false });
    }
  }
};

const handleUpdateProjectPermissions: SubmissionHandler = async (GenericObject) => {
  // const values = GenericObject as { username: string; company };
  const groups = GenericObject.groups
    .filter((group: PermissionBoxEntry) => group.enabled)
    .map((group: PermissionBoxEntry) => group.type);

  if (!selectedUser.value) {
    return;
  }

  updateUserProjectPermission({
    username: selectedUser.value.username,
    updatedProjectPermission: {
      customer_name: customerName,
      site_id: siteId,
      company_type: GenericObject.company_type,
      groups,
    },
  });
  isEditProjectPermissionModalOpen.value = false;
};

const removeUserFromProject = () => {
  if (!selectedUser.value) {
    return;
  }

  const currentProjectPermission = selectedUser.value.projects.find(
    (project) => project.customer_name === customerName && project.site_id === siteId,
  );

  currentProjectPermission &&
    updateUserProjectPermission({
      username: selectedUser.value.username,
      updatedProjectPermission: { ...currentProjectPermission, groups: [] },
    });
};

const deleteUserPermanently = () => {
  selectedUser.value && deleteUser(selectedUser.value.username);
};

const openAdminUpdateUserPropertiesModal = (user: User) => {
  if (hasAdminPermission) {
    isAdminUpdateUserPropertiesModalOpen.value = true;
    selectedUser.value = user;
  }
};

const handleAdminUpdateUserProperties: SubmissionHandler = async (GenericObject) => {
  selectedUser.value &&
    adminUpdateUserProperties({
      username: selectedUser.value.username,
      name: GenericObject.name,
      company: GenericObject.company,
      role: GenericObject.role,
    });
  isAdminUpdateUserPropertiesModalOpen.value = false;
};
</script>
