<template>
  <div class="relative">
    <CriticalPathEdgeMarker class="absolute" />
    <div
      v-if="entireCriticalPathDifference && entireCriticalPathDifference.endDate"
      class="absolute -top-[45px] left-1/2 -translate-x-1/2 flex gap-2 items-center whitespace-nowrap"
    >
      <div>
        {{
          entireCriticalPathDifference.projectedEndDate
            ? t("analytics.critical_path.projected_project_end")
            : t("analytics.critical_path.actual_end")
        }}:
        <span class="font-semibold">{{
          format(entireCriticalPathDifference.endDate, "dd.MM.yyyy")
        }}</span>
      </div>
      <CriticalPathDelay :differenceForPath="entireCriticalPathDifference" />
      <OaiTooltip position="right" v-if="selectionMode === 'criticalPath'">
        <InformationCircleIcon
          v-if="!isEntireCriticalPathDifferenceLocked"
          class="w-6 h-6 text-gray-600 cursor-pointer"
          @click="handleEntireCriticalPathDifferenceClick"
          @mouseenter="handleEntireCriticalPathDifferenceMouseEnter"
          @mouseleave="handleEntireCriticalPathDifferenceMouseLeave"
        />
        <SolidInformationCircleIcon
          v-else
          class="w-6 h-6 text-gray-500 cursor-pointer"
          @click="handleEntireCriticalPathDifferenceClick"
          @mouseenter="handleEntireCriticalPathDifferenceMouseEnter"
          @mouseleave="handleEntireCriticalPathDifferenceMouseLeave"
        />
        <template #tooltip>
          <div v-if="!isEntireCriticalPathDifferenceLocked">
            {{ t("analytics.critical_path.click_to_lock") }}
          </div>
        </template>
      </OaiTooltip>
    </div>
    <VueFlow
      class="oaiCriticalPathGraph"
      :nodes="nodes"
      :edges="edges"
      :nodesDraggable="false"
      :nodesFocusable="false"
      :nodesConnectable="!readonly"
      :isValidConnection="isValidConnection"
      :deleteKeyCode="deleteKeyCodePredicate"
      :minZoom="0.05"
      :maxZoom="4"
      :fitViewOnInit="true"
      @connect="handleConnect"
      @edgesChange="handleEdgesChange"
      @nodeContextMenu="handleSelectionContextMenu"
    >
      <template #node-criticalPath="props">
        <CriticalPathGraphNode
          :criticalPathNode="props.data"
          :dimmed="dimmed"
          :selected="
            props.selected ||
            (criticalPathEdgeAndNodeIds.nodeIds.has(props.id) && selectionMode === 'criticalPath')
          "
          :highlightedYellow="
            lastFinishedSelectedNodeIds.has(props.id) && selectionMode === 'criticalPath'
          "
          :highlightedBlue="
            selectedNodeIds.length > 0 &&
            isEntireCriticalPathDifferenceHighlighted &&
            differenceForPaths.lastPlannedPath?.lastNode._id === props.id &&
            selectionMode === 'criticalPath'
          "
          :highlightedPurple="
            selectedNodeIds.length > 0 &&
            isEntireCriticalPathDifferenceHighlighted &&
            entireCriticalPathDifference?.lastNode._id === props.id
          "
          :layout="layout"
          :context="context"
          :differenceForPathsByNode="differenceForPathsByNode"
          :differenceForPathsByNotFinishedNode="differenceForPathsByNotFinishedNode"
        />
      </template>
      <CriticalPathBackground :layout="layout" />
    </VueFlow>
    <CriticalPathTagMenu
      v-if="tagContextMenuPosition"
      :position="tagContextMenuPosition"
      :criticalPath="criticalPath"
      :selectedNodeIds="selectedNodeIds"
      @close="tagContextMenuPosition = null"
      @change="emit('change', $event)"
    />
  </div>
</template>

<script lang="ts" setup>
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { InformationCircleIcon as SolidInformationCircleIcon } from "@heroicons/vue/24/solid";
import {
  Connection,
  Edge,
  Node,
  NodeMouseEvent,
  useVueFlow,
  VueFlow,
  GraphEdge,
  GraphNode,
  EdgeChange,
} from "@vue-flow/core";
import "@vue-flow/core/dist/style.css";
import "@vue-flow/core/dist/theme-default.css";
import { format } from "date-fns";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import {
  CriticalPathEdge,
  CriticalPathNode,
  CriticalPathPosition,
  CriticalPathDifferenceForPath,
  CriticalPathContext,
  CriticalPathEx,
  CriticalPathLayout,
} from "shared/types/CriticalPath";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { PlanConfig } from "shared/types/Plan";
import CriticalPathBackground from "shared/views/critical_path/components/CriticalPathBackground.vue";
import CriticalPathDelay from "shared/views/critical_path/components/CriticalPathDelay.vue";
import CriticalPathEdgeMarker from "shared/views/critical_path/components/CriticalPathEdgeMarker.vue";
import CriticalPathGraphNode from "shared/views/critical_path/components/CriticalPathGraphNode.vue";
import CriticalPathTagMenu from "shared/views/critical_path/components/CriticalPathTagMenu.vue";
import { getCriticalPathEdgeIds } from "shared/views/critical_path/criticalPath";
import {
  calculateCriticalPathDifferenceForPaths,
  calculateEntireCriticalPathDifference,
} from "shared/views/critical_path/criticalPathDifference";
import { NodeHeight, NodeWidth } from "shared/views/critical_path/criticalPathLayout";
import "./criticalPath.css";

const props = defineProps<{
  criticalPath: CriticalPathEx;
  context: CriticalPathContext;
  hierarchyTags: HierarchyTagStore[];
  height: number;
  planConfig: PlanConfig;
  selectionMode: "simple" | "criticalPath";
  layout: CriticalPathLayout;
  readonly?: boolean;
}>();
const emit = defineEmits<{
  (eventName: "change", payload: CriticalPathEx): void;
  (eventName: "selectNodes", payload: string[]): void;
}>();

const { t } = useI18n();

const { nodes: vueFlowNodes } = useVueFlow();

const tagContextMenuPosition = ref<{ x: number; y: number } | null>(null);
const isEntireCriticalPathDifferenceLocked = ref(false);
const isEntireCriticalPathDifferenceHighlighted = ref(false);

const nodes = computed<Node<CriticalPathNode>[]>(() => {
  const nodePositionById = props.layout.nodes.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {} as Record<string, CriticalPathPosition<CriticalPathNode>>);
  return props.criticalPath.nodes
    .map((node) => {
      const nodePosition = nodePositionById[node._id];
      if (!nodePosition) {
        return null;
      }
      return {
        id: node._id,
        width: NodeWidth,
        height: NodeHeight,
        type: "criticalPath",
        data: node,
        deletable: false,
        position: { x: nodePosition.x, y: nodePosition.y },
      };
    })
    .filter((node) => node) as Node<CriticalPathNode>[];
});

const selectedNodeIds = computed(() =>
  vueFlowNodes.value.filter((node) => node.selected).map((node) => node.id),
);

const dimmed = computed(() => selectedNodeIds.value.length > 0);

const criticalPathEdgeAndNodeIds = computed(() => {
  const lastNodeId =
    isEntireCriticalPathDifferenceHighlighted.value && entireCriticalPathDifference.value
      ? entireCriticalPathDifference.value.lastNode._id
      : null;
  return getCriticalPathEdgeIds(
    props.criticalPath,
    selectedNodeIds.value,
    lastNodeId,
    props.context,
  );
});

const edges = computed<Edge[]>(() => {
  const validNodeIds = new Set(nodes.value.map((node) => node.id));
  return props.criticalPath.edges
    .filter((edge) => validNodeIds.has(edge.source_id) && validNodeIds.has(edge.target_id))
    .map((edge) => {
      const id = `${edge.source_id}_${edge.target_id}`;
      const highlighted = criticalPathEdgeAndNodeIds.value.edgeIds.has(id);
      return {
        id,
        source: edge.source_id,
        target: edge.target_id,
        markerEnd: "oaiArrowClosed",
        updatable: false,
        class: !highlighted && dimmed.value ? "dimmed" : "",
        animated: highlighted,
      };
    });
});

const differenceForPaths = computed(() =>
  calculateCriticalPathDifferenceForPaths(props.criticalPath, props.context),
);

const entireCriticalPathDifference = computed(() =>
  calculateEntireCriticalPathDifference(differenceForPaths.value, props.context),
);

const differenceForPathsByNode = computed(() =>
  differenceForPaths.value.paths.reduce((acc, item) => {
    acc[item.lastNode._id] = item;
    return acc;
  }, {} as Record<string, CriticalPathDifferenceForPath>),
);

const differenceForPathsByNotFinishedNode = computed(() =>
  differenceForPaths.value.paths.reduce((acc, item) => {
    for (const nodeId of item.notFinishedNodeIds) {
      acc[nodeId] = item;
    }
    return acc;
  }, {} as Record<string, CriticalPathDifferenceForPath>),
);

const lastFinishedSelectedNodeIds = computed(
  () =>
    new Set<string>(
      [...criticalPathEdgeAndNodeIds.value.nodeIds]
        .map((nodeId) => differenceForPathsByNode.value[nodeId]?.forLastFinished.node?._id)
        .filter((id) => id) as string[],
    ),
);

const levelIndexes = computed(() =>
  props.layout.levels.reduce((acc, level, index) => {
    acc[level.id] = index;
    return acc;
  }, {} as Record<string, number>),
);

const handleConnect = (params: Connection) => {
  const edgeIds = new Set(edges.value.map((edge) => `${edge.source}_${edge.target}`));
  if (edgeIds.has(`${params.source}_${params.target}`)) {
    return;
  }
  const newEdge: CriticalPathEdge = {
    source_id: params.source,
    target_id: params.target,
  };
  emit("change", {
    ...props.criticalPath,
    edges: [...props.criticalPath.edges, newEdge],
  });
};

const isValidConnection = (
  connection: Connection,
  elements: {
    edges: GraphEdge[];
    nodes: GraphNode[];
    sourceNode: GraphNode<CriticalPathNode>;
    targetNode: GraphNode<CriticalPathNode>;
  },
) => {
  const from = elements.sourceNode.data;
  const to = elements.targetNode.data;
  const fromLevelIndex = levelIndexes.value[from.level_id] || 0;
  const toLevelIndex = levelIndexes.value[to.level_id] || 0;
  return (
    (from.building_id === to.building_id || props.layout.sharedLevelIds.has(from.level_id)) &&
    toLevelIndex < fromLevelIndex
  );
};

const deleteKeyCodePredicate = (event: KeyboardEvent) =>
  !props.readonly && (event.key === "Backspace" || event.key === "Delete");

const handleEdgesChange = (changes: EdgeChange[]) => {
  const removedIds = changes.reduce((acc, change) => {
    if (change.type === "remove") {
      acc.add(`${change.source}_${change.target}`);
    }
    return acc;
  }, new Set<string>());

  if (removedIds.size > 0) {
    emit("change", {
      ...props.criticalPath,
      edges: props.criticalPath.edges.filter(
        (edge) => !removedIds.has(`${edge.source_id}_${edge.target_id}`),
      ),
    });
  }
};

const highlightEntireCriticalPathDifference = (options?: { lock: boolean }) => {
  const nodeId = entireCriticalPathDifference.value?.forLastFinished.node?._id;
  vueFlowNodes.value.forEach((node) => {
    node.selected = node.id === nodeId;
  });
  nextTick(() => {
    isEntireCriticalPathDifferenceHighlighted.value = true;
    isEntireCriticalPathDifferenceLocked.value =
      options?.lock !== undefined ? options.lock : isEntireCriticalPathDifferenceLocked.value;
  });
};

const handleEntireCriticalPathDifferenceClick = () => {
  isEntireCriticalPathDifferenceLocked.value = !isEntireCriticalPathDifferenceLocked.value;
};

const handleEntireCriticalPathDifferenceMouseEnter = () => {
  highlightEntireCriticalPathDifference();
};

const handleEntireCriticalPathDifferenceMouseLeave = () => {
  if (isEntireCriticalPathDifferenceLocked.value) {
    return;
  }
  vueFlowNodes.value.forEach((node) => {
    node.selected = false;
  });
};

const handleSelectionContextMenu = (payload: NodeMouseEvent) => {
  if (
    !payload.node.selected ||
    props.selectionMode !== "simple" ||
    props.criticalPath.tags.length === 0
  ) {
    return;
  }
  const event = payload.event as MouseEvent;
  event.preventDefault();
  tagContextMenuPosition.value = {
    x: event.pageX,
    y: event.pageY,
  };
};

watch(
  () => props.selectionMode,
  () => {
    vueFlowNodes.value.forEach((node) => {
      node.selected = false;
    });
  },
);

onMounted(() => {
  highlightEntireCriticalPathDifference({ lock: true });
});

watch(selectedNodeIds, () => {
  emit("selectNodes", selectedNodeIds.value);
  isEntireCriticalPathDifferenceHighlighted.value = false;
  isEntireCriticalPathDifferenceLocked.value = false;
});
</script>
