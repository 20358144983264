import { useQuery } from "@tanstack/vue-query";
import { computed, Ref } from "vue";
import {
  useCurrentCustomerName,
  useCurrentSiteId,
  useHasPermission,
} from "shared/composables/project";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { OutagesByRange } from "shared/types/Camera";

export const useOutagesByRange = (
  interval: Ref<{ start: Date; end: Date } | null> | Date,
  end?: Date,
) => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const hasPermission = useHasPermission("planner_user");

  const startDate = interval instanceof Date ? interval : interval.value?.start;
  const endDate = interval instanceof Date ? end : interval.value?.end;

  const { data, isLoading, isFetching } = useQuery<OutagesByRange>({
    queryKey: ["outages-by-range", currentCustomerName, currentSiteId, startDate, endDate],
    queryFn: () =>
      hasPermission && startDate && endDate
        ? CameraRepository.loadOutagesByRange(
            currentCustomerName,
            currentSiteId,
            startDate,
            endDate,
          )
        : ({} as OutagesByRange),
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const outagesByRange = computed(() => data.value || ({} as OutagesByRange));

  return { outagesByRange, isLoading, isFetching };
};
