import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { Ref } from "vue";
import ProjectDurationSettingsRepository from "../repositories/ProjectDurationSettingsRepository";
import {
  ProjectDurationSettings,
  ProjectDurationSettingsToUpdate,
  PublicHolidaysResponse,
} from "../types/ProjectDurationSettings";
import { useCurrentCustomerName, useCurrentSiteId } from "./project";

export const useProjectDurationSettings = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const { data: projectDurationSettings, isLoading: isProjectDurationSettingsLoading } =
    useQuery<ProjectDurationSettings>({
      queryKey: ["project-duration-settings", currentCustomerName, currentSiteId],
      queryFn: () =>
        ProjectDurationSettingsRepository.loadProjectDurationSettings(
          currentCustomerName,
          currentSiteId,
        ),
    });

  return { projectDurationSettings, isProjectDurationSettingsLoading };
};

export const useUpdateProjectDurationSettings = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { mutateAsync: updateProjectDurationSettings, isError: isUpdateError } = useMutation<
    ProjectDurationSettings,
    Error,
    ProjectDurationSettingsToUpdate
  >({
    mutationFn: (projectDurationSettingsToUpdate) =>
      ProjectDurationSettingsRepository.updateProjectDurationSettings(
        currentCustomerName,
        currentSiteId,
        projectDurationSettingsToUpdate,
      ),
    onSuccess: (updatedProjectDurationSettings) => {
      queryClient.setQueryData(
        ["project-duration-settings", currentCustomerName, currentSiteId],
        updatedProjectDurationSettings,
      );
    },
  });

  return { updateProjectDurationSettings, isUpdateError };
};

export const usePublicHolidays = (countryCode: string, zipCode: string, year: Ref<number>) => {
  const { data: publicHolidaysResponse, isLoading: arePublicHolidaysLoading } =
    useQuery<PublicHolidaysResponse | null>({
      queryKey: ["public-holidays", countryCode, zipCode, year],
      queryFn: () =>
        ProjectDurationSettingsRepository.loadPublicHolidays(countryCode, zipCode, year.value),
    });

  return { publicHolidaysResponse, arePublicHolidaysLoading };
};
