<template>
  <div
    class="bg-white border p-4 rounded-lg shadow-lg flex flex-col gap-1 font-normal max-w-[600px]"
  >
    <div v-if="criticalPathNode.tags.length > 0 && isAdmin" class="flex gap-2 flex-wrap mb-1">
      <div
        v-for="tagId in criticalPathNode.tags"
        :key="tagId"
        class="bg-gray-400 text-white px-2 py-0.5 rounded text-xs truncate"
      >
        [{{ context.criticalPathTagLetterById[tagId] }}]
        {{ context.criticalPathTagsById[tagId]?.name }}
      </div>
    </div>
    <div class="font-semibold text-base">{{ criticalPathNode.full_name }}</div>
    <div class="flex flex-col text-sm" v-if="componentSourceIds.length > 0">
      <div class="oaiCriticalPathGraphNodeHoverItem border-b">
        <div />
        <div
          class="text-white rounded-lg px-2 py-0.5 truncate w-min"
          :style="{ backgroundColor: '#bccfcc' }"
        >
          {{ t("analytics.critical_path.planned_end") }}
        </div>
        <div class="text-white rounded-lg px-2 py-0.5 truncate w-min bg-green">
          {{ t("analytics.critical_path.actual_end") }}
        </div>
        <div>{{ t("analytics.critical_path.working_days") }}</div>
      </div>
      <CriticalPathGraphNodeHoverItem
        v-for="sourceId in componentSourceIds"
        :key="sourceId"
        :sourceId="sourceId"
        :criticalPathNode="criticalPathNode"
        :context="context"
        :differenceForNode="differenceForNode"
      />
      <div
        class="oaiCriticalPathGraphNodeHoverItem border-t font-semibold"
        v-if="
          differenceForNode.plannedEvent &&
          differenceForNode.actualEvent?.end &&
          differenceForNode.workingDays !== null &&
          context.plannedEventsBySourceId[differenceForNode.actualEvent.source_id]?._id !==
            differenceForNode.plannedEvent?._id
        "
      >
        <div class="py-0.5" />
        <div class="py-0.5">{{ format(differenceForNode.plannedEvent.end, dateFormat) }}</div>
        <div class="py-0.5">{{ format(differenceForNode.actualEvent.end, dateFormat) }}</div>
        <div
          class="w-min rounded-full px-2 py-0.5 whitespace-nowrap"
          :class="differenceWithBackgroundClass"
        >
          {{ differenceForNode.workingDays }}
        </div>
      </div>
    </div>
    <div
      v-if="
        differenceForPathByNotFinishedNode &&
        differenceForPathByNotFinishedNode.forLastFinished.workingDays !== null &&
        !bottomOneInPath
      "
      class="mt-6 text-sm border-t pt-1 px-1"
    >
      {{ t("analytics.critical_path.previous_delta") }}:
      <span
        class="w-min rounded-full px-2 py-0.5 whitespace-nowrap font-semibold"
        :class="differenceWithBackgroundClassForPath"
        >{{ differenceForPathByNotFinishedNode.forLastFinished.workingDays }}
        {{ t("analytics.critical_path.working_days") }}</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useHasPermission } from "shared/composables/project";
import {
  CriticalPathNodeEx,
  CriticalPathContext,
  CriticalPathDifference,
  CriticalPathDifferenceForPath,
} from "shared/types/CriticalPath";
import { useDifferenceTextClass, useDifferenceTextWithBackgroundClass } from "../composables";
import CriticalPathGraphNodeHoverItem from "./CriticalPathGraphNodeHoverItem.vue";
import "./criticalPath.css";

const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  criticalPathNode: CriticalPathNodeEx;
  context: CriticalPathContext;
  differenceForPathsByNotFinishedNode: Record<string, CriticalPathDifferenceForPath>;
  differenceForNode: CriticalPathDifference;
}>();

const { t } = useI18n();

const isAdmin = useHasPermission(["app_admin", "pct_admin"]);

const componentSourceIds = computed(() => {
  const { plannedEventsBySourceId, actualEventsBySourceId } = props.context;
  const result = props.criticalPathNode.component_source_ids.slice();
  result.sort((a, b) => {
    const plannedA = plannedEventsBySourceId[a]?.end.getTime() || 0;
    const plannedB = plannedEventsBySourceId[b]?.end.getTime() || 0;
    if (plannedA === plannedB) {
      const actualA = actualEventsBySourceId[a]?.end?.getTime() || Infinity;
      const actualB = actualEventsBySourceId[b]?.end?.getTime() || Infinity;
      return actualA - actualB;
    }
    return plannedA - plannedB;
  });
  return result;
});

const differenceWithBackgroundClass = useDifferenceTextWithBackgroundClass(
  computed(() => props.differenceForNode.workingDays),
);

const differenceForPathByNotFinishedNode = computed(
  () => props.differenceForPathsByNotFinishedNode[props.criticalPathNode._id],
);

const differenceWithBackgroundClassForPath = useDifferenceTextClass(
  computed(() => differenceForPathByNotFinishedNode.value?.forLastFinished.workingDays ?? null),
);

const bottomOneInPath =
  (props.context.edgesByTargetId[props.criticalPathNode._id] || []).length === 0;
</script>
