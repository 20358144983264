import { AxiosRequestConfig, AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import CameraRepository from "shared/repositories/CameraRepository";
import { apiClient } from "shared/repositories/clients";
import {
  CameraImageItem,
  DailyTimelapseResponse,
  GalleryImageList,
  GalleryImagesPaginatedResponse,
  GalleryPaginatedImg,
  GalleryDownloadItem,
} from "@/types/Camera";
import { CustomizableTimelapse } from "@/types/CustomizableTimelapse";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const formatUtcDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

const loadDailyTimelapse = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string | null,
): Promise<DailyTimelapseResponse> => {
  const searchParams = new URLSearchParams();
  date && searchParams.set("date", date);

  return apiClient
    .get<void, AxiosResponse<DailyTimelapseResponse>>(
      `/camera/daily-timelapse/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const loadProjectTimelapse = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<{ presigned_url_timelapse: string } | null> =>
  apiClient
    .get<void, AxiosResponse<{ presigned_url_timelapse: string }>>(
      `/camera/project-timelapse/${customerName}/${siteId}/${cameraId}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadPublicProjectTimelapse = (
  customerName: string,
  siteId: string,
  cameraId: string,
  token: string,
): Promise<{ presigned_url_timelapse: string } | null> =>
  apiClient
    .get<void, AxiosResponse<{ presigned_url_timelapse: string }>>(
      `/camera/${token}/project-timelapse/${customerName}/${siteId}/${cameraId}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });

const loadGalleryPaginatedImg = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date?: string | null,
  timeFilter?: string | null,
  config: AxiosRequestConfig = {},
): Promise<GalleryPaginatedImg> => {
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);
  }

  if (timeFilter) {
    searchParams.set("time_filter", timeFilter);
  }

  return apiClient
    .get<void, AxiosResponse<GalleryPaginatedImg>>(
      `/camera/gallery/single/paginated-image/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
      config,
    )
    .then((response) => response.data);
};

const getCameraGalleryForTimeRange = (
  customerName: string,
  siteId: string,
  cameraId: string,
  startDate?: string | null,
  endDate?: string | null,
): Promise<CameraImageItem[]> => {
  return apiClient
    .get<void, AxiosResponse<GalleryImageList>>(
      `/camera/gallery/single/time-range/${customerName}/${siteId}/${cameraId}/${startDate}/${endDate}`,
    )
    .then((response) => response.data.items);
};

const getPublicPaginatedGalleryImages = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string | null,
  token: string,
): Promise<GalleryImagesPaginatedResponse> => {
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);
  }

  return apiClient
    .get<void, AxiosResponse<GalleryImagesPaginatedResponse>>(
      `/camera/${token}/gallery/paginated/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const getPaginatedGalleryImages = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string | null,
): Promise<GalleryImagesPaginatedResponse> => {
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);
  }

  return apiClient
    .get<void, AxiosResponse<GalleryImagesPaginatedResponse>>(
      `/camera/gallery/paginated/${customerName}/${siteId}/${cameraId}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const loadGalleryDownloadItems = (
  customerName: string,
  siteId: string,
  cameraIds: string[],
  startDate: string | null,
  endDate: string | null,
  imgsPerDay: number | null,
  abortSignal: AbortSignal | undefined,
): Promise<GalleryDownloadItem[]> => {
  const searchParams = new URLSearchParams();
  searchParams.set("camera_ids", cameraIds.join(","));

  if (startDate) {
    searchParams.set("start_date", startDate);
  }

  if (endDate) {
    searchParams.set("end_date", endDate);
  }

  if (imgsPerDay) {
    searchParams.set("imgs_per_day", String(imgsPerDay));
  }

  const config: AxiosRequestConfig = { signal: abortSignal };
  return apiClient
    .get<void, AxiosResponse<GalleryDownloadItem[]>>(
      `/camera/gallery/${customerName}/${siteId}/download-items?${searchParams.toString()}`,
      config,
    )
    .then((response) => {
      return response.data;
    });
};

const mapCustomizableTimelapse = (
  customizableTimelapse: CustomizableTimelapse<string>,
): CustomizableTimelapse => ({
  ...customizableTimelapse,
  start_date: parseUtcDate(customizableTimelapse.start_date),
  end_date: parseUtcDate(customizableTimelapse.end_date),
  created: parseISO(customizableTimelapse.created),
});

const loadCustomizableTimelapses = (
  customerName: string,
  siteId: string,
): Promise<CustomizableTimelapse[]> =>
  apiClient
    .get<void, AxiosResponse<CustomizableTimelapse<string>[]>>(
      `/camera/customizable-timelapse/${customerName}/${siteId}`,
    )
    .then((response) =>
      response.data.map((customizableTimelapse) => mapCustomizableTimelapse(customizableTimelapse)),
    );

const deleteCustomizableTimelapse = (
  customerName: string,
  siteId: string,
  customizableTimelapseDbId: string,
): Promise<void> =>
  apiClient
    .delete<void, AxiosResponse<void>>(
      `/camera/customizable-timelapse/${customerName}/${siteId}/${customizableTimelapseDbId}`,
    )
    .then((response) => response.data);

type CustomizableTimelapseToCreate = Pick<
  CustomizableTimelapse,
  "camera_id" | "name" | "start_date" | "end_date" | "duration"
>;

const formatCustomizableTimelapseToCreate = (
  customizableTimelapse: CustomizableTimelapseToCreate,
) => ({
  ...customizableTimelapse,
  start_date: formatUtcDate(customizableTimelapse.start_date),
  end_date: formatUtcDate(customizableTimelapse.end_date),
});

const createCustomizableTimelapse = (
  customerName: string,
  siteId: string,
  customizableTimelapseToCreate: CustomizableTimelapseToCreate,
): Promise<CustomizableTimelapse> =>
  apiClient
    .post<void, AxiosResponse<CustomizableTimelapse<string>>>(
      `/camera/customizable-timelapse/${customerName}/${siteId}`,
      formatCustomizableTimelapseToCreate(customizableTimelapseToCreate),
    )
    .then((response) => mapCustomizableTimelapse(response.data));

const addPersonImageToDatasetBuffer = (
  customerName: string,
  siteId: string,
  cameraId: string,
  timestamp: string,
) =>
  apiClient
    .post<void, AxiosResponse<void>>(
      `/camera/dataset/person/image/${customerName}/${siteId}/${cameraId}/${timestamp}`,
    )
    .then((response) => response.data);

export default {
  ...CameraRepository,
  loadDailyTimelapse,
  loadProjectTimelapse,
  loadGalleryPaginatedImg,
  getCameraGalleryForTimeRange,
  getPaginatedGalleryImages,
  loadGalleryDownloadItems,
  loadCustomizableTimelapses,
  deleteCustomizableTimelapse,
  createCustomizableTimelapse,
  loadPublicProjectTimelapse,
  getPublicPaginatedGalleryImages,
  addPersonImageToDatasetBuffer,
};
