<template>
  <Modal :open="true" @close="handleClose" customCls="w-full m-5 xxl:w-2/3">
    <template #title>
      <div class="flex gap-4 justify-between pr-10 font-normal">
        <div class="font-semibold invisible lg:visible">
          {{ t("analytics.critical_path.title") }}
        </div>
        <div class="flex gap-2" v-if="!isLoading && editedOrCriticalPath && !readonly && isAdmin">
          <button
            class="py-0.5 px-4 border rounded cursor-pointer border-gray-300"
            :class="isTagPanelOpen ? 'bg-gray-100' : ''"
            @click="isTagPanelOpen = !isTagPanelOpen"
          >
            {{ t("analytics.critical_path.similar_components") }} ({{
              editedOrCriticalPath.tags.length
            }})
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="h-[85vh]">
        <div class="flex items-center justify-center h-full" v-if="isLoading">
          <LoadingSpinner />
        </div>
        <div
          v-if="!isLoading && editedOrCriticalPath && planConfig && criticalPathContext && layout"
          class="h-full flex flex-col gap-2"
        >
          <div class="flex flex-1 gap-2">
            <div ref="graphContainer" class="flex-1">
              <CriticalPathGraph
                class="h-full border rounded border-gray-400"
                v-if="graphContainer"
                :criticalPath="editedOrCriticalPath"
                :context="criticalPathContext"
                :hierarchyTags="hierarchyTags"
                :height="graphContainer.clientHeight"
                :planConfig="planConfig"
                :selectionMode="isTagPanelOpen ? 'simple' : 'criticalPath'"
                :readonly="readonly"
                :layout="layout"
                @change="handleCriticalPathChange"
                @selectNodes="selectedNodeIds = $event"
              />
            </div>
            <CriticalPathTags
              v-if="isTagPanelOpen"
              :criticalPath="editedOrCriticalPath"
              :context="criticalPathContext"
              :selectedNodeIds="selectedNodeIds"
              class="w-[300px] shrink-0"
              @change="handleCriticalPathChange"
              @close="isTagPanelOpen = false"
            />
          </div>
          <button
            v-if="!readonly"
            type="button"
            @click="handleSaveClick"
            :disabled="isUpdateCriticalPathLoading || !editedCriticalPath"
            class="self-end gap-2 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-white shadow-sm bg-green-400 hover:bg-green-600 disabled:bg-gray-300"
          >
            {{ t("buttons.save") }}
            <LoadingSpinner class="w-4 h-4" v-if="isUpdateCriticalPathLoading" />
          </button>
        </div>
        <div
          v-if="!isLoading && (!editedOrCriticalPath || !planConfig)"
          class="flex h-full items-center justify-center"
        >
          {{ t("analytics.critical_path.no_critical_path") }}
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useCriticalPathWithLayout, usePlanConfig } from "shared/composables/planner";
import { useUpdateCriticalPath } from "shared/composables/planner";
import { useHasPermission } from "shared/composables/project";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import {
  useConfirmationModalIsOpen,
  useSaveBeforeLeaveConfirmationModal,
} from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import { CriticalPathEx, CriticalPathToUpdate } from "shared/types/CriticalPath";
import CriticalPathGraph from "shared/views/critical_path/components/CriticalPathGraph.vue";
import CriticalPathTags from "shared/views/critical_path/components/CriticalPathTags.vue";
import { getCriticalPathContext } from "shared/views/critical_path/criticalPath";
import { createCriticalPathEx } from "shared/views/critical_path/criticalPathNode";

const props = defineProps<{ origin?: string }>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const { t } = useI18n();

const isAdmin = useHasPermission(["app_admin", "pct_admin"]);
const isPlannerUser = useHasPermission("planner_user");

const editedCriticalPath = ref<CriticalPathEx | null>(null);
const graphContainer = ref<HTMLDivElement | null>(null);
const mountTimer = ref<ReturnType<typeof setTimeout> | null>(null);
const isTagPanelOpen = ref(false);
const selectedNodeIds = ref<string[]>([]);

const trackEvent = useTrackEvent();

const { criticalPath, isLoading: isCriticalPathLoading, layout } = useCriticalPathWithLayout();
const { updateCriticalPath, isLoading: isUpdateCriticalPathLoading } = useUpdateCriticalPath();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const { planConfig, isLoading: isPlanConfigLoading } = usePlanConfig();
const { projectDurationSettings, isProjectDurationSettingsLoading } = useProjectDurationSettings();

const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();

const isConfirmationModalOpen = useConfirmationModalIsOpen();

const readonly = computed(() => !isAdmin && !isPlannerUser);

const criticalPathEx = computed(() =>
  criticalPath.value && planConfig.value
    ? createCriticalPathEx(criticalPath.value, planConfig.value, hierarchyTags.value)
    : undefined,
);

const criticalPathContext = computed(() =>
  editedOrCriticalPath.value && planConfig.value && projectDurationSettings.value
    ? getCriticalPathContext(
        editedOrCriticalPath.value,
        planConfig.value,
        hierarchyTags.value,
        projectDurationSettings.value,
      )
    : null,
);

const isLoading = computed(
  () =>
    isCriticalPathLoading.value ||
    areHierarchyTagsLoading.value ||
    !!mountTimer.value ||
    isPlanConfigLoading.value ||
    isProjectDurationSettingsLoading.value,
);

const editedOrCriticalPath = computed(() => editedCriticalPath.value || criticalPathEx.value);

const handleSaveClick = () => {
  if (!editedCriticalPath.value) {
    return;
  }
  trackEvent("planner_delta_save");
  const criticalPathToUpdate: CriticalPathToUpdate = {
    nodes: editedCriticalPath.value.nodes.map((node) => ({
      _id: node._id,
      tags: node.tags,
    })),
    edges: editedCriticalPath.value.edges,
    tags: editedCriticalPath.value.tags,
  };
  updateCriticalPath(criticalPathToUpdate)
    .then(() => {
      editedCriticalPath.value = null;
    })
    .catch(() => undefined);
};

const handleCriticalPathChange = (newCriticalPathChange: CriticalPathEx) => {
  editedCriticalPath.value = newCriticalPathChange;
};

const handleClose = () => {
  if (isConfirmationModalOpen.value) {
    return;
  }
  if (editedCriticalPath.value) {
    showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
      if (confirmed) {
        emit("close");
      }
    });
  } else {
    emit("close");
  }
};

onMounted(() => {
  // bit delayed until graph is rendered to make it render correctly
  // not sure why this is needed, but might to do something with that it is shown in the modal
  if (mountTimer.value) {
    clearTimeout(mountTimer.value);
  }
  mountTimer.value = setTimeout(() => {
    mountTimer.value = null;
  }, 500);
  trackEvent("planner_delta_view", { origin: props.origin });
});

onUnmounted(() => {
  if (mountTimer.value) {
    clearTimeout(mountTimer.value);
  }
});
</script>
