<template>
  <div :class="['grid grid-cols-2 gap-5', cls]">
    <div class="flex flex-col">
      <label
        :for="`${name}-date`"
        class="block text-sm font-medium leading-6 text-gray-900 text-left rounded border-gray-300 focus:border-yellow-500 focus:ring-0"
        v-if="dateLabel"
        >{{ dateLabel }}</label
      >
      <input
        type="date"
        :name="`${name}-date`"
        class="rounded border-gray-300 focus:border-yellow-500 focus:ring-0"
        :class="['customInput w-ful', { 'bg-gray-100': disabled }]"
        v-model="dateText"
        @change="handleDateAndTimeChange()"
        :disabled="disabled"
        :max="formatDate(maxDate)"
      />
    </div>
    <div class="flex flex-col">
      <label
        :for="`${name}-time`"
        class="block text-sm font-medium leading-6 text-gray-900 text-left rounded border-gray-300 focus:border-yellow-500 focus:ring-0"
        v-if="timeLabel"
        >{{ timeLabel }}</label
      >
      <input
        type="time"
        :name="`${name}-time`"
        class="rounded border-gray-300 focus:border-yellow-500 focus:ring-0"
        :class="['customInput w-full', { 'bg-gray-100': disabled || timeDisabled }]"
        v-model="timeText"
        @change="handleDateAndTimeChange()"
        :disabled="disabled || timeDisabled"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { format, parse } from "date-fns";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "DateTime",
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Date as PropType<Date | null>,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    timeDisabled: {
      type: Boolean,
      required: false,
    },
    cls: {
      type: String,
      required: false,
    },
    maxDate: {
      type: Date,
      required: false,
    },
    dateLabel: {
      type: String,
      required: false,
    },
    timeLabel: {
      type: String,
      required: false,
    },
  },
  emits: ["changed"],
  data() {
    return {
      dateText: "",
      timeText: "",
    };
  },
  mounted() {
    this.initializeDateAndTimeText();
  },
  watch: {
    value() {
      this.initializeDateAndTimeText();
    },
  },
  methods: {
    initializeDateAndTimeText() {
      if (this.value) {
        this.dateText = this.formatDate(this.value);
        this.timeText = this.formatTime(this.value);
      }
    },
    handleDateAndTimeChange() {
      const date =
        this.dateText && this.timeText
          ? parse(`${this.dateText} ${this.timeText}`, "yyyy-MM-dd HH:mm", this.value || new Date())
          : null;
      this.$emit("changed", date);
    },
    formatDate(date: Date | null | undefined) {
      return date ? format(date, "yyyy-MM-dd") : "";
    },
    formatTime(date: Date | null | undefined) {
      return date ? format(date, "HH:mm") : "";
    },
  },
});
</script>
