<template>
  <div class="mt-4 flow-root">
    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div v-if="activeTab === 'cold_temperature' || activeTab === 'hot_temperature'">
          <div class="overflow-hidden">
            <ul role="list" class="divide-y divide-gray-200">
              <li v-for="(rule, index) in rules" :key="index">
                <div
                  :class="[
                    'flex items-center',
                    {
                      'bg-red-100': validationError && errorTemperatureValidation(rule),
                    },
                  ]"
                >
                  <div class="flex min-w-0 flex-1 items-center">
                    <div class="wrapper lg:m-2 m-1 w-3 md:w-5 lg:w-9">
                      <div class="line"></div>
                      <div class="wordwrapper">
                        <div class="word">
                          {{ rules.length > 1 && index > 0 ? $t("report.weather.or") : 1 }}
                        </div>
                      </div>
                    </div>
                    <div class="lg:flex min-w-0 flex-1 items-center md:gap-4 p-2 lg:p-0">
                      <div class="grid grid-cols-3 items-center gap-2 lg:py-4">
                        <p class="truncate text-sm font-medium">
                          {{ $t("report.weather.min_value") }}
                        </p>
                        <div>
                          <select
                            id="min_op"
                            name="min_top"
                            v-model="rule.min_operator"
                            class="oai-inputs"
                          >
                            <option
                              v-for="operator in filterOperators(activeTab, 'min_value')"
                              :key="operator"
                              class="py-1"
                            >
                              {{ operator }}
                            </option>
                          </select>
                        </div>
                        <div class="relative">
                          <input
                            type="number"
                            placeholder="00,00"
                            v-model="rule.min_value"
                            class="oai-inputs block pl-4 pr-8"
                          />
                          <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                          >
                            <span class="text-gray-500 sm:text-sm text-xs" id="celcius">°C</span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="rounded lg:bg-gray-100 px-2 py-1 uppercase text-gray-600 text-center text-xs lg:text-base md:text-left"
                      >
                        {{ $t("report.weather.and") }}
                      </div>
                      <div class="grid grid-cols-3 items-center gap-1 lg:py-4">
                        <p class="truncate text-sm font-medium">
                          {{ $t("report.weather.max_value") }}
                        </p>
                        <select
                          id="min_op"
                          name="min_top"
                          v-model="rule.max_operator"
                          class="oai-inputs"
                        >
                          <option
                            v-for="operator in filterOperators(activeTab, 'max_value')"
                            :key="operator"
                            class="py-1"
                          >
                            {{ operator }}
                          </option>
                        </select>
                        <div class="relative">
                          <input
                            type="number"
                            placeholder="00,00"
                            v-model="rule.max_value"
                            class="oai-inputs block pl-4 pr-8"
                          />
                          <div
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                          >
                            <span class="text-gray-500 sm:text-sm" id="price-currency">°C</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="self-start lg:self-center">
                    <XMarkIcon
                      class="h-5 text-red-900 hover:text-red"
                      @click="$emit('deleteEntry', index)"
                    />
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <button v-if="rules.length < 2" class="ml-16 mt-2">
            <PlusIcon
              class="h-8 w-8 rounded-full bg-orange p-2 text-white"
              @click="$emit('addEntry', activeTab)"
            />
          </button>
        </div>

        <!-- Precipitation -->
        <div v-if="activeTab === 'precipitation'">
          <div class="overflow-hidden bg-white">
            <ul role="list" class="divide-y divide-gray-200 gap-2">
              <li v-for="(rule, index) in rules" :key="index">
                <div
                  :class="[
                    'flex items-center',
                    {
                      'bg-red-100': validationError && errorPrecipitationValidation(rule),
                    },
                  ]"
                >
                  <div class="flex min-w-0 flex-1 items-center">
                    <div class="w-16">
                      <div class="wrapper" v-if="rules.length > 1 && index > 0">
                        <div class="line"></div>
                        <div class="wordwrapper">
                          <div class="word">{{ $t("report.weather.or") }}</div>
                        </div>
                      </div>
                      <div class="wrapper" v-else>
                        <div class="line"></div>
                        <div class="wordwrapper">
                          <div class="word">1</div>
                        </div>
                      </div>
                    </div>
                    <div class="lg:flex min-w-0 flex-1 items-center md:gap-4">
                      <div
                        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-24 items-center gap-1 py-4"
                      >
                        <div class="lg:col-span-3">
                          <p class="truncate text-sm font-medium">
                            {{ $t("report.weather.precipitation_volume") }}
                          </p>
                        </div>
                        <div class="col-span-1 text-center md:col-span-2">
                          <p class="truncate text-sm font-medium">{{ rule.volume_operator }}</p>
                        </div>

                        <div class="lg:col-span-2">
                          <div class="lg:max-w-max">
                            <input
                              type="number"
                              placeholder="00,00"
                              v-model="rule.volume_value"
                              class="oai-inputs block lg:pl-4"
                            />
                          </div>
                        </div>

                        <div class="lg:col-span-3">
                          <select
                            id="min_op"
                            name="min_top"
                            v-model="rule.volume_unit"
                            :disabled="precipitation24Disabled(rule.time_period)"
                            :class="[
                              'oai-inputs',
                              { inputDisabled: precipitation24Disabled(rule.time_period) },
                            ]"
                          >
                            <option
                              v-for="operator in filterOperators(activeTab, 'volume_unit')"
                              :key="operator"
                              class="py-1"
                            >
                              {{ operator }}
                            </option>
                          </select>
                        </div>
                        <div class="lg:col-span-1 text-center md:col-span-2">
                          <p class="text-sm font-medium">{{ $t("report.weather.for") }}</p>
                        </div>
                        <div class="lg:col-span-3">
                          <select
                            id="min_op"
                            name="min_top"
                            v-model="rule.duration_operator"
                            :disabled="precipitation24Disabled(rule.time_period)"
                            :class="[
                              'oai-inputs',
                              { inputDisabled: precipitation24Disabled(rule.time_period) },
                            ]"
                            @change="updatePrecipitationRule(rule.duration_operator, index)"
                          >
                            <option
                              v-for="operator in filterOperators(activeTab, 'duration_operator')"
                              :key="operator"
                              :value="operator"
                              class="py-1"
                            >
                              {{
                                operator === "ENTIRE_TIME_PERIOD"
                                  ? $t(`report.weather.${operator}`)
                                  : operator
                              }}
                            </option>
                          </select>
                        </div>

                        <div class="lg:col-span-3">
                          <div class="relative lg:max-w-max">
                            <input
                              type="number"
                              :placeholder="''"
                              v-model="rule.duration_value"
                              :disabled="precipitation24Disabled(rule.time_period)"
                              :class="[
                                'oai-inputs block pl-4 pr-6',
                                {
                                  inputDisabled:
                                    precipitation24Disabled(rule.time_period) ||
                                    entireTimePeriodDisabled(rule.duration_operator),
                                },
                              ]"
                            />
                            <div
                              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                            >
                              <span class="text-gray-500 sm:text-sm" id="price-currency">h</span>
                            </div>
                          </div>
                        </div>
                        <div class="lg:col-span-3 flex items-center justify-center">
                          <p class="text-sm font-medium">{{ $t("report.weather.within") }}</p>
                        </div>
                        <div class="lg:col-span-4">
                          <select
                            id="min_op"
                            name="min_top"
                            v-model="rule.time_period"
                            class="oai-inputs"
                            @change="updatePrecipitationRule(rule.time_period, index)"
                          >
                            <option
                              v-for="operator in filterOperators(activeTab, 'time_period')"
                              :key="operator"
                              :value="operator"
                              class="py-1"
                            >
                              {{ $t(`report.weather.${operator}`) }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="self-start p-2 lg:p-0 lg:self-center">
                    <XMarkIcon
                      class="h-5 text-red-900 hover:text-red"
                      @click="$emit('deleteEntry', index)"
                    />
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <button v-if="rules.length < 4" class="ml-16 mt-2">
            <PlusIcon
              class="h-8 w-8 rounded-full bg-orange p-2 text-white"
              @click="$emit('addEntry', activeTab)"
            />
          </button>
        </div>

        <!-- Wind -->
        <div v-if="activeTab === 'wind'">
          <div class="overflow-hidden bg-white">
            <div
              :class="[
                'flex items-center',
                {
                  'bg-red-100':
                    validationError &&
                    (rules.max_value === '' || (rules.max_value !== '' && rules.max_value < 0)),
                },
              ]"
            >
              <div class="flex min-w-0 flex-1 items-center">
                <div class="w-16">
                  <div class="wrapper">
                    <div class="line"></div>
                    <div class="wordwrapper">
                      <div class="word">1</div>
                    </div>
                  </div>
                </div>
                <div class="flex min-w-0 flex-1 items-center md:pl-4 md:gap-4">
                  <div class="grid md:grid-cols-6 items-center gap-1 py-4">
                    <div class="col-span-3">
                      <p class="truncate text-sm font-medium">
                        {{ $t("report.weather.max_wind_speed") }}
                      </p>
                    </div>
                    <div class="col-span-1">
                      <p class="truncate text-sm font-medium">{{ rules.max_operator }}</p>
                    </div>
                    <div class="col-span-2">
                      <div class="relative max-w-max">
                        <!-- eslint-disable vue/no-mutating-props -->
                        <input
                          type="number"
                          placeholder="00,00"
                          v-model="rules.max_value"
                          min="0"
                          class="oai-inputs block md:pl-4 md:pr-12"
                        />
                        <!-- eslint-enable -->
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          <span class="text-gray-500 sm:text-sm" id="wind-unit">{{
                            $t("report.weather.wind_type")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { XMarkIcon, PlusIcon } from "@heroicons/vue/20/solid";

export default {
  name: "RuleTable",
  props: ["activeTab", "rules", "validationError"],
  emits: ["deleteEntry", "addEntry", "resetFixedRule"],
  components: {
    XMarkIcon,
    PlusIcon,
  },

  methods: {
    errorTemperatureValidation(rule) {
      return rule["min_value"] === "" || rule["max_value"] === "";
    },
    errorPrecipitationValidation(rule) {
      if (rule["volume_value"] === "") {
        return true;
      } else {
        if (rule["volume_value"] <= 0) {
          return true;
        }
      }
      if (rule["duration_operator"] === ">=") {
        if (rule["duration_value"] === null || rule["duration_value"] === "") {
          return true;
        } else {
          if (rule["duration_value"] < 1) {
            return true;
          }
        }
      }

      return false;
    },
    precipitation24Disabled(value) {
      return value === "24/7-7";
    },
    entireTimePeriodDisabled(value) {
      return value === "ENTIRE_TIME_PERIOD";
    },
    updatePrecipitationRule(newOperator, index) {
      this.$emit("resetFixedRule", index, newOperator);
    },
    filterOperators(tab, value_type) {
      if (tab == "cold_temperature" && value_type == "min_value") {
        return ["<", "<="];
      }
      if (tab == "hot_temperature" && value_type == "max_value") {
        return [">", ">="];
      }
      if (tab == "precipitation" && value_type == "volume_operator") {
        return [">="];
      }
      if (tab == "precipitation" && value_type == "duration_operator") {
        return [">=", "ENTIRE_TIME_PERIOD"];
      }
      if (tab == "precipitation" && value_type == "volume_unit") {
        return ["mm", "mm/h"];
      }
      if (tab == "precipitation" && value_type == "time_period") {
        return ["WEATHER_TIME_RANGE", "24/7-7", "7/7-14"];
      }

      return [">=", ">", "<", "<="];
    },
  },
};
</script>
<style>
.wrapper {
  position: relative;
  height: 50px;
  top: -50%;
}

.line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  z-index: 1;
}

.wordwrapper {
  text-align: center;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -8px;
  z-index: 2;
}

.word {
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font: bold 10px arial, sans-serif;
  background: #fff;
}
</style>
