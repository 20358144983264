import { AxiosResponse } from "axios";
import dateService from "../services/dateService";
import durationService from "../services/durationService";
import {
  NonWorkingDay,
  ProjectDurationSettings,
  ProjectDurationSettingsToUpdate,
  PublicHolidaysResponse,
} from "../types/ProjectDurationSettings";
import { apiClient } from "./clients";

const mapNonWorkingDays = (nonWorkingDays: NonWorkingDay<string>[]) =>
  nonWorkingDays.map((nonWorkingDay) => ({
    ...nonWorkingDay,
    start_date: dateService.parseLocalDate(nonWorkingDay.start_date),
    end_date: dateService.parseLocalDate(nonWorkingDay.end_date),
  }));

const mapProjectDurationSettings = (
  projectDurationSettings: Omit<
    ProjectDurationSettings<string>,
    "settings" | "settingsWithoutDisturbances"
  >,
): ProjectDurationSettings => {
  const nonWorkingDays = mapNonWorkingDays(projectDurationSettings.non_working_days);
  return {
    ...projectDurationSettings,
    non_working_days: nonWorkingDays,
    settings: durationService.calculateSettings(
      projectDurationSettings.working_hours,
      nonWorkingDays.filter((nonWorkingDay) => nonWorkingDay.is_critical),
    ),
    settingsWithoutDisturbances: durationService.calculateSettings(
      projectDurationSettings.working_hours,
      nonWorkingDays.filter(
        (nonWorkingDay) => nonWorkingDay.is_critical && nonWorkingDay.type !== "disturbance",
      ),
    ),
  };
};

const loadProjectDurationSettings = (
  customerName: string,
  siteId: string,
): Promise<ProjectDurationSettings> =>
  apiClient
    .get<Omit<ProjectDurationSettings<string>, "settings" | "settingsWithoutDisturbances">>(
      `/durations/${customerName}/${siteId}/settings`,
    )
    .then((response) => mapProjectDurationSettings(response.data));

const formatProjectDurationSettingsToUpdate = (
  projectDurationSettingsToUpdate: ProjectDurationSettingsToUpdate,
) => ({
  ...projectDurationSettingsToUpdate,
  non_working_days: projectDurationSettingsToUpdate.non_working_days?.map((non_working_day) => ({
    ...non_working_day,
    name: non_working_day.name,
    start_date: dateService.formatLocalDate(non_working_day.start_date),
    end_date: dateService.formatLocalDate(non_working_day.end_date),
  })),
});

const updateProjectDurationSettings = (
  customerName: string,
  siteId: string,
  projectDurationSettingsToUpdate: ProjectDurationSettingsToUpdate,
): Promise<ProjectDurationSettings> =>
  apiClient
    .patch<
      void,
      AxiosResponse<
        Omit<ProjectDurationSettings<string>, "settings" | "settingsWithoutDisturbances">
      >
    >(
      `/durations/${customerName}/${siteId}/settings`,
      formatProjectDurationSettingsToUpdate(projectDurationSettingsToUpdate),
    )
    .then((response) => mapProjectDurationSettings(response.data));

const loadPublicHolidays = (
  countryCode: string,
  zipCode: string,
  year: number,
): Promise<PublicHolidaysResponse | null> =>
  apiClient
    .get<void, AxiosResponse<PublicHolidaysResponse<string>>>(
      `/durations/public-holidays/${countryCode}/${zipCode}/${year}`,
    )
    .then((response) =>
      response.data
        ? {
            ...response.data,
            holidays: mapNonWorkingDays(response.data.holidays),
          }
        : null,
    );

export default {
  loadProjectDurationSettings,
  updateProjectDurationSettings,
  loadPublicHolidays,
};
