import debounce from "lodash.debounce";
import { computed, onMounted, onUnmounted, Ref, ref, watch } from "vue";

const ratio4to3 = 4 / 3;

export const calculateRatioDimensions = (width: number, height: number, ratio: number) => {
  if (width / height >= ratio) {
    return {
      width: height * ratio,
      height: height,
    };
  }
  return {
    width: width,
    height: width * (1 / ratio),
  };
};

export const calculate4to3Dimensions = (width: number, hei: number) =>
  calculateRatioDimensions(width, hei, ratio4to3);

const calculateFullWindowRatioDimensions = (ratio: number) =>
  calculateRatioDimensions(window.innerWidth || 800, window.innerHeight || 600, ratio);

const calculateFullScreenRatioDimensions = (ratio: number) => {
  const screenHeight = window.screen.height || 600;
  return calculateRatioDimensions(
    window.screen.width || 800,
    window.navigator.platform.includes("Mac") ? screenHeight - 37 : screenHeight,
    ratio,
  );
};

const useFullWindow4to3RatioDimensions = () => {
  const dimensions = ref(calculateFullWindowRatioDimensions(ratio4to3));

  const handleResize = () => {
    dimensions.value = calculateFullWindowRatioDimensions(ratio4to3);
  };

  onMounted(() => {
    window.addEventListener("resize", handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
  });

  return dimensions;
};

export const useFullWindow4to3RatioDimensionStyle = () => {
  const dimensions = useFullWindow4to3RatioDimensions();
  return computed(() => `width: ${dimensions.value.width}px; height: ${dimensions.value.height}px`);
};

const useFullScreen4to3RatioDimensions = () => {
  const dimensions = ref(calculateFullScreenRatioDimensions(ratio4to3));

  const handleResize = () => {
    dimensions.value = calculateFullScreenRatioDimensions(ratio4to3);
  };

  onMounted(() => {
    window.addEventListener("resize", handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize);
  });

  return dimensions;
};

export const useFullScreen4to3RatioDimensionStyle = () => {
  const dimensions = useFullScreen4to3RatioDimensions();
  return computed(() => `width: ${dimensions.value.width}px; height: ${dimensions.value.height}px`);
};

export const useResizeObserver = (elementRef: Ref<HTMLDivElement | null>, handler: () => void) => {
  const resizeObserver = new ResizeObserver(handler);

  const observe = () => {
    resizeObserver.disconnect();
    if (elementRef.value) {
      resizeObserver.observe(elementRef.value);
    }
  };

  watch(
    () => elementRef.value,
    () => {
      observe();
    },
  );

  onMounted(() => {
    observe();
  });

  onUnmounted(() => {
    resizeObserver.disconnect();
  });
};

export const useDebouncedBoundingClientRect = (
  elementRef: Ref<HTMLDivElement | null>,
  wait: number,
) => {
  const rect = ref<DOMRect | undefined>(elementRef.value?.getBoundingClientRect());

  const calculateRect = () => {
    const newRect = elementRef.value?.getBoundingClientRect();
    if (
      (newRect && !rect.value) ||
      (!newRect && rect.value) ||
      (newRect &&
        rect.value &&
        (newRect.width !== rect.value.width ||
          newRect.height !== rect.value.height ||
          newRect.x !== rect.value.x ||
          newRect.y !== rect.value.y ||
          newRect.top !== rect.value.top ||
          newRect.bottom !== rect.value.bottom ||
          newRect.left !== rect.value.left ||
          newRect.right !== rect.value.right))
    ) {
      rect.value = newRect;
    }
  };

  const debouncedCalculateRect = debounce(calculateRect, wait);

  useResizeObserver(elementRef, debouncedCalculateRect);

  onMounted(() => {
    calculateRect();
  });

  return rect;
};
