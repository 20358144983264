<template>
  <div class="md:px-6 px-4">
    <PageHeader v-if="currentStream && !onlyLeft">{{ currentStream.name }}</PageHeader>
    <CameraNavbar :stream="currentStream" v-if="currentStream && !onlyLeft" />
    <NoDataYet :noData="noData" v-if="noData" :style="{ maxHeight: 'calc(100vh - 150px)' }" />
    <div v-else>
      <div class="grid grid-cols-5 lg:grid-cols-10 mb-20 sm:mb-0" v-if="!onlyLeft && !noData">
        <div class="col-span-6">
          <div class="mt-2 pr-2 lg:max-w-screen-md lg:pr-6 2xl:max-w-screen-2xl">
            <slot name="contentLeft"></slot>
          </div>
        </div>
        <div class="col-span-5 lg:col-span-4">
          <div class="sm:pl-2 pt-6 lg:pl-10">
            <slot name="contentRight"></slot>
          </div>
        </div>
      </div>
      <slot name="contentLeft" v-else></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import NoDataYet from "@/components/layout/NoDataYet.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import { useStreams } from "@/composables/stream";
import CameraNavbar from "@/views/camera/components/CameraNavbar.vue";

const props = defineProps({
  onlyLeft: {
    type: Boolean,
    required: false,
  },
  noData: {
    type: Boolean,
  },
  cameraId: {
    type: String,
  },
});

const { streams } = useStreams();
const route = useRoute();
const { t } = useI18n();

const currentStream = computed(() =>
  streams.value.find(
    (stream) =>
      stream.camera_id === route.params.camera_id &&
      props.cameraId !== t("app_features.site_activity"),
  ),
);
</script>
