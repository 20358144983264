<template>
  <div
    class="w-full flex justify-center items-center h-full"
    v-if="isProjectLoading || isLogoUrlLoading"
  >
    <LoadingSpinner />
  </div>
  <div v-else-if="project" class="flex flex-col gap-12">
    <div class="flex flex-col gap-4">
      <h1 class="text-xl font-bold text-gray-900 px-1">
        {{ $t("admin.project_settings.tab_general") }}
      </h1>
      <Form
        @submit="handleSubmit"
        :initialValues="initialValues"
        :validationSchema="schema"
        class="flex flex-col gap-8 w-full 2xl:w-2/3 settingsCard"
        v-slot="{ isSubmitting, meta }"
        ref="formRef"
      >
        <Field name="name" :label="t('admin.general_project_settings.field_name_label')" />
        <Field
          name="country_code"
          :label="t('admin.general_project_settings.field_country_code_label')"
          :readonly="true"
        />
        <Field
          name="zip_code"
          :label="t('admin.general_project_settings.field_zip_code_label')"
          :readonly="true"
        />
        <Field
          name="timezone"
          :label="t('admin.general_project_settings.field_timezone_label')"
          :readonly="true"
        />
        <Field name="street" :label="t('admin.general_project_settings.field_street_label')" />
        <Field
          name="planned_start"
          :label="t('admin.general_project_settings.field_planned_start_label')"
          :readonly="true"
        />
        <Field
          name="planned_end"
          :label="t('admin.general_project_settings.field_planned_end_label')"
          :readonly="true"
        />
        <Field
          name="status"
          :label="t('admin.general_project_settings.field_status_label')"
          :readonly="true"
        />
        <button
          type="submit"
          :disabled="isSubmitting || !meta.dirty"
          class="flex items-center gap-2 rounded-md self-end w-min bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 disabled:bg-gray-300 disabled:cursor-default"
        >
          <span>{{ t("buttons.save") }}</span>
          <LoadingSpinner v-if="isSubmitting && meta.valid" size="w-4 h-4" />
        </button>
        <div v-if="isUpdateError" class="text-red-900 text-right text-sm">
          {{ t("admin.general_project_settings.save_error_message") }}
        </div>
      </Form>
    </div>
    <div class="flex flex-col gap-4">
      <h1 class="text-xl font-bold text-gray-900 px-1">
        {{ $t("admin.general_project_settings.logo") }}
      </h1>
      <div class="w-full 2xl:w-2/3 settingsCard">
        <LogoUpload
          @updateFile="uploadLogo($event)"
          @deleteFile="deleteLogo"
          :savedLogo="logoUrl"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { Form, SubmissionHandler } from "vee-validate";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { NavigationGuard, onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import * as yup from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useSaveBeforeLeaveConfirmationModal } from "shared/composables/toast";
import { Project } from "shared/types/Project";
import Field from "@/components/forms/Field.vue";
import {
  useDeleteLogo,
  useLoadCurrentProject,
  useLogoUrl,
  useUpdateCurrentProject,
  useUploadLogo,
} from "@/composables/project";
import LogoUpload from "@/views/settings_project/components/LogoUpload.vue";

const { t } = useI18n();

const { project, isProjectLoading } = useLoadCurrentProject();
const { updateProject, isUpdateError } = useUpdateCurrentProject();
const { logoUrl, isLogoUrlLoading } = useLogoUrl();
const { deleteLogo } = useDeleteLogo();
const { uploadLogo } = useUploadLogo();
const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();

const formRef = ref<typeof Form | null>(null);

const navigationGuard: NavigationGuard = (to, from, next) => {
  const meta = formRef.value?.meta;

  if (!meta?.dirty) {
    return next();
  }
  showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
    if (confirmed) {
      next();
    }
  });
};

onBeforeRouteLeave(navigationGuard);
onBeforeRouteUpdate(navigationGuard);

const statusLabels: Record<Project["status"], string> = {
  active: t("admin.general_project_settings.project_status.active"),
  completed: t("admin.general_project_settings.project_status.completed"),
};

const maxCharacterCount = 1000;

type FormValues = {
  name: string;
  country_code: string;
  zip_code: string;
  timezone: string;
  street: string;
  planned_start: string;
  planned_end: string | null;
  status: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required(t("admin.general_project_settings.validation_name_required"))
    .max(
      maxCharacterCount,
      t("admin.general_project_settings.validation_max_characters", { maxCharacterCount }),
    ),
  street: yup
    .string()
    .required(t("admin.general_project_settings.validation_street_required"))
    .max(
      maxCharacterCount,
      t("admin.general_project_settings.validation_max_characters", { maxCharacterCount }),
    ),
});

const initialValues = computed(() => {
  if (!project.value) {
    return undefined;
  }
  const formValues: FormValues = {
    name: project.value.name,
    country_code: project.value.country_code,
    zip_code: project.value.zip_code,
    timezone: project.value.timezone,
    street: project.value.street,
    planned_start: format(project.value.planned_start, "dd.MM.yyyy"),
    planned_end: project.value.planned_end
      ? format(project.value.planned_end, "dd.MM.yyyy")
      : project.value.planned_end,
    status: statusLabels[project.value.status],
  };
  return formValues;
});

const handleSubmit: SubmissionHandler = (genericObject, context) => {
  const values = genericObject as FormValues;

  return updateProject({
    name: values.name,
    street: values.street,
  })
    .then(() => context.resetForm({ values: initialValues.value }))
    .catch(() => {
      // have to catch the error here, otherwise it's propagated as an unhandled error
      // it's already logged by vue-query
      return undefined;
    });
};
</script>
