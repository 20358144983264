<template>
  <Sidebar>
    <div class="outerContainer flex flex-col lg:overflow-hidden">
      <ProjectOverviewV2Header
        :canEdit="canEdit"
        :currentDashboard="currentDashboard"
        :dashboards="dashboardsWithDefault"
        @toggleAddWidgetSidebar="isAddWidgetsSideBarOpen = !isAddWidgetsSideBarOpen"
        @selectDashboard="setLastSelectedDashboardId($event._id)"
        @showAddWidgetsSidebar="isAddWidgetsSideBarOpen = $event"
        @resetCurrentDashboard="handleResetDashboardClick"
        @refresh="handleRefresh"
      />
      <div
        class="flex-1 pb-1 lg:overflow-auto flex flex-col gap-3"
        :style="{
          paddingLeft: '10px',
          paddingRight: isAddWidgetsSideBarOpen
            ? `${addWidgetSidebarWidth + gridMargin + 10}px`
            : isMobileBreakpoint(breakpoint)
            ? '10px'
            : '0',
          scrollbarGutter: 'stable',
        }"
      >
        <div
          v-if="gridLayout.length !== 0 || isAddWidgetsSideBarOpen"
          :class="['select-none', gridLayout.length === 0 && isAddWidgetsSideBarOpen && 'flex-1']"
          ref="containerRef"
          @dragenter="handleDragEnter"
          @dragover="handleDragOver"
        >
          <BackgroundGrid
            :show="isChanging || !!draggedWidget"
            :gridMargin="gridMargin"
            :columnWidth="columnWidth"
            :rowHeight="rowHeight"
          >
            <GridLayout
              ref="gridLayoutComponent"
              :key="gridLayoutKey"
              :layout="gridLayout"
              :responsiveLayouts="responsiveLayouts"
              :rowHeight="rowHeight"
              :isDraggable="canEditCurrentDashboard"
              :isResizable="canEditCurrentDashboard"
              :useCssTransforms="false"
              verticalCompact
              responsive
              :cols="gridColumns"
              :margin="[gridMargin, gridMargin]"
              :breakpoints="{
                lg: mobileBreakpoint,
                md: mobileBreakpoint,
                sm: mobileBreakpoint,
                xs: 0,
                xxs: 0,
              }"
              @layoutUpdated="handleLayoutUpdated"
              @breakpointChanged="handleBreakpointChanged"
            >
              <GridItem
                v-for="item in gridLayout"
                :key="item.i"
                @resize="handleResize"
                @resized="handleResized($event, item.type)"
                @move="isChanging = true"
                @moved="handleItemMoved(item.type)"
                dragAllowFrom=".vgl-item__drag-handle"
                v-bind="item"
              >
                <Component
                  v-if="draggedWidget?.i !== item.i"
                  :is="widgetComponents[item.type]"
                  :arg="item.arg"
                  :width="calculateLayoutItemWidth(item)"
                  :height="calculateLayoutItemHeight(item)"
                  :dashboardGridSize="dashboardGridSize"
                  :dashboard="currentDashboard"
                  @delete="deleteWidget(item)"
                  @widgetRouteClicked="
                    trackEvent('dashboard_widget_routing_click', {
                      type: item.type,
                      report_type: $event || '',
                    })
                  "
                  @trackInteraction="
                    trackEvent('dashboard_widget_interaction', {
                      type: item.type,
                      report_type: $event || '',
                    })
                  "
                />
              </GridItem>
            </GridLayout>
          </BackgroundGrid>
        </div>
        <button
          v-if="
            !isAddWidgetsSideBarOpen &&
            canEdit &&
            currentDashboard &&
            hasPermissionToEditDashboard(currentDashboard)
          "
          class="bg-gray-50 px-4 rounded-md border-transparent py-3 hover:bg-yellow-200 mb-3 mx-[10px] flex items-center gap-1 group"
          @click="isAddWidgetsSideBarOpen = true"
          :style="{
            paddingLeft: '10px',
            paddingRight: '10px',
          }"
        >
          <PlusIcon class="h-4 w-4" />
          <span class="text-sm"> {{ $t("dashboard.add_widget") }} </span>
        </button>
      </div>
    </div>
    <div
      class="fixed right-5 bottom-3 addWidgetsSideBarContainer"
      v-if="isAddWidgetsSideBarOpen && canEditCurrentDashboard"
      :style="{ width: `${addWidgetSidebarWidth}px` }"
    >
      <AddWidgetsSidebar
        class="h-full"
        :widgets="availableWidgets"
        :dashboardGridSize="dashboardGridSize"
        @addWidget="addWidget($event)"
        @close="isAddWidgetsSideBarOpen = false"
        @widgetDragStart="handleWidgetDragStart"
        @widgetDrag="handleWidgetDrag"
        @widgetDragEnd="handleWidgetDragEnd"
      />
    </div>
  </Sidebar>
</template>

<script lang="ts" setup>
import { PlusIcon } from "@heroicons/vue/24/solid";
import { GridLayout, GridItem, Breakpoints, Breakpoint } from "grid-layout-plus";
import isMobile from "is-mobile";
import debounce from "lodash.debounce";
import { Component as VueComponent, onMounted, onUnmounted, Ref, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useConfirmationModal } from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import Sidebar from "@/components/layout/Sidebar.vue";
import { useCreateOrUpdateDashboard, useLastSelectedDashboardId } from "@/composables/dashboard";
import { useDebouncedBoundingClientRect } from "@/composables/screen";
import {
  Dashboard,
  DashboardGridSize,
  OculaiLayout,
  OculaiLayoutItem,
  OculaiResponsiveLayout,
  Widget,
} from "@/types/Dashboard";
import AddWidgetsSidebar from "@/views/dashboard/componentsV2/AddWidgetsSidebar.vue";
import BackgroundGrid from "@/views/dashboard/componentsV2/BackgroundGrid.vue";
import CustomerLogoWidget from "@/views/dashboard/componentsV2/CustomerLogoWidget.vue";
import LoadingWidget from "@/views/dashboard/componentsV2/LoadingWidget.vue";
import PlannerWidget from "@/views/dashboard/componentsV2/PlannerWidget.vue";
import ProcessWidget from "@/views/dashboard/componentsV2/ProcessWidget.vue";
import ProjectOverviewV2Header from "@/views/dashboard/componentsV2/ProjectOverviewV2Header.vue";
import ReportWidget from "@/views/dashboard/componentsV2/ReportWidget.vue";
import StreamWidget from "@/views/dashboard/componentsV2/StreamWidget.vue";
import WeatherWidget from "@/views/dashboard/componentsV2/WeatherWidget.vue";
import {
  useHasPermissionToEditDashboard,
  useDashboardsWithDefault,
  useLayoutContext,
  useAspectRatioBugFix,
} from "@/views/dashboard/composables";
import {
  addWidgetsToLayout,
  calculateRealWidgetHeight,
  calculateRealWidgetWidth,
  convertDashboardToOculaiLayout,
  convertOculaiLayoutToDashboardWidgets,
  createDefaultLayout,
  createResponsiveLayout,
  desktopColumns,
  getAvailableWidgets,
  isMobileBreakpoint,
  liveStreamWidgetHeaderHeight,
  loadingWidgetsLayout,
  mobileColumns,
  removeWidgetFromLayout,
} from "@/views/dashboard/services/projectOverviewV2Layout";
import { useDragging } from "@/views/dashboard/services/widgetDragger";
import "./styles.css";

const widgetComponents: Record<Widget, VueComponent> = {
  WeatherWidget,
  LoadingWidget,
  StreamWidget,
  PlannerWidget,
  ProcessWidget,
  ReportWidget,
  CustomerLogoWidget,
};

const addWidgetSidebarWidth = 300;
const gridMargin = 10;
const gridColumns: Breakpoints = {
  lg: desktopColumns,
  md: desktopColumns,
  sm: desktopColumns,
  xs: mobileColumns,
  xxs: mobileColumns,
};

const { t } = useI18n();

const containerRef = ref<HTMLDivElement | null>(null);
const gridLayout = ref<OculaiLayout>(loadingWidgetsLayout);
const gridLayoutComponent = ref<InstanceType<typeof GridLayout> | null>(null);
const responsiveLayouts: Ref<OculaiResponsiveLayout> = ref(
  createResponsiveLayout(loadingWidgetsLayout),
);
const breakpoint = ref<Breakpoint>("lg");
const ignoreNextLayoutUpdate = ref(false);
const createDashboardPromise = ref<Promise<Dashboard | null>>(Promise.resolve(null));
const isChanging = ref(false);
const isAddWidgetsSideBarOpen = ref(false);

const { layoutContext, isLoading: isLayoutContextLoading } = useLayoutContext();
const { dashboardsWithDefault, isLoading: areDashboardsLoading } =
  useDashboardsWithDefault(layoutContext);
const { createOrUpdateDashboard } = useCreateOrUpdateDashboard();
const { lastSelectedDashboardId, setLastSelectedDashboardId } = useLastSelectedDashboardId();
const streamWidgetColumns = ref(2);
const gridLayoutRefreshKey = ref(0);

// the wait time for debounce has to be 16, so it's in sync with the grid library,
// which does exactly the same debounce
const containerRect = useDebouncedBoundingClientRect(containerRef, 16);

const {
  handleWidgetDragStart,
  handleWidgetDrag,
  handleWidgetDragEnd,
  handleDragEnter,
  handleDragOver,
  draggedWidget,
} = useDragging(containerRect, gridLayoutComponent, gridLayout);

const showConfirmationModal = useConfirmationModal();

const hasPermissionToEditDashboard = useHasPermissionToEditDashboard();

const trackEvent = useTrackEvent();

const setLayout = (layout: OculaiLayout) => {
  gridLayout.value = layout;
  responsiveLayouts.value = createResponsiveLayout(layout);
};

const aspectRatioBugFix = useAspectRatioBugFix(gridLayout, setLayout);

const gridLayoutKey = computed(() =>
  isLoading.value
    ? `${isLoading.value}`
    : `${currentDashboard.value?._id}_${gridLayoutRefreshKey.value}`,
);

const mobileBreakpoint = computed(() => (isAddWidgetsSideBarOpen.value ? 1 : 727));

const canEdit = computed(
  () =>
    !!currentDashboard.value &&
    !!layoutContext.value &&
    !isMobileBreakpoint(breakpoint.value) &&
    !isLoading.value &&
    !isMobile({ tablet: true, featureDetect: true }),
);

const canEditCurrentDashboard = computed(
  () =>
    canEdit.value && currentDashboard.value && hasPermissionToEditDashboard(currentDashboard.value),
);

const columnWidth = computed(() => {
  if (!containerRect.value) {
    return 30;
  }
  const numberOfColumns = gridColumns[breakpoint.value];
  // there are margins on the side too, that's why numberOfColumns + 1
  return (containerRect.value.width - (numberOfColumns + 1) * gridMargin) / numberOfColumns;
});

// see the formulas in https://www.notion.so/oculai/dcdd775d5e20463c893e98dfcf8713fe
const rowHeight = computed(
  () =>
    (4 * liveStreamWidgetHeaderHeight +
      3 * streamWidgetColumns.value * columnWidth.value -
      (streamWidgetColumns.value - 1) * gridMargin) /
    (4 * streamWidgetColumns.value),
);

const dashboardGridSize: Ref<DashboardGridSize> = computed(() => ({
  width: columnWidth.value,
  height: rowHeight.value,
  spacing: gridMargin,
}));

const calculateBestStreamWidgetColumns = () => {
  const widths = gridLayout.value
    .filter((widget) => widget.type === "StreamWidget")
    .map((widget) => widget.w);
  streamWidgetColumns.value = widths.length > 0 ? Math.max(...widths) : 2;
};

const handleBreakpointChanged = (newBreakpoint: Breakpoint) => {
  ignoreNextLayoutUpdate.value = true;
  breakpoint.value = newBreakpoint;
  // here one might think the second argument (layout) could be also used,
  // but it seems like that's not an up-to-date version from responsiveLayouts.value

  // also important, that we make a copy of the original array with [...]
  // so a layout updated event will be triggered and the ignoreNextLayoutUpdate toggled to false
  // this is needed, because when the grid is initialized, it might not trigger a layout updated event
  // after the breakpoint changed one. This would leave ignoreNextLayoutUpdate to stay true.
  gridLayout.value = [...responsiveLayouts.value[newBreakpoint]];
};

const currentDashboard = computed<Dashboard>(
  () =>
    dashboardsWithDefault.value.find(
      (dashboard) => dashboard._id === lastSelectedDashboardId.value,
    ) ||
    dashboardsWithDefault.value.find((dashboard) => dashboard.default) ||
    dashboardsWithDefault.value[0],
);

const version = ref(currentDashboard.value?.version || 0);

const isLoading = computed(() => areDashboardsLoading.value || isLayoutContextLoading.value);

const availableWidgets = computed(() =>
  isLoading.value || !layoutContext.value
    ? []
    : getAvailableWidgets(gridLayout.value, layoutContext.value),
);

const recalculateLayout = (options?: { triggerDbUpdate?: boolean }) => {
  const newLayout =
    isLoading.value || !currentDashboard.value || !layoutContext.value
      ? loadingWidgetsLayout
      : convertDashboardToOculaiLayout(currentDashboard.value, layoutContext.value);
  ignoreNextLayoutUpdate.value =
    options?.triggerDbUpdate !== undefined ? !options.triggerDbUpdate : true;
  setLayout(newLayout);
};

const calculateLayoutItemWidth = (layoutItem: OculaiLayoutItem) =>
  calculateRealWidgetWidth(dashboardGridSize.value, layoutItem.w);

const calculateLayoutItemHeight = (layoutItem: OculaiLayoutItem) =>
  calculateRealWidgetHeight(dashboardGridSize.value, layoutItem.h);

const doCreateOrUpdateDashboard = () => {
  version.value += 1;

  const dashboardToCreate: Dashboard = {
    ...currentDashboard.value,
    widgets: convertOculaiLayoutToDashboardWidgets(gridLayout.value),
    version: version.value,
  };

  // have to catch the error here, otherwise it's propagated as an unhandled error
  // it's already logged by vue-query
  const promise = createOrUpdateDashboard(dashboardToCreate).catch(() => null);
  if (dashboardToCreate.generated) {
    createDashboardPromise.value = promise;
  }
};

const debouncedCreateOrUpdateDashboard = debounce(doCreateOrUpdateDashboard, 500);

const createOrUpdateDashboardWithCreateWait = () =>
  createDashboardPromise.value.finally(() => {
    debouncedCreateOrUpdateDashboard();
  });

const handleLayoutUpdated = (layout: OculaiLayout) => {
  // this is needed for the case when the widget is grabbed, but in the end moved back to its original position
  // fortunately layout updated is triggered and isChanging can be toggled to false
  isChanging.value = false;

  // this we calculate always when layout changes
  calculateBestStreamWidgetColumns();

  if (ignoreNextLayoutUpdate.value) {
    ignoreNextLayoutUpdate.value = false;
    return;
  }
  if (isLoading.value || draggedWidget.value) {
    return;
  }
  setLayout(layout);
  createOrUpdateDashboardWithCreateWait();
};

const addWidget = (widget: OculaiLayoutItem) => {
  const newLayout = addWidgetsToLayout([widget], gridLayout.value);
  setLayout(newLayout);
};

const deleteWidget = (widget: OculaiLayoutItem) => {
  const newLayout = removeWidgetFromLayout(widget, gridLayout.value);
  setLayout(newLayout);
  trackEvent("dashboard_widget_remove", { type: widget.type });
};

const handleResetDashboardClick = () => {
  showConfirmationModal({
    confirmAction: t("dashboard.reset_confirmation.confirm"),
    cancelAction: t("dashboard.reset_confirmation.cancel"),
    message: t("dashboard.reset_confirmation.message"),
    header: t("dashboard.reset_confirmation.header"),
  }).then((confirmed) => {
    if (confirmed && layoutContext.value) {
      const newLayout = createDefaultLayout(layoutContext.value);
      setLayout(newLayout);
    }
  });
};

const handleResize = (widgetId: string) => {
  isChanging.value = true;
  aspectRatioBugFix.handleResize(widgetId);
};

const handleResized = (widgetId: string, widgetType: string) => {
  isChanging.value = false;
  aspectRatioBugFix.handleResized(widgetId);
  trackEvent("dashboard_widget_resize_apply", { type: widgetType });
};

const handleItemMoved = (itemType: string) => {
  isChanging.value = false;
  trackEvent("dashboard_widget_reorder_apply", { type: itemType });
};

const handleRefresh = () => {
  gridLayoutRefreshKey.value += 1;
};

watch([isLoading, layoutContext], (newValues, oldValues) => {
  const [newIsLoading, newLayoutContext] = newValues;
  const [oldIsLoading, oldLayoutContext] = oldValues;
  const triggerDbUpdate =
    !newIsLoading &&
    !oldIsLoading &&
    newLayoutContext &&
    oldLayoutContext &&
    newLayoutContext !== oldLayoutContext
      ? true
      : undefined;
  recalculateLayout({ triggerDbUpdate });
});

const isSameId = (newDashboard: Dashboard, oldDashboard: Dashboard) =>
  oldDashboard.generated || newDashboard._id === oldDashboard._id;

watch(currentDashboard, (newDashboard, oldDashboard) => {
  if (
    (!newDashboard && oldDashboard) ||
    (newDashboard && !oldDashboard) ||
    (newDashboard && oldDashboard && !isSameId(newDashboard, oldDashboard)) ||
    (newDashboard &&
      oldDashboard &&
      isSameId(newDashboard, oldDashboard) &&
      newDashboard.version > version.value)
  ) {
    version.value = newDashboard?.version || 0;
    isAddWidgetsSideBarOpen.value = isAddWidgetsSideBarOpen.value && canEditCurrentDashboard.value;
    recalculateLayout();
  }
});

onMounted(() => {
  recalculateLayout();
  document.body.classList.add("overflow-x-hidden");
  trackEvent("dashboard_view");
});

onUnmounted(() => {
  document.body.classList.remove("overflow-x-hidden");
});
</script>

<style scoped>
.addWidgetsSideBarContainer {
  top: 115px;
}

.outerContainer {
  min-height: calc(100vh - 52px);
}

@media (min-width: 640px) {
  .addWidgetsSideBarContainer {
    top: 122px;
  }

  .outerContainer {
    min-height: calc(100vh - 60px);
  }
}

@media (min-width: 1024px) {
  .addWidgetsSideBarContainer {
    top: 62px;
  }

  .outerContainer {
    min-height: auto;
    height: 100vh;
  }
}
</style>
