<template>
  <div :style="fullWindow4to3RatioDimensionStyle" class="relative">
    <OculaiVideoPlayer
      :loading="loading"
      :src="timelapseUrl"
      :noSrcMessage="$t('err.no_data_yet')"
      class="flex-1"
    />
    <a :href="timelapseUrl" v-if="!loading" class="absolute top-3 right-3">
      <ArrowDownTrayIcon class="h-6 w-6 text-white hover:text-yellow-300" />
    </a>
  </div>
</template>

<script lang="ts" setup>
import { ArrowDownTrayIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import logger from "shared/services/logger";
import OculaiVideoPlayer from "@/components/other/OculaiVideoPlayer.vue";
import { useFullWindow4to3RatioDimensionStyle } from "@/composables/screen";
import CameraRepository from "@/repositories/CameraRepository";

const loading = ref(false);
const timelapseUrl = ref("");

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const route = useRoute();

const fullWindow4to3RatioDimensionStyle = useFullWindow4to3RatioDimensionStyle();

onMounted(() => {
  loading.value = true;

  CameraRepository.loadPublicProjectTimelapse(
    currentCustomerName,
    currentSiteId,
    route.params.camera_id as string,
    route.params.token as string,
  )
    .then((data) => {
      timelapseUrl.value = data?.presigned_url_timelapse || "";
    })
    .catch((error) => {
      timelapseUrl.value = "";
      logger.error(error);
    })
    .finally(() => {
      loading.value = false;
    });
});
</script>
