<template>
  <div
    :class="[
      'w-full',
      (props?.item?.route || (props?.item?.options && props?.item?.options[0]?.route)) &&
        'cursor-pointer',
    ]"
    ref="parent"
    style="position: relative"
    @mouseenter="open = true"
    @mouseleave="open = false"
    @click="handleClick"
  >
    <div
      :class="[
        item?.current ? 'bg-oaiGray-400 text-yellow-400' : 'text-white-900 hover:text-yellow-400',
        open && 'bg-oaiGray-400 text-yellow-400',
      ]"
    >
      <slot></slot>
    </div>

    <SafeArea
      v-if="open && isSafeAreaOpen && parent && child"
      :anchor="parent"
      :submenu="child"
      :disablePointerEvents="disablePointerEvents"
    />

    <div
      v-if="open"
      class="submenu-container opacity-100"
      :style="submenuPositionStyle"
      ref="child"
      @click.stop
    >
      <ul class="customSubMenu min-w-64">
        <slot name="customContent">
          <h3 class="mb-2 font-semibold text-white cursor-default">{{ item?.name }}</h3>
          <li
            v-for="option in item?.options"
            :key="option.name"
            :class="[
              option?.current ? ' text-yellow-400' : 'hover:text-yellow-400',
              'flex gap-x-3 group hover:border-white border-l border-gray-400 cursor-pointer text-sm leading-6 ',
            ]"
          >
            <router-link
              :to="option?.route"
              class="w-full flex items-center gap-1 mx-2 px-2 py-1 rounded-md hover:bg-oaiGray-300"
              v-if="option.route"
            >
              <component :is="option?.icon" class="h-4 w-4 shrink-0" aria-hidden="true" />
              {{ option?.name }}
            </router-link>
            <a
              v-if="option.url"
              :href="option.url"
              class="w-full flex items-center gap-1 mx-2 px-2 py-1 rounded-md hover:bg-oaiGray-300"
              target="_blank"
            >
              <component :is="option?.icon" class="h-4 w-4 shrink-0" aria-hidden="true" />
              {{ option?.name }}
            </a>
          </li>
        </slot>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import isMobile from "is-mobile";
import { ref, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { MenuEntry } from "@/types/Sidebar";
import useMousePosition from "@/types/useMousePosition";
import SafeArea from "./SidebarSafeArea.vue";

const open = ref(false);
const parent = ref<HTMLElement | null>(null);
const child = ref<HTMLElement | null>();
const disablePointerEvents = ref(false);
const isSafeAreaOpen = ref(true);
const parentRect = ref();
const router = useRouter();
const submenuRect = ref();
const props = defineProps<{ menuRect: DOMRect; item?: MenuEntry }>();

onMounted(() => {
  submenuRect.value = child.value?.getBoundingClientRect();
  parentRect.value = parent?.value?.getBoundingClientRect();
});

const mouseAt = useMousePosition();
let timeout = undefined as ReturnType<typeof setTimeout> | undefined;

const parentWidth = computed(() => (parent.value ? parent.value.offsetWidth : 0));

watch(
  mouseAt,
  () => {
    if (!parentRect.value || !props.menuRect) {
      return;
    }

    const isWithinItem =
      mouseAt.value.x > parentRect.value.x &&
      mouseAt.value.y > parentRect.value.y &&
      mouseAt.value.x < parentRect.value.x + parentRect.value.width &&
      mouseAt.value.y < parentRect.value.y + parentRect.value.height;

    const isWithinMenu =
      mouseAt.value.x > props.menuRect.x && // Check if x exists
      mouseAt.value.y > props.menuRect.y && // Check if y exists
      mouseAt.value.x < props.menuRect.x + props.menuRect.width &&
      mouseAt.value.y < props.menuRect.y + props.menuRect.height;

    if (!isWithinItem && isWithinMenu) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        open.value = false;
      }, 300); // Delay for smooth transition
    } else {
      clearTimeout(timeout);
    }
  },
  { deep: true },
);

const handleClick = () => {
  if (!isMobile({ tablet: true, featureDetect: true })) {
    if (props?.item?.route && !props.item.isClickable) {
      router.push(props.item.route);
    } else if (
      props?.item?.options &&
      props?.item?.options[0] &&
      props?.item?.options[0]?.route &&
      !props.item.isClickable
    ) {
      router.push(props?.item?.options[0]?.route);
    }
  }
};
type SubmenuStyle = {
  position: "absolute" | "relative" | "fixed" | "sticky";
  left: string;
  top?: string;
  bottom?: string;
};
watch(child, () => {
  submenuRect.value = child.value?.getBoundingClientRect();
});

const submenuPositionStyle = computed(() => {
  const style: SubmenuStyle = { position: "absolute", left: `${parentWidth.value}px` };
  const viewportHeight = window.innerHeight;
  if (submenuRect.value && parentRect.value) {
    if (submenuRect.value.bottom > viewportHeight) {
      style.bottom = "0";
    } else {
      style.top = "0";
    }
  }
  return style;
});
</script>

<style scoped>
li {
  cursor: pointer;
}

ul {
  width: fit-content;
}
.submenu-container {
  z-index: 9999;
  position: absolute;
}
</style>
