export const sectionsConfig = {
  machinery: {
    machinery_type: {
      field_type: "combobox",
      width: "md:w-1/3 xl:w-1/4 w-full",
      order: 1,
    },
    amount: {
      field_type: "number",
      width: "md:w-1/3 xl:w-1/4 w-full",
      order: 2,
    },
    note: {
      field_type: "textarea",
      width: "md:w-1/3 xl:w-1/4 w-full",
      order: 3,
    },
  },
  special_events: {
    special_events_type: {
      field_type: "combobox",
      width: "xl:w-1/4 md:w-1/3 w-full",
      order: 1,
    },
    note: {
      field_type: "textarea",
      width: "xl:w-1/4 md:w-1/3 w-full",
      order: 2,
    },
  },
  workforce: {
    workforce_type: {
      field_type: "combobox",
      width: "w-full md:w-1/4",
      order: 1,
    },
    company: {
      field_type: "combobox",
      width: "w-full md:w-1/4",
      order: 2,
    },
    amount: {
      field_type: "number",
      width: "w-full md:w-1/4",
      order: 3,
    },
    note: {
      field_type: "textarea",
      width: "md:w-1/4 w-full",
      order: 4,
    },
  },
  processes: {
    process_type: {
      field_type: "combobox",
      width: "xl:w-1/4 md:w-1/3 w-full",
      order: 1,
    },
    location: {
      field_type: "combobox",
      width: "xl:w-1/4 md:w-1/3 w-full",
      order: 2,
    },
    start_time: {
      field_type: "time",
      width: "md:w-44 w-full",
      order: 3,
    },
    end_time: {
      field_type: "time",
      width: "md:w-44 w-full",
      order: 4,
    },
    note: {
      field_type: "textarea",
      width: "xl:w-1/4 md:w-1/3 w-full",
      order: 5,
    },
  },
  material: {
    material_type: {
      field_type: "combobox",
      width: "md:w-1/4 w-full ",
      order: 1,
    },
    amount: {
      field_type: "number",
      width: "w-full md:w-1/4",
      order: 2,
    },
    unit: {
      field_type: "combobox",
      width: "w-full md:w-1/4",
      order: 3,
    },
    note: {
      field_type: "textarea",
      width: "md:w-1/4 w-full",
      order: 4,
    },
  },
};
