import { GridLayout } from "grid-layout-plus";
import throttle from "lodash.throttle";
import { onBeforeUnmount, onMounted, ref, Ref } from "vue";
import { OculaiLayout, OculaiLayoutItem } from "@/types/Dashboard";

const useMousePosition = () => {
  const mouseAt = { x: -1, y: -1 };

  const syncMousePosition = (event: MouseEvent) => {
    mouseAt.x = event.clientX;
    mouseAt.y = event.clientY;
  };

  onMounted(() => {
    document.addEventListener("dragover", syncMousePosition);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("dragover", syncMousePosition);
  });

  return mouseAt;
};

export const useDragging = (
  containerRect: Ref<DOMRect | undefined>,
  gridLayoutComponent: Ref<InstanceType<typeof GridLayout> | null>,
  gridLayout: Ref<OculaiLayout>,
) => {
  const draggedWidget = ref<OculaiLayoutItem | null>();

  const mouseAt = useMousePosition();

  const handleWidgetDragStart = (event: DragEvent) => {
    const data = event.dataTransfer?.getData("application/json");
    draggedWidget.value = data ? JSON.parse(data) : null;
  };

  const handleWidgetDrag = throttle(() => {
    if (!containerRect.value || !gridLayoutComponent.value || !draggedWidget.value) {
      return;
    }

    const mouseInGrid =
      mouseAt.x > containerRect.value.left &&
      mouseAt.x < containerRect.value.right &&
      mouseAt.y > containerRect.value.top &&
      mouseAt.y < containerRect.value.bottom;

    const dropId = draggedWidget.value.i;

    if (mouseInGrid && !gridLayout.value.find((item) => item.i === dropId)) {
      gridLayout.value.push({
        ...draggedWidget.value,
      });
    }

    const index = gridLayout.value.findIndex((item) => item.i === dropId);
    if (index === -1) {
      return;
    }

    const item = gridLayoutComponent.value.getItem(dropId);
    if (!item) {
      return;
    }

    try {
      item.wrapper.style.display = "none";
    } catch {
      // just ignore
    }

    item.state = {
      ...item.state,
      top: mouseAt.y - containerRect.value.top,
      left: mouseAt.x - containerRect.value.left,
    };

    const newPosition = item.calcXY(
      mouseAt.y - containerRect.value.top,
      mouseAt.x - containerRect.value.left,
    );

    if (mouseInGrid) {
      gridLayoutComponent.value.dragEvent(
        "dragstart",
        dropId,
        newPosition.x,
        newPosition.y,
        draggedWidget.value.h,
        draggedWidget.value.w,
      );
      draggedWidget.value.x = gridLayout.value[index].x;
      draggedWidget.value.y = gridLayout.value[index].y;
    } else {
      gridLayoutComponent.value.dragEvent(
        "dragend",
        dropId,
        newPosition.x,
        newPosition.y,
        draggedWidget.value.h,
        draggedWidget.value.w,
      );
      gridLayout.value = gridLayout.value.filter((item) => item.i !== dropId);
    }
  });

  const handleWidgetDragEnd = (event: DragEvent) => {
    const finalDraggedWidget = draggedWidget.value;
    draggedWidget.value = null;

    if (!containerRect.value || !gridLayoutComponent.value || !finalDraggedWidget) {
      return;
    }

    const mouseInGrid =
      mouseAt.x > containerRect.value.left &&
      mouseAt.x < containerRect.value.right &&
      mouseAt.y > containerRect.value.top &&
      mouseAt.y < containerRect.value.bottom;

    if (!mouseInGrid) {
      return;
    }

    const dropId = finalDraggedWidget.i;

    gridLayoutComponent.value.dragEvent(
      "dragend",
      dropId,
      finalDraggedWidget.x,
      finalDraggedWidget.y,
      finalDraggedWidget.h,
      finalDraggedWidget.w,
    );

    gridLayout.value = gridLayout.value.filter((item) => item.i !== dropId);

    if (event.dataTransfer?.dropEffect !== "move") {
      return;
    }

    gridLayout.value.push(finalDraggedWidget);

    gridLayoutComponent.value.dragEvent(
      "dragend",
      finalDraggedWidget.i,
      finalDraggedWidget.x,
      finalDraggedWidget.y,
      finalDraggedWidget.h,
      finalDraggedWidget.w,
    );

    const item = gridLayoutComponent.value.getItem(dropId);
    if (!item) {
      return;
    }

    try {
      item.wrapper.style.display = "";
    } catch {
      // just ignore
    }
  };

  const handleDragEnter = (event: DragEvent) => {
    event.preventDefault();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "move";
    }
  };

  const handleDragOver = (event: DragEvent) => {
    event.preventDefault();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "move";
    }
  };

  return {
    draggedWidget,
    handleWidgetDragStart,
    handleWidgetDrag,
    handleWidgetDragEnd,
    handleDragEnter,
    handleDragOver,
  };
};
