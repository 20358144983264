<template>
  <div class="relative">
    <Combobox as="div" v-model="selectedOption" @update:modelValue="emits('update', $event)">
      <ComboboxLabel class="text-sm font-medium leading-6 text-gray-900" v-if="label">{{
        label
      }}</ComboboxLabel>
      <div class="relative">
        <ComboboxInput
          required
          :disabled="disabled"
          :class="[!disabled ? 'oai-inputs' : 'overviewInput', 'w-full text-xs ']"
          :placeholder="t('report.general.empty_combobox')"
          :displayValue="(value: unknown) => (value as (typeof options)[number])?.name || query"
          @change="query = $event.target.value"
          aria-autocomplete="one-time-code"
          autocomplete="one-time-code"
          @keydown.enter.prevent="createCustomField(query)"
        />
        <ComboboxButton
          class="focus:outline-none absolute inset-y-0 right-0 flex items-center rounded-r-md px-2"
          v-if="!disabled"
          @click="query = ''"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
        <ComboboxOptions
          :disabled="disabled"
          class="focus:outline-none absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-gray-500/5 text-sm"
          @click="emits('update', selectedOption)"
        >
          <ComboboxOption
            v-for="(option, index) in filteredData"
            :key="index"
            :value="option"
            as="template"
            v-slot="{ active, selected: selectedValue }"
          >
            <li
              class="align-center relative flex cursor-default select-none items-center justify-between py-2 pl-3 pr-3"
              :class="{
                'bg-yellow-600 text-white': active,
                'text-gray-900': !active || selectedValue || option.value === selectedValue.value,
              }"
            >
              <span>
                {{ option.name }}
              </span>

              <span
                class="items- center flex p-1 hover:bg-gray-100"
                v-if="customOptions.some((custom) => custom.value === option.value)"
                @click.stop="deleteCustom(option.value)"
              >
                <XMarkIcon class="z-50 h-3 w-3 text-xs text-red" />
              </span>
            </li>
          </ComboboxOption>
          <ComboboxOption
            v-if="query !== '' && !filteredData.some((custom) => custom.name === query)"
            v-slot="{ active }"
          >
            <div
              :class="{
                'relative cursor-default select-none bg-yellow-600 px-4 py-2 font-semibold text-white':
                  active,
                'relative cursor-default select-none px-4 py-2 text-gray-900': !active,
              }"
            >
              <p @click="createCustomField(query)">
                {{ t("buttons.create") }}:
                <span
                  class="mx-1 rounded-md bg-gray-100 px-3 py-1 font-normal text-gray-500 hover:bg-yellow hover:text-white"
                >
                  {{ query }}</span
                >
              </p>
            </div>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>
    <small v-if="!selected && error" class="text-red absolute left-0" style="top: calc(100% + 4px)">
      {{ t("err.required") }}
    </small>
  </div>
</template>

<script lang="ts" setup>
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxLabel,
} from "@headlessui/vue";
import { ChevronUpDownIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { watch } from "vue";
import { ref, computed, PropType } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array as PropType<{ value: string; name: string }[]>,
    default: () => [],
  },
  selected: {
    type: String,
  },
  label: {
    type: String,
  },
  error: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["update", "createOption", "deleteOption"]);

const query = ref("");
const selectedOption = ref();
const customOptions = ref<typeof props.options>([]);

watch(
  () => props.selected,
  (value, old) => {
    if (old) {
      return;
    }

    selectedOption.value = props.options.find((option) => option.value === value) || {
      value: value,
      name: value,
    };
  },
  { immediate: true },
);

const filteredData = computed(() => {
  return [...props.options, ...customOptions.value]
    .filter((option) => {
      return option.name.toLowerCase().includes(query.value.toLowerCase());
    })
    .map((option) => ({ ...option }));
});

const createCustomField = (q: string) => {
  const customField = { value: q, name: q };
  customOptions.value.push(customField);
  selectedOption.value = customField;
  query.value = "";

  emits("createOption", customField);
  emits("update", customField);
};

const deleteCustom = (value: string) => {
  customOptions.value = customOptions.value.filter((option) => option.value !== value);

  emits("deleteOption", value);
};
</script>
