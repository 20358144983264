<template>
  <OaiTooltip
    v-if="
      !differenceForPath &&
      selected &&
      differenceForNode.workingDays !== null &&
      criticalPathNode.sub_state === 'at_least_one_part_finished'
    "
    :cls="['absolute text-xs -top-[17px] right-[2px]', differenceForNodeClass]"
  >
    {{ differenceForNode.workingDays }} {{ t("working_day.working_day_abbrev") }}
    <template #tooltip>
      <div class="text-xs" v-if="differenceForNode.plannedEvent || differenceForNode.actualEvent">
        <div class="flex gap-1" v-if="differenceForNode.plannedEvent">
          <div class="w-[130px]">{{ t("analytics.critical_path.planned_end") }}:</div>
          <div>{{ format(differenceForNode.plannedEvent.end, dateFormat) }}</div>
        </div>
        <div class="flex gap-1" v-if="differenceForNode.actualEvent">
          <div class="w-[130px]">{{ t("analytics.critical_path.actual_end") }}:</div>
          <div>
            {{
              differenceForNode.actualEvent.end
                ? format(differenceForNode.actualEvent.end, dateFormat)
                : "-"
            }}
          </div>
        </div>
        <div class="flex gap-1">
          <div class="w-[130px]">{{ t("working_day.working_day") }}:</div>
          <div>{{ differenceForNode.workingDays }}</div>
        </div>
      </div>
    </template>
  </OaiTooltip>
  <CriticalPathDelay
    v-if="differenceForPath"
    :differenceForPath="differenceForPath"
    cls="absolute -top-[25px] left-1/2 -translate-x-1/2"
  />
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import {
  CriticalPathContext,
  CriticalPathDifference,
  CriticalPathDifferenceForPath,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import CriticalPathDelay from "shared/views/critical_path/components/CriticalPathDelay.vue";
import { useDifferenceTextClass } from "../composables";

const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  criticalPathNode: CriticalPathNodeEx;
  selected: boolean;
  context: CriticalPathContext;
  differenceForPathsByNode: Record<string, CriticalPathDifferenceForPath>;
  differenceForNode: CriticalPathDifference;
}>();

const { t } = useI18n();

const differenceForPath = computed<CriticalPathDifferenceForPath | undefined>(
  () => props.differenceForPathsByNode[props.criticalPathNode._id],
);

const differenceForNodeClass = useDifferenceTextClass(
  computed(() => props.differenceForNode.workingDays),
);
</script>
