<template>
  <Switch
    v-model="model"
    @update:modelValue="emit('changed', $event)"
    :class="[
      model && !disabled ? 'bg-green-600' : 'bg-gray-200',
      !disabled ? 'cursor-pointer' : 'cursor-default',
      'relative inline-flex h-6 w-11 shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
    ]"
    :disabled="disabled"
  >
    <span
      :class="[
        model ? 'translate-x-5' : 'translate-x-0',
        'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
      ]"
    >
      <span
        :class="[
          model ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
        ]"
        aria-hidden="true"
      >
        <EllipsisHorizontalIcon class="h-3 w-3 text-gray-400"></EllipsisHorizontalIcon>
      </span>
      <span
        :class="[
          model ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
        ]"
        aria-hidden="true"
      >
        <CheckIcon
          class="h-3 w-3"
          :class="[disabled ? 'text-gray-200' : 'text-green-600']"
        ></CheckIcon>
      </span>
    </span>
  </Switch>
</template>

<script lang="ts" setup>
import { Switch } from "@headlessui/vue";
import { CheckIcon, EllipsisHorizontalIcon } from "@heroicons/vue/24/outline";

const model = defineModel<boolean>();
const emit = defineEmits(["changed"]);
defineProps({
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
