import numberService from "@/services/numberService";
import { UnitValue } from "@/types/UnitValue";
import { calculateValue, precision } from "@/views/unit_values/services/unitValues";

const calculateDurationCustomValueDifference = (unitValue: UnitValue) => {
  if (unitValue.duration === null) {
    return null;
  }
  if (unitValue.type_duration_factor !== null) {
    if (unitValue.type_adjusted_duration === null) {
      return unitValue.duration;
    }
    return unitValue.duration - unitValue.type_adjusted_duration;
  }
  if (unitValue.oai_duration === null) {
    return null;
  }
  return unitValue.duration - unitValue.oai_duration;
};

const calculateDuration = (unitValue: UnitValue) => {
  const typeAdjustmentDifference =
    unitValue.oai_duration !== null && unitValue.type_adjusted_duration !== null
      ? unitValue.type_adjusted_duration - unitValue.oai_duration
      : null;
  return {
    oculai: unitValue.oai_duration,
    oculai_adjusted: unitValue.oai_duration,
    type_adjustment: unitValue.type_duration_factor,
    type_adjustment_difference: typeAdjustmentDifference,
    custom_value: unitValue.custom_adjusted_duration,
    custom_value_difference: calculateDurationCustomValueDifference(unitValue),
    final_value: unitValue.duration,
  };
};

const calculateWorkforceCustomValueDifference = (unitValue: UnitValue) => {
  if (unitValue.workforce === null) {
    return null;
  }
  if (unitValue.type_workforce_delta !== null) {
    if (unitValue.type_adjusted_workforce === null) {
      return unitValue.workforce - unitValue.type_workforce_delta;
    }
    return unitValue.workforce - unitValue.type_adjusted_workforce;
  }
  const oai_adjusted_workforce = unitValue.oai_adjusted_workforce ?? unitValue.oai_workforce;
  if (oai_adjusted_workforce === null) {
    return null;
  }
  return unitValue.workforce - oai_adjusted_workforce;
};

const calculateWorkforce = (unitValue: UnitValue) => {
  const oculai = unitValue.oai_adjusted_workforce ?? unitValue.oai_workforce;
  return {
    oculai,
    oculai_adjusted: oculai,
    type_adjustment: unitValue.type_workforce_delta,
    type_adjustment_difference: unitValue.type_workforce_delta,
    custom_value: unitValue.custom_adjusted_workforce,
    custom_value_difference: calculateWorkforceCustomValueDifference(unitValue),
    final_value: unitValue.workforce,
  };
};

const calculateUnitValueOculaiNew = (unitValue: UnitValue) => {
  const value = calculateValue(unitValue.duration, unitValue.workforce, unitValue.quantity);
  if (value === null) {
    return null;
  }
  return numberService.fixToPrecision(value, precision);
};

const calculateUnitValueCustomValueDifference = (unitValue: UnitValue) => {
  if (unitValue.value === null) {
    return null;
  }
  if (unitValue.type_value_delta !== null) {
    if (unitValue.type_adjusted_value === null) {
      return unitValue.value - unitValue.type_value_delta;
    }
    return unitValue.value - unitValue.type_adjusted_value;
  }
  const oai_adjusted_value = calculateUnitValueOculaiNew(unitValue);
  if (oai_adjusted_value === null) {
    return null;
  }
  return unitValue.value - oai_adjusted_value;
};

const calculateUnitValue = (unitValue: UnitValue) => {
  return {
    oculai: unitValue.oai_value,
    oculai_adjusted: calculateUnitValueOculaiNew(unitValue),
    type_adjustment: unitValue.type_value_delta,
    type_adjustment_difference: unitValue.type_value_delta,
    custom_value: unitValue.custom_adjusted_value,
    custom_value_difference: calculateUnitValueCustomValueDifference(unitValue),
    final_value: unitValue.value,
  };
};

export default (unitValue: UnitValue) => {
  return {
    duration: calculateDuration(unitValue),
    workforce: calculateWorkforce(unitValue),
    value: calculateUnitValue(unitValue),
  };
};
