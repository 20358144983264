<template>
  <div>
    <!-- building rectangles -->
    <div
      class="absolute top-0 left-0 border-gray-600 border-2"
      :style="{
        width: `${buildingPosition.width * viewport.zoom}px`,
        height: `${buildingPosition.height * viewport.zoom}px`,
        transform: `${createTranslate(buildingPosition.x, buildingPosition.y)}`,
      }"
      v-for="buildingPosition in buildingPositionsForRectangles"
      :key="buildingPosition.id || 'null'"
    />
    <!-- building roofs -->
    <div
      class="absolute top-0 left-0 border-gray-600 h-0"
      :style="{
        width: `${buildingPosition.width * viewport.zoom}px`,
        borderBottom: `${BuildingRoofHeight * viewport.zoom}px solid #4B5563`,
        borderLeft: `${BuildingRoofEdge * viewport.zoom}px solid transparent`,
        borderRight: `${BuildingRoofEdge * viewport.zoom}px solid transparent`,
        transform: `${createTranslate(
          buildingPosition.x,
          buildingPosition.y - BuildingRoofHeight + 1,
        )}`,
      }"
      v-for="buildingPosition in layout.buildings.filter((position) => position.id !== null)"
      :key="buildingPosition.id || 'null'"
    />
    <!-- level horizontal lines -->
    <div
      class="absolute top-0 left-0 border-dashed border-gray-600"
      :class="index === 0 ? '' : 'border-t'"
      :style="{
        width: `${item.buildingPosition.width * viewport.zoom}px`,
        transform: `${createTranslate(item.buildingPosition.x, item.levelPosition.y)}`,
      }"
      v-for="(item, index) in buildingsWithLevels"
      :key="item.id"
    />
    <!-- building labels -->
    <div
      class="absolute top-0 left-0 truncate text-center z-[100] text-white"
      :style="{
        width: `${position.width * viewport.zoom}px`,
        height: `${BuildingRoofHeight * viewport.zoom}px`,
        padding: `0 ${BuildingRoofEdge * viewport.zoom}px`,
        lineHeight: `${BuildingRoofHeight * viewport.zoom}px`,
        fontSize: `${labelFontSize * viewport.zoom}px`,
        transform: `${createTranslate(position.x, position.y - BuildingRoofHeight)}`,
      }"
      v-for="position in layout.buildings.filter((p) => p.label)"
      :key="position.id || 'null'"
    >
      <OaiTooltip cls="truncate">
        {{ position.label }}
        <template #tooltip>
          <div>
            {{ position.label }}
          </div>
        </template>
      </OaiTooltip>
    </div>
    <!-- level labels -->
    <div
      class="absolute top-0 left-0 flex items-center px-1 z-[100]"
      :style="{
        width: `${LevelLabelWidth * viewport.zoom}px`,
        height: `${position.height * viewport.zoom}px`,
        fontSize: `${labelFontSize * viewport.zoom}px`,
        transform: `${createTranslate(position.x - LevelLabelWidth, position.y)}`,
      }"
      v-for="position in layout.levels"
      :key="position.id"
    >
      <OaiTooltip cls="truncate">
        {{ position.label }}
        <template #tooltip>
          <div>
            {{ position.label }}
          </div>
        </template>
      </OaiTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useVueFlow } from "@vue-flow/core";
import { computed } from "vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { CriticalPathLayout, CriticalPathPosition } from "shared/types/CriticalPath";
import {
  BuildingRoofEdge,
  BuildingRoofHeight,
  LevelLabelWidth,
} from "shared/views/critical_path/criticalPathLayout";

const labelFontSize = 16;

const props = defineProps<{ layout: CriticalPathLayout }>();

const { viewport } = useVueFlow();

const buildingsWithLevels = computed(() => {
  const sharedLevelsWithBuilding = props.layout.sharedBuilding
    ? props.layout.levels
        .filter((levelPosition) => props.layout.sharedLevelIds.has(levelPosition.id))
        .map((levelPosition) => ({
          id: levelPosition.id,
          buildingPosition: props.layout.sharedBuilding as CriticalPathPosition<null, null>,
          levelPosition,
        }))
    : [];
  return [
    ...props.layout.buildings.flatMap((buildingPosition) =>
      props.layout.levels
        .filter((levelPosition) => !props.layout.sharedLevelIds.has(levelPosition.id))
        .map((levelPosition) => ({
          id: `${buildingPosition.id}_${levelPosition.id}`,
          buildingPosition,
          levelPosition,
        })),
    ),
    ...sharedLevelsWithBuilding,
  ];
});

const buildingPositionsForRectangles = computed(() => {
  return [
    ...props.layout.buildings.filter((position) => position.width > 0),
    props.layout.sharedBuilding,
  ].filter((item) => item) as CriticalPathLayout["buildings"];
});

const createTranslate = (x: number, y: number) => {
  const finalX = x * viewport.value.zoom + viewport.value.x;
  const finalY = y * viewport.value.zoom + viewport.value.y;
  return `translate(${finalX}px, ${finalY}px)`;
};
</script>
