<template>
  <div class="w-18 fixed bottom-0 flex items-center pb-2">
    <a
      href="https://www.oculai.de/impressum"
      class="group flex pr-6 text-xs text-gray-500 2xl:text-sm"
    >
      <ArrowTopRightOnSquareIcon class="mr-1 flex h-4 w-4" aria-hidden="true" />
      {{ $t("authentication.login.imprint") }}
    </a>
    <a
      href="https://www.oculai.de/datenschutz"
      class="group flex pr-6 text-xs text-gray-500 2xl:text-sm"
    >
      <ArrowTopRightOnSquareIcon class="mr-1 flex h-4 w-4" aria-hidden="true" />
      {{ $t("authentication.login.data_protection") }}
    </a>
    <div>
      <select
        id="language"
        name="language"
        class="focus:outline-none mt-1 block w-full border-gray-300 py-2 pl-3 pr-10 text-xs focus:border-gray-500 focus:ring-gray-500"
        v-model="selectedLanguage"
        @change="changeLanguage"
      >
        <option v-for="lang in languages" :key="lang.id" :value="lang">
          {{ lang.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/solid";
import { ref, onMounted, watch } from "vue";

interface Language {
  id: number;
  name: string;
  identifier: string;
  icon: string;
}

const languages: Language[] = [
  { id: 1, name: "Deutsch", identifier: "de", icon: "de" },
  { id: 2, name: "English", identifier: "en", icon: "gb" },
  { id: 3, name: "Français", identifier: "fr", icon: "fr" },
];

const selectedLanguage = ref<Language | null>(null);

const changeLanguage = () => {
  if (selectedLanguage.value) {
    localStorage.setItem("lang", selectedLanguage.value.identifier);
    window.location.reload();
  }
};

const loadLanguage = () => {
  const storedLang = localStorage.getItem("lang") || "de";
  selectedLanguage.value = languages.find((lang) => lang.identifier === storedLang) || languages[0];
};

watch(selectedLanguage, (newLang) => {
  if (newLang) {
    localStorage.setItem("lang", newLang.identifier);
  }
});

onMounted(loadLanguage);
</script>
