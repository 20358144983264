import { useMutation } from "@tanstack/vue-query";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import logger from "shared/services/logger";
import IssueReportRepository from "@/repositories/IssueReportRepository";
import { IssueReport, NewIssueReport } from "@/types/IssueReport";

export const useCreateSelfResolvedIssueReport = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();

  const {
    mutateAsync: createSelfResolvedIssueReport,
    isLoading: isLoading,
    error,
  } = useMutation<IssueReport, Error, NewIssueReport>({
    mutationFn: (issue) =>
      IssueReportRepository.createSelfResolvedIssueReport(customerName, siteId, issue),
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  return { createSelfResolvedIssueReport, isLoading, error };
};
