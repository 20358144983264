import { Breakpoint } from "grid-layout-plus";
import { Project } from "shared/types/Project";
import { Stream } from "shared/types/Stream";
import i18n from "@/i18n";
import {
  Dashboard,
  DashboardGridSize,
  DashboardLayoutContext,
  DashboardType,
  DashboardWidget,
  DashboardWidgetToAddOrRemove,
  OculaiLayout,
  OculaiLayoutItem,
  OculaiResponsiveLayout,
  Widget,
} from "@/types/Dashboard";
import { Report } from "@/types/Report";

const { t } = i18n.global;

export const liveStreamWidgetHeaderHeight = 60;
export const mobileColumns = 2;
export const desktopColumns = 6;

const minWidgetWidth = 1;
const minWidgetHeight = 1;

const defaultWidgetWidth = 2;
const defaultWidgetHeight = 2;

const streamWidgetWithBig = 4;
const streamWidgetHeightBig = 4;

export const loadingWidgetsLayout: OculaiLayout = [
  {
    name: "loadingWidget1",
    x: 0,
    y: 0,
    w: 2,
    h: 2,
    i: "loadingWidget1",
    type: "LoadingWidget",
    arg: null,
  },
  {
    name: "loadingWidget2",
    x: 2,
    y: 0,
    w: 2,
    h: 2,
    i: "loadingWidget2",
    type: "LoadingWidget",
    arg: null,
  },
  {
    name: "loadingWidget3",
    x: 4,
    y: 0,
    w: 2,
    h: 1,
    i: "loadingWidget3",
    type: "LoadingWidget",
    arg: null,
  },
  {
    name: "loadingWidget4",
    x: 4,
    y: 2,
    w: 2,
    h: 3,
    i: "loadingWidget4",
    type: "LoadingWidget",
    arg: null,
  },
  {
    name: "loadingWidget5",
    x: 0,
    y: 2,
    w: 4,
    h: 2,
    i: "loadingWidget5",
    type: "LoadingWidget",
    arg: null,
  },
];

const createStreamWidgets = (project: Project, streams: Stream[]): OculaiLayoutItem[] =>
  streams.map((stream) => ({
    name: stream.name,
    x: 0,
    y: 0,
    w: streams.length === 1 ? streamWidgetWithBig : defaultWidgetWidth,
    h: streams.length === 1 ? streamWidgetHeightBig : defaultWidgetHeight,
    i: `streamWidget-${stream._id}`,
    type: "StreamWidget",
    arg: stream.camera_id,
    preserveAspectRatio: true,
    class: "oaiStreamWidgetContainer",
  }));

const createReportWidgets = (reports: Report[]): OculaiLayoutItem[] => {
  return reports.map((report) => ({
    name: report.name,
    x: 0,
    y: 0,
    w: report.type === "query_value" ? 1 : defaultWidgetWidth,
    h: report.type === "query_value" ? 1 : defaultWidgetHeight,
    i: `reportWidget-${report._id}`,
    type: "ReportWidget",
    arg: report._id,
  }));
};

const createDesktopLayout = (layout: OculaiLayout): OculaiLayout => {
  const resultLayout = [] as OculaiLayout;

  let x = 0;
  let y = 0;
  let highestWidgetInRow: OculaiLayoutItem | null = null;

  for (const widget of layout) {
    const previousWidget = resultLayout[resultLayout.length - 1];
    if (previousWidget && previousWidget.h + widget.h <= defaultWidgetHeight) {
      resultLayout.push({
        ...widget,
        x: previousWidget.x,
        y: previousWidget.y + previousWidget.h,
      });
    } else {
      if (x + widget.w > desktopColumns) {
        if (highestWidgetInRow && highestWidgetInRow.h > defaultWidgetHeight) {
          x = highestWidgetInRow.w;
        } else {
          x = 0;
        }
        y += defaultWidgetHeight;
        highestWidgetInRow = null;
      }
      resultLayout.push({
        ...widget,
        x,
        y,
      });
      x += widget.w;
    }
    if (!highestWidgetInRow || widget.h > highestWidgetInRow.h) {
      highestWidgetInRow = widget;
    }
  }

  return resultLayout;
};

const calculateSortScore = (widget: OculaiLayoutItem) => {
  const subGrid = 2;
  const gridX = Math.floor(widget.x / subGrid);
  const gridY = Math.floor(widget.y / subGrid);
  const gridXAdjustment = widget.x + widget.w > (gridX + 1) * subGrid && widget.h > 1 ? 1 : 0;
  return 1000 * (gridY * 100 + gridX + gridXAdjustment) + widget.y * 100 + widget.x;
};

const convertToMobileLayout = (layout: OculaiLayout): OculaiLayout => {
  const sortedLayout = layout.slice().map((widget) => ({
    ...widget,
    sortScore: calculateSortScore(widget),
  }));
  sortedLayout.sort((a, b) => a.sortScore - b.sortScore);
  return sortedLayout.reduce((acc, widget, index) => {
    const previousWidget = acc[index - 1] || ({ w: 0, h: 0, x: 0, y: 0 } as OculaiLayoutItem);
    const width = Math.min(widget.w, mobileColumns);
    const height = widget.preserveAspectRatio ? width : Math.min(widget.h, mobileColumns);
    const putOnSameRow = previousWidget.w === 1 && width === 1 && previousWidget.x !== 1;
    const newWidget = {
      ...widget,
      x: putOnSameRow ? 1 : 0,
      y: putOnSameRow ? previousWidget.y : previousWidget.y + previousWidget.h,
      w: width,
      h: height,
    };
    acc.push(newWidget);
    return acc;
  }, [] as OculaiLayout);
};

export const calculateLayout = (
  context: DashboardLayoutContext,
  excludeWidgetIds?: Set<string>,
): OculaiLayout => {
  const { project, streams, permissions, reports } = context;
  const isActiveProject = project?.status === "active";
  const showWeatherWidget = isActiveProject;
  const showProcessWidget = isActiveProject && permissions.hasProcessPermission;
  const showPlannerWidget = permissions.hasPlannerPermission && permissions.hasProcessPermission;

  const weatherWidget: OculaiLayoutItem = {
    name: t("dashboard.project.weather_card.header"),
    x: 0,
    y: 0,
    w: defaultWidgetWidth,
    h: 1,
    i: "weatherWidget",
    type: "WeatherWidget",
    arg: null,
  };

  const processWidget: OculaiLayoutItem = {
    name: t("dashboard.process_widget.header"),
    x: 0,
    y: 0,
    w: defaultWidgetWidth,
    h: defaultWidgetHeight,
    i: "processWidget",
    type: "ProcessWidget",
    arg: null,
    preserveAspectRatio: true,
  };

  const plannerWidget: OculaiLayoutItem = {
    name: t("dashboard.project.planner_card.current_procedures"),
    x: 0,
    y: 0,
    w: defaultWidgetWidth,
    h: 1,
    i: "plannerWidget",
    type: "PlannerWidget",
    arg: null,
  };

  const customerLogoWidget: OculaiLayoutItem = {
    name: t("admin.general_project_settings.logo"),
    x: 0,
    y: 0,
    w: defaultWidgetWidth,
    h: 1,
    i: "customerLogoWidget",
    type: "CustomerLogoWidget",
    arg: null,
  };

  const layout = [
    ...createStreamWidgets(project, streams),
    showWeatherWidget && weatherWidget,
    showPlannerWidget && plannerWidget,
    showProcessWidget && processWidget,
    ...createReportWidgets(reports),
    customerLogoWidget,
  ].filter(
    (item) => item && !excludeWidgetIds?.has(`${item.type}_${item.arg}`),
  ) as OculaiLayoutItem[];

  const layoutWithMinSize = layout.map((widget) => ({
    ...widget,
    minW: widget.minW ?? minWidgetWidth,
    minH: widget.minH ?? minWidgetHeight,
  }));

  return createDesktopLayout(layoutWithMinSize);
};

export const convertDashboardToOculaiLayout = (
  dashboard: Dashboard,
  context: DashboardLayoutContext,
): OculaiLayout => {
  const calculatedLayout = calculateLayout(context);
  const getKey = (widget: { type: Widget; arg: string | null }) => `${widget.type}_${widget.arg}`;
  const calculatedWidgetByType = calculatedLayout.reduce((acc, widget) => {
    acc[getKey(widget)] = widget;
    return acc;
  }, {} as Record<string, OculaiLayoutItem>);
  return dashboard.widgets
    .filter((widget) => calculatedWidgetByType[getKey(widget)])
    .map((widget) => {
      const calculatedWidget = calculatedWidgetByType[getKey(widget)];
      return {
        ...calculatedWidget,
        x: widget.x,
        y: widget.y,
        w: widget.width,
        h: widget.height,
      };
    });
};

export const convertOculaiLayoutToDashboardWidgets = (layout: OculaiLayout): DashboardWidget[] =>
  layout.map((widget) => ({
    x: widget.x,
    y: widget.y,
    width: widget.w,
    height: widget.h,
    type: widget.type as DashboardWidget["type"],
    arg: widget.arg,
  }));

export const isMobileBreakpoint = (breakpoint: Breakpoint) => ["xxs", "xs"].includes(breakpoint);

export const createDefaultDashboard = (name: string, context: DashboardLayoutContext) => {
  const layout = createDefaultLayout(context);
  const defaultDashboard: Dashboard = {
    _id: "default",
    name,
    version: 1,
    type: "project",
    widgets: convertOculaiLayoutToDashboardWidgets(layout),
    customer_name: context.project.customer_name,
    site_id: context.project.site_id,
    user_email: null,
    default: true,
    created: new Date(),
    created_by: "oculai",
    updated: new Date(),
    updated_by: "oculai",
    generated: true,
  };
  return defaultDashboard;
};

export const getAvailableWidgets = (layout: OculaiLayout, context: DashboardLayoutContext) => {
  const currentWidgetKeys = new Set(layout.map((widget) => `${widget.type}_${widget.arg}`));
  const calculatedLayout = calculateLayout(context);
  return calculatedLayout.filter(
    (widget) => !currentWidgetKeys.has(`${widget.type}_${widget.arg}`),
  );
};

const getWidgetIdsWhichAreHiddenByDefault = (context: DashboardLayoutContext) => {
  return new Set([
    ...context.reports
      .filter((report) => report.type !== "milestone")
      .map((report) => `ReportWidget_${report._id}`),
    "CustomerLogoWidget_null",
  ]);
};

export const createResponsiveLayout = (layout: OculaiLayout): OculaiResponsiveLayout => {
  const mobileLayout = convertToMobileLayout(layout);
  return {
    xxs: mobileLayout,
    xs: mobileLayout,
    sm: layout,
    md: layout,
    lg: layout,
  };
};

export const createDefaultLayout = (context: DashboardLayoutContext) =>
  calculateLayout(context, getWidgetIdsWhichAreHiddenByDefault(context));

const calculateFreePosition = (layout: OculaiLayout, width: number, height: number) => {
  const grid: boolean[][] = [];

  for (const widget of layout) {
    for (let row = widget.y; row < widget.y + widget.h; row++) {
      grid[row] = grid[row] || [];
      for (let column = widget.x; column < widget.x + widget.w; column++) {
        grid[row][column] = true;
      }
    }
  }

  const maxRow = layout.length > 0 ? Math.max(...layout.map((widget) => widget.y + widget.h)) : 0;

  const fits = (columnFrom: number, rowFrom: number) => {
    for (let row = rowFrom; row < rowFrom + height; row++) {
      for (let column = columnFrom; column < columnFrom + width; column++) {
        if (column >= desktopColumns) {
          return false;
        }
        const gridRow = grid[row] || [];
        if (gridRow[column]) {
          return false;
        }
      }
    }
    return true;
  };

  for (let row = 0; row < maxRow; row++) {
    for (let column = 0; column < desktopColumns; column++) {
      if (fits(column, row)) {
        return [column, row];
      }
    }
  }

  return [0, maxRow + 1];
};

export const addWidgetsToLayout = (widgets: OculaiLayoutItem[], layout: OculaiLayout) => {
  const finalLayout = [...layout];
  for (const widget of widgets) {
    const [x, y] = calculateFreePosition(finalLayout, widget.w, widget.h);
    finalLayout.push({
      ...widget,
      x,
      y,
    });
  }
  return finalLayout;
};

export const removeWidgetFromLayout = (widget: OculaiLayoutItem, layout: OculaiLayout) =>
  layout.filter(({ type, arg }) => `${type}_${arg}` !== `${widget.type}_${widget.arg}`);

export const addWidgetToDashboard = (
  dashboard: Dashboard,
  widgetToAdd: DashboardWidgetToAddOrRemove,
  context: DashboardLayoutContext,
): Dashboard | null => {
  if (
    dashboard.widgets.some(
      (widget) => widget.type === widgetToAdd.type && widget.arg === widgetToAdd.arg,
    )
  ) {
    return null;
  }

  const calculatedLayout = calculateLayout(context);
  const calculatedWidget = calculatedLayout.find(
    (widget) => widget.type === widgetToAdd.type && widget.arg === widgetToAdd.arg,
  );

  if (!calculatedWidget) {
    return null;
  }

  const layout = convertDashboardToOculaiLayout(dashboard, context);
  const newLayout = addWidgetsToLayout([calculatedWidget], layout);

  return {
    ...dashboard,
    version: dashboard.version + 1,
    widgets: convertOculaiLayoutToDashboardWidgets(newLayout),
  };
};

export const removeWidgetFromDashboard = (
  dashboard: Dashboard,
  widgetToRemove: DashboardWidgetToAddOrRemove,
  context: DashboardLayoutContext,
): Dashboard | null => {
  if (
    !dashboard.widgets.some(
      (widget) => widget.type === widgetToRemove.type && widget.arg === widgetToRemove.arg,
    )
  ) {
    return null;
  }

  const calculatedLayout = calculateLayout(context);
  const calculatedWidget = calculatedLayout.find(
    (widget) => widget.type === widgetToRemove.type && widget.arg === widgetToRemove.arg,
  );

  if (!calculatedWidget) {
    return null;
  }

  const layout = convertDashboardToOculaiLayout(dashboard, context);
  const newLayout = removeWidgetFromLayout(calculatedWidget, layout);

  return {
    ...dashboard,
    version: dashboard.version + 1,
    widgets: convertOculaiLayoutToDashboardWidgets(newLayout),
  };
};

const dashboardTypes: DashboardType[] = ["project", "user"];

export const createDashboardMenuCategories = (dashboards: Dashboard[]) =>
  dashboardTypes.map((type) => {
    const dashboardsForCategory = dashboards.filter((dashboard) => dashboard.type === type);
    dashboardsForCategory.sort((a, b) => a.created.getTime() - b.created.getTime());
    const defaultDashboard = dashboardsForCategory.find((dashboard) => dashboard.default);
    return {
      type,
      dashboards: [
        defaultDashboard,
        ...dashboardsForCategory.filter((dashboard) => dashboard !== defaultDashboard),
      ].filter((dashboard) => dashboard) as Dashboard[],
    };
  });

export const calculateRealWidgetWidth = (dashboardGridSize: DashboardGridSize, w: number) =>
  Math.floor(dashboardGridSize.width * w + dashboardGridSize.spacing * (w - 1));

export const calculateRealWidgetHeight = (dashboardGridSize: DashboardGridSize, h: number) =>
  Math.floor(dashboardGridSize.height * h + dashboardGridSize.spacing * (h - 1));
