import {
  UnitValue,
  UnitValueCellNavigationDirection,
  UnitValueRowTag,
  UnitValueType,
} from "@/types/UnitValue";

type Context = {
  currentField: keyof UnitValue;
  direction: UnitValueCellNavigationDirection;
  fields: (keyof UnitValue)[];
  type: UnitValueType;
  types: UnitValueType[];
  tag: UnitValueRowTag;
  tags: UnitValueRowTag[];
};

const createInputSelector = (field: string, type: UnitValueType, tag: UnitValueRowTag) =>
  `input[data-unitValueField="${field}"][data-unitValueType="${type}"][data-unitValueTagId="${tag.id}"]`;

const focusInput = (field: keyof UnitValue, type: UnitValueType, tag: UnitValueRowTag) => {
  const selector = createInputSelector(field, type, tag);
  const inputToFocus = document.querySelector(selector) as HTMLInputElement | null;
  inputToFocus?.focus();
};

const focusAdjacentVerticalCell = (context: Context) => {
  const { tag, tags, type, currentField } = context;
  const currentRowTagIndex = tags.indexOf(tag);
  const offset = context.direction === "up" ? -1 : 1;
  const tagToFocus = tags[currentRowTagIndex + offset];

  if (tagToFocus) {
    focusInput(currentField, type, tagToFocus);
  }
};

const getTypeOffsetForHorizontalFocus = (context: Context, fieldToFocus: keyof UnitValue) => {
  const { fields, currentField } = context;
  const currentFieldIndex = fields.indexOf(currentField);
  const newFieldIndex = fields.indexOf(fieldToFocus);

  if (currentFieldIndex === 0 && newFieldIndex === fields.length - 1) {
    return -1;
  }
  if (currentFieldIndex === fields.length - 1 && newFieldIndex === 0) {
    return 1;
  }
  return 0;
};

const getTypeForHorizontalFocus = (context: Context, fieldToFocus: keyof UnitValue) => {
  const offset = getTypeOffsetForHorizontalFocus(context, fieldToFocus);
  const { type, types } = context;
  const currentTypeIndex = types.indexOf(type);
  return types[currentTypeIndex + offset];
};

const focusAdjacentHorizontalCell = (context: Context) => {
  const { tag, fields, currentField } = context;
  const fieldLength = fields.length;
  const offset = context.direction === "left" ? -1 : 1;
  const newFieldIndex = fields.indexOf(currentField) + offset;
  const newFieldIndexCircular = ((newFieldIndex % fieldLength) + fieldLength) % fieldLength;
  const fieldToFocus = fields[newFieldIndexCircular];
  const typeToFocus = getTypeForHorizontalFocus(context, fieldToFocus);

  if (fieldToFocus && typeToFocus) {
    focusInput(fieldToFocus, typeToFocus, tag);
  }
};

const focusTopOrBottomCell = (context: Context) => {
  const { currentField, type, tags } = context;
  const tagIndex = context.direction === "top" ? 0 : tags.length - 1;
  const tag = tags[tagIndex];
  if (tag) {
    focusInput(currentField, type, tag);
  }
};

const focusCell = (context: Context) => {
  if (context.direction === "up" || context.direction === "down") {
    focusAdjacentVerticalCell(context);
  }
  if (context.direction === "left" || context.direction === "right") {
    focusAdjacentHorizontalCell(context);
  }
  if (context.direction === "top" || context.direction === "bottom") {
    focusTopOrBottomCell(context);
  }
};

export default {
  focusCell,
};
