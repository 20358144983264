import { eachDayOfInterval, format, startOfDay } from "date-fns";
import durationService from "shared/services/durationService";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { ActualEvent, PlanConfig, PlannedEvent, PlannerItem } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { PlannerItemProgress } from "@/types/Plan";

const sum = (numbers: number[]): number => numbers.reduce((acc, number) => acc + number, 0);

const calculatePercentage = (finished: number, total: number) =>
  total !== 0 ? finished / total : null;

const calculateProjectProgressForPlannerItem = (
  plannerItem: PlannerItem,
  processDaysBySourceId: Record<string, Set<string>> | undefined,
  plannedEventsByPlannerItemId: Record<string, PlannedEvent>,
  actualEventsBySourceId: Record<string, ActualEvent>,
  projectDurationSettings: ProjectDurationSettings,
  now: Date,
): Pick<
  PlannerItemProgress,
  "total_working_days" | "finished_working_days" | "actual_finished_working_days"
> => {
  const plannedEvent = plannedEventsByPlannerItemId[plannerItem._id];

  if (!plannedEvent) {
    return {
      total_working_days: 0,
      finished_working_days: 0,
      actual_finished_working_days: 0,
    };
  }

  const { workingDays: totalWorkingDays } = durationService.calculateDuration(
    projectDurationSettings.settings,
    plannedEvent.start,
    plannedEvent.end,
  );

  const actualEvent = actualEventsBySourceId[plannerItem.source_id];

  if (!actualEvent) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: 0,
      actual_finished_working_days: 0,
    };
  }

  if (now < actualEvent.start) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: 0,
      actual_finished_working_days: 0,
    };
  }

  if (actualEvent.end && now >= startOfDay(actualEvent.end)) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: totalWorkingDays,
      actual_finished_working_days: totalWorkingDays,
    };
  }

  const processDays =
    (processDaysBySourceId && processDaysBySourceId[plannerItem.source_id]) || new Set<string>();

  const finishedWorkingDays = processDaysBySourceId
    ? eachDayOfInterval({ start: actualEvent.start, end: now }).reduce((acc, day) => {
        const formattedDate = format(day, "yyyy-MM-dd");
        return processDays.has(formattedDate) ? acc + 1 : acc;
      }, 0)
    : durationService.calculateDuration(projectDurationSettings.settings, actualEvent.start, now)
        .workingDays;

  return {
    total_working_days: totalWorkingDays,
    finished_working_days: Math.min(finishedWorkingDays, totalWorkingDays * 0.95),
    actual_finished_working_days: finishedWorkingDays,
  };
};

const getPlannerItemsToProcess = (planConfig: PlanConfig, hierarchyTags: HierarchyTagStore[]) => {
  const plannerItemsByParentId = planConfig.planner_items.reduce((acc, plannerItem) => {
    if (plannerItem.parent_id) {
      if (!acc[plannerItem.parent_id]) {
        acc[plannerItem.parent_id] = [];
      }
      acc[plannerItem.parent_id].push(plannerItem);
    }
    return acc;
  }, {} as Record<string, PlannerItem[]>);
  const hierarchyTagsBySourceId = hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      if (!acc[sourceId]) {
        acc[sourceId] = [];
      }
      acc[sourceId].push(tag);
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore[]>);

  const result: PlannerItem[] = [];

  const traverse = (plannerItems: PlannerItem[]): boolean => {
    let visible = false;
    for (const plannerItem of plannerItems) {
      const childPlannerItems = plannerItemsByParentId[plannerItem._id] || [];
      const allChildrenTrackingDisabled =
        childPlannerItems.length > 0 &&
        childPlannerItems.every((plannerItem) => !plannerItem.tracking_enabled);

      if (childPlannerItems.length === 0 || allChildrenTrackingDisabled) {
        if (plannerItem.tracking_enabled && hierarchyTagsBySourceId[plannerItem.source_id]) {
          result.push(plannerItem);
          visible = true;
        }
      } else {
        const childVisible = traverse(childPlannerItems);
        if (childVisible) {
          result.push(plannerItem);
          visible = true;
        }
      }
    }
    return visible;
  };

  traverse(planConfig.planner_items.filter((plannerItem) => !plannerItem.parent_id));

  return result;
};

const calculateProjectProgressItems = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[] | undefined,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
  date?: Date,
): PlannerItemProgress[] => {
  const now = startOfDay(new Date());
  const plannedEventsByPlannerItemId = planConfig.planned_events.reduce((acc, plannedEvent) => {
    acc[plannedEvent.planner_item_id] = plannedEvent;
    return acc;
  }, {} as Record<string, PlannedEvent>);
  const actualEventsBySourceId = planConfig.actual_events.reduce((acc, actualEvent) => {
    acc[actualEvent.source_id] = actualEvent;
    return acc;
  }, {} as Record<string, ActualEvent>);

  const plannerItemsToProcess = getPlannerItemsToProcess(planConfig, hierarchyTags);
  const plannerItemsByParentId = plannerItemsToProcess.reduce((acc, plannerItem) => {
    if (plannerItem.parent_id) {
      if (!acc[plannerItem.parent_id]) {
        acc[plannerItem.parent_id] = [];
      }
      acc[plannerItem.parent_id].push(plannerItem);
    }
    return acc;
  }, {} as Record<string, PlannerItem[]>);
  const processDaysBySourceId = processes?.reduce((acc, process) => {
    const key = process.planner_item_mapping?.source_id;
    if (key) {
      if (!acc[key]) {
        acc[key] = new Set<string>();
      }
      acc[key].add(process.date);
    }
    return acc;
  }, {} as Record<string, Set<string>>);

  const result: Omit<PlannerItemProgress, "weight">[] = [];

  const traverse = (plannerItems: PlannerItem[]): Omit<PlannerItemProgress, "weight">[] => {
    const traverseResult: Omit<PlannerItemProgress, "weight">[] = [];
    for (const plannerItem of plannerItems) {
      const childPlannerItems = plannerItemsByParentId[plannerItem._id] || [];

      if (childPlannerItems.length === 0) {
        traverseResult.push({
          _id: plannerItem._id,
          ...calculateProjectProgressForPlannerItem(
            plannerItem,
            processDaysBySourceId,
            plannedEventsByPlannerItemId,
            actualEventsBySourceId,
            projectDurationSettings,
            date || now,
          ),
          root: !plannerItem.parent_id,
        });
      } else {
        const childPlannerItemProgresses = traverse(childPlannerItems);
        traverseResult.push({
          _id: plannerItem._id,
          total_working_days: sum(
            childPlannerItemProgresses.map((item) => item.total_working_days),
          ),
          finished_working_days: sum(
            childPlannerItemProgresses.map((item) => item.finished_working_days),
          ),
          actual_finished_working_days: sum(
            childPlannerItemProgresses.map((item) => item.actual_finished_working_days),
          ),
          root: !plannerItem.parent_id,
        });
      }
    }
    traverseResult.forEach((item) => result.push(item));
    return traverseResult;
  };

  traverse(plannerItemsToProcess.filter((plannerItem) => !plannerItem.parent_id));

  const totalWorkingDays = sum(
    result.filter(({ root }) => root).map(({ total_working_days }) => total_working_days),
  );

  return result.map((plannerItemProgress) => ({
    ...plannerItemProgress,
    weight: calculatePercentage(plannerItemProgress.total_working_days, totalWorkingDays),
  }));
};

const calculateProjectProgress = (plannerItemProgresses: PlannerItemProgress[]) => {
  const rootPlannerItemProgresses = plannerItemProgresses.filter(
    (progressItem) => progressItem.root,
  );
  const totalWorkingDays = sum(
    rootPlannerItemProgresses.map((progressItem) => progressItem.total_working_days),
  );
  const finishedWorkingDays = sum(
    rootPlannerItemProgresses.map((progressItem) => progressItem.finished_working_days),
  );

  return {
    totalWorkingDays,
    finishedWorkingDays,
  };
};

const calculateProjectProgressPercentage = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[] | undefined,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
  date?: Date,
): number | null => {
  const plannerItemProgresses = calculateProjectProgressItems(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
    date,
  );
  const { totalWorkingDays, finishedWorkingDays } = calculateProjectProgress(plannerItemProgresses);

  return calculatePercentage(finishedWorkingDays, totalWorkingDays);
};

export default {
  calculatePercentage,
  getPlannerItemsToProcess,
  calculateProjectProgressItems,
  calculateProjectProgress,
  calculateProjectProgressPercentage,
};
