import segmentPlugin from "@analytics/segment";
import Analytics from "analytics";
import { useStore } from "vuex";
import { useCurrentSiteId, useCurrentCustomerName } from "shared/composables/project";

const enabled = process.env.VUE_APP_USE_ANALYTICS === "1";

export const analytics = enabled
  ? Analytics({
      app: "oai-app",
      plugins: [
        segmentPlugin({
          writeKey: "oztwLWx4eAuka846o0VkQriiFY69Zk9d",
        }),
      ],
    })
  : null;

export const useTrackEvent = () => {
  const store = useStore();
  const user = store?.state?.user;
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  return (event: string, props: Partial<Record<string, string>> = {}) => {
    if (analytics) {
      return analytics.track(event, {
        email: user?.email || "unknown",
        name: user?.name || "unknown",
        site_id: currentSiteId,
        customer_name: currentCustomerName,
        ...props,
      });
    }
  };
};
