<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full lg:w-2/3 m-2">
    <template #content>
      <div>
        <div v-if="!isLoading" class="flex flex-col gap-2">
          <ReportFormWithPlot
            v-if="report"
            :hierarchyTags="hierarchyTags"
            :report="report"
            @change="handleReportChange"
          />
          <MainButton
            :label="t('buttons.create')"
            type="button"
            color="yellow"
            :style="{ margin: 0 }"
            class="self-end"
            @click="handleCreateClick"
            :isDisabled="isReportCreating"
          >
            <LoadingSpinner size="w-5 h-5 mr-2" v-if="isReportCreating" />
          </MainButton>
        </div>
        <div v-else class="flex items-center justify-center h-[400px]">
          <LoadingSpinner />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import MainButton from "@/components/other/MainButton.vue";
import { useCreateDefaultReport, useCreateReport } from "@/composables/report";
import { Report, ReportPlotType, ReportToCreateOrUpdate } from "@/types/Report";
import ReportFormWithPlot from "@/views/reports/components/ReportFormWithPlot.vue";

const props = defineProps<{ plotType: ReportPlotType }>();
const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "done", report: Report): void;
}>();

const { t } = useI18n();

const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
const { createDefaultReport, isLoading: isCreateDefaultReportLoading } = useCreateDefaultReport();
const { createReport, isLoading: isReportCreating } = useCreateReport();

const report = ref<Report | null>(null);

const isLoading = computed(
  () => areHierarchyTagsLoading.value || isCreateDefaultReportLoading.value,
);

const initializeReport = () => {
  if (isCreateDefaultReportLoading.value) {
    return;
  }
  report.value = createDefaultReport(props.plotType);
};

const handleReportChange = (change: Partial<Report>) => {
  if (!report.value) {
    return;
  }
  report.value = { ...report.value, ...change };
};

const handleCreateClick = () => {
  if (!report.value || isReportCreating.value) {
    return;
  }
  const reportToCreate: ReportToCreateOrUpdate = {
    _id: report.value._id,
    type: report.value.type,
    name: report.value.name || createDefaultReport(props.plotType, report.value).name,
    filters: report.value.filters,
    config: report.value.config,
    aggregation: report.value.aggregation,
  };
  createReport(reportToCreate)
    .then((createdReport) => {
      emit("done", createdReport);
    })
    .catch(() => undefined);
};

watch([isCreateDefaultReportLoading, () => props.plotType], () => {
  initializeReport();
});

onMounted(() => {
  initializeReport();
});
</script>
