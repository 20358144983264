<template>
  <div
    :class="['px-2 py-0.5 rounded-md truncate', bgClassByType[tag.type], textClassByType[tag.type]]"
    :title="tag.name"
  >
    {{ tag.name }}
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";

const textClassByType: Record<HierarchyType, string> = {
  building: "text-gray-100",
  level: "text-gray-700",
  section: "text-gray-700",
  component: "text-gray-700",
};

const bgClassByType: Record<HierarchyType, string> = {
  building: "bg-gray-400",
  level: "bg-gray-300",
  section: "bg-gray-200",
  component: "bg-gray-100",
};

defineProps<{ tag: Pick<HierarchyTagStore, "type" | "name"> }>();
</script>
