export const disturbances = [
  "plans_not_available",
  "bad_weather",
  "holiday_season",
  "late_deliveries",
  "staff_shortage",
  "refused_acceptance",
  "missing_installation_parts",
  "unpaid_invoices",
  "missing_scaffolding",
  "missing_formwork",
  "missing_material",
  "relocation_of_construction_site_equipment",
  "missing_cranes",
  "missing_pre_prepayment",
] as const;
