import { defineComponent } from "vue";
import { useToast } from "vue-toastification";

type ToastType = "error" | "warning" | "success";

export default defineComponent({
  data() {
    return {
      toast: useToast(),
    };
  },
  methods: {
    showToast(type: ToastType, message: string): void {
      const toastFunctions: Record<
        ToastType,
        typeof this.toast.error | typeof this.toast.warning | typeof this.toast.success
      > = {
        error: this.toast.error,
        warning: this.toast.warning,
        success: this.toast.success,
      };

      const showToastFunction = toastFunctions[type] || this.toast;
      showToastFunction(message, { toastClassName: "custom-toast-class" });
    },
  },
});
