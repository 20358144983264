<template>
  <label class="flex cursor-pointer items-center w-fit">
    <input
      type="checkbox"
      class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500 cursor-pointer"
      v-model="showMeanWorkingHours"
      @change="emit('configChange', getConfig())"
    />
    <span class="ml-2">{{ t("analytics.reports.show_mean_working_hours") }}</span>
  </label>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import {
  WorkingHourCurveReportConfig,
  WorkingHourCurveReportFilters,
} from "@/types/reports/PlotWorkingHourCurve";

const props = defineProps<{
  config: WorkingHourCurveReportConfig;
  filters: WorkingHourCurveReportFilters;
}>();
const emit = defineEmits<{
  (eventName: "configChange", config: WorkingHourCurveReportConfig): void;
}>();

const { t } = useI18n();

const showMeanWorkingHours = ref(true);

watch(
  () => props.config,
  () => {
    showMeanWorkingHours.value = props.config.show_mean;
  },
  { immediate: true },
);

const getConfig = () => {
  const payload: WorkingHourCurveReportConfig = {
    show_mean: showMeanWorkingHours.value,
  };
  return payload;
};
</script>
