import durationService from "shared/services/durationService";
import { ActualEvent, PlannedEvent } from "shared/types/Plan";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";

export const calculateEndDateDeviationWd = (
  plannedEvent: PlannedEvent | undefined,
  actualEvent: ActualEvent | undefined,
  projectDurationSettings: ProjectDurationSettings,
) => {
  const plannedEnd = plannedEvent?.end;
  const actualEnd = actualEvent?.end;
  if (!plannedEnd || !actualEnd) {
    return null;
  }
  if (actualEnd > plannedEnd) {
    return -durationService.calculateDuration(
      projectDurationSettings.settings,
      plannedEnd,
      actualEnd,
    ).workingDays;
  }
  return durationService.calculateDuration(projectDurationSettings.settings, actualEnd, plannedEnd)
    .workingDays;
};
