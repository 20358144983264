import { parseISO } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";

const parseLocalDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const formatLocalDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

export default {
  parseLocalDate,
  formatLocalDate,
};
