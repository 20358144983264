<template>
  <div class="relative" :style="sizeStyles">
    <div class="text-center pl-6 pr-8 truncate" :style="{ height: `${headerHeight}px` }">
      <RouterLink
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        class="text-lg font-semibold text-gray-900 hover:text-yellow-800"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="working_hour_curve"
      :filters="filters"
      :reportSummary="data"
      :aggregationLabel="aggregationLabel"
      :height="filterBadgesHeight"
      class="ml-2"
    />
    <div :style="{ height: `${chartHeight}px` }" class="overflow-hidden">
      <Chart :options="chartOptions" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { Chart } from "highcharts-vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { DashboardGridSize } from "@/types/Dashboard";
import {
  MilestoneReportSummary,
  MilestoneReportConfig,
  MilestoneReportFilters,
} from "@/types/reports/PlotMilestone";
import { useChartSize } from "@/views/reports/composables";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";

interface SeriesDataItem {
  x: number | null;
  y: number;
  name: string;
  events: {
    plan: MilestoneReportSummary["planner_items"][number];
    actual: MilestoneReportSummary["actual_events"][number];
  };
}

const defaultFullHeight = 600;

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    data?: MilestoneReportSummary;
    reportId: string;
    filters: MilestoneReportFilters;
    config: MilestoneReportConfig;
    title: string;
    width?: number;
    height?: number;
    spacingX?: number;
    spacingY?: number;
    dashboardGridSize?: DashboardGridSize;
  }>(),
  { height: defaultFullHeight, spacingX: 0, spacingY: 0 },
);

const emit = defineEmits(["widgetRouteClicked"]);

const { headerHeight, chartHeight, sizeStyles, filterBadgesHeight } = useChartSize(
  computed(() => ({ ...props })),
  {
    minGridColumns: 2,
    minGridRows: 2,
  },
);

const defaultChartOptions = computed(() => ({
  chart: {
    zoomType: "y",
    height: chartHeight.value,
  },
  title: {
    text: undefined,
  },
  legend: {
    enabled: false,
  },
  yAxis: {
    min: 0,
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  },
  tooltip: {
    useHTML: true,
    outside: true,

    formatter: function (this: { point: SeriesDataItem }) {
      const events = this.point.events;
      const name = events.plan.tags
        .slice()
        .sort((a, b) => a.type.localeCompare(b.type))
        .map((tag) => tag.name)
        .join(" | ");

      const tooltip = `
        <div style="padding:6px">
          <h4 style="font-size: 1rem; margin-top:10px; padding-bottom:4px; text-align: left;border-bottom: 1px solid lightgray">${name}</h4>
          <div style="display: flex; padding-top: 1rem;align-items:center;width:min-content;justify-content: space-between">
            <span
              style="border-radius: 5px;background: ${seriesColors["plan"]}"
            >
              ${t("analytics.reports.target_period")}
            </span>
            <span style="margin-left: 0.5rem;">
              ${format(new Date(events.plan.planned_event.start), "dd.MM.yyyy")} -
              ${format(new Date(events.plan.planned_event.end), "dd.MM.yyyy")}
            </span>
          </div>
          <div style="display: flex; padding-top: 0.5rem;align-items:center;width:min-content;justify-content: space-between">
            <span
              style="padding: 3px 4px;background: ${
                seriesColors["actual"]
              };border-radius: 5px;color: white;"
            >
              ${t("analytics.reports.actual_period")}
            </span>
            <span style="margin-left: 0.5rem;">
              ${
                events.actual
                  ? `${format(new Date(events.actual.start), "dd.MM.yyyy")} -
                  ${
                    events.actual.end
                      ? format(new Date(events.actual.end), "dd.MM.yyyy")
                      : t("analytics.reports.present")
                  }`
                  : t("analytics.reports.actual_not_started")
              }
            </span>
          </div>
        </div>`;
      return tooltip;
    },
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      week: "%e. %B",
      month: "%B '%y",
    },
    plotLines: [
      {
        color: "#fa5252",
        dashStyle: "Dot",
        width: 1,
        value: Date.now(),
        zIndex: 5,
        label: {
          rotation: 0,
          textAlign: "right",
          x: 16,
          useHTML: true,
          formatter(): string {
            return `<div style="background:#fa5252;padding: 4px 8px; color: white; border-radius: 0 5px 5px 0;">${format(
              new Date(),
              "dd.MM.yyyy",
            )}</div>`;
          },
        },
      },
    ],
  },
}));

const seriesColors = {
  plan: "#BCCFCC",
  actual: "#336260",
};

const chartOptions = computed(() => {
  if (!props.data) {
    return defaultChartOptions.value;
  }

  const reportConfig = props.config;
  const mode = reportConfig.mode;

  const chartConfiguration =
    mode === "end_date" ? getEndDateChartData(props.data) : getSequenceGroupedChartData(props.data);

  return {
    ...defaultChartOptions.value,
    ...chartConfiguration,
  };
});

const getEndDateChartData = (data: MilestoneReportSummary) => {
  const yAxisCategories = data.planner_items?.map((item) => {
    const sortedTags = item.tags.slice().sort((a, b) => a.type.localeCompare(b.type));
    const tags = sortedTags.map((tag) => tag.name).join(" | ");
    return tags;
  });

  const plansLevels: Record<string, number> = {};
  const actualSeriesData: SeriesDataItem[] = [];
  const zones: Record<string, number | string>[] = [];
  const plansSeriesData: SeriesDataItem[] = [];

  const getActualBySourceId =
    (data.actual_events || []).reduce((acc, item) => {
      acc[item.source_id] = item;

      return acc;
    }, {} as Record<string, SeriesDataItem["events"]["actual"]>) || {};

  const anyActualCompleted = (data.actual_events || []).some((item) => item.end !== null);

  data.planner_items?.forEach((item, i) => {
    plansLevels[item.source_id] = i;
    const actual = getActualBySourceId[item.source_id];

    const generalData = {
      name: item.name,
      y: i,
      events: {
        actual: actual,
        plan: item,
      },
    };

    plansSeriesData.push({
      ...generalData,
      x: new Date(item.planned_event.end).getTime(),
    });

    if (anyActualCompleted) {
      actualSeriesData.push({
        ...generalData,
        x: actual?.end ? new Date(actual.end).getTime() : null,
      });
    }
  });

  for (let i = actualSeriesData.length - 2; i >= 0; i--) {
    if (actualSeriesData[i].x === null) {
      actualSeriesData.pop();
    } else {
      break;
    }
  }

  actualSeriesData.forEach((_, i) => {
    if (actualSeriesData[i]?.x === null) {
      actualSeriesData[i].x = Date.now();

      if (zones.at(-1)?.value === i) {
        const zone = zones.at(-1) as Record<string, number | string>;
        zone.value = i + 1;
      } else {
        zones.push(
          { value: i - 1 },
          { value: i + 1, color: seriesColors["actual"], dashStyle: "Dash" },
        );
      }
    }
  });

  return {
    yAxis: {
      tickPixelInterval: 2,
      categories: yAxisCategories,
      plotLines: [],
      title: {
        text: undefined,
      },
    },
    series: [
      {
        name: "Planned",
        data: plansSeriesData,
        type: "spline",
        lineWidth: 3,
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 4,
        },
        color: seriesColors["plan"],
      },
      ...(anyActualCompleted
        ? [
            {
              name: "Actual",
              data: actualSeriesData,
              type: "spline",
              marker: {
                enabled: true,
                symbol: "circle",
                radius: 4,
              },
              zones,
              lineWidth: 3,
              color: seriesColors["actual"],
            },
          ]
        : []),
    ],
  };
};

const splitSequences = (plannerItems: MilestoneReportSummary["planner_items"]) => {
  const sequences: Record<string, MilestoneReportSummary["planner_items"]> = {};

  plannerItems?.forEach((item) => {
    item.tags.forEach((tag) => {
      if (tag.type === "section") {
        if (!sequences[tag.name]) {
          sequences[tag.name] = [item];
        } else {
          sequences[tag.name].push(item);
        }
      }
    });
  });

  for (const sequence in sequences) {
    const sequenceItems = sequences[sequence];

    if (sequenceItems.length < 2) {
      delete sequences[sequence];
      continue;
    }

    sequences[sequence].sort(
      (a, b) => new Date(a.planned_event.end).getTime() - new Date(b.planned_event.end).getTime(),
    );
  }

  return sequences;
};

const getSequenceGroupedChartData = (data: MilestoneReportSummary) => {
  const plannerItems = splitSequences(data.planner_items);
  const yAxisCategories: string[] = [];
  const yPlotLines: unknown[] = [];
  const plansSeriesData: Record<
    string,
    { plans: SeriesDataItem[]; actual: SeriesDataItem[]; zones: Record<string, unknown>[] }
  > = {};

  const getActualBySourceId =
    (data.actual_events || []).reduce((acc, item) => {
      acc[item.source_id] = item;

      return acc;
    }, {} as Record<string, SeriesDataItem["events"]["actual"]>) || {};

  let yLevel = 0;

  Object.entries(plannerItems).forEach(([sectionName, group], i) => {
    const categories = group.map((item) => {
      const tags = item.tags.slice().sort((a, b) => a.type.localeCompare(b.type));
      const stringTags = tags.map((tag) => tag.name).join(" | ");

      return stringTags;
    });

    yAxisCategories.push(...categories);

    const plannerItemsData: SeriesDataItem[] = [];
    const actualItemsData: SeriesDataItem[] = [];

    group.forEach((item) => {
      const actual = getActualBySourceId[item.source_id];
      const generalData = {
        name: sectionName,
        y: yLevel,
        events: {
          actual: actual,
          plan: item,
        },
      };

      plannerItemsData.push({
        ...generalData,
        x: new Date(item.planned_event.end).getTime(),
      });
      actualItemsData.push({
        ...generalData,
        x: actual?.end ? new Date(actual.end).getTime() : null,
      });

      yLevel++;
    });

    if (i !== Object.keys(plannerItems).length - 1) {
      yPlotLines.push({
        color: "#989898",
        width: 1,
        value: yLevel - 0.5,
        zIndex: 5,
      });
    }

    plansSeriesData[sectionName] = {
      plans: plannerItemsData,
      actual: actualItemsData,
      zones: [],
    };
  });

  for (const sectionName in plansSeriesData) {
    const data = plansSeriesData[sectionName].actual;
    for (let i = data.length - 2; i >= 0; i--) {
      if (data[i].x === null) {
        data.pop();
      } else {
        break;
      }
    }

    let shiftTo = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].x === null) {
        shiftTo++;
      } else {
        break;
      }
    }

    plansSeriesData[sectionName].actual = data.slice(shiftTo);
  }

  for (const sectionName in plansSeriesData) {
    const data = plansSeriesData[sectionName];

    data.actual.forEach((item, i) => {
      if (item.x === null && i !== 0) {
        item.x = Date.now();

        if (data.zones.at(-1)?.value === i) {
          const zone = data.zones.at(-1) as Record<string, unknown>;
          zone.value = i + 1;
        } else {
          data.zones.push(
            { value: item.y - 1 },
            { value: item.y + 1, color: seriesColors["actual"], dashStyle: "Dash" },
          );
        }
      }
    });
  }

  const items = Object.entries(plansSeriesData);

  const sequences = items.flatMap(([name, data]) => {
    const planId = name;

    return [
      {
        name,
        id: planId,
        type: "spline",
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 4,
        },
        lineWidth: 3,
        dataType: "Plan",
        data: data.plans,
        color: seriesColors["plan"],
      },
      {
        name,
        type: "spline",
        dataType: "Actual",
        marker: {
          enabled: true,
          symbol: "circle",
          radius: 4,
        },
        lineWidth: 3,
        linkedTo: planId,
        data: data.actual,
        zones: data.zones,
        color: seriesColors["actual"],
      },
    ];
  });

  return {
    yAxis: {
      tickPixelInterval: 2,
      categories: yAxisCategories,
      plotLines: yPlotLines,
      title: {
        text: undefined,
      },
    },
    series: sequences,
  };
};

const aggregationLabel = computed(() => {
  const modes: Record<MilestoneReportConfig["mode"], string> = {
    sequence: t("analytics.reports.sequence_mode"),
    end_date: t("analytics.reports.end_date_mode"),
  };
  return modes[props.config.mode];
});
</script>
