<template>
  <div class="flex border-r flex-col xl:flex-row pt-4 xl:pt-0">
    <div class="xl:hidden md:px-8 px-4">
      <select class="w-auto oai-inputs" @change="handleSelectChange">
        <option
          v-for="report in reports"
          :key="report.name"
          :value="report._id"
          :selected="selectedReport?._id === report._id"
        >
          {{ report.name }}
        </option>
      </select>
    </div>
    <div class="bg-white hidden overflow-y-auto xl:block w-[250px] pb-32">
      <div v-for="(reports, type) in reportsByType" :key="type" class="border-b">
        <Disclosure v-slot="{ open }" defaultOpen>
          <DisclosureButton
            class="flex items-center justify-between bg-gray-100 px-2 py-1 w-full border-l-4 font-medium"
            :class="[open && 'bg-gray-100', selectedReport?.type === type && '']"
          >
            <small>{{ $t(`analytics.reports.${type}_plot`) }}</small>
            <ChevronLeftIcon :class="open ? '-rotate-90 transform' : ''" class="w-4" />
          </DisclosureButton>

          <DisclosurePanel>
            <div
              v-for="report in reports"
              :class="selectedReport?._id === report._id && 'border-l-yellow text-oaiGray-400 '"
              :key="report.name"
              @click="switchToReport(report)"
              class="py-2 px-2 lg:pl-6 lg:py-4 border-l-4 cursor-pointer truncate border-t text-gray-600 text-xs break-words"
              :title="report.name"
            >
              {{ report.name }}
            </div></DisclosurePanel
          >
        </Disclosure>
      </div>
      <div class="flex justify-center mt-4">
        <AddReportMenu @createReport="emit('createReport', $event)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import { Report, ReportPlotType } from "@/types/Report";
import AddReportMenu from "@/views/reports/components/AddReportMenu.vue";

const props = defineProps<{
  reports: Report[];
  selectedReport?: Report;
}>();
const emit = defineEmits<{
  (eventName: "selectReport", report: Report): void;
  (eventName: "createReport", type: ReportPlotType): void;
}>();

const switchToReport = (report: Report) => {
  if (report && report._id !== props.selectedReport?._id) {
    emit("selectReport", report);
  }
};

const handleSelectChange = (event: Event) => {
  const reportId = (event.target as HTMLInputElement).value;
  const report = props.reports.find((f) => f._id === reportId);
  if (report) {
    switchToReport(report);
  }
};

const reportsByType = computed(() => {
  return props.reports.reduce((acc, report) => {
    acc[report.type] = acc[report.type] || [];
    acc[report.type].push(report);
    return acc;
  }, {} as Record<ReportPlotType, Report[]>);
});
</script>
