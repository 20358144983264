import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["name", "disabled", "max"]
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = ["for"]
const _hoisted_6 = ["name", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['grid grid-cols-2 gap-5', _ctx.cls])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.dateLabel)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: `${_ctx.name}-date`,
            class: "block text-sm font-medium leading-6 text-gray-900 text-left rounded border-gray-300 focus:border-yellow-500 focus:ring-0"
          }, _toDisplayString(_ctx.dateLabel), 9, _hoisted_2))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        type: "date",
        name: `${_ctx.name}-date`,
        class: _normalizeClass(["rounded border-gray-300 focus:border-yellow-500 focus:ring-0", ['customInput w-ful', { 'bg-gray-100': _ctx.disabled }]]),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateText) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleDateAndTimeChange())),
        disabled: _ctx.disabled,
        max: _ctx.formatDate(_ctx.maxDate)
      }, null, 42, _hoisted_3), [
        [_vModelText, _ctx.dateText]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.timeLabel)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: `${_ctx.name}-time`,
            class: "block text-sm font-medium leading-6 text-gray-900 text-left rounded border-gray-300 focus:border-yellow-500 focus:ring-0"
          }, _toDisplayString(_ctx.timeLabel), 9, _hoisted_5))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        type: "time",
        name: `${_ctx.name}-time`,
        class: _normalizeClass(["rounded border-gray-300 focus:border-yellow-500 focus:ring-0", ['customInput w-full', { 'bg-gray-100': _ctx.disabled || _ctx.timeDisabled }]]),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.timeText) = $event)),
        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleDateAndTimeChange())),
        disabled: _ctx.disabled || _ctx.timeDisabled
      }, null, 42, _hoisted_6), [
        [_vModelText, _ctx.timeText]
      ])
    ])
  ], 2))
}