import { startOfDay, subDays } from "date-fns";
import durationService from "shared/services/durationService";
import {
  CriticalPathContext,
  CriticalPathDifference,
  CriticalPathDifferenceForPath,
  CriticalPathDifferenceForPaths,
  CriticalPathEx,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import { ActualEvent, PlannedEvent } from "shared/types/Plan";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { calculateIsLastInCriticalPath } from "shared/views/critical_path/criticalPath";

export const calculateWorkingDaysDifferenceForDates = (
  date1: Date,
  date2: Date,
  projectDurationSettings: ProjectDurationSettings,
) =>
  date1 < date2
    ? -durationService.calculateDuration(projectDurationSettings.settings, date1, date2).workingDays
    : durationService.calculateDuration(projectDurationSettings.settings, date2, date1).workingDays;

export const calculateWorkingDaysDifference = (
  plannedEvent: PlannedEvent | null,
  actualEvent: ActualEvent | null,
  projectDurationSettings: ProjectDurationSettings,
) => {
  if (!plannedEvent) {
    return null;
  }
  const plannedEnd = startOfDay(plannedEvent.end);
  if (!actualEvent || !actualEvent.end) {
    const now = subDays(startOfDay(new Date()), 1);
    if (plannedEnd <= now) {
      return calculateWorkingDaysDifferenceForDates(plannedEnd, now, projectDurationSettings);
    }
    return null;
  }
  return calculateWorkingDaysDifferenceForDates(
    plannedEnd,
    startOfDay(actualEvent.end),
    projectDurationSettings,
  );
};

const getPlannedEventForCalculation = (node: CriticalPathNodeEx, context: CriticalPathContext) => {
  const now = subDays(startOfDay(new Date()), 1);
  const { actualEventsBySourceId, plannedEventsBySourceId } = context;

  const finishedActualEvents = node.component_source_ids
    .map((sourceId) => actualEventsBySourceId[sourceId])
    .filter((event) => event?.end);

  const plannedEvents = node.component_source_ids
    .map((sourceId) => plannedEventsBySourceId[sourceId])
    .filter((event) => event);

  const finishedSourceIds = new Set(finishedActualEvents.map((event) => event.source_id));

  const plannedEventsToUse = plannedEvents.filter((event) => {
    if (finishedActualEvents.length > 0) {
      const sourceId = context.plannerItemsById[event.planner_item_id].source_id;
      return finishedSourceIds.has(sourceId);
    }
    return startOfDay(event.end) <= now;
  });

  return plannedEventsToUse.reduce((acc, event) => {
    if (!acc) {
      return event;
    }
    return acc.end > event.end ? acc : event;
  }, null as PlannedEvent | null);
};

const getActualEventForCalculation = (node: CriticalPathNodeEx, context: CriticalPathContext) => {
  const { actualEventsBySourceId } = context;

  const finishedActualEvents = node.component_source_ids
    .map((sourceId) => actualEventsBySourceId[sourceId])
    .filter((event) => event?.end);

  return finishedActualEvents.reduce((acc, event) => {
    const accEnd = acc?.end;
    const eventEnd = event.end;
    if (!accEnd) {
      return event;
    }
    if (!eventEnd) {
      return acc;
    }
    return accEnd > eventEnd ? acc : event;
  }, null as ActualEvent | null);
};

export const calculateWorkingDaysDifferenceWithEvents = (
  node: CriticalPathNodeEx,
  context: CriticalPathContext,
): CriticalPathDifference => {
  const plannedEvent = getPlannedEventForCalculation(node, context);
  const actualEvent = getActualEventForCalculation(node, context);

  const workingDays = calculateWorkingDaysDifference(
    plannedEvent,
    actualEvent,
    context.projectDurationSettings,
  );

  return {
    node,
    plannedEvent,
    actualEvent,
    workingDays,
  };
};

export const calculateWorkingDaysDifferenceWithEventsForCriticalPath = (
  lastNode: CriticalPathNodeEx,
  context: CriticalPathContext,
) => {
  const { edgesByTargetId, criticalPathNodesById } = context;

  const traverseNodes = (
    nodeIds: string[],
  ): { result: Set<string>; notFinishedNodeIds: Set<string> } => {
    const result = new Set<string>();
    const notFinishedNodeIds = new Set<string>();
    for (const nodeId of nodeIds) {
      const node = criticalPathNodesById[nodeId];
      const sourceIds = edgesByTargetId[nodeId] || [];
      if (node.sub_state !== "at_least_one_part_finished") {
        notFinishedNodeIds.add(nodeId);
      }
      if (node.sub_state === "at_least_one_part_finished" || sourceIds.length === 0) {
        result.add(nodeId);
        continue;
      }
      traverseNodes(sourceIds).result.forEach((resultNodeId) => result.add(resultNodeId));
      traverseNodes(sourceIds).notFinishedNodeIds.forEach((resultNodeId) =>
        notFinishedNodeIds.add(resultNodeId),
      );
    }
    return { result, notFinishedNodeIds };
  };

  const { result: nodeIdsSet, notFinishedNodeIds } = traverseNodes([lastNode._id]);

  const differences = [...nodeIdsSet]
    .map((nodeId) => {
      const node = criticalPathNodesById[nodeId];
      return calculateWorkingDaysDifferenceWithEvents(node, context);
    })
    .filter((difference) => difference.workingDays !== null);

  const lastDifference = differences.reduce((acc, difference) => {
    const accWorkingDays = acc?.workingDays ?? null;
    if (accWorkingDays === null) {
      return difference;
    }
    const currentWorkingDays = difference.workingDays;
    if (currentWorkingDays === null) {
      return acc;
    }
    return currentWorkingDays < accWorkingDays ? difference : acc;
  }, null as CriticalPathDifference | null);

  if (!lastDifference) {
    return {
      difference: {
        node: lastNode,
        actualEvent: null,
        plannedEvent: null,
        workingDays: 0,
      },
      notFinishedNodeIds,
    };
  }

  return {
    difference: lastDifference,
    notFinishedNodeIds,
  };
};

export const calculateProjectedEnd = (
  differenceForLastNode: CriticalPathDifference,
  differenceForLastFinished: CriticalPathDifference,
  context: CriticalPathContext,
): Date | null => {
  if (
    !differenceForLastNode.plannedEvent ||
    differenceForLastNode.actualEvent?.end ||
    differenceForLastFinished.workingDays === null
  ) {
    return null;
  }
  return (
    durationService.calculateEndDate(
      context.projectDurationSettings.settings,
      startOfDay(differenceForLastNode.plannedEvent.end),
      -differenceForLastFinished.workingDays,
    ) ?? null
  );
};

const getPlannedEventForLastNode = (lastNode: CriticalPathNodeEx, context: CriticalPathContext) => {
  const lastNodePlannedEvents = lastNode.component_source_ids
    .map((sourceId) => context.plannedEventsBySourceId[sourceId])
    .filter((event) => event);
  return lastNodePlannedEvents.reduce((acc, event) => {
    if (!acc) {
      return event;
    }
    return acc.end > event.end ? acc : event;
  }, null as PlannedEvent | null);
};

export const calculateCriticalPathDifferenceForPaths = (
  criticalPath: CriticalPathEx,
  context: CriticalPathContext,
): CriticalPathDifferenceForPaths => {
  const paths = criticalPath.nodes
    .filter((node) => calculateIsLastInCriticalPath(node, context))
    .map((node) => {
      const forLastNode = {
        ...calculateWorkingDaysDifferenceWithEvents(node, context),
        plannedEvent: getPlannedEventForLastNode(node, context),
      };
      const { difference: forLastFinished, notFinishedNodeIds } =
        calculateWorkingDaysDifferenceWithEventsForCriticalPath(node, context);
      const projectedEndDate = calculateProjectedEnd(forLastNode, forLastFinished, context);
      const endDate = forLastNode.actualEvent?.end || projectedEndDate;
      return {
        lastNode: node,
        forLastNode,
        forLastFinished,
        projectedEndDate,
        endDate,
        notFinishedNodeIds,
      };
    });

  const lastPlannedPath = paths.reduce((acc, path) => {
    const accPlannedEventEnd = acc?.forLastNode.plannedEvent?.end;
    const pathPlannedEventEnd = path.forLastNode.plannedEvent?.end;
    if (!accPlannedEventEnd) {
      return path;
    }
    if (!pathPlannedEventEnd) {
      return acc;
    }
    return pathPlannedEventEnd > accPlannedEventEnd ? path : acc;
  }, null as CriticalPathDifferenceForPath | null);

  return {
    paths,
    lastPlannedPath,
  };
};

export const calculateEntireCriticalPathDifference = (
  differenceForPaths: CriticalPathDifferenceForPaths,
  context: CriticalPathContext,
): CriticalPathDifferenceForPath | null => {
  const validDifferenceForPaths = differenceForPaths.paths.filter(
    (item) => item.forLastFinished.workingDays !== null,
  );

  const lastDifference = validDifferenceForPaths.reduce((acc, differenceForPath) => {
    const accEnd = acc?.endDate;
    if (!accEnd) {
      return differenceForPath;
    }
    const currentEnd = differenceForPath.endDate;
    if (!currentEnd) {
      return acc;
    }
    return currentEnd.getTime() > accEnd.getTime() ? differenceForPath : acc;
  }, null as CriticalPathDifferenceForPath | null);

  if (!differenceForPaths.lastPlannedPath) {
    return null;
  }

  const latestPlannedEvent = differenceForPaths.lastPlannedPath.forLastNode.plannedEvent;

  if (!latestPlannedEvent || !lastDifference?.endDate) {
    return null;
  }

  return {
    ...lastDifference,
    forLastNode: {
      ...lastDifference.forLastNode,
      plannedEvent: latestPlannedEvent,
    },
    forLastFinished:
      differenceForPaths.lastPlannedPath.lastNode._id !== lastDifference.lastNode._id
        ? {
            ...lastDifference.forLastFinished,
            workingDays: calculateWorkingDaysDifferenceForDates(
              startOfDay(latestPlannedEvent.end),
              startOfDay(lastDifference.endDate),
              context.projectDurationSettings,
            ),
          }
        : lastDifference.forLastFinished,
  };
};

export const calculateEntireCriticalPathDifferenceByCriticalPath = (
  criticalPath: CriticalPathEx,
  context: CriticalPathContext,
) => {
  const paths = calculateCriticalPathDifferenceForPaths(criticalPath, context);
  return calculateEntireCriticalPathDifference(paths, context);
};
