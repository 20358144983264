import { eachDayOfInterval, format, getDay, subDays } from "date-fns";
import { computed } from "vue";
import { ProjectDurationSettings } from "../types/ProjectDurationSettings";
import { useProjectDurationSettings } from "./projectDurationSettings";

export const useNonWorkingDaysByDaySet = () => {
  const { projectDurationSettings } = useProjectDurationSettings();
  return computed(
    () =>
      projectDurationSettings.value &&
      projectDurationSettings.value.non_working_days.reduce((acc, holiday) => {
        const days = eachDayOfInterval({
          start: holiday.start_date,
          end: holiday.end_date,
        }).map((day) => format(day, "yyyy-MM-dd"));
        for (const day of days) {
          acc.add(day);
        }
        return acc;
      }, new Set<string>()),
  );
};

const orderedDays: readonly (keyof ProjectDurationSettings["working_hours"])[] = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

export const useIsWorkingDay = () => {
  const { projectDurationSettings } = useProjectDurationSettings();
  return computed(() => (date: Date) => {
    if (!projectDurationSettings.value) {
      return true;
    }
    const day = projectDurationSettings.value.working_hours[orderedDays[getDay(date)]];
    return !!day.start_time && !!day.end_time;
  });
};

export const useTodayMinusOneWorkingDay = () => {
  const { projectDurationSettings } = useProjectDurationSettings();
  return computed(() => {
    const todayMinusOneDay = subDays(new Date(), 1);
    if (!projectDurationSettings.value) {
      return todayMinusOneDay;
    }
    let date = todayMinusOneDay;
    for (let i = 0; i < 6; i++) {
      const day = projectDurationSettings.value.working_hours[orderedDays[getDay(date)]];
      if (day.start_time && day.end_time) {
        return date;
      }
      date = subDays(date, 1);
    }
    return todayMinusOneDay;
  });
};
