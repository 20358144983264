import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full md:px-6 px-4" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "mx-auto mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cog8ToothIcon = _resolveComponent("Cog8ToothIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_BulkExport = _resolveComponent("BulkExport")!
  const _component_CalendarMonth = _resolveComponent("CalendarMonth")!
  const _component_LoadingSection = _resolveComponent("LoadingSection")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PageHeader, null, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_router_link, {
                to: {
                name: 'ProjectSettings',
                params: {
                  customer_name: _ctx.$route.params.customer_name,
                  site_id: _ctx.$route.params.site_id,
                  tab: 'daily_report',
                },
              }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Cog8ToothIcon, { class: "md:h-8 md:w-8 w-6 block m-0 box-border shrink-0 text-gray-700 hover:text-orange-700" })
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ]),
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("report.title")) + " ", 1)
          ]),
          _: 1
        }),
        (!_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_BulkExport, {
                open: _ctx.openExport,
                onOnCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openExport = false))
              }, null, 8, ["open"]),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.year && _ctx.month)
                  ? (_openBlock(), _createBlock(_component_CalendarMonth, {
                      key: 0,
                      days: _ctx.days,
                      currentMonth: _ctx.month,
                      currentYear: _ctx.year,
                      onBulkExport: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openExport = !_ctx.openExport)),
                      onNextMonth: _ctx.nextMonth,
                      onPrevMonth: _ctx.prevMonth,
                      onResetPag: _ctx.resetPag,
                      onInitializeReport: _ctx.initializeReport,
                      onDeleteReport: _ctx.deleteReport
                    }, null, 8, ["days", "currentMonth", "currentYear", "onNextMonth", "onPrevMonth", "onResetPag", "onInitializeReport", "onDeleteReport"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : (_openBlock(), _createBlock(_component_LoadingSection, {
              key: 1,
              loading: _ctx.isLoading
            }, null, 8, ["loading"]))
      ])
    ]),
    _: 1
  }))
}