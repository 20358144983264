import { useQuery } from "@tanstack/vue-query";
import { useI18n } from "vue-i18n";
import OpsProcessesRepository from "shared/repositories/OpsProcessesRepository";
import { ShortenedProcessWithTags } from "shared/types/Process";
import logger from "../services/logger";
import { useCurrentCustomerName, useCurrentSiteId } from "./project";
import { useCustomToast } from "./toast";

export const useShortenedProcessesWithTags = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const { t } = useI18n();
  const {
    data: shortenedProcessesWithTags,
    isFetching,
    error,
    refetch,
  } = useQuery<ShortenedProcessWithTags[] | undefined>({
    queryKey: ["shortened-processes-with-tags", customerName, siteId],
    queryFn: () => OpsProcessesRepository.loadShortenedProcessesWithTags(customerName, siteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.processes.unable_to_load"));
      return false;
    },
  });

  return {
    shortenedProcessesWithTags,
    isLoading: isFetching,
    error,
    refetchShortenedProcessesWithTags: refetch,
  };
};
