const configEnum = {
  API_BASE: String,
  USE_ANALYTICS: String,
  BASE_URL: String,
  INTERCOM_APP_ID: String,
  INTERCOM_API_BASE: String,
  INTERCOM_WIDGET_API_BASE: String,
  SENTRY_DSN: String,
  MICROSOFT_LOGIN_REDIRECT_URL: String,
} as const;

type Config = {
  API_BASE: string;
  USE_ANALYTICS: string;
  BASE_URL: string;
  INTERCOM_APP_ID: string;
  INTERCOM_API_BASE: string;
  INTERCOM_WIDGET_API_BASE: string;
  SENTRY_DSN: string;
  MICROSOFT_LOGIN_REDIRECT_URL: string;
};

let config = null as unknown as Config;

export const getConfig = () => {
  if (config === null) {
    const missingConfigurations = {} as Config;

    Object.entries(configEnum).forEach((entry) => {
      const key = entry[0] as keyof typeof configEnum;
      const type = entry[1];

      const value = process.env[`VUE_APP_${key}`];

      if (value === undefined) {
        throw new Error(`Missing configuration for ${key} in .env file`);
      }

      if (type === String) {
        (missingConfigurations[key] as unknown) = value;
      } else {
        throw new Error(`Invalid type ${type}`);
      }
    });

    config = missingConfigurations;
  }

  return config;
};
