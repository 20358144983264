import { TranslationSchema } from "./en";

const translations: TranslationSchema = {
  app_features: {
    planner: "Programme",
    processes: "Données du processus",
    site_activity: "Activité du chantier",
    project_overview: "Aperçu du projet",
    project_participants: "Participants au projet",
    live: "Live",
    period: "Délai",
    gallery: "Galerie",
    project_timelapse: "Timelapse du projet",
    daily_timelapse: "Timelapse par jour",
    customizable_timelapse: "Timelapse personnalisable",
    daily_report: "Rapport journalier",
    compare: "Comparer",
    timelapse: "Timelapse ",
    settings: {
      user: "Utilisateur",
      language: "Langue ",
    },
    languages: {
      de: "Allemand",
      en: "Anglais ",
    },
    project_settings: "Paramètres",
    new_feature_badge: "Nouveau",
  },
  permissions: {
    project_admin: "Administrateur du projet",
    all_camera_features_user: "Fonctionnalités de la caméra",
    planner_user: "Calendrier",
    processes_user: "Données de processus",
    site_activity_user: "Activité sur le site",
    daily_report_user: "Rapport quotidien",
    live_camera_feature_user: "Image en direct seulement",
  },
  project: {
    status: {
      active: "En cours",
      completed: "Terminé",
    },
    add: {
      title: "Ajouter un projet / Demande",
      subtitle:
        "La demande transmet les informations du projet seront transmises à l'équipe oculai, et nous prendrons contact avec vous !",
      name: "Nom",
      country_code: "Code pays",
      street: "Rue",
      zip_code: "Code postal",
      estimated_duration: "Durée en mois",
      estimated_cameras: "Nombre estimé de caméras",
      estimated_shell_volume: "Volume du gros œuvre",
      estimated_start: "Date de début du projet",
      project_goals: "Objectifs du projet",
      goals: {
        cameras: "Livestream & Time-lapse",
        shorten_construction_time: "Réduire le temps de construction",
        target_actual_schedule: "Calendrier prévu-réalisé",
        unit_values: "Valeurs d'effort",
        claims: "Réclamations",
        site_management_assistant: "Assistant gestion de chantier",
      },
      layout_plans: "Plan d'agencement",
      optional: "optionnel",
      add_project: "Demander un projet",
      success_message:
        "La demande a été effectuée avec succès, l'équipe d'oculai vous contactera sous peu !",
      validation: {
        at_least_one_enabled: "Au moins un objectif de projet doit être sélectionné",
        file_size_exceeded: "La taille des fichiers ne doit pas dépasser 50 Mo au total",
      },
      file_input: {
        upload_plan: "Télécharger le plan",
        drag_and_drop: "Drag & Drop le plan au format PDF",
        wrong_file_format: "Format de fichier incorrect. Seuls les PDF sont acceptés.",
      },
    },
  },
  partials: {
    sidebar: {
      projects: "Projets",
      show_all_projects: "Voir tous les projets",
      log_out: "Déconnexion ",
      search_project_admin:
        "recherche d'un projet, d'un nom de client ou d'un identifiant de site...",
      search_project_user: "recherche de projet...",
      go_to_project: "Aller au projet",
      search_not_found: "Aucun projet n'a été trouvé",
      camera: "Caméras",
    },
    breadcrumb: {
      projects: "Projets ",
    },
  },
  dashboard: {
    home: {
      project_card: {
        schedule_progress: "Progression du planning",
        actual_progress: "Réel",
        planned_progress: "Prévu",
        planner_delta: "Delta de planning",
      },
      all_projects: "Tous les projets",
      add_project: "Demander un projet",
    },
    project: {
      planner_card: {
        current_procedures: "Procédures en cours",
        procedure: "Procédure",
        progress: "Évolution",
        estimated_end: "Date de fin estimée",
        no_current_procedures: "Pas de procédure en cours ",
        start_date: "Date de Début",
      },
      weather_card: {
        header: "Météo",
        current_weather: "Météo actuelle",
        kmh: "km/h",
        no_data: "Pas de données météorologiques disponibles",
      },
      stream_card: {
        detail_view: "Voir le détail",
        connection_lost:
          "Malheureusement, la connexion a été interrompue. Dernier signal: {updatedAt}.",
      },
    },
    process_widget: {
      header: "Derniers processus",
      no_processes: "Aucun processus au cours des 7 derniers jours",
      all_caught_up: "Tu as vu tous les procès des 7 derniers jours !",
      to_overview_link: "Aperçu du processus",
      next_button: "Suivant",
    },
    add_widget: "Ajouter un widget",
    remove_widget: "Supprimer le widget",
    no_widgets_to_add: "pas de widgets disponibles",
    loading_error: "Impossible de charger le tableau de bord",
    reset_error: "Impossible de réinitialiser le tableau de bord",
    create_or_update_error: "Impossible de mettre à jour le tableau de bord",
    categories: {
      camera: "Caméras",
      report: "Rapports",
      other: "Autres",
    },
    reset_confirmation: {
      header: "Confirmation",
      message: "Vous perdrez vos personnalisations lorsque vous réinitialiserez la mise en page.",
      confirm: "Réinitialiser",
      cancel: "Annuler",
    },
    delete_confirmation: {
      header: "Confirmation",
      message: 'Le tableau de bord "{name}" sera supprimé.',
      confirm: "Supprimer le tableau de bord",
      cancel: "Annuler",
    },
    default_dashboard: "Tableau de bord",
    menu_categories: {
      user: "Pour toi",
      project: "Pour tous",
    },
    create_dashboard_modal: {
      title: "Votre nouveau tableau de bord",
      name: "Nom",
      submit: "Créer",
    },
    add_to_dashboard: "Ajouter au tableau de bord",
    add_button: "Ajouter",
    stream: {
      archived_stream: "complétée",
    },
    drag_me: "Faire glisser pour déplacer",
    reset_dashboard: "Réinitialiser le tableau de bord",
    refresh_data: "Rafraîchir les données du tableau de bord",
  },
  authentication: {
    validation: {
      validation_field_required: "Champ requis",
      validation_email_invalid: "Adresse e-mail invalide",
      validation_password_not_equal: "Le nouveau mot de passe ne correspond pas.",
      validation_password_characters:
        "Le nouveau mot de passe doit contenir au moins 10 caractères.",
    },
    login: {
      email: "Adresse email",
      password: "Mot de passe",
      wrong_email_or_password: "Erreur! Adresse email ou mot de passe incorect",
      sign_in: "Inscription",
      forgot_password: "Mot de passe oublié ?",
      imprint: "Impression",
      data_protection: "Protection des données ",
      or: "ou",
      social_error: "Impossible de se connecter. Vous devez être invité pour utiliser oculai",
      sign_in_with_microsoft: "Se connecter avec Microsoft",
    },
    forgot_password: {
      forgot_password: "Mot de passe oublié ?",
      forgot_text:
        "Pas de panique, nous vous envoyons les instructions pour réinitialiser votre mot de passe",
      email: "Adresse email",
      reset_password: "Renseigner le nouveau mot de passe",
      back_to_login: "Retour à la page de connexion",
      user_not_found: "Erreur! Utilisateur inexistant ",
      success_message: "Veuillez vérifier votre courrier électronique",
    },
    reset_password: {
      new_password: "Nouveau mot de passe",
      change_password: "Changer le mot de passe",
      back_to_login: "Retour à la page de connexion",
      error_invalid_code: "Erreur ! Code de vérification incorrect.",
      error_password_length:
        "Erreur ! Votre nouveau mot de passe doit comprendre au moins 10 caractères",
      error_user_not_existing: "Erreur ! Cet utilisateur n’existe pas.",
      error_limit_exceeded:
        "Trop de tentatives de connexion ont été enregistrées, merci de réessayer ultérieurement.",
      error_unknown: "Erreur de type inconnue, merci de contacter notre équipe !",
    },
    sign_up: {
      email: "Adresse email",
      name: "Nom",
      first_name: "Prénom",
      last_name: "Nom de famille",
      company: "Entreprise",
      your_role: "Votre rôle",
      role: "Rôle",
      temporary_password: "mot de passe temporaire (reçu par mail)",
      new_password: "Nouveau mot de passe",
      repeat_new_password: "Saisir à nouveau le mot de passe",
      sign_up: "Inscription",
      already_registered: "Vous avez déjà un compte chez Oculai ? Connexion",
      error_mail_password: "Erreur! Adresse email ou mot de passe temporaire erroné.",
      error_must_be_mail: "Erreur ! Votre nom d’utilisateur doit être une adresse email.",
      error_user_not_confirmed: "Erreur! Le compte n’a pas encore été vérifié",
      error_user_not_existing: "Erreur ! Cet utilisateur n’existe pas.",
      error_unknown: "Erreur de type inconnue, merci de contacter notre équipe !",
      error_modal_exists_text: "Ce nom d’utilisateur existe déjà",
      error_modal_login: "Merci de vous connecter sur",
      error_modal_login_page: "La page de connection",
      error_modal_in: ".",
      terms_conditions_1: "En créant un compte oculai, tu acceptes les",
      terms_conditions_2: "conditions générales",
      terms_conditions_3: "d'oculai.",
      sign_up_with_microsoft: "S'inscrire auprès de Microsoft",
      social_error: "Impossible de se connecter. Vous devez être invité pour utiliser oculai",
    },
    session_expired: {
      message: "Votre session a expiré. Veuillez actualiser la page.",
      refresh_page: "rafraîchir la page",
    },
  },
  admin: {
    settings: {
      user: {
        title: "Informations sur l’utilisateur",
        sub_title: "Voici vos informations concernant votre compte Oculai.",
        name: "Name",
        company: "Entreprise",
        role: "Rôle",
      },
      language: {
        title: "Langue",
        language_choice: "Choix de la langue ",
      },
    },
    project_participants: {
      participants: "Participants au projet",
      add_participants: "Ajouter des utilisateurs au projet",
      table_user: "Participant",
      table_project: "Projet",
      table_permissions: "Autorisations",
      company_type: "Entreprise",
      email: "E-mail",
      pending_invite: "Invitation en attente",
      company_types: {
        general_contractor: "Entrepreneur Général",
        subcontractor: "Sous-traitant",
        owner: "Propriétaire/Promoteur",
        architect: "Architecte/Planificateur",
        other: "Autre",
      },
      user_roles: {
        project_management: "Gestion de projet",
        site_management: "Gestion de chantier",
        foreman: "Chef de chantier",
        management_board: "Conseil d'administration / Direction générale",
        division_management: "Gestion de division / Gestion de département",
        bim_management: "Gestion BIM",
        lean_management: "Gestion Lean",
        calculation: "Calcul",
        controlling: "Contrôle de gestion",
        procurement: "Approvisionnement",
        scheduling: "Planification",
        business_development: "Développement commercial",
        assistance: "Assistance",
        other: "Autre",
      },
      modal_invite_user: {
        invite_users: "Inviter des utilisateurs",
        add_user: "Ajouter un utilisateur",
        invite: "Inviter",
        error_mail_required: "Adresse e-mail requise",
        error_mail_invalid: "Adresse e-mail invalide",
        error_company_type_required: "Entreprise requise",
      },
      modal_delete_user: {
        delete_from_project: "Supprimer l'utilisateur du projet",
        delete_permanently: "Supprimer définitivement le compte utilisateur",
      },
      modal_permission: {
        update: "Mettre à jour",
        cancel: "Annuler",
      },
      error: {
        permission_update: "Erreur, les autorisations n'ont pas pu être mises à jour.",
        properties_update: "Erreur, les propriétés utilisateur n'ont pas pu être mises à jour.",
        invite: "Erreur, les utilisateurs n'ont pas pu être invités.",
        delete: "Erreur, l'utilisateur n'a pas pu être supprimé.",
      },
    },
    project_settings: {
      tab_general: "Général",
      tab_project_participants: "Participants au projet",
      tab_durations: "Heures de travail et vacances",
      tab_dcr: "Rapport journalier",
    },
    general_project_settings: {
      project_status: {
        active: "actif",
        completed: "terminé",
      },
      validation_name_required: "le nom est obligatoire",
      validation_street_required: "l'adresse est requise",
      validation_max_characters: "{maxCharacterCount} caractères maximum",
      field_name_label: "Nom",
      field_country_code_label: "Code pays",
      field_zip_code_label: "Code postal",
      field_timezone_label: "Fuseau horaire",
      field_street_label: "Adresse",
      field_planned_start_label: "Date de début",
      field_planned_end_label: "Date de fin",
      field_status_label: "État",
      save_error_message: "Impossible d'enregistrer les mises à jour",
      logo: "Logo",
      no_logo_available: "Pas de logo disponible",
    },
    durations_project_settings: {
      working_hours_header: "Heures de travail",
      holidays_header: "Jours non ouvrables (jours fériés, congés d'entreprise et perturbations)",
      holidays_editor_header: "Ajouter un nouveau jour férié",
      start: "Commencer",
      end: "Fin",
      breaks: "Pauses (h)",
      hours: "h",
      add: "Ajouter",
      save_error_message: "Impossible d'enregistrer les mises à jour",
      validation_start_more_than_end: "Le début devrait être avant la fin",
      validation_start_or_end_empty: "Le début/la fin est requis",
      validation_working_hours_breaks:
        "La pause doit être supérieure à 0 et inférieure au nombre d'heures disponibles",
      validation_name_empty: "Le nom est obligatoire",
      date_range: "Choisir une plage de dates",
      holidays_field_name: "Nom de la personne",
      holidays_field_date: "Date",
      holidays_field_type: "Type",
      holidays_field_start: "Heure de début",
      holidays_field_end: "Heure de fin",
      holidays_field_duration: "Durée",
      holidays_field_created_by: "Créé par",
      holidays_field_disturbance_type: "Type de perturbation",
      holidays_field_disturbance_description: "Description de la perturbation",
      no_holidays_message: "Pas de vacances trouvées",
      add_public_holidays_button: "Importation de jours fériés",
      public_holidays_for: "Jours fériés pour",
      no_public_holiday_message: "Pas de jours fériés pour",
      validation_start_required: "L'heure de début est requise",
      validation_end_required: "L'heure de fin est requise",
      validation_date_required: "La date est obligatoire",
      validation_disturbance_type_empty: "Le type de perturbation est requis",
      working_day_label: "jour | jours",
      total_working_days_label: "Nombre total de jours de travail: {total}",
      total_working_hours_label: "Nombre total d'heures de travail: {total}",
      working_days_calculation_tooltip_header:
        "Les jours de travail sont calculés sur la base de la règle suivante:",
      working_days_calculation_tooltip_item1: "pas de temps de travail configuré : 0 jour",
      working_days_calculation_tooltip_item2: "0h < durée de travail < 8h : 0,5 jours",
      working_days_calculation_tooltip_item3: "8h <= durée de travail : 1 jour",
      non_working_day_type_labels: {
        public_holiday: "Jour férié",
        company_holiday: "Jour férié d'entreprise",
        disturbance: "Perturbation",
      },
    },
  },
  camera: {
    components: {
      weather_container: {
        title: "Météo",
        kmh: "km/h",
        view_more: "Voir plus",
        view_less: "Voir moins",
        clock: "h",
      },
    },
    period: {
      info_text:
        "Vous pouvez demander le matériel vidéo original, non compressé, datant de moins de 7 jours.",
      playback_period: "Durée de la lecture",
      date: "Date",
      time: "Heure",
      duration: "Durée",
      in_minutes: "En minutes",
      play: "Play",
    },
    gallery: {
      pick_a_date: "Choisir une date",
      date: "Date",
      search: "Rechercher",
      error_timeframe: "Pas d’information disponible à cette date",
      download: {
        menu_title: "Télécharger des images",
        title: "Télécharger les images de la galerie",
        date_picker_placeholder: "Tous les jours",
        files: "Fichier | Fichiers",
        start_download: "Démarrer le téléchargement",
        stop_download: "Arrêter le téléchargemen",
        resume_download: "Reprendre le téléchargement",
        ongoing_download:
          "Téléchargement en cours. Veuillez d'abord arrêter le téléchargement avant de fermer!",
        success: "Téléchargement réussi de l'archive zip!",
        partial_download: "Archive zip partiellement téléchargée.",
        base_file_name: "galerie",
        imgs_per_day: "image par jour | images par jour",
        all_imgs: "Toutes les images",
      },
    },
    daily_timelapse: {
      pick_a_date: "Choisir une date",
      date: "Date",
      search: "Rechercher",
      error_timeframe: "Pas d’information disponible à cette date ",
    },
    compare: {
      pick_a_date: "Pick date and time",
      date: "Date",
      time: "Time",
      before: "Before",
      after: "After",
      button: "Search",
      err_no_image:
        "Il n'y a pas d'image disponible pour la combinaison de date et d'heure demandée. Nous montrons l'image la plus proche que nous avons trouvée.",
      err_future_date: "Veuillez choisir un jour antérieur à aujourd'hui!",
      err_no_image_range_1: "Nous n'avons pas pu trouver d'image dans la gamme de",
      err_no_image_range_2: "Veuillez entrer une autre valeur !",
      err_after_date: "La date après doit être plus grande que la date avant.",
      err_after_time: "Le temps après doit être plus grand que le temps avant pour la même date.",
      tutorial:
        "Ici, vous pouvez comparer les images de deux points dans le temps. Utilisez le curseur dans l'image pour voir la progression du chantier!",
      no_image: "Pas d'image pour cette date.",
    },
    customizable_timelapse: {
      create_button: "Créer un timelapse",
      table: {
        name: "Nom",
        camera: "Camera",
        start_date: "Début",
        end_date: "Fin",
        duration: "Durée",
        duration_min: "min",
        created: "Date de création",
        play: "Play",
        play_button: "Play",
        extended: "Extension",
        extended_remove: "Retirer la timelapse",
        extended_download: "Télécharger ",
      },
      create_modal: {
        title: "Timelapse personnalisée",
        timelapse_name: "Nom de la timelapse",
        camera: "Camera",
        start: "Début",
        end: "Fin",
        duration: "Durée de la timelapse",
        option_1: "1 minute",
        option_2: "2 minutes",
        option_3: "3 minutes",
        option_4: "4 minutes",
        option_5: "5 minutes",
        create: "Créer une timelapse",
        cancel: "Annuler ",
      },
      success_notification: {
        message:
          "Timelapse crée avec succès! La création de la vidéo peut prendre jusqu’à 60 minutes.",
      },
      errors: {
        create_error: "Erreur lors de la création de la vidéo. Veuillez vérifier votre saisie.",
        unique_name:
          "Veuillez choisir un nom unique pour le timelapse, que vous n'avez pas encore attribué.",
        start_date_after_today: "La date de début est postérieure à la date d'aujourd'hui.",
        end_date_after_today: "La date de fin est postérieure à la date d'aujourd'hui.",
        end_date_before_start_date: "La date de fin est antérieure à la date de début.",
        missing_name: "Nom de l'accélérateur manquant.",
        missing_start_date: "Veuillez choisir une date de début.",
        missing_end_date: "Veuillez choisir une date de fin.",
      },
    },
    dashboard: {
      rename: "Renommer l'appareil photo",
    },
    update_error: "Impossible de mettre à jour l'appareil photo",
  },
  analytics: {
    processes: {
      last_updated: "Dernière mise à jour: ",
      no_data:
        "Il n'y a pas encore de données de processus disponibles. Le tracking va bientôt commencer!",
      processes: "Processus",
      display_type_label: "Type d'affichage",
      sort_by: "Trier par",
      process_group: "Groupe de processus",
      building: "Bâtiment",
      section: "Section",
      level: "Niveau",
      component: "Composant",
      tags: "Tags",
      placeholder_hierarchy_tag: "Sélectionner une étiquette de hiérarchie",
      placeholder_process_type: "Sélectionner un type de processus",
      clear_filters: "Effacer tous les filtres",
      component_view: "Vue du composant",
      section_view: "Vue de la section",
      level_view: "Vue de niveau",
      type: "Type",
      location: "Emplacement",
      start: "Première activité",
      end: "Dernière activité",
      process: "Processus",
      date: "Date",
      duration: "Durée",
      item: "Article | Articles",
      brutto_day_duration: "Durée totale",
      brutto_hour_duration: "Nombre total d'heures",
      net_day_duration: "Durée d'activité",
      net_hour_duration: "Heures d'activité",
      standstill_days: "Jours d'arrêt",
      utilization: "Taux d'utilisation",
      processes_view: "Vue des processus",
      new_version: "Nouvelle version",
      old_version: "Ancienne version",
      amount: "Quantité",
      summary: "Résumé",
      aggregation_view: "Par groupe de processus",
      plain_view: "Par processus individuel",
      process_data_gantt: "Données du processus Gantt",
      process_data_table: "Journal d'activité",
      unit_values: "Valeurs unitaires",
      brutto_day_duration_tooltip:
        "Durée totale en jours ouvrables entre le premier et le dernier processus enregistré dans cette catégorie",
      net_day_duration_tooltip:
        "Nombre de jours où des processus ont été enregistrés dans cette catégorie",
      net_hour_duration_tooltip:
        "Somme des durées des processus individuels dans cette catégorie sans chevauchements temporels",
      standstill_days_tooltip:
        "Nombre de jours entre la première et la dernière activité enregistrée sur lesquels aucun travail n'a été enregistré dans cette catégorie",
      utilization_tooltip: "Heures actives / Heures totales",
      total_duration: "Durée totale",
      net_duration: "Durée nette",
      workers: "Travailleurs",
      working_hours: "Heures de travail",
      time_range: "Plage de temps",
      show_activity_calendar: "Afficher le calendrier des activités",
      sidebar: {
        analysis: "Analyse",
        before_after: "Avant & Après",
        total: "Total",
        processes_selected: "Process sélectionné | Processes sélectionnés",
        active_total_days: "Jours actifs/total",
        productive_days: "Temps productifs",
        outage_days: "Jours d'arrêt",
        working_hours: "Heures de travail",
        select_row: "Sélectionner la ligne",
        open_in_activity_log: "Ouvrir dans le journal d'activité",
      },
      shortcuts: {
        title: "Raccourcis",
        draw: "Dessiner",
        draw_area: "Dessiner une zone pour sélectionner plusieurs événements",
        select_multiple: "Sélectionner plusieurs événements",
        event: "événement",
        open_context_menu: "Ouvrir le menu contextuel",
        resource: "ressource",
        right_click: "Clic droit",
        click: "Clic",
      },
      unable_to_load: "Impossible de charger les processus",
    },
    reports: {
      my_reports: "Mes rapports",
      reports: "Rapports",
      name: "Nom",
      processes_components: "Processus / Composant",
      building: "Bâtiment",
      section: "Section",
      level: "Niveau",
      by_building: "Par bâtiment",
      by_section: "Par section",
      by_level: "Par niveau",
      filters: "Filtres",
      type: "Type",
      is: "est",
      custom: "Personnalisé",
      plot_config: "Configuration du graphique",
      trade: "Métier",
      component: "Composant",
      select_values: "Sélectionner des valeurs",
      utilization: "Utilisation",
      working_hours: "Heures de travail",
      mean: "Moyen: ",
      planned: "Planifié",
      total: "Total",
      remove_report: "Supprimer le rapport",
      remove_filter: "Supprimer le filtre",
      select_filters: "Sélectionner des filtres",
      no_available_filters_left: "Aucun filtre disponible",
      all: "Tous",
      cycle_times_plot: "Temps de passage",
      working_hour_curve_plot: "Courbe du temps de travail",
      no_data: "Aucune donnée disponible",
      absolute_date: "Date absolue",
      show_mean_working_hours: "Afficher les heures de travail moyennes",
      in_progress: "En cours",
      show_utilization: "Afficher l'utilisation",
      show_planned: "Afficher le planifié",
      unit_values_plot: "Valeurs unitaires",
      unit_values: "Valeurs unitaires",
      unit_value: "Valeur unitaire",
      unit_values_missing: "Les valeurs unitaires sont manquantes",
      no_reports: "Il n'y a pas encore de rapports.",
      preset_mode: "Composants",
      process_mode: "Processus individuels",
      only: "Seulement",
      location: "Emplacement",
      milestone: "Jalon",
      milestone_plot: "Jalon",
      target_period: "Période cible: ",
      actual_period: "Période réelle: ",
      present: "présent",
      modes: "Triage",
      actual_not_started: "Pas encore commencé",
      reset_zoom: "Réinitialiser le zoom",
      end_date_mode: "Par dates de fin",
      sequence_mode: "Par phase de construction",
      create_first_report: "Créer le premier rapport",
      planned_title: "Les jours de travail prévus sont pris du calendrier.",
      planned_description:
        "Les jours de travail prévus sont calculés sur la base des heures de travail configurées dans les paramètres du projet et excluent les jours fériés et les vacances de l'entreprise.",
      active_title:
        "Les jours de travail actifs sont des jours où au moins une activité a eu lieu.",
      active_description:
        "Les jours de travail actifs sont calculés sur la base des heures de travail configurées dans les paramètres du projet et excluent les jours fériés, les vacances de l'entreprise et les perturbations.",
      inactive_title:
        "Les jours de travail inactifs sont des jours où aucune activité n'a eu lieu.",
      inactive_description:
        "Les jours de travail inactifs sont calculés sur la base des heures de travail configurées dans les paramètres du projet et excluent les jours fériés, les vacances de l'entreprise et les perturbations.",
      utilization_title:
        "L'utilisation est basée sur le rapport entre les heures de travail et la capacité.",
      utilization_description:
        "La capacité est calculée sur la base du nombre maximum de travailleurs multiplié par les heures de travail configurées dans les paramètres du projet.",
      working_hours_title: "Heures de travail.",
      working_hours_description:
        "Les heures de travail sont calculées sur la base de la somme du nombre maximum de travailleurs multiplié par le temps qu'ils passent sur le chantier.",
      outages_title: "Interruptions.",
      outages_description:
        "Les jours de panne de caméra sont des jours ouvrables pendant lesquels les caméras pertinentes pour le composant n'ont pas fonctionné pendant plus de 50% du temps.",
      unit_values_title: "Valeurs unitaires",
      unit_values_description:
        "Les valeurs unitaires sont calculées sur la base des heures de travail et de la quantité et indiquent l'effort temporel nécessaire par unité de quantité.",
      dont_save_report: "Ne pas enregistrer le rapport",
      update_report_error: "Le rapport ne peut pas être sauvegardé",
      delete_report_error: "Impossible de supprimer le rapport",
      refresh_report_error: "Impossible d'actualiser les données des rapports",
      delete_confirmation: {
        header: "Confirmation",
        message: 'Le rapport "{name}" sera supprimé.',
        confirm: "Supprimer le rapport",
        cancel: "Annuler",
      },
      report_summary_load_error: "Impossible de charger les données du rapport",
      edit_report: "Éditer le rapport",
      dashboard_new_report: "nouveau",
      query_value_plot: "Valeur unique",
      show_previous_period_value: "Comparaison avec la période précédente",
      previous_period_label: "Période précédente",
      metric: "Métrique",
      query_value_metrics: {
        working_hours: "Heures de travail",
        unit_value: "Valeur unitaire",
        utilization: "Utilisation",
        productive_days: "Temps productifs",
        active_days: "Jours actifs",
        inactive_days: "Jours inactifs",
        delta_planner: "Planificateur delta moyen",
        project_progress: "L'avancement du projet",
      },
      query_value_aggregations: {
        sum: "Somme",
        average: "Moyenne",
        median: "Médiane",
        latest: "Le plus récent",
      },
      query_value_units: {
        hours: "h",
        working_days: "JO",
        working_days_full: "Jours ouvrables",
      },
      query_value_formatting_rules: {
        header: "Règles de formatage visuel",
        if_value_is: "Si la valeur est",
        show_with: "Afficher avec",
        actions: {
          green_background: "Fond vert",
          red_background: "Fond rouge",
          yellow_background: "Fond jaune",
          green_foreground: "Vert",
          red_foreground: "Rouge",
          yellow_foreground: "Jaune",
        },
      },
      query_value_progress: {
        weight: "Poids",
        progress_for_date: "Progrès pour la date",
        not_finished: "pas terminé",
        finished_working_hours_parent_tooltip: "Somme des éléments de construction subordonnés",
        weight_tooltip:
          "Indique le pourcentage d'influence (poids) du composant sur l'avancement global, sur la base des durées prévues dans le calendrier.",
        status: {
          completed: "Terminé",
          not_started: "Non commencé",
          active: "Actif",
        },
        planner_item: "élément",
        project: "projet",
        planned_working_days: "jours de travail prévus",
        worked_out_of: "Travail effectué {actual} sur {total} jours de travail prévus",
        aggregated_progress: "Progrès agrégé",
        fixe_value: "valeur fixe",
      },
      aggregation: "Agrégation",
      period: "Période",
      previous_period: "Période précédente",
      unable_to_load: "Impossible de charger les rapports",
    },
    planner: {
      label_upload_planner: "Télécharger le plan du projet",
      plan_upload_desc: "Glisser-déposer un fichier MS Project XML dans la boîte.",
      title: "Emploi du temps",
      last_updated: "Dernière mise à jour:",
      zoom: "Zoom",
      target_period: "Période visée: ",
      actual_period: "Période réelle: ",
      chart_progress: "Statut: ",
      chart_target_period: "Période visée: ",
      chart_actual_period: "Période réelle: ",
      chart_sub_processes: "sous-processus: ",
      chart_not_started: "Pas commencé",
      chart_no_end_date: "Pas de date de fin ",
      no_planner_import_possible:
        "Le suivi a déjà commencé. Contactez l'équipe oculai pour télécharger une nouvelle version du plan.",
      wrong_file_format:
        "Le format ile n'est pas pris en charge. Veillez à télécharger un fichier MS Project XML.",
      all: "Tous",
      present: "présent",
      visible: "Visible",
      events: "Evénements",
      items: "Articles de planification",
      critical_path: "Chemin critique",
      not_started_event: "Pas encore commencé",
      actual_not_started: "Pas encore commencé",
      modes: {
        visitor: "Visiteur",
        revision: "Éditeur",
      },
      milestone: "Jalon",
      planned_event_name: "Prévu",
      actual_event_name: "Réel",
      start_date: "Date de début",
      start_time: "Heure de début",
      end_date: "Date de fin",
      end_time: "Heure de fin",
      event_validation: {
        start_date_required: "La date de début est obligatoire",
        end_date_required: "La date de fin est obligatoire",
        start_date_in_future: "La date de début ne peut pas être dans le futur",
        end_date_in_future: "La date de fin ne peut pas être dans le futur",
        start_bigger_than_end: "La date de début doit être inférieure à la date de fin",
      },
      comments: {
        add_comment_label: "Ajouter un commentaire",
        commented: "a commenté",
        add_comment_menu_item: "Ajouter un commentaire",
        unable_to_create_error: "Impossible de créer un commentaire",
        unable_to_delete_error: "Impossible de supprimer un commentaire",
        delete_comment_menu_item: "Supprimer",
        delete_confirmation_title: "Supprimer la confirmation",
        delete_confirmation_confirm: "Supprimer",
        delete_confirmation_cancel: "Annuler",
        loading_message: "Chargement des commentaires...",
      },
      edit_event_menu_item: "Éditer",
      show_processes_menu_item: "Montrer les processus",
      hide_processes_menu_item: "Masquer les processus",
      more_info_menu_item: "Plus d'informations",
      add_actual_event: "ajouter un événement",
      edit_actual_event: "modifier la tâche",
      actual_active: "en cours",
      actual_finished: "terminé",
      delete_actual_confirmation_title: "supprimer la demande",
      delete_actual_confirmation_confirm: "supprimer",
      delete_actual_confirmation_cancel: "Annuler",
      delete_actual_confirmation_message: "Souhaitez-vous vraiment supprimer l'événement ?",
      actual_not_filled: "Veuillez remplir tous les champs ",
      actual_period_error: "La date de fin est antérieure à la date de début",
      actual_period_event_error: "Une erreur est survenue lors de l'enregistrement",
      nothing_to_undo_message: "Rien à défaire",
      nothing_to_redo_message: "Rien à refaire",
      excel_export_error_message: "Impossible de récupérer les données d'exportation",
      unable_to_load_plan_error_message: "Impossible de charger le plan",
      unable_to_generate_pdf_error_message: "Impossible de générer un PDF",
      create_revision_error_message: "Un problème s'est produit. Veuillez réessayer!",
      calendar_week_label: "SC",
      name_column_label: "Nom",
      export_name: "Emploi_du_temps",
      processes: {
        breaks: "Pauses",
        intervals: "Intervalles",
      },
      working_days_input_label: "Jours ouvrables",
      outages: "Interruptions",
      no_search_results: "Aucun enregistrement n'a été trouvé",
      non_working_days: "Jours non ouvrables",
      completeview: "Vue complète",
      currentview: "Vue actuelle",
      show_complete_planner: "Afficher le plan complet",
      working_days_label: "Jours ouvrables",
      unable_to_load_plan: "Impossible de charger le plan",
      mark_as_done_modal: {
        mark_as_done_button: "Marquer comme fait",
        date_label: "Quand la procédure a-t-elle été achevée?",
        go_to_last_process: "aller à la fin du processus",
        go_to_last_concreting: "aller à la fin du bétonnage",
      },
      unable_to_save_changes: "Impossible d'enregistrer les modifications",
      planned_progress: "Progrès prévus",
      actual_progress: "Progrès réels",
      project_completed_message: "Projet achevé - 100%",
      to_planner_link: "Au planificateur",
      progress_excel_export: {
        name: "nom",
        planned_start: "début prévu",
        planned_end: "fin prévue",
        actual_start: "début réel",
        actual_end: "fin réelle",
        planned_working_days: "JO planifiés",
        actual_working_days: "JO réels",
        progress: "progrès",
        completed: "achevé",
        deviation_end_date: "écart par rapport à la date de fin",
        weight: "poids",
      },
    },
    site_activity: {
      title: "Activité du chantier",
      tab_walking_paths: "Chemins de randonnée",
      pick_a_date: "Choisir une date",
      date: "Date",
      search: "Rechercher",
      error_timeframe: "Pas d’information disponible à cette date ",
      no_data:
        "Aucune activité de construction enregistrée jusqu'à présent. Le tracking va bientôt commencer!",
    },
    critical_path: {
      title: "Chemin critique",
      similar_components: "Composants similaires",
      no_critical_path: "Pas de chemin critique trouvé",
      unable_to_load: "Impossible de charger le chemin critique",
      unable_to_update: "Impossible de mettre à jour le chemin critique",
      planned_end: "Fin prévue",
      projected_end: "Fin projetée",
      projected_project_end: "Fin prévisionnelle du projet",
      actual_end: "Fin réelle",
      days: "jours",
      working_days: "JO",
      not_started: "pas commencé",
      active: "actif",
      new_tag: "nouvelle étiquette",
      click_to_lock: "cliquer pour verrouiller",
      latest_planned_component_label: "dernier composant prévu",
      time_critical_component_label: "composant critique",
      projected_end_component_label: "composante finale projetée",
      in_delay: "en retard",
      previous_delta: "Delta par rapport au niveau précédent",
    },
  },
  calendar: {
    today: "Aujourd'hui",
    week_days: {
      "1": {
        long: "Lundi",
        short: "Lun",
      },
      "2": {
        long: "Mardi",
        short: "Mar",
      },
      "3": {
        long: "Mercredi",
        short: "Mer",
      },
      "4": {
        long: "Jeudi",
        short: "Jeu",
      },
      "5": {
        long: "Vendredi",
        short: "Ven",
      },
      "6": {
        long: "Samedi",
        short: "Sam",
      },
      "7": {
        long: "Samedi",
        short: "Dim",
      },
      "0": {
        long: "Samedi",
        short: "Dim",
      },
    },
    months: {
      "1": {
        long: "Janvier",
        short: "Janv",
      },
      "2": {
        long: "Février",
        short: "Févr",
      },
      "3": {
        long: "Mars",
        short: "Mars",
      },
      "4": {
        long: "Avril",
        short: "Avr",
      },
      "5": {
        long: "Mai",
        short: "Mai",
      },
      "6": {
        long: "Juin",
        short: "Juin",
      },
      "7": {
        long: "Juillet",
        short: "Juil",
      },
      "8": {
        long: "Aout",
        short: "Aout",
      },
      "9": {
        long: "Septembre",
        short: "Sept",
      },
      "10": {
        long: "Octobre",
        short: "Oct",
      },
      "11": {
        long: "Novembre",
        short: "Nov",
      },
      "12": {
        long: "Décembre",
        short: "Déc",
      },
    },
  },
  report: {
    title: "Rapport journalier",
    planned_project_start: "Lancement du projet",
    planned_project_end: "Fin prévue du projet",
    delete_report: "Supprimer le rapport",
    overview_link: "Vers l'aperçu du rapport",
    config: "Paramètres",
    logo_upload: "Logo de l'entreprise",
    logo_upload_desc: "Déposer l'image ici PNG ou JPG",
    config_sections: "Sections du rapport",
    bulk_download: "Bulk download",
    weather_threshold: "Météo",
    tracking_label_enabled: "Suivi intelligent par oculai",
    tracking_label_disabled: "Prise en charge des heures de travail configurées pour le projet",
    process_time_label: "Attribuer l'heure de début et de fin d'un nouveau processus",
    process_time_desc:
      "En sélectionnant cette option, vous initialisez les heures de début et de fin des processus avec les valeurs de l'heure de travail",
    logo_tooltip:
      "Uploade ici le logo de l'entreprise au format PNG ou JPEG afin d'individualiser le rapport de chantier.",
    working_hours_tooltip:
      "Définis ici si oculai doit déterminer le temps de travail de manière automatique ou si tu veux définir un temps de travail fixe pour chaque jour de la semaine.",
    weather_warnings_tooltip:
      "Définir des règles pour afficher les alertes de mauvais temps dans l'aperçu mensuel du rapport de chantier.",
    sections_tooltip:
      "Déplacez des sections de rapport par glisser-déposer entre les boîtes Actif et Inactif pour afficher ou masquer des sections dans le rapport.\n\nDéplacez une section dans une boîte pour invidualiser l'ordre.",
    site_duration_start: "Début",
    site_duration_end: "Fin",
    report_approved: "Rapport approuvé",
    weather: {
      time_range: "Considérer les avertissements dans la fourchette de temps de :",
      precipitation: "Précipitations",
      cold_temperature: "Température de l'air (froid)",
      hot_temperature: "Température de l'air (chaud)",
      wind: "Vent",
      max_wind_speed: "Vitesse du vent (max)",
      cold_temperature_type: "°C",
      hot_temperature_type: "°C",
      precipitation_type: "mm/h",
      wind_type: "km/h",
      min: "min",
      max: "max",
      min_value: "Minimum",
      max_value: "Maximum",
      "24/7-7": "24h 7-7",
      "7/7-14": "7h 7-14",
      WEATHER_TIME_RANGE: "Période de travail",
      ENTIRE_TIME_PERIOD: "Période entière",
      precipitation_volume: "Précipitations",
      for: "pour",
      within: "dans un délai",
      unit: "Unité",
      and: "et",
      or: "ou",
    },
    section_title: {
      working_hours: "Heures de travail",
      workforce: "Personnel",
      processes: "Processus",
      special_events: "Événements spéciaux",
      notes: "Notes",
      materials: "Incoming Material",
      material: "Material",
      machinery: "Heavy Machinery",
      old_data_label: "Récupérer les entrées des jours précédents",
      gallery: "Galerie",
      site_duration: "Site duration",
      weather: "Weather",
      disturbances: "Perturbation",
    },
    sections: {
      workforce: {
        workforce_type_entries: {
          project_manager: "Chef de projet",
          construction_manager: "Gestionnaire de site",
          foreman: "Contremaître",
          construction_worker: "Ouvrier du bâtiment",
          construction_skilled_worker: "Ouvrier qualifié de la construction",
          crane_operator: "Opérateur de grue",
          equipment_operator: "Opérateur d'équipement",
        },
        workforce_type: "Rôle",
        company: "Entreprise",
        amount: "Quantité travailleurs",
        note: "Notes",
      },
      processes: {
        process_type: "Activités",
        location: "Emplacement",
        start_time: "Heure de début",
        end_time: "Heure de fin",
        duration: "Durée",
        note: "Notes",
      },
      special_events: {
        special_events_type_entries: {
          additional_service: "Services supplémentaires",
          order_contractor: "Nouvelle commande d'un contractant",
          plan_change: "Changement de planification",
          heavy_equipment_use: "Utilisation d'équipements lourds",
          acceptance: "Acceptation",
          visit: "Visitez",
          other: "Autre",
          impediment: "Empêchement",
          hourly_wage_work: "Travail à salaire horaire",
          reinforcement_inspection: "Inspection de l'armature",
          condition_assessment: "Évaluation de l'état",
        },
        special_events_type: "Type d'événement",
        note: "Notes",
      },
      material: {
        material_type: "Name",
        amount: "Quantité",
        unit: "Unit",
        note: "Note",
        unit_entries: {
          kg: "kg",
          "m^2": "m^2",
          "m^3": "m^3",
          piece: "pièces",
          t: "t",
        },
      },
      machinery: {
        machinery_type: "Type",
        amount: "Quantité",
        note: "Notes",
      },
      gallery: {
        oai_images_title: "Sélectionner parmi les galeries d'images",
        custom_images_title: "OU Téléchargez vos images personnalisées",
        no_images: "Aucune image trouvée pour",
        add_note: "Ajouter une note",
      },
      disturbances: {
        date_range: "Plage de temps",
        description: "Description",
        name: "Nom",
        type: "Type",
        confirmation_header: "Confirmation",
        cancel_action: "Annuler",
        delete_action: "Supprimer",
        confirmation_message:
          "Êtes-vous sûr de vouloir supprimer cette perturbation? Cette action affecte les perturbations de tous les rapports.",
      },
    },
    general: {
      custom_author: "Entrée personnalisée",
      empty_options: "Ajouter une nouvelle valeur",
      empty_combobox: "Veuillez choisir une valeur",
      last_update: "Dernière mise à jour",
      approved_at: "Approuvé à",
      approved_by: "Approuvé par",
      by: "par",
      before: "Avant",
      after: "Après",
      old_data_label: "Obtenir les données des jours précédents",
      merge_processes_title: "Fusionner des processus déjà remplis avec des données oculai.",
      merge_processes_sub_title: "Les entrées identiques ne sont pas fusionnées.",
      merge_processes_button: "Fusionner les processus",
      merge_notification: "Ajouter des données oculai",
      prev_day: "Obtenir le jour précédent",
      old_data_title: "Récupérer les anciennes données de",
      no_images: "Aucune image disponible pour le moment !",
      section_empty: "Aucune entrée",
      processing: "Traitement",
      data: "données",
      create: "Créer",
      type_value: "Tapez la valeur que vous voulez ajouter",
      gallery_no_image: "Aucune image disponible",
      notes_placeholder: "Ajouter une note...",
      enable_time_visibility: "Afficher les champs d'heure pour les entrées de processus",
    },
    bulk_export: {
      title: "Bulk Export",
      main_label: "Choisis une période",
      calendar_label:
        "Tu peux choisir une période allant jusqu'à 31 jours pour le téléchargement des rapports.",
      file_type_label: "Type de document",
      status_type_label: "Statut du rapport",
      date_range_placeholder: "Choisis jusqu'à 31 jours",
      approved_reports: "Rapports notifiés",
      all_reports: "Tous les rapports",
      today: "Aujourd'hui",
      this_month: "Ce mois-ci",
      last_month: "Mois dernier",
      last_30_days: "30 derniers jours",
      this_week: "Cette semaine",
      last_week: "Semaine dernière",
      pdf: "PDF combiné",
      zip: "ZIP",
    },
  },
  buttons: {
    cancel: "Annuler",
    save: "Sauvez",
    back_overview: "Retour à l'aperçu",
    export_report: "Exportation du rapport",
    export_plain: "Exportateur",
    send: "Envoyer",
    copy: "Copie",
    stay_page: "Continuer à éditer",
    leave_page: "Partir quand même",
    export: "Exportation et rejet des modifications",
    search: "Cherchez",
    download: "Download",
    edit: "Modifier",
    bulk_export: "Bulk export",
    save_and_approve: "Enregistrer et approuver",
    approve: "Approuver",
    revert_changes: "Revenir sur les modifications",
    apply: "Appliquer",
    comment: "Pour commenter",
    add_images: "Ajout d'images",
    download_image_range: "Télécharger les images de l'intervalle de temps",
    count_selected: "{selected} sur {total} sélectionné",
    add: "Ajouter",
    delete: "Supprimer",
    time_range: "Plage de temps",
    import: "Importer",
    clear: "Effacer",
    load_more: "Charger plus",
    create: "Créer",
    submit: "Soumettre",
    next: "Suivant",
  },
  err: {
    no_reports_found: "Aucun rapport trouvé pour une période donnée",
    required: "Le champ est obligatoire",
    negative_value: "Valeur négative",
    err_future_date: "Cette date se situe dans le futur. Veuillez choisir une autre date !",
    no_data_found:
      "Il n'y a pas de données enregistrées pour la date sélectionnée. Veuillez choisir une autre date !",
    no_weather_data: "Il n'y a pas de données météorologiques disponibles pour ce jour.,",
    err_period_future_date: "Veuillez choisir un jour antérieur à aujourd'hui",
    no_streaming_found: "Pas de streaming pour la période sélectionnée",
    time: "L'heure de fin doit être supérieure à l'heure de début",
    empty_values: "Veuillez remplir tous les champs en surbrillance!",
    saved_values: "Aucune donnée disponible pour le jour sélectionné",
    invalid_date: "Invalid dare. PLease follow the pattern shown.",
    save_before_leave:
      "Vous avez des sessions d'édition ouvertes. Veuillez sauvegarder avant de partir!",
    save_before_export:
      "Vous avez des modifications non sauvegardées. Voulez-vous exporter le rapport et supprimer les modifications?",
    save_before_leave_title: "Modifications non enregistrées",
    save_before_mode_switch:
      "Les modifications non enregistrées seront perdues. Continuer quand même?",
    weather: {
      data_not_found: "Aucune donnée météo trouvée !",
      project_not_found: "Projet non trouvé",
    },
    internal_error: "Erreur de serveur interne.",
    error_streams_not_found: "Il n'y a pas d'images disponibles pour le moment!",
    no_images_found: "Il n'y a pas d'images disponibles pour la date sélectionnée.",
    server_error: "Un problème est survenu. Veuillez réessayer plus tard.",
    file_type_image: "Type de fichier non valide. Veuillez télécharger un fichier image.",
    no_timelapse_found: "Il n'y a pas de timelapse enregistré pour la date sélectionnée.",
    no_live_found: "Cette caméra est actuellement éteinte.",
    no_data_yet: "Nous ne pouvons pas encore fournir de données. Revenez plus tard !",
    export: {
      no_date_selected: "Aucune date sélectionnée",
      no_export_option: "Aucune option d'exportation sélectionnée",
      no_status_option: "Aucune option d'état sélectionnée",
      no_reports_found: "Aucun rapport trouvé pour une période donnée",
    },
    planner: {
      no_camera_per_item:
        "Il n'y a pas d'image de l'appareil photo pour cet élément du planificateur.",
    },
    inputs: {
      time_wrong_format: "Format de l'heure incorrect",
    },
  },
  general: {
    new_label: "Nouveau",
    before: "Avant",
    after: "Après",
    active: "Active",
    inactive: "Inactive",
    label_upload_image: "Upload an image",
    loading_message: "Cela peut prendre quelques secondes, ne fermez pas cette page.",
    loading: "Loading...",
    search_placeholder: "Rechercher",
    no_data_yet: "Pas encore de données",
  },
  video_player: {
    unable_to_play_message: "Impossible de lire la vidéo",
    low_bandwidth_mode: "Mode faible bande passante",
  },
  time: {
    working_day: "Jour de travail | Jours de travail",
    day: "Jour | Jours",
    hour: "Heure | Heures",
    minute: "Minute | Minutes",
    last_day: "Dernier jour",
    last_week: "Semaine dernière | {count} dernières semaines",
    last_calendar_week: "Dernière semaine calendaire",
    last_month: "Mois dernier | {count} derniers mois",
    last_year: "Année dernière | {count} dernières années",
    all_time: "Total",
    end_gantt: "Fin",
    start_gantt: "Début",
  },
  working_day: {
    working_day: "Jour ouvré | Jours ouvrés",
    working_day_abbrev: "JO",
    calendar_day: "Jour | Jours",
    non_working_day: "Jour non ouvrable",
    holiday: "Vacances",
    holiday_days: "Jour de vacances | Jours de vacances",
    critical_disturbance: "Perturbation critique",
    critical_disturbance_days: "Jour de perturbation critique | Jours de perturbation critique",
    active: "Actif",
    active_days: "Jour actif | Jours actifs",
    inactive: "Inactif",
    inactive_days: "Jour inactif | Jours inactifs",
    outage: "Panne",
    outage_days: "Jour de panne de caméra | Jours de panne de caméra",
  },
  unit_values: {
    header: "Valeurs unitaires",
    loading_error: "Impossible de charger les valeurs unitaires",
    saving_error: "Impossibilité de sauvegarder les valeurs unitaires",
    no_unit_values: "Pas de valeurs unitaires",
    building_label: "Bâtiment",
    level_label: "Niveau",
    section_label: "Section du bâtiment",
    duration_label: "Durée de la formation",
    duration_unit: "h",
    workforce_label: "Effectifs",
    workforce_unit: "n",
    quantity_label: "Quantité de travail",
    unit_value_label: "Valeur unitaire",
    unit_value_unit: "ht",
    merge_rows: "Fusionner les lignes",
    split_rows: "Diviser les lignes",
    add_type: "Ajouter une valeur unitaire",
    export_to_excel: "Exporter vers Excel",
    planned_start: "commencer",
    planned_end: "Fin",
    detail_oculai_new_calculated: "oculai ajusté",
    detail_delta_label: "Majoration",
    detail_custom_delta_label: "Ajustement",
    approved: "Approuvé",
    header_included_processes: "Processus inclus",
    select_type: "Sélectionner un type",
    column_modal: {
      title_add: "Nouvelle valeur unitaire",
      title_edit: "Modifier la valeur de l'unité",
      type_field: "Type",
      validation_type_required: "Est nécessaire",
      no_types_found: "Aucun type trouvé",
      invalid_number: "Numéro invalide",
      cleaned_unit_values_header: "Valeur ajustée de l'effort",
      type_duration_factor: "Facteur d'heures marginales",
      type_duration_factor_tooltip:
        "Les heures marginales sont des heures de travail qui ne sont pas affectées à l'activité principale. La préparation du poste de travail (nettoyage, rangement, déplacement) en est un exemple.",
      type_workforce_delta: "Majoration de la main-d'œuvre",
      type_workforce_delta_tooltip:
        "Les heures aveugles sont des heures de travail qui ne peuvent pas être saisies par oculai, car elles ne peuvent pas être saisies par la caméra. Le décoffrage du plafond en est un exemple.",
      type_value_delta: "Majoration statique Valeur de la charge de travail",
      type_value_delta_tooltip:
        "Le forfait d'heures aveugles permet de corriger directement la valeur de l'effort : 0.5 (valeur de l'effort oculai) + 0.3 (forfait d'heures aveugles) = 0.8",
    },
    types: {
      uv_wall_default: { label: "Murs en béton coulé sur place", unit: "m²" },
      uv_wall_default_formwork_m2: {
        label: "Murs en béton coulé sur place coffrage",
        unit: "m²",
      },
      uv_wall_default_rebar_t: { label: "Murs en béton coulé sur place armature", unit: "t" },
      uv_wall_default_concrete_m3: {
        label: "Murs en béton coulé sur place benne/pompe",
        unit: "m³",
      },
      uv_wall_default_concrete_bucket_m3: {
        label: "Murs en béton coulé sur place benne",
        unit: "m³",
      },
      uv_wall_default_concrete_pump_m3: {
        label: "Murs en béton coulé sur place pompage",
        unit: "m³",
      },
      uv_wall_default_element_m2: { label: "Murs préfabriqués/murs creux", unit: "m²" },
      uv_wall_parapet_m2: { label: "Parapet", unit: "m²" },
      uv_wall_parapet_formwork_m2: { label: "Coffrage d'acrotère", unit: "m²" },
      uv_wall_parapet_rebar_t: { label: "Armature d'acrotère", unit: "t" },
      uv_wall_parapet_concrete_m3: { label: "Parapet bétonnage benne/pompe", unit: "m³" },
      uv_wall_parapet_concrete_bucket_m3: { label: "Parapet bétonnage benne", unit: "m³" },
      uv_wall_parapet_concrete_pump_m3: { label: "Parapet bétonnage pompe", unit: "m³" },
      uv_wall_column_m3: { label: "Colonnes en béton coulé sur place", unit: "m³" },
      uv_wall_column_pc: { label: "Poteaux en béton coulé sur place", unit: "pc" },
      uv_wall_column_m2: { label: "Poteaux en béton coulé sur place", unit: "m²" },
      uv_wall_column_formwork_m2: {
        label: "Poteaux en béton coulé sur place coffrage",
        unit: "m²",
      },
      uv_wall_column_rebar_t: {
        label: "Poteaux en béton coulé sur place armature",
        unit: "t",
      },
      uv_wall_column_concrete_bucket_m3: {
        label: "Poteaux en béton coulé sur place benne/pompe",
        unit: "m³",
      },
      uv_wall_column_concrete_bucket: {
        label: "Poteaux en béton coulé sur place benne",
        unit: "m³",
      },
      uv_wall_column_concrete_pump: {
        label: "Poteaux en béton coulé sur place pompage",
        unit: "m³",
      },
      uv_wall_column_element_pc: { label: "Poteaux préfabriqués", unit: "pc" },
      uv_wall_brickwork_all_m2: { label: "Maçonnerie totale", unit: "m²" },
      uv_wall_brick_outline_lm: { label: "Couche d'affleurement", unit: "ml" },
      uv_wall_brickwork_m2: { label: "Maçonnerie", unit: "m²" },
      uv_ceiling_m2: { label: "Dalle", unit: "m²" },
      uv_ceiling_default_formwork_m2: { label: "Coffrage de dalle", unit: "m²" },
      uv_ceiling_element_semi_m2: { label: "Prédalles", unit: "m²" },
      uv_ceiling_default_rebar: { label: "Armature de plafond", unit: "t" },
      uv_ceiling_default_concrete_m3: { label: "Bétonnage de dalle benne/pompe", unit: "m³" },
      uv_ceiling_default_concrete_bucket_m3: {
        label: "Bétonnage de dalle benne",
        unit: "m³",
      },
      uv_ceiling_default_concrete_pump_m3: { label: "Bétonnage de dalle pompe", unit: "m³" },
      uv_ceiling_element_full_m2: { label: "Plafonds préfabriqués", unit: "m²" },
      uv_ceiling_underbeams_m3: { label: "Poutres", unit: "m³" },
      uv_ceiling_underbeams_formwork_m2: { label: "Poutre Coffrage", unit: "m²" },
      uv_ceiling_underbeams_rebar_t: { label: "Poutre d'armature", unit: "t" },
      uv_ceiling_underbeams_concrete_m3: {
        label: "Poutre bétonnage benne/pompe",
        unit: "m³",
      },
      uv_ceiling_underbeams_concrete_bucket_m3: {
        label: "Poutre de bétonnage benne",
        unit: "m³",
      },
      uv_ceiling_underbeams_concrete_pump_m3: {
        label: "Poutre de bétonnage pompe",
        unit: "m³",
      },
      uv_foundation_slab_m2: { label: "Plaque de sol", unit: "m²" },
      uv_foundation_slab_formwork_lm: { label: "Plaque de sol coffrage", unit: "ml" },
      uv_foundation_slab_rebar_t: { label: "Plaque de sol armature", unit: "t" },
      uv_foundation_slab_concrete_m3: {
        label: "Dalle de sol bétonnage benne/pompe",
        unit: "m³",
      },
      uv_foundation_slab_concrete_bucket_m3: {
        label: "Plaque de sol bétonnage benne",
        unit: "m³",
      },
      uv_foundation_slab_concrete_pump_m3: {
        label: "Plaque de sol bétonnage pompe",
        unit: "m³",
      },
      uv_foundation_point_m2: { label: "Fondations individuelles", unit: "m²" },
      uv_foundation_point_formwork_lm: {
        label: "Fondations individuelles coffrage",
        unit: "ml",
      },
      uv_foundation_point_rebar_t: { label: "Fondations individuelles armature", unit: "t" },
      uv_foundation_point_concrete_m3: {
        label: "Fondations individuelles bétonnage benne/pompe",
        unit: "m³",
      },
      uv_foundation_point_concrete_bucket_m3: {
        label: "Fondations individuelles bétonnage benne",
        unit: "m³",
      },
      uv_foundation_point_concrete_pump_m3: {
        label: "Fondations individuelles bétonnage pompe",
        unit: "m³",
      },
      uv_foundation_stripe_m2: { label: "Fondations en bandes", unit: "m²" },
      uv_foundation_stripe_formwork_lm: {
        label: "Fondations en bandes coffrage",
        unit: "ml",
      },
      uv_foundation_stripe_rebar_t: { label: "Fondations en bandes armature", unit: "t" },
      uv_foundation_stripe_concrete_m3: {
        label: "Fondations filantes bétonnage benne/pompe",
        unit: "m³",
      },
      uv_foundation_stripe_concrete_bucket_m3: {
        label: "Fondations filantes bétonnage benne",
        unit: "m³",
      },
      uv_foundation_stripe_concrete_pump_m3: {
        label: "Fondations en bande bétonnage pompe",
        unit: "m³",
      },
    },
  },
  paywall: {
    working_hours:
      "Tu es intéressé par les heures de travail consacrées à chaque processus ? Contacte l'équipe oculai.",
  },
  disturbances: {
    disturbance: "Perturbation | Perturbations",
    type: {
      plans_not_available: "Plans non disponibles",
      bad_weather: "Mauvais temps",
      holiday_season: "Saison des vacances",
      late_deliveries: "Livraisons tardives",
      staff_shortage: "Pénurie de personnel",
      refused_acceptance: "Refus d'acceptation des composants critiques statiquement",
      missing_installation_parts: "Pièces d'installation manquantes",
      unpaid_invoices: "Factures impayées",
      missing_scaffolding: "Échafaudage manquant",
      missing_formwork: "Coffrage manquant",
      missing_material: "Matériel manquant",
      relocation_of_construction_site_equipment: "Déplacement de l'équipement de chantier",
      missing_cranes: "Grues manquantes",
      missing_pre_prepayment: "Prépaiement manquant",
    },
  },
  process_classes: {
    "0": "Couche de Gravier",
    "1": "Couche de Béton (Benne)",
    "2": "Couche de Béton (Pompe)",
    "3": "Coffrage Plaque de fondation",
    "4": "Coffrage Fondations en Bandes",
    "5": "Coffrage Fondations Ponctuelles",
    "6": "Coffrage Fondation Puits d'ascenseur",
    "7": "Barre d'armature Plaque de fondation",
    "8": "Barre d'armature Fondations en Bandes",
    "9": "Barre d'armature Fondations Ponctuelles",
    "10": "Barre d'armature Fondation Puits d'ascenseur",
    "11": "Bétonnage Plaque de Fondation (Benne)",
    "12": "Bétonnage Plaque de Fondation (Pompe)",
    "13": "Bétonnage Fondations en Bandes (Benne)",
    "14": "Bétonnage Fondations en Bandes (Pompe)",
    "15": "Bétonnage Fondations Ponctuelles (Benne)",
    "16": "Bétonnage Fondations Ponctuelles (Pompe)",
    "17": "Bétonnage Fondation Puits d'ascenseur (Benne)",
    "18": "Bétonnage Fondation Puits d'ascenseur (Pompe)",
    "19": "Isolation Plaque de Fondation",
    "20": "Isolation Fondations en Bandes",
    "21": "Isolation Fondations Ponctuelles",
    "22": "Isolation Fondation Puits d'ascenseur",
    "23": "Éléments de Plafond",
    "24": "Elément de Plafond Préfabriqué",
    "25": "Poutre Préfabriquée",
    "26": "Balcon Préfabriqué",
    "27": "Escaliers Préfabriqués",
    "28": "Coffrage Plafond",
    "29": "Edge Coffrage Plafond",
    "30": "Coffrage sous-poutres",
    "31": "Barre d'armature Plafond",
    "32": "Tubes Plafond",
    "33": "Barre d'armature sous-poutres",
    "34": "Bétonnage Plafond (Benne)",
    "35": "Bétonnage Plafond (Pompe)",
    "36": "Bétonnage sous-poutres (Benne)",
    "37": "Bétonnage sous-poutres (Pompe)",
    "38": "Isolation Plafond",
    "39": "Maçonnerie",
    "40": "Maçonnerie Schéma",
    "41": "Mur Préfabriqués",
    "42": "Precast Colonne",
    "43": "Wet Cell",
    "44": "Coffrage Mur",
    "45": "Coffrage Colonne",
    "46": "Coffrage Parapet",
    "47": "Barre d'armature Mur",
    "48": "Barre d'armature Colonne",
    "49": "Barre d'armature Parapet",
    "50": "Bétonnage Mur (Benne)",
    "51": "Bétonnage Mur (Pompe)",
    "52": "Bétonnage Colonne (Benne)",
    "53": "Bétonnage Colonne (Pompe)",
    "54": "Bétonnage Parapet (Benne)",
    "55": "Bétonnage Parapet (Pompe)",
    "56": "Chevrons de Toit",
    "57": "Isolation du Toit",
    "58": "Tuiles de Toit",
    "59": "Toiture Métallique",
    "60": "Reinforcement Mur Préfabriqué",
    "61": "Bétonnage Mur Préfabriqué (Benne)",
    "62": "Bétonnage Precast Wall (Pompe)",
    "63": "Maçonnerie Parapet",
    "64": "Parapet Préfabriqué",
    "65": "Coffrage Plateforme Escaliers",
    "66": "Barre d'armature Plateforme Escaliers",
    "67": "Bétonnage Plateforme Escaliers (Benne)",
    "68": "Bétonnage Plateforme Escaliers (Pompe)",
    "69": "Assujettissement",
    "70": "Formateurs de Vide Plafond",
    "1000": "Panneaux Mur",
    "1001": "Barres longitudinales (couche inférieure) Mur",
    "1002": "Étriers de cisaillement Mur",
    "1003": "Soudure Mur",
    "1004": "Installation de la clé de cisaillement Mur",
    "1005": "ESS (Structure en acier intégrée) Mur",
    "1006": "Barres lâches Mur",
    "1007": "Coffrage découpé Mur",
    "1008": "Coussinets en plastique de sécurité Mur",
    "1009": "Garde-corps de sécurité Mur",
    "1010": "Installation du dispositif de levage Mur",
    "1011": "Inclinaison + Déplacement Mur",
    "1012": "Installation du mur Dalle supérieure",
    "1013": "Consoles inférieures Dalle supérieure",
    "1014": "Barres longitudinales Dalle",
    "1015": "Entretoises en béton Dalle supérieure",
    "1016": "Panneaux Dalle",
    "1017": "Soudure Dalle",
    "1018": "ESS transversale (Structure en acier intégrée) Dalle",
    "1019": "Attaches ESS pour armatures Dalle supérieure",
    "1020": "Conduits - pré-tension",
    "1021": "ESS longitudinale (Structure en acier intégrée) Dalle",
    "1022": "Barres lâches en U Dalle supérieure",
    "1023": "Consoles externes des panneaux Dalle",
    "1024": "Cages pour arrêt d'eau",
    "1025": "Étriers de cisaillement Dalle",
    "1026": "Conduits (pour équipements électriques) Dalle supérieure",
    "1027": "Retirer le dispositif de levage Dalle supérieure",
    "1028": "Garde-corps de sécurité Dalle",
    "1029": "Coussinets en plastique de sécurité Dalle",
    "1030": "Pieds de départ Dalle de base",
    "1031": "Barres de départ Dalle de base",
    "1032": "Clé de cisaillement mâle Dalle de base",
    "1033": "Processus de glissement Dalle de base",
    "1034": "Glissement (chaussures Dalle de base)",
    "1035": "Consoles inférieures Dalle de base",
    "1036": "Tampon de nettoyage du sol",
    "1037": "Tampon de soudure",
    "1038": "Glissement (Tampon de chaussures)",
    "1039": "Glissement (Tampon d'élément)",
    "1040": "Glissement (Tampon de poussoirs)",
    "1041": "Tampon de levage des corbeaux",
    "1042": "Tampon de cadre en acier",
    "1043": "Fermeture de rideau",
    "1044": "Ouverture de rideau",
    "1045": "Démontage des échafaudages galerie centrale",
    "1046": "Montage des échafaudages",
    "1047": "Tampon de coffrage arrêt d'extrémité",
    "1048": "Tampon de joint d'arrêt en caoutchouc",
    "1049": "Retrait glissement (Tampon de chaussures)",
    "1050": "Retrait des ponts",
    "1051": "Mise en place du coffrage interne",
    "1052": "Pré-coulage des tuyaux",
    "1053": "Feuille pour tuyaux",
    "1054": "Camion de bétonnage",
    "1055": "Pompe de bétonnage",
    "1056": "Nettoyage des relevés de béton",
    "1057": "Nettoyage au jet d'eau",
    "1058": "Membrane pour béton frais",
    "1059": "Retrait du coffrage talon externe dalle supérieure",
    "1060": "Retrait des murs de coffrage arrêt d'extrémité",
    "1061": "Tapis talon externe",
    "1062": "Application de bitume",
    "1063": "Retrait des tours d'échafaudage",
    "1064": "Recul de la poutre de lancement MT1",
    "1065": "Recul de la poutre de lancement galerie centrale",
    "1066": "Recul de la poutre de lancement MT2",
    "1067": "Recul de la poutre de lancement RT1",
    "1068": "Recul de la poutre de lancement RT2",
    "1069": "Installation des poussoirs",
    "1070": "Retrait des poussoirs",
    "1071": "Vérins de soutien SN+1",
    "1072": "Huilage du coffrage",
    "1073": "Cales en béton",
    "1074": "Recul de la poutre de lancement en position centrale",
    "1075": "Installation des ponts S+1",
    "1076": "Garde-corps de sécurité en haut",
    "1077": "Nettoyage des tuyaux/appareils en béton",
    concrete: "Béton",
    prefab: "Préfabriqué",
    reinforce: "Armature",
    support: "Coffrage",
    brickwork: "Maçonnerie",
    sheath: "Revêtement",
    safety: "Sécurité",
    transport: "Transport",
    ground: "Sol",
    foundation: "Fondation",
    ceiling: "Plafond",
    wall: "Mur",
    roof: "Toit",
  },
  issue_report: {
    report_process_issue: "Signaler un problème de processus",
    issue_type: "Type de problème",
    select_issue: "Sélectionner un problème",
    new_process_class: "Nouvelle classe de processus",
    process_mappable: "Mappable",
    non_mappable_process_warning:
      "Tu es sûr ? La classe de processus ne peut pas être affectée à une activité dans le calendrier.",
    new_level: "Nouveau niveau",
    start: "Début",
    end: "Fin",
    intervals_error: "Les intervalles ne doivent pas se chevaucher.",
    issue_description: "Description du problème",
    issue_process_class: "Classe de processus",
    issue_time_interval: "Intervalle de temps",
    issue_level: "Niveau",
    issue_people_count: "Nombre de personnes",
    issue_location: "Emplacement / Section",
    issue_process_not_exist: "Le processus ne devrait pas exister",
    resolve: "Résoudre",
    report_to_oculai: "Impossible de résoudre ce problème ? Signaler à l'équipe oculai",
    process_updated: "Processus mis à jour avec succès !",
    issue_failed_to_resolve: "L'erreur n'a pas pu être corrigée",
    issue_reported: "Demande soumise avec succès à oculai",
    issue_failed_to_report: "Impossible de signaler le problème",
    level_to_mask_mapping_not_applicable:
      "Suite à la modification de l'étage, le processus n'a pas pu être affecté à une activité dans l'échéancier - l'équipe oculai vérifie la modification !",
    update_process_class: "Mettre à jour la classe de processus",
    update_level: "Mettre à jour le niveau",
    update_time_interval: "Mettre à jour les intervalles de temps",
  },
  form: {
    select_option: "Sélectionner l'option",
    update: "Actualiser",
  },
};

export default translations;
