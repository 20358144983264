import { endOfDay, isWithinInterval, startOfDay } from "date-fns";
import { Data } from "@/views/process_table/types";

export const filterColumnDateField = (value: unknown, item: Data, field?: string) => {
  if (!value) {
    return true;
  }

  const [start, end] = value as [Date, Date];

  if (!field) {
    const isValid = !!item.subData?.some((process) =>
      isWithinInterval(process.date as Date, { start: startOfDay(start), end: endOfDay(end) }),
    );

    return isValid;
  }

  const isValid = isWithinInterval(item[field] as Date, {
    start: startOfDay(start),
    end: endOfDay(end),
  });

  return isValid;
};

export const filterProcessIds = (v: unknown, item: Data): boolean => {
  const value = v as string[];
  if (value && value.length === 0) {
    return true;
  }

  return (
    Array.isArray(value) &&
    value.includes(
      item?._id !== undefined && item?._id !== null ? (item._id.toString() as string) : "",
    )
  );
};

export const filterProcessField = (v: unknown, item: Data): boolean => {
  const value = v as Record<"processTypes" | "processes", string[]>;

  if (value.processes && value.processes.length === 0) {
    return true;
  }
  return (
    Array.isArray(value.processes) &&
    value.processes.includes(
      item?.encoded_label !== undefined && item?.encoded_label !== null
        ? (item?.encoded_label.toString() as string)
        : "",
    )
  );
};
