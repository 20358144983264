<template>
  <div v-if="loading" class="flex flex-col items-center justify-center w-full h-screen z-50">
    <svg
      class="animate-spin -ml-1 mr-3 h-10 w-10 mb-4 text-yellow-700"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <h2 class="text-center text-xl font-semibold">{{ $t("general.loading") }}</h2>
    <p class="w-1/3 text-center">{{ $t("general.loading_message") }}</p>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSection",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
