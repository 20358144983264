import { AxiosResponse } from "axios";
import { format } from "date-fns";
import dateService from "../services/dateService";
import { OutagesByRange } from "../types/Camera";
import { apiClient } from "./clients";

const loadOutagesByRange = (
  customerName: string,
  siteId: string,
  startDate: Date,
  endDate: Date,
): Promise<OutagesByRange> =>
  apiClient
    .get<void, AxiosResponse<OutagesByRange<string>>>(
      `/camera/outages-range/${customerName}/${siteId}/${format(startDate, "yyyy-MM-dd")}/${format(
        endDate,
        "yyyy-MM-dd",
      )}`,
    )
    .then((response) =>
      Object.entries(response.data).reduce((acc, [key, value]) => {
        acc[key] = value.map((item) => ({
          ...item,
          start_time: dateService.parseLocalDate(item.start_time),
          end_time: dateService.parseLocalDate(item.end_time),
          tml_start: dateService.parseLocalDate(item.tml_start),
          tml_end: dateService.parseLocalDate(item.tml_end),
        }));
        return acc;
      }, {} as OutagesByRange),
    );

const loadMostRecentCameraImageUrl = (
  customerName: string,
  siteId: string,
  cameraId: string,
): Promise<string> =>
  apiClient
    .get<void, AxiosResponse<{ url: string }>>(
      `/camera/gallery/most-recent-camera-image/${customerName}/${siteId}/${cameraId}`,
    )
    .then((response) => response.data.url);

export default {
  loadOutagesByRange,
  loadMostRecentCameraImageUrl,
};
