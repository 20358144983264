<template>
  <TransitionRoot as="div" :show="!!open">
    <Dialog as="div" class="relative z-40" @close="$emit('closed')">
      <div class="fixed inset-0 bg-gray-500 opacity-50" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-200 sm:duration-200"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-200 sm:duration-200"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <DialogPanel
              :class="['pointer-events-auto flex min-w-[350px]']"
              :style="{
                width: sliderPosition ? `${windowWidth - sliderPosition}px` : '600px',
                maxWidth: maxWidth,
              }"
            >
              <div
                class="w-5 flex justify-center items-center -mr-2.5 z-10 group cursor-[col-resize] relative"
                ref="slider"
              >
                <div class="h-full w-1 transition group-hover:bg-gray-400" />
              </div>
              <div class="flex h-full flex-col flex-1 overflow-y-auto bg-white py-6 shadow-xl">
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="leading-6 text-gray-900 text-sm">
                      <slot name="title" />
                    </DialogTitle>
                    <div class="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        class="rounded-md bg-white text-gray-400 hover:text-yellow outline-none"
                        @click="$emit('closed')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative flex-1 px-4 sm:px-6">
                  <slot name="content" />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { useDraggable, useWindowSize } from "@vueuse/core";
import { ref } from "vue";

const slider = ref<HTMLElement | null>(null);

const { x: sliderPosition } = useDraggable(slider);
const { width: windowWidth } = useWindowSize();

defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  maxWidth: {
    type: String,
    default: "40vw",
  },
});

defineEmits(["closed"]);
</script>
