import { format } from "date-fns";
import * as XLSX from "xlsx";
import durationService from "shared/services/durationService";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import {
  ActualEvent,
  PlanConfig,
  PlannedEvent,
  PlannerItem,
  PlannerItemWithChildren,
} from "shared/types/Plan";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import i18n from "@/i18n";
import projectProgressService from "@/services/projectProgressService";
import textService from "@/services/textService";
import { PlannerItemProgress } from "@/types/Plan";
import { calculateEndDateDeviationWd } from "@/views/planner/planner";

const { t } = i18n.global;

const noColumn = Symbol("no-column");
const emptyValue = "";
const dateFormat = "dd.MM.yyyy";

const allTagTypes: HierarchyType[] = ["building", "level", "section", "component"];

const getFlatPlannerItems = (plannerItems: PlannerItemWithChildren[]) => {
  const flatPlannerItems: PlannerItem[] = [];

  const traverse = (plannerItems: PlannerItemWithChildren[]) => {
    for (const plannerItem of plannerItems) {
      flatPlannerItems.push(plannerItem);
      traverse(plannerItem.children);
    }
  };

  traverse(plannerItems.filter((plannerItem) => !plannerItem.parent_id));

  return flatPlannerItems;
};

const createHeaderRow = (usedTagTypes: Set<HierarchyType>) => [
  t("analytics.planner.progress_excel_export.name"),
  ...allTagTypes.map((type) =>
    usedTagTypes.has(type) ? t(`analytics.processes.${type}`) : noColumn,
  ),
  t("analytics.planner.progress_excel_export.planned_start"),
  t("analytics.planner.progress_excel_export.planned_end"),
  t("analytics.planner.progress_excel_export.actual_start"),
  t("analytics.planner.progress_excel_export.actual_end"),
  t("analytics.planner.progress_excel_export.planned_working_days"),
  t("analytics.planner.progress_excel_export.actual_working_days"),
  t("analytics.planner.progress_excel_export.progress"),
  t("analytics.planner.progress_excel_export.completed"),
  t("analytics.planner.progress_excel_export.deviation_end_date"),
  t("analytics.planner.progress_excel_export.weight"),
];

const getTagOrNoColumn = (
  plannerItem: PlannerItem,
  tagsBySourceId: Record<string, HierarchyTagStore>,
  usedTagTypes: Set<HierarchyType>,
  tagType: HierarchyType,
) => {
  if (!usedTagTypes.has(tagType)) {
    return noColumn;
  }
  return tagsBySourceId[`${tagType}_${plannerItem.source_id}`]?.name || emptyValue;
};

const formatDateOrEmpty = (date: Date | null | undefined) =>
  date ? format(date, dateFormat) : emptyValue;

const calculateWorkingDays = (
  projectDurationSettings: ProjectDurationSettings,
  event: PlannedEvent | ActualEvent | undefined,
) => {
  if (!event || !event.end) {
    return emptyValue;
  }
  return durationService.calculateDuration(projectDurationSettings.settings, event.start, event.end)
    .workingDays;
};

const toFixedPrecision = (number: number | null) =>
  number !== null ? parseFloat(number.toFixed(3)) : emptyValue;

const calculateProgressPercentage = (plannerItemProgress: PlannerItemProgress) => {
  const progress = projectProgressService.calculatePercentage(
    plannerItemProgress.finished_working_days,
    plannerItemProgress.total_working_days,
  );
  return toFixedPrecision(progress);
};

const calculateCompleted = (plannerItemProgress: PlannerItemProgress) => {
  const completed =
    plannerItemProgress.total_working_days === plannerItemProgress.finished_working_days &&
    plannerItemProgress.total_working_days !== 0;
  return completed ? "TRUE" : "FALSE";
};

const getProgressWeight = (plannerItemProgress: PlannerItemProgress) => {
  if (plannerItemProgress.weight === null) {
    return emptyValue;
  }
  return toFixedPrecision(plannerItemProgress.weight);
};

const calculateDeviationEndDate = (
  projectDurationSettings: ProjectDurationSettings,
  plannedEvent: PlannedEvent | undefined,
  actualEvent: ActualEvent | undefined,
) => calculateEndDateDeviationWd(plannedEvent, actualEvent, projectDurationSettings) ?? emptyValue;

const createPlannerItemRow = (
  plannerItem: PlannerItem,
  tagsByTypeAndSourceId: Record<string, HierarchyTagStore>,
  usedTagTypes: Set<HierarchyType>,
  plannedEventsByPlannerId: Record<string, PlannedEvent>,
  actualEventsBySourceId: Record<string, ActualEvent>,
  projectDurationSettings: ProjectDurationSettings,
  plannerItemProgressById: Record<string, PlannerItemProgress>,
) => {
  const plannedEvent = plannedEventsByPlannerId[plannerItem._id];
  const actualEvent = actualEventsBySourceId[plannerItem.source_id];
  const plannerItemProgress = plannerItemProgressById[plannerItem._id];
  return [
    plannerItem.name,
    ...allTagTypes.map((type) =>
      getTagOrNoColumn(plannerItem, tagsByTypeAndSourceId, usedTagTypes, type),
    ),
    formatDateOrEmpty(plannedEvent?.start),
    formatDateOrEmpty(plannedEvent?.end),
    formatDateOrEmpty(actualEvent?.start),
    formatDateOrEmpty(actualEvent?.end),
    calculateWorkingDays(projectDurationSettings, plannedEvent),
    calculateWorkingDays(projectDurationSettings, actualEvent),
    calculateProgressPercentage(plannerItemProgress),
    calculateCompleted(plannerItemProgress),
    calculateDeviationEndDate(projectDurationSettings, plannedEvent, actualEvent),
    getProgressWeight(plannerItemProgress),
  ];
};

const createExcelData = (
  plannerItemsWithChildren: PlannerItemWithChildren[],
  plannerItemProgresses: PlannerItemProgress[],
  hierarchyTags: HierarchyTagStore[],
  planConfig: PlanConfig,
  projectDurationSettings: ProjectDurationSettings,
) => {
  const tagsBySourceId = hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      if (!acc[sourceId]) {
        acc[sourceId] = [];
      }
      acc[sourceId].push(tag);
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore[]>);

  const tagsByTypeAndSourceId = hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      acc[`${tag.type}_${sourceId}`] = tag;
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  const actualEventsBySourceId = planConfig.actual_events.reduce((acc, actualEvent) => {
    acc[actualEvent.source_id] = actualEvent;
    return acc;
  }, {} as Record<string, ActualEvent>);

  const plannedEventsByPlannerId = planConfig.planned_events.reduce((acc, plannedEvent) => {
    acc[plannedEvent.planner_item_id] = plannedEvent;
    return acc;
  }, {} as Record<string, PlannedEvent>);

  const plannerItemProgressById = plannerItemProgresses.reduce((acc, plannerItemProgress) => {
    acc[plannerItemProgress._id] = plannerItemProgress;
    return acc;
  }, {} as Record<string, PlannerItemProgress>);

  const flatPlannerItems = getFlatPlannerItems(plannerItemsWithChildren);
  const usedTagTypes = new Set(
    flatPlannerItems
      .flatMap((plannerItem) => tagsBySourceId[plannerItem.source_id] || [])
      .map((tag) => tag.type),
  );

  return [
    createHeaderRow(usedTagTypes),
    ...flatPlannerItems.map((plannerItem) =>
      createPlannerItemRow(
        plannerItem,
        tagsByTypeAndSourceId,
        usedTagTypes,
        plannedEventsByPlannerId,
        actualEventsBySourceId,
        projectDurationSettings,
        plannerItemProgressById,
      ),
    ),
  ].map((row) => row.filter((item) => item !== noColumn));
};

const exportProgressToExcel = (
  plannerItemsWithChildren: PlannerItemWithChildren[],
  plannerItemProgresses: PlannerItemProgress[],
  hierarchyTags: HierarchyTagStore[],
  planConfig: PlanConfig,
  projectDurationSettings: ProjectDurationSettings,
) => {
  const excelData = createExcelData(
    plannerItemsWithChildren,
    plannerItemProgresses,
    hierarchyTags,
    planConfig,
    projectDurationSettings,
  );
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(excelData);
  XLSX.utils.book_append_sheet(workbook, worksheet);
  XLSX.writeFile(
    workbook,
    `oculai_${textService.toNormalizedFileName(
      t("analytics.reports.query_value_metrics.project_progress"),
    )}.xlsx`,
  );
};

export default exportProgressToExcel;
