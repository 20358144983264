<template>
  <div class="flex flex-col gap-3 sm:rounded-lg">
    <h1 class="text-xl font-bold text-gray-900 px-1">
      {{ t("admin.durations_project_settings.working_hours_header") }}
    </h1>
    <Form
      @submit="handleSubmit"
      :initialValues="initialValues"
      :validationSchema="schema"
      class="flex flex-col gap-3"
      v-slot="{ isSubmitting, meta, values, setFieldValue, errors }"
      ref="formRef"
    >
      <div class="flex flex-col gap-8 settingsCard">
        <table class="hidden lg:table">
          <thead>
            <tr>
              <th class="p-1"></th>
              <th class="p-1 timeColumn" v-for="i in 7" :key="i">
                {{ t(`calendar.week_days.${i}.short`) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-0.5 pr-3">
                {{ t("admin.durations_project_settings.start") }}
              </td>
              <td class="p-0.5 timeColumn" v-for="day in days" :key="day">
                <input
                  type="time"
                  class="oai-inputs"
                  :class="errors[day] ? ['border-red-900 focus:border-red-900 text-red-900'] : []"
                  :value="values[day].start_time"
                  @change="
                    setFieldValue(day, {
                      ...values[day],
                      start_time: ($event.target as HTMLInputElement).value || null,
                      breaks:
                        ($event.target as HTMLInputElement).value || values[day].end_time
                          ? values[day].breaks
                          : null,
                    })
                  "
                />
              </td>
            </tr>
            <tr>
              <td class="p-0.5 pr-3">
                {{ t("admin.durations_project_settings.end") }}
              </td>
              <td class="p-0.5 timeColumn" v-for="day in days" :key="day">
                <input
                  type="time"
                  class="oai-inputs"
                  :class="errors[day] ? ['border-red-900 focus:border-red-900 text-red-900'] : []"
                  :value="values[day].end_time"
                  @change="
                    setFieldValue(day, {
                      ...values[day],
                      end_time: ($event.target as HTMLInputElement).value || null,
                      breaks:
                        ($event.target as HTMLInputElement).value || values[day].start_time
                          ? values[day].breaks
                          : null,
                    })
                  "
                />
              </td>
            </tr>
            <tr>
              <td class="p-0.5 pr-3 whitespace-nowrap">
                {{ t("admin.durations_project_settings.breaks") }}
              </td>
              <td class="p-0.5 timeColumn" v-for="day in days" :key="day">
                <input
                  :disabled="!values[day].start_time && !values[day].end_time"
                  type="number"
                  class="oai-inputs disabled:bg-gray-100"
                  :class="errors[day] ? ['border-red-900 focus:border-red-900 text-red-900'] : []"
                  :value="values[day].breaks"
                  @change="
                    setFieldValue(day, {
                      ...values[day],
                      breaks:
                        parseFloat(($event.target as HTMLInputElement).value.replace(',', '')) ||
                        null,
                    })
                  "
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td
                v-for="day in days"
                :key="day"
                class="p-1 text-xs align-top"
                :class="errors[day] ? '' : 'text-center'"
              >
                <span class="text-red-900" v-if="errors[day]">{{ errors[day] }} </span>
                <span v-else>
                  {{
                    `${formatNumber(durationService.calculateWorkingDay(values[day]))} ${t(
                      "admin.durations_project_settings.working_day_label",
                      {
                        count: durationService.calculateWorkingDay(values[day]),
                      },
                    )}`
                  }}
                  ({{ formatNumber(durationService.calculateWorkingHours(values[day])) }}
                  {{ $t("admin.durations_project_settings.hours") }})
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="lg:hidden">
          <div class="grid grid-cols-8 justify-center items-center">
            <div class="p-1 text-xs"></div>
            <div class="col-span-2 p-1">{{ t("admin.durations_project_settings.start") }}</div>
            <div class="col-span-2 p-1">{{ t("admin.durations_project_settings.end") }}</div>
            <div class="col-span-2 p-1">{{ t("admin.durations_project_settings.breaks") }}</div>
            <div class="p-1"></div>
          </div>
          <div class="my-2" v-for="(day, index) in days" :key="day">
            <div class="grid grid-cols-8 gap-2 justify-center items-center">
              <div class="p-1 text-xs">
                {{ t(`calendar.week_days.${index + 1}.short`).charAt(0) }}
              </div>
              <div class="col-span-2">
                <input
                  :key="day"
                  type="time"
                  class="oai-inputs"
                  :class="errors[day] ? ['border-red-900 focus:border-red-900 text-red-900'] : []"
                  :value="values[day].start_time"
                  @change="
                    setFieldValue(day, {
                      ...values[day],
                      start_time: ($event.target as HTMLInputElement).value || null,
                      breaks:
                        ($event.target as HTMLInputElement).value || values[day].end_time
                          ? values[day].breaks
                          : null,
                    })
                  "
                />
              </div>
              <div class="col-span-2">
                <input
                  :key="day"
                  type="time"
                  class="oai-inputs"
                  :class="errors[day] ? ['border-red-900 focus:border-red-900 text-red-900'] : []"
                  :value="values[day].end_time"
                  @change="
                    setFieldValue(day, {
                      ...values[day],
                      end_time: ($event.target as HTMLInputElement).value || null,
                      breaks:
                        ($event.target as HTMLInputElement).value || values[day].start_time
                          ? values[day].breaks
                          : null,
                    })
                  "
                />
              </div>
              <div class="col-span-2">
                <input
                  :disabled="!values[day].start_time && !values[day].end_time"
                  type="number"
                  class="oai-inputs disabled:bg-gray-100"
                  :class="errors[day] ? ['border-red-900 focus:border-red-900 text-red-900'] : []"
                  :value="values[day].breaks"
                  @change="
                    setFieldValue(day, {
                      ...values[day],
                      breaks:
                        parseFloat(($event.target as HTMLInputElement).value.replace(',', '')) ||
                        null,
                    })
                  "
                />
              </div>
              <div
                :key="day"
                class="p-1 text-xs align-top"
                :class="errors[day] ? '' : 'text-center'"
              >
                <span v-if="!errors[day]">
                  {{ `${formatNumber(durationService.calculateWorkingDay(values[day]))}` }}
                </span>
              </div>
              <span class="text-red-900 col-span-8 text-xs" v-if="errors[day]"
                >{{ errors[day] }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between flex-wrap gap-2 text-sm">
        <div>
          <div class="flex items-center gap-1">
            <span>{{
              t("admin.durations_project_settings.total_working_days_label", {
                total: formatNumber(calculateTotalDays(values as FormValues)),
              })
            }}</span>
            <OaiTooltip position="right">
              <InformationCircleIcon class="h-5 text-green" />
              <template #tooltip>
                <div class="text-xs">
                  <span>{{
                    t("admin.durations_project_settings.working_days_calculation_tooltip_header")
                  }}</span>
                  <ul class="ml-5 list-disc">
                    <li>
                      {{
                        t("admin.durations_project_settings.working_days_calculation_tooltip_item1")
                      }}
                    </li>
                    <li>
                      {{
                        t("admin.durations_project_settings.working_days_calculation_tooltip_item2")
                      }}
                    </li>
                    <li>
                      {{
                        t("admin.durations_project_settings.working_days_calculation_tooltip_item3")
                      }}
                    </li>
                  </ul>
                </div>
              </template>
            </OaiTooltip>
          </div>
          <div>
            {{
              t("admin.durations_project_settings.total_working_hours_label", {
                total: formatNumber(calculateTotalHours(values as FormValues)),
              })
            }}
          </div>
        </div>
        <MainButton
          :label="$t('buttons.save')"
          type="submit"
          color="yellow"
          class="sm:mr-0"
          :isDisabled="isSubmitting || !meta.dirty"
        >
          <LoadingSpinner
            v-if="isSubmitting && meta.valid"
            size="w-5 h-5"
            color="white"
            class="mr-2"
          />
        </MainButton>
      </div>
      <div v-if="isUpdateError" class="text-red-900 text-center text-sm md:text-right">
        {{ t("admin.durations_project_settings.save_error_message") }}
      </div>
    </Form>
  </div>
</template>

<script lang="ts" setup>
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { differenceInMinutes, parse } from "date-fns";
import { Form, SubmissionHandler } from "vee-validate";
import { computed, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useUpdateProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import durationService from "shared/services/durationService";
import { DayInterval, ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import MainButton from "@/components/other/MainButton.vue";

const { t, locale } = useI18n();
const localeValue = locale.value;

const formRef = ref<typeof Form | null>(null);

type FormValues = ProjectDurationSettings["working_hours"];

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const props = defineProps({
  projectDurationSettings: {
    type: Object as PropType<ProjectDurationSettings>,
    required: true,
  },
});

defineExpose({ form: formRef });

const schema = yup.object({
  ...days.reduce((acc, day) => {
    acc[day] = yup
      .object({
        start_time: yup.string().required().nullable(),
        end_time: yup.string().required().nullable(),
        breaks: yup.number().required().nullable(),
      })
      .test(
        "is-start-more-than-end",
        t("admin.durations_project_settings.validation_start_more_than_end"),
        (values) => {
          if (values.start_time && values.end_time) {
            return values.start_time < values.end_time;
          }
          return true;
        },
      )
      .test(
        "is-only-one-empty",
        t("admin.durations_project_settings.validation_start_or_end_empty"),
        (values) =>
          (!!values.start_time && !!values.end_time) ||
          (!values.start_time && !values.end_time && !values.breaks),
      )
      .test(
        "is-breaks-valid",
        t("admin.durations_project_settings.validation_working_hours_breaks"),
        (values) => {
          if (!values.start_time || !values.end_time || !values.breaks) {
            return true;
          }
          const referenceDate = new Date();
          const start = parse(values.start_time, "HH:mm", referenceDate);
          const end = parse(values.end_time, "HH:mm", referenceDate);
          const allMinutes = differenceInMinutes(end, start);
          return values.breaks > 0 && values.breaks * 60 < allMinutes;
        },
      );
    return acc;
  }, {} as Record<string, ObjectSchema<DayInterval>>),
});

const initialValues = computed(() => props.projectDurationSettings.working_hours);

const { updateProjectDurationSettings, isUpdateError } = useUpdateProjectDurationSettings();
const trackEvent = useTrackEvent();
const handleSubmit: SubmissionHandler = (genericObject, context) => {
  trackEvent("settings_working-hours-save");
  return updateProjectDurationSettings({
    working_hours: genericObject as FormValues,
  })
    .then(() => context.resetForm({ values: initialValues.value }))
    .catch(() => {
      // have to catch the error here, otherwise it's propagated as an unhandled error
      // it's already logged by vue-query
      return undefined;
    });
};

const calculateTotalDays = (values: FormValues) =>
  Object.values(values).reduce((acc, day) => acc + durationService.calculateWorkingDay(day), 0);

const calculateTotalHours = (values: FormValues) =>
  Object.values(values).reduce((acc, day) => acc + durationService.calculateWorkingHours(day), 0);

const formatNumber = (value: number) =>
  value.toLocaleString(localeValue, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });
</script>
