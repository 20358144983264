<template>
  <div>
    <div>
      <label class="block text-sm font-medium leading-6 text-gray-900">{{
        t("unit_values.column_modal.type_field")
      }}</label>
      <div :class="['relative mt-2 sm:mt-0 rounded-md shadow-sm']">
        <Combobox as="div" :modelValue="value" @update:modelValue="setValue($event)">
          <div class="relative">
            <ComboboxInput
              class="relative rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500 sm:text-sm text-xs sm:leading-6 w-full border-none"
              @click="handleComboboxInputClick"
              :displayValue="
                ((currentValue: UnitValueType) =>
                  (currentValue
                    ? `${t(`unit_values.types.${currentValue}.label`)} [${t(
                        `unit_values.types.${currentValue}.unit`,
                      )}]`
                    : '')) as (typeof ComboboxInput)['displayValue']
              "
              @change="query = $event.target.value"
              aria-autocomplete="one-time-code"
              autocomplete="one-time-code"
            />
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
            >
              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" />
            </span>
            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ComboboxOptions
                class="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-yellow-500/5 focus:outline-none text-xs sm:text-sm w-full"
                style="max-height: 230px"
              >
                <ColumnModalTypeFieldListOption
                  :existingTypes="existingTypes"
                  v-for="typeWithChildren in unitValueTypesWithChildren"
                  :typeWithChildren="typeWithChildren"
                  :key="typeWithChildren.type"
                  :parent="null"
                  :parentChildren="unitValueTypesWithChildren"
                />
                <div
                  v-if="unitValueTypesWithChildren.length === 0"
                  class="px-3 py-2 select-none text-gray-900"
                >
                  {{ t("unit_values.column_modal.no_types_found") }}
                </div>
              </ComboboxOptions>
            </transition>
          </div>
        </Combobox>
      </div>
    </div>
    <div
      v-if="errorMessage"
      class="text-xs mt-1 text-red-600 sm:col-span-2 sm:items-end sm:col-start-2"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Combobox, ComboboxInput, ComboboxOptions } from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/24/solid";
import FuzzySearch from "fuzzy-search";
import { useField } from "vee-validate";
import { computed, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import textService from "@/services/textService";
import { UnitValueType, UnitValueTypeWithChildren } from "@/types/UnitValue";
import ColumnModalTypeFieldListOption from "@/views/unit_values/components/ColumnModalTypeFieldListOption.vue";
import {
  rootUnitValueTypes,
  directUnitValueChildTypesByType,
} from "@/views/unit_values/services/unitValueTypes";

const { t } = useI18n();
const query = ref("");

defineProps({
  existingTypes: {
    type: Array as PropType<string[]>,
    required: true,
  },
});

const { errorMessage, value, setValue } = useField<UnitValueType | null>("type");

const normalizedQueryItems = computed(() =>
  textService
    .normalize(query.value)
    .toLowerCase()
    .split(" ")
    .map((item) => item.trim())
    .filter((item) => item),
);

const createLabel = (type: UnitValueType) =>
  `${t(`unit_values.types.${type}.label`)} [${t(`unit_values.types.${type}.unit`)}]`;

const filterByQueryAndFillMatchType = (typeWithChildren: UnitValueTypeWithChildren[]) => {
  if (value.value && query.value === createLabel(value.value)) {
    return typeWithChildren;
  }
  return normalizedQueryItems.value.reduce((acc, search) => {
    const searcher = new FuzzySearch(acc, ["normalizedLabel"], {
      caseSensitive: false,
      sort: false,
    });
    const matchingTypesWithChildren = searcher.search(search);
    const result = acc.filter(
      (typeWithChildren) =>
        matchingTypesWithChildren.includes(typeWithChildren) ||
        typeWithChildren.children.length > 0,
    );
    result.forEach((typeWithChildren) => {
      if (typeWithChildren.matchType !== "children") {
        typeWithChildren.matchType = matchingTypesWithChildren.includes(typeWithChildren)
          ? "label"
          : "children";
      }
    });
    return result;
  }, typeWithChildren);
};

const createUnitValueTypesWithChildren = (
  unitValueTypes: UnitValueType[],
): UnitValueTypeWithChildren[] => {
  const typesWithLabel = unitValueTypes.map((type) => {
    const label = createLabel(type);
    return {
      type,
      label,
      normalizedLabel: textService.normalize(label),
      children: createUnitValueTypesWithChildren(directUnitValueChildTypesByType[type]),
    };
  });
  typesWithLabel.sort((a, b) => a.label.localeCompare(b.label));
  return filterByQueryAndFillMatchType(typesWithLabel);
};

const unitValueTypesWithChildren = computed(() =>
  createUnitValueTypesWithChildren(rootUnitValueTypes),
);

const handleComboboxInputClick = (event: PointerEvent) => {
  const inputElement = event.target as HTMLInputElement;
  inputElement.dispatchEvent(new Event("input"));
};
</script>
