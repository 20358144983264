import {
  CriticalPath,
  CriticalPathEx,
  CriticalPathNode,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { ActualEvent, PlanConfig, PlannedEvent, PlannerItem } from "shared/types/Plan";

type Context = {
  tagsById: Record<string, HierarchyTagStore>;
  tagsByTypeAndSourceId: Record<string, HierarchyTagStore>;
  actualEventsBySourceId: Record<string, ActualEvent>;
  actualEventsByFullId: Record<string, ActualEvent[]>;
  plannedEventsBySourceId: Record<string, PlannedEvent>;
};

const getNodeName = (node: CriticalPathNode, context: Context) =>
  context.tagsById[node.section_id]?.name || "unknown";

const getNodeFullName = (node: CriticalPathNode, context: Context) =>
  [node.building_id, node.level_id, node.section_id]
    .filter((tagId) => tagId)
    .map((tagId) => context.tagsById[tagId as string]?.name)
    .filter((name) => name)
    .join(", ") || "unknown";

const getNodeFullId = (node: Pick<CriticalPathNode, "building_id" | "level_id" | "section_id">) =>
  `${node.building_id}_${node.level_id}_${node.section_id}`;

const getSourceIds = (node: CriticalPathNode, context: Context) => {
  const { tagsById } = context;
  const buildingTag = node.building_id ? tagsById[node.building_id] : null;
  const levelTag = tagsById[node.level_id];
  const sectionTag = tagsById[node.section_id];

  if (!levelTag || !sectionTag) {
    return [];
  }

  const buildingSourceIds = new Set(buildingTag?.source_ids || []);
  const levelSourceIds = new Set(levelTag.source_ids);
  return sectionTag.source_ids.filter(
    (sourceId) =>
      levelSourceIds.has(sourceId) &&
      (buildingSourceIds.size === 0 || buildingSourceIds.has(sourceId)),
  );
};

const getComponentSourceIds = (node: CriticalPathNode, context: Context) =>
  getSourceIds(node, context).filter(
    (sourceId) => context.tagsByTypeAndSourceId[`component_${sourceId}`],
  );

export const getComponentTags = (
  node: CriticalPathNode,
  componentSourceIds: string[],
  context: Context,
) => {
  const tags = componentSourceIds
    .map((sourceId) => context.tagsByTypeAndSourceId[`component_${sourceId}`])
    .filter((tag) => tag) as HierarchyTagStore[];
  tags.sort((a, b) => a.number - b.number);
  return tags;
};

const getLevelSplitsForNode = (
  node: CriticalPathNode,
  componentSourceIds: string[],
  context: Context,
) => {
  const { tagsById } = context;
  const componentTags = getComponentTags(node, componentSourceIds, context);
  const componentEncodedLabels = new Set(componentTags.flatMap((tag) => tag.attached_processes));

  const levelTag = tagsById[node.level_id];

  if (!levelTag) {
    return [];
  }

  const splits = levelTag.splits || [];
  return splits.filter((split) =>
    split.processes.some((encodedLabel) => componentEncodedLabels.has(encodedLabel)),
  );
};

const getActualEventsByFullId = (planConfig: PlanConfig, hierarchyTags: HierarchyTagStore[]) => {
  const tagsByTypeAndSourceId = hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      acc[`${tag.type}_${sourceId}`] = tag;
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  return planConfig.actual_events.reduce((acc, actualEvent) => {
    const buildingId = tagsByTypeAndSourceId[`building_${actualEvent.source_id}`]?._id || null;
    const levelId = tagsByTypeAndSourceId[`level_${actualEvent.source_id}`]?._id;
    const sectionId = tagsByTypeAndSourceId[`section_${actualEvent.source_id}`]?._id;
    if (levelId && sectionId) {
      const fullId = getNodeFullId({
        building_id: buildingId,
        level_id: levelId,
        section_id: sectionId,
      });
      if (!acc[fullId]) {
        acc[fullId] = [];
      }
      acc[fullId].push(actualEvent);
    }
    return acc;
  }, {} as Record<string, ActualEvent[]>);
};

const getState = (fullId: string, context: Context): CriticalPathNodeEx["state"] => {
  const actualEvents = context.actualEventsByFullId[fullId] || [];
  if (actualEvents.length > 0) {
    return actualEvents.every((actualEvent) => actualEvent.end) ? "finished" : "in_progress";
  }
  return "not_started";
};

const getSubState = (fullId: string, context: Context): CriticalPathNodeEx["sub_state"] => {
  const actualEvents = context.actualEventsByFullId[fullId] || [];
  if (actualEvents.length > 0 && actualEvents.some((actualEvent) => actualEvent.end)) {
    return "at_least_one_part_finished";
  }
  return null;
};

const createCriticalPathNodeEx = (node: CriticalPathNode, context: Context): CriticalPathNodeEx => {
  const fullId = getNodeFullId(node);
  const componentSourceIds = getComponentSourceIds(node, context);
  return {
    ...node,
    name: getNodeName(node, context),
    full_name: getNodeFullName(node, context),
    full_id: fullId,
    component_source_ids: componentSourceIds,
    level_splits: getLevelSplitsForNode(node, componentSourceIds, context),
    state: getState(fullId, context),
    sub_state: getSubState(fullId, context),
  };
};

const createContext = (planConfig: PlanConfig, hierarchyTags: HierarchyTagStore[]): Context => {
  const tagsById = hierarchyTags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  const tagsByTypeAndSourceId = hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      acc[`${tag.type}_${sourceId}`] = tag;
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  const actualEventsBySourceId = planConfig.actual_events.reduce((acc, actualEvent) => {
    acc[actualEvent.source_id] = actualEvent;
    return acc;
  }, {} as Record<string, ActualEvent>);

  const actualEventsByFullId = getActualEventsByFullId(planConfig, hierarchyTags);

  const plannerItemsById = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem>);

  const plannedEventsBySourceId = planConfig.planned_events.reduce((acc, plannedEvent) => {
    const plannerItem = plannerItemsById[plannedEvent.planner_item_id];
    acc[plannerItem.source_id] = plannedEvent;
    return acc;
  }, {} as Record<string, PlannedEvent>);

  return {
    tagsById,
    tagsByTypeAndSourceId,
    actualEventsBySourceId,
    actualEventsByFullId,
    plannedEventsBySourceId,
  };
};

export const createCriticalPathEx = (
  criticalPath: CriticalPath,
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
): CriticalPathEx => {
  const context = createContext(planConfig, hierarchyTags);
  return {
    ...criticalPath,
    nodes: criticalPath.nodes.map((node) => createCriticalPathNodeEx(node, context)),
  };
};
