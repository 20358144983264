<template>
  <BasicMenu :position="menuPosition">
    <template #icon>
      <slot name="icon">
        <PlusCircleIcon
          class="text-yellow-300 cursor-pointer hover:text-yellow-500 transition-colors w-10 h-10"
        />
      </slot>
    </template>
    <template #content="{ item }">
      <component
        :is="item"
        v-slot="{ active }"
        @click="plotType.enabled && emit('createReport', plotType.type)"
        v-for="plotType in plotTypes"
        :key="plotType.type"
      >
        <span
          :class="[
            active && plotType.enabled
              ? 'bg-gray-100 text-yellow-600 cursor-pointer '
              : 'text-gray-700',
            'flex items-center px-4 py-2',
          ]"
        >
          {{ t(`analytics.reports.${plotType.type}_plot`) }}
          <LockClosedIcon v-if="!plotType.enabled" class="h-4 w-4 ml-2" />
        </span>
      </component>
    </template>
  </BasicMenu>
</template>

<script setup lang="ts">
import { LockClosedIcon, PlusCircleIcon } from "@heroicons/vue/24/outline";
import { useI18n } from "vue-i18n";
import BasicMenu from "@/components/other/BasicMenu.vue";
import { ReportPlotType } from "@/types/Report";
import { usePlotTypes } from "@/views/reports/composables";

withDefaults(defineProps<{ menuPosition?: "left" | "right" | "center" }>(), {
  menuPosition: "center",
});
const emit = defineEmits<{
  (eventName: "createReport", type: ReportPlotType): void;
}>();

const { t } = useI18n();

const plotTypes = usePlotTypes();
</script>
