<template>
  <BaseWidget :isLoading="isLogoUrlFetching" @trackInteraction="emit('trackInteraction')">
    <div class="h-full flex items-center justify-center p-5 text-center">
      <img :src="logoUrl" class="w-full h-full object-contain" alt="logo" v-if="logoUrl" />
      <div v-else class="text-md text-gray-600">
        {{ t("admin.general_project_settings.no_logo_available") }}
      </div>
    </div>
  </BaseWidget>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useLogoUrl } from "@/composables/project";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";

const emit = defineEmits<{ (eventName: "trackInteraction"): void }>();

const { t } = useI18n();
const { logoUrl, isLogoUrlFetching } = useLogoUrl();
</script>
