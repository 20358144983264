<template>
  <div
    type="button"
    @click="open = !open"
    id="project-button-div"
    :class="[
      'group w-full flex items-center px-4 py-2 text-sm font-medium text-gray-400 justify-between gap-2',
      props.collapsed && 'justify-center',
      open && 'bg-oaiGray-400 pb-0 text-yellow-400',
      item?.current && 'text-yellow-400 bg-oaiGray-400',
    ]"
  >
    <div class="flex items-center gap-3">
      <component :is="item?.icon" id="project-button-icon-main" class="h-6 w-6"></component>
      <div class="pr-2" v-if="!props.collapsed">
        {{ item?.name }}
      </div>
    </div>
    <ChevronLeftIcon
      v-if="!collapsed"
      id="project-button-icon-dropdown"
      :class="['h-5 w-5', open && '-rotate-90']"
      aria-hidden="true"
    />
  </div>
  <div v-if="open" :class="['pl-5', open && 'bg-oaiGray-400']">
    <div
      v-for="option in item?.options"
      :key="option.name"
      class="group group-hover:text-yellow-400"
    >
      <router-link
        v-if="option?.route"
        :to="option.route"
        :class="[
          option.current ? 'bg-oaiGray-400 text-yellow-400' : 'text-gray-400',
          'flex gap-2 items-center py-2 text-sm group-hover:text-yellow-400',
          props.collapsed && 'justify-center',
        ]"
      >
        <span v-if="!props.collapsed" class="truncate">
          {{ option.name }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChevronLeftIcon } from "@heroicons/vue/24/solid";
import { PropType, ref } from "vue";
import { MenuEntry } from "@/types/Sidebar";

const open = ref(false);

const props = defineProps({
  collapsed: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  item: {
    type: Object as PropType<MenuEntry>,
  },
});
</script>
