import { addDays, eachDayOfInterval, getDay, intervalToDuration, parse } from "date-fns";
import {
  DurationSettings,
  TimeInterval,
  DateInterval,
  WeekDayTimeIntervals,
  WorkingDaysAndHoursMap,
} from "../types/ProjectDurationSettings";

const calculateWorkingHours = (timeInterval: TimeInterval) => {
  if (!timeInterval.start_time || !timeInterval.end_time) {
    return 0;
  }
  const referenceDate = new Date();
  const interval = intervalToDuration({
    start: parse(timeInterval.start_time, "HH:mm", referenceDate),
    end: parse(timeInterval.end_time, "HH:mm", referenceDate),
  });

  const hours = interval?.hours || 0;
  const minutes = interval?.minutes || 0;

  return hours + minutes / 60 - (timeInterval.breaks || 0);
};

const calculateWorkingDayFromWorkingHours = (workingHours: number) => {
  if (workingHours === 0) {
    return 0;
  }
  return workingHours < 8 ? 0.5 : 1;
};

const calculateWorkingDay = (timeInterval: TimeInterval) => {
  const workingHours = calculateWorkingHours(timeInterval);
  return calculateWorkingDayFromWorkingHours(workingHours);
};

const calculateWorkingDayAndHour = (day: TimeInterval) => {
  const workingHours = calculateWorkingHours(day);
  const workingDays = calculateWorkingDayFromWorkingHours(workingHours);
  return { workingHours, workingDays, startTime: day.start_time, endTime: day.end_time };
};

const calculateWorkingDaysAndHoursMap = (workingHours: WeekDayTimeIntervals) =>
  [
    calculateWorkingDayAndHour(workingHours.Sun),
    calculateWorkingDayAndHour(workingHours.Mon),
    calculateWorkingDayAndHour(workingHours.Tue),
    calculateWorkingDayAndHour(workingHours.Wed),
    calculateWorkingDayAndHour(workingHours.Thu),
    calculateWorkingDayAndHour(workingHours.Fri),
    calculateWorkingDayAndHour(workingHours.Sat),
  ] as WorkingDaysAndHoursMap;

const mergeDateIntervals = (dateIntervals: DateInterval[]) => {
  if (dateIntervals.length <= 1) {
    return dateIntervals;
  }

  const mergedDateIntervals: DateInterval[] = [
    {
      start_date: dateIntervals[0].start_date,
      end_date: dateIntervals[0].end_date,
    },
  ];

  for (let i = 1; i < dateIntervals.length; i++) {
    const currentDateInterval = dateIntervals[i];
    const lastMergedDateInterval = mergedDateIntervals[mergedDateIntervals.length - 1];

    if (currentDateInterval.start_date <= lastMergedDateInterval.end_date) {
      lastMergedDateInterval.end_date =
        lastMergedDateInterval.end_date > currentDateInterval.end_date
          ? lastMergedDateInterval.end_date
          : currentDateInterval.end_date;
    } else {
      mergedDateIntervals.push({
        start_date: currentDateInterval.start_date,
        end_date: currentDateInterval.end_date,
      });
    }
  }

  return mergedDateIntervals;
};

const calculateSettings = (
  workingHours: WeekDayTimeIntervals,
  dateIntervals: DateInterval[],
): DurationSettings => {
  const workingDaysAndHoursMap = calculateWorkingDaysAndHoursMap(workingHours);
  const mergedNonWorkingDays = mergeDateIntervals(dateIntervals);
  return {
    workingDaysAndHoursMap,
    nonWorkingDays: mergedNonWorkingDays,
  };
};

const calculateDurationForInterval = (
  workingDaysAndHoursMap: WorkingDaysAndHoursMap,
  start: Date,
  end: Date,
) => {
  const days = eachDayOfInterval({ start, end });
  return days
    .filter((day) => day.getTime() !== end.getTime())
    .reduce(
      (acc, day) => {
        const duration = workingDaysAndHoursMap[getDay(day)];
        acc.workingDays += duration.workingDays;
        acc.workingHours += duration.workingHours;
        return acc;
      },
      { workingDays: 0, workingHours: 0 },
    );
};

const calculateIntervals = (dateIntervals: DateInterval[], start: Date, end: Date) => {
  const intervals: DateInterval[] = [];
  for (const dateInterval of dateIntervals) {
    const dateIntervalStartDate = dateInterval.start_date;
    const dateIntervalEndDate = addDays(dateInterval.end_date, 1);
    if (dateIntervalStartDate < start && dateIntervalEndDate < start) {
      continue;
    }
    if (dateIntervalStartDate > end) {
      break;
    }
    if (dateIntervalStartDate > start) {
      intervals.push({ start_date: start, end_date: dateIntervalStartDate });
    }
    start = dateIntervalEndDate;
  }
  if (start < end) {
    intervals.push({ start_date: start, end_date: end });
  }
  return intervals;
};

const calculateDuration = (durationSettings: DurationSettings, start: Date, end: Date) => {
  const intervals = calculateIntervals(durationSettings.nonWorkingDays, start, end);
  return intervals.reduce(
    (acc, interval) => {
      const duration = calculateDurationForInterval(
        durationSettings.workingDaysAndHoursMap,
        interval.start_date,
        interval.end_date,
      );
      acc.workingDays += duration.workingDays;
      acc.workingHours += duration.workingHours;
      return acc;
    },
    { workingDays: 0, workingHours: 0 },
  );
};

const calculateEndDate = (
  durationSettings: DurationSettings,
  startDate: Date,
  workingDays: number,
) => {
  if (workingDays === 0) {
    return startDate;
  }

  const maxIterations = 500;

  for (let i = 1; i < maxIterations; i++) {
    const endDate = addDays(startDate, i * Math.sign(workingDays));
    const duration =
      workingDays > 0
        ? calculateDuration(durationSettings, startDate, endDate)
        : calculateDuration(durationSettings, endDate, startDate);
    if (duration.workingDays >= Math.abs(workingDays)) {
      return endDate;
    }
  }
};

export default {
  calculateWorkingDay,
  calculateWorkingHours,
  calculateDuration,
  calculateSettings,
  calculateEndDate,
};
