<template>
  <OaiTooltip
    v-if="differenceForPath.forLastFinished.workingDays !== null"
    :cls="[
      cls,
      'text-xs px-2 py-0.5 rounded-full whitespace-nowrap z-[100] cursor-pointer',
      lastFinishedClass,
    ]"
    @click="emit('click')"
  >
    {{ differenceForPath.forLastFinished.workingDays }} {{ t("working_day.working_day_abbrev") }}
    <template #tooltip>
      <div class="text-xs">
        <div class="flex gap-1" v-if="differenceForPath.forLastNode.plannedEvent">
          <div class="w-[130px]">{{ t("analytics.critical_path.planned_end") }}:</div>
          <div>{{ format(differenceForPath.forLastNode.plannedEvent.end, dateFormat) }}</div>
        </div>
        <div class="flex gap-1" v-if="differenceForPath.endDate">
          <div class="w-[130px]">
            {{
              differenceForPath.projectedEndDate
                ? t("analytics.critical_path.projected_end")
                : t("analytics.critical_path.actual_end")
            }}:
          </div>
          <div>
            {{ format(differenceForPath.endDate, dateFormat) }}
          </div>
        </div>
        <div class="flex gap-1">
          <div class="w-[130px]">{{ t("working_day.working_day") }}:</div>
          <div>
            {{ differenceForPath.forLastFinished.workingDays }}
          </div>
        </div>
      </div>
    </template>
  </OaiTooltip>
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { CriticalPathDifferenceForPath } from "shared/types/CriticalPath";
import { useDifferenceTextWithBackgroundClass } from "../composables";

const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  differenceForPath: CriticalPathDifferenceForPath;
  cls?: string;
}>();

const emit = defineEmits<{ (eventName: "click"): void }>();

const { t } = useI18n();

const lastFinishedClass = useDifferenceTextWithBackgroundClass(
  computed(() => props.differenceForPath.forLastFinished.workingDays),
);
</script>
