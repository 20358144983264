import { ref, onMounted, onBeforeUnmount } from "vue";

const useMousePosition = () => {
  const mousePosition = ref({ x: 0, y: 0 });

  const updateMousePosition = (event: MouseEvent) => {
    mousePosition.value.x = event.clientX;
    mousePosition.value.y = event.clientY;
  };

  onMounted(() => {
    window.addEventListener("mousemove", updateMousePosition);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("mousemove", updateMousePosition);
  });

  return mousePosition;
};

export default useMousePosition;
