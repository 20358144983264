import i18n from "shared/i18n";

const { localeText } = i18n.global;

const fixToPrecision = (value: number, precision: number) => parseFloat(value.toFixed(precision));

const parseText = (value: string, precision: number) => {
  const parsedValue = parseFloat(value.replaceAll(",", "."));
  if (Number.isNaN(parsedValue)) {
    return parsedValue;
  }
  return fixToPrecision(parsedValue, precision);
};

const getDecimalSeparator = () => {
  const n = 1.1;
  return n.toLocaleString(localeText).substring(1, 2);
};

export default {
  fixToPrecision,
  parseText,
  getDecimalSeparator,
};
