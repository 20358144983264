import { UnitValueType } from "@/types/UnitValue";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type HierarchyItem =
  | {
      process_type: string;
    }
  | Record<UnitValueType, HierarchyItem>;

const uv_wall_column_hierarchy: HierarchyItem = {
  uv_wall_column_formwork_m2: { process_type: "wall_column_formwork" },
  uv_wall_column_rebar_t: { process_type: "wall_column_rebar" },
  uv_wall_column_concrete_bucket_m3: {
    uv_wall_column_concrete_bucket: { process_type: "wall_column_concrete_bucket" },
    uv_wall_column_concrete_pump: { process_type: "wall_column_concrete_pump" },
  },
};

export const unitValueTypeHierarchy: HierarchyItem = {
  uv_wall_default: {
    uv_wall_default_formwork_m2: { process_type: "wall_default_formwork" },
    uv_wall_default_rebar_t: { process_type: "wall_default_rebar" },
    uv_wall_default_concrete_m3: {
      uv_wall_default_concrete_bucket_m3: { process_type: "wall_default_concrete_bucket" },
      uv_wall_default_concrete_pump_m3: { process_type: "wall_default_concrete_pump" },
    },
  },
  uv_wall_default_element_m2: { process_type: "wall_default_element" },
  uv_wall_parapet_m2: {
    uv_wall_parapet_formwork_m2: { process_type: "wall_parapet_formwork" },
    uv_wall_parapet_rebar_t: { process_type: "wall_parapet_rebar" },
    uv_wall_parapet_concrete_m3: {
      uv_wall_parapet_concrete_bucket_m3: { process_type: "wall_parapet_concrete_bucket" },
      uv_wall_parapet_concrete_pump_m3: { process_type: "wall_parapet_concrete_pump" },
    },
  },
  uv_wall_column_m3: uv_wall_column_hierarchy,
  uv_wall_column_pc: uv_wall_column_hierarchy,
  uv_wall_column_m2: uv_wall_column_hierarchy,
  uv_wall_column_element_pc: { process_type: "wall_column_element" },
  uv_wall_brickwork_all_m2: {
    uv_wall_brick_outline_lm: { process_type: "wall_brick_outline" },
    uv_wall_brickwork_m2: { process_type: "wall_brickwork" },
  },
  uv_ceiling_m2: {
    uv_ceiling_default_formwork_m2: { process_type: "ceiling_default_formwork" },
    uv_ceiling_element_semi_m2: { process_type: "ceiling_element_semi" },
    uv_ceiling_default_rebar: { process_type: "ceiling_default_rebar" },
    uv_ceiling_default_concrete_m3: {
      uv_ceiling_default_concrete_bucket_m3: { process_type: "ceiling_default_concrete_bucket" },
      uv_ceiling_default_concrete_pump_m3: { process_type: "ceiling_default_concrete_pump" },
    },
  },
  uv_ceiling_element_full_m2: { process_type: "ceiling_element_full" },
  uv_ceiling_underbeams_m3: {
    uv_ceiling_underbeams_formwork_m2: { process_type: "ceiling_underbeams_formwork" },
    uv_ceiling_underbeams_rebar_t: { process_type: "ceiling_underbeams_rebar" },
    uv_ceiling_underbeams_concrete_m3: {
      uv_ceiling_underbeams_concrete_bucket_m3: {
        process_type: "ceiling_underbeams_concrete_bucket",
      },
      uv_ceiling_underbeams_concrete_pump_m3: {
        process_type: "ceiling_underbeams_concrete_bucket",
      },
    },
  },
  uv_foundation_slab_m2: {
    uv_foundation_slab_formwork_lm: { process_type: "foundation_slab_formwork" },
    uv_foundation_slab_rebar_t: { process_type: "foundation_slab_rebar" },
    uv_foundation_slab_concrete_m3: {
      uv_foundation_slab_concrete_bucket_m3: { process_type: "foundation_slab_concrete_bucket" },
      uv_foundation_slab_concrete_pump_m3: { process_type: "foundation_slab_concrete_pump" },
    },
  },
  uv_foundation_point_m2: {
    uv_foundation_point_formwork_lm: { process_type: "foundation_point_formwork" },
    uv_foundation_point_rebar_t: { process_type: "foundation_point_rebar" },
    uv_foundation_point_concrete_m3: {
      uv_foundation_point_concrete_bucket_m3: { process_type: "foundation_point_concrete_bucket" },
      uv_foundation_point_concrete_pump_m3: { process_type: "foundation_point_concrete_pump" },
    },
  },
  uv_foundation_stripe_m2: {
    uv_foundation_stripe_formwork_lm: { process_type: "foundation_stripe_formwork" },
    uv_foundation_stripe_rebar_t: { process_type: "foundation_stripe_rebar" },
    uv_foundation_stripe_concrete_m3: {
      uv_foundation_stripe_concrete_bucket_m3: {
        process_type: "foundation_stripe_concrete_bucket",
      },
      uv_foundation_stripe_concrete_pump_m3: { process_type: "foundation_stripe_concrete_pump" },
    },
  },
};

export const directUnitValueChildTypesByType = {} as Record<UnitValueType, UnitValueType[]>;

const calculateDirectUnitValueChildTypesByType = (item: HierarchyItem): UnitValueType[] => {
  if ("process_type" in item) {
    return [];
  }
  const children = [] as UnitValueType[];
  for (const [key, value] of Object.entries(item)) {
    directUnitValueChildTypesByType[key as UnitValueType] =
      calculateDirectUnitValueChildTypesByType(value);
    children.push(key as UnitValueType);
  }
  return children;
};

calculateDirectUnitValueChildTypesByType(unitValueTypeHierarchy);

const findHierarchyItem = (
  unitValueType: UnitValueType,
  item: HierarchyItem,
): HierarchyItem | null => {
  if ("process_type" in item) {
    return null;
  }
  for (const [key, value] of Object.entries(item)) {
    if (key === unitValueType) {
      return value;
    }
    const result = findHierarchyItem(unitValueType, value);
    if (result) {
      return result;
    }
  }
};

const getAllProcessTypes = (item: HierarchyItem): string[] => {
  if ("process_type" in item) {
    return [item.process_type];
  }
  const result: string[] = [];
  for (const [, value] of Object.entries(item)) {
    for (const subResult of getAllProcessTypes(value)) {
      result.push(subResult);
    }
  }
  return result;
};

export const getProcessTypeByUnitValueType = (unitValueType: UnitValueType) => {
  const item = findHierarchyItem(unitValueType, unitValueTypeHierarchy);
  return getAllProcessTypes(item);
};

export const rootUnitValueTypes = Object.keys(unitValueTypeHierarchy) as UnitValueType[];
