<template>
  <div class="mainContainer">
    <div class="relative hidden tooltipContainer">
      <div class="absolute arrowLeft" :style="{ top: '1px', right: '-10px' }" />
      <div
        class="absolute whitespace-nowrap bg-oaiGray-900 text-white px-2 py-1 rounded text-xs"
        :style="{ top: '-4px', right: '-10px', transform: 'translateX(100%)' }"
      >
        {{ t("dashboard.drag_me") }}
      </div>
    </div>
    <svg
      class="w-4 h-4 vgl-item__drag-handle"
      :class="iconClass"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="#4B5563"
    >
      <circle cx="5.5" cy="2.5" r="1.5" />
      <circle cx="5.5" cy="13.5" r="1.5" />
      <circle cx="5.5" cy="8" r="1.5" />
      <circle cx="10.5" cy="2.5" r="1.5" />
      <circle cx="10.5" cy="13.5" r="1.5" />
      <circle cx="10.5" cy="8" r="1.5" />
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{ iconClass?: string }>();
</script>

<style scoped>
.mainContainer:hover .tooltipContainer {
  display: block;
}

.vgl-item--dragging .tooltipContainer {
  display: none !important;
}

.arrowLeft {
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #000;
}
</style>
