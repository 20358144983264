import {
  DependencyModel,
  EventModel,
  Model,
  ResourceModel,
  SchedulerPro,
} from "@bryntum/schedulerpro";
import { resetLag } from "./SchedulerProConfig";

type EventBoundary = {
  shouldCloseActualEvent: boolean;
  allChildrenTrackingDisabled: boolean;
  actualEarliestStart: Date | null;
  actualLatestEnd: Date | null;
  plannedEarliestStart: Date | null;
  plannedLatestEnd: Date | null;
};

const calculateEventBoundary = (schedulerPro: SchedulerPro, resource: Model): EventBoundary => {
  let shouldCloseActualEvent = true;
  let allChildrenTrackingDisabled = true;
  let actualEarliestStart: Date | null = null;
  let actualLatestEnd: Date | null = null;
  let plannedEarliestStart: Date | null = null;
  let plannedLatestEnd: Date | null = null;

  for (const resourceChild of resource.children as ResourceModel[]) {
    const events = schedulerPro.eventStore.getEventsForResource(
      resourceChild.id as string,
    ) as EventModel[];
    const actualEvent = events.find((event) => event.getData("type") === "actual");
    const plannedEvent = events.find((event) => event.getData("type") === "planned");

    if (
      (!actualEvent && resourceChild.getData("trackingEnabled")) ||
      (actualEvent && actualEvent.getData("active") && resourceChild.getData("trackingEnabled"))
    ) {
      shouldCloseActualEvent = false;
    }

    if (actualEvent) {
      if (!actualEarliestStart || actualEvent.startDate < actualEarliestStart) {
        actualEarliestStart = actualEvent.startDate as Date;
      }
      if (!actualLatestEnd || actualEvent.endDate > actualLatestEnd) {
        actualLatestEnd = actualEvent.endDate as Date;
      }
    }
    if (plannedEvent) {
      if (!plannedEarliestStart || plannedEvent.startDate < plannedEarliestStart) {
        plannedEarliestStart = plannedEvent.startDate as Date;
      }
      if (!plannedLatestEnd || plannedEvent.endDate > plannedLatestEnd) {
        plannedLatestEnd = plannedEvent.endDate as Date;
      }
    }
    if (resourceChild.getData("trackingEnabled")) {
      allChildrenTrackingDisabled = false;
    }
  }
  return {
    shouldCloseActualEvent,
    allChildrenTrackingDisabled,
    actualEarliestStart,
    actualLatestEnd,
    plannedEarliestStart,
    plannedLatestEnd,
  };
};

const updateActualEvent = async (
  schedulerPro: SchedulerPro,
  resource: Model,
  actualEvent: EventModel | undefined,
  calculatedEventDates: EventBoundary,
) => {
  const { eventStore } = schedulerPro;
  const {
    actualEarliestStart,
    allChildrenTrackingDisabled,
    actualLatestEnd,
    shouldCloseActualEvent,
  } = calculatedEventDates;
  const eventShouldExist = actualEarliestStart && actualLatestEnd;

  if (eventShouldExist && !actualEvent) {
    return eventStore.applyChangeset({
      added: [
        {
          type: "actual",
          resourceId: resource.id,
          startDate: actualEarliestStart,
          endDate: actualLatestEnd,
          active: !shouldCloseActualEvent,
        },
      ],
    });
  }

  if (!eventShouldExist && actualEvent && !allChildrenTrackingDisabled) {
    return eventStore.remove(actualEvent.id);
  }

  if (actualEvent) {
    if (actualEarliestStart && actualLatestEnd) {
      if (actualEvent.getData("startDate")?.getTime() !== actualEarliestStart.getTime()) {
        await actualEvent.setStartDate(actualEarliestStart, false);
      }
      await actualEvent.setEndDate(actualLatestEnd);
    }
    actualEvent.set("active", !shouldCloseActualEvent);
  }
};

const updatePlannedEvent = async (
  schedulerPro: SchedulerPro,
  resource: Model,
  plannedEvent: EventModel | undefined,
  calculatedEventDates: EventBoundary,
) => {
  const { eventStore } = schedulerPro;
  const { plannedEarliestStart, plannedLatestEnd } = calculatedEventDates;
  const eventShouldExist = plannedEarliestStart && plannedLatestEnd;

  if (eventShouldExist && !plannedEvent) {
    return eventStore.applyChangeset({
      added: [
        {
          type: "planned",
          resourceId: resource.id,
          startDate: plannedEarliestStart,
          endDate: plannedLatestEnd,
        },
      ],
    });
  }

  if (!eventShouldExist && plannedEvent) {
    return eventStore.remove(plannedEvent.id);
  }

  if (plannedEvent) {
    if (plannedEarliestStart && plannedLatestEnd) {
      if (plannedEvent.getData("startDate")?.getTime() !== plannedEarliestStart.getTime()) {
        resetLag(schedulerPro, plannedEvent.id);
        await plannedEvent.setStartDate(plannedEarliestStart, false);
      }
      await plannedEvent.setEndDate(plannedLatestEnd);
    }
  }
};

const updateParentEvents = async (schedulerPro: SchedulerPro, resource: Model) => {
  if (resource.isRoot || (resource.children as ResourceModel[]).length === 0) {
    return;
  }

  const calculatedEventBoundary = calculateEventBoundary(schedulerPro, resource);
  const events = schedulerPro.eventStore.getEventsForResource(
    resource.id as string,
  ) as EventModel[];
  const actualEvent = events.find((event) => event.getData("type") === "actual");
  const plannedEvent = events.find((event) => event.getData("type") === "planned");

  await updateActualEvent(schedulerPro, resource, actualEvent, calculatedEventBoundary);
  await updatePlannedEvent(schedulerPro, resource, plannedEvent, calculatedEventBoundary);
};

export const updateAllParentEvents = async (schedulerPro: SchedulerPro) => {
  const processResource = async (resource: Model) => {
    for (const resourceChild of resource.children as Model[]) {
      await processResource(resourceChild);
    }
    await updateParentEvents(schedulerPro, resource);
  };

  await processResource(schedulerPro.resourceStore.rootNode);
};

export const updateParentEventsForResourceAndItsParents = async (
  schedulerPro: SchedulerPro,
  resource: Model,
) => {
  while (resource && !resource.isRoot) {
    await updateParentEvents(schedulerPro, resource);
    resource = resource.parent;
  }
};

export const updateParentEventsForDependencies = async (
  schedulerPro: SchedulerPro,
  resource: Model,
) => {
  while (resource && !resource.isRoot) {
    const dependentResources = (schedulerPro.dependencyStore.allRecords as DependencyModel[])
      .filter(
        (dependency) => (dependency.fromEvent as EventModel | null)?.resourceId === resource.id,
      )
      .map((dependency) => (dependency.toEvent as EventModel).resource);
    for (const dependentResource of dependentResources) {
      await updateParentEventsForResourceAndItsParents(schedulerPro, dependentResource);
    }
    resource = resource.parent;
  }
};
