<template>
  <FieldArray :name="name" v-slot="{ fields, remove }">
    <div class="block text-sm font-medium leading-6 text-gray-900">
      {{ $t(`project.add.layout_plans`) }}
      <span class="font-normal text-gray-600">({{ $t(`project.add.optional`) }})</span>
      <span v-if="filesError" class="pl-2 font-normal text-xs text-red">{{ filesError }}</span>
      <FileInputAddProject :name="name" />
      <div v-for="(file, index) in fields as FieldEntry<FileEntry>[]" :key="index">
        <div class="flex items-center gap-2">
          <div>{{ file.value.name }}</div>
          <XMarkIcon
            @click="remove(index)"
            class="h-4 w-4 a:text-red-500 cursor-pointer"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  </FieldArray>
</template>

<script setup lang="ts">
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { FieldArray, FieldEntry, useFieldError } from "vee-validate";
import FileInputAddProject from "@/views/dashboard/components/FileInputAddProject.vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

type FileEntry = {
  size: number;
  name: string;
  file_base64: string;
};

const filesError = useFieldError(props.name);
</script>
