<template>
  <div
    class="border-r"
    :class="{
      'border-b': !tag.last,
    }"
  >
    <ValueCellNumberInput
      name="custom_adjusted_duration"
      field="duration"
      :unitValue="unitValue"
      :rowHeight="rowHeight"
      :value="unitValue.duration"
      :originalValue="unitValue.custom_adjusted_duration"
      :hasError="errorField === 'duration'"
      :highlightWhenZero="true"
      :showClear="unitValue.custom_adjusted_duration !== null"
      :infoPosition="unitValue.duration !== null ? 'right' : 'none'"
      :unitValueAggregate="unitValueAggregate"
      @changed="handleNumberInputChanged"
      @focused="emitFocused"
      @blurred="emitBlurred"
      @navigated="handleNavigated"
    />
  </div>
  <div
    class="border-r"
    :class="{
      'border-b': !tag.last,
    }"
  >
    <ValueCellNumberInput
      v-if="isAdminMode"
      name="oai_adjusted_workforce"
      field="workforce"
      :unitValue="unitValue"
      :rowHeight="rowHeight"
      :value="unitValue.oai_adjusted_workforce ?? unitValue.workforce"
      :originalValue="unitValue.oai_adjusted_workforce"
      :hasError="errorField === 'oai_adjusted_workforce' || errorField === 'workforce'"
      :highlightWhenZero="true"
      :showClear="unitValue.oai_adjusted_workforce !== null"
      :infoPosition="unitValue.workforce !== null ? 'right' : 'none'"
      :warningTriangle="true"
      :unitValueAggregate="unitValueAggregate"
      @changed="handleNumberInputChanged"
      @focused="emitFocused"
      @blurred="emitBlurred"
      @navigated="handleNavigated"
    />
    <ValueCellNumberInput
      v-else
      name="custom_adjusted_workforce"
      field="workforce"
      :unitValue="unitValue"
      :rowHeight="rowHeight"
      :value="unitValue.workforce"
      :originalValue="unitValue.custom_adjusted_workforce"
      :hasError="errorField === 'workforce'"
      :highlightWhenZero="true"
      :showClear="unitValue.custom_adjusted_workforce !== null"
      :infoPosition="unitValue.workforce !== null ? 'right' : 'none'"
      :unitValueAggregate="unitValueAggregate"
      @changed="handleNumberInputChanged"
      @focused="emitFocused"
      @blurred="emitBlurred"
      @navigated="handleNavigated"
    />
  </div>
  <div class="border-r" :class="{ 'border-b': !tag.last }">
    <ValueCellNumberInput
      name="quantity"
      :unitValue="unitValue"
      :rowHeight="rowHeight"
      :value="unitValue.quantity"
      :originalValue="unitValue.quantity"
      :hasError="errorField === 'quantity'"
      :unitValueAggregate="unitValueAggregate"
      infoPosition="none"
      @changed="handleNumberInputChanged"
      @focused="emitFocused"
      @blurred="emitBlurred"
      @navigated="handleNavigated"
    />
  </div>
  <div
    :class="{
      'border-b': !tag.last,
      'border-r border-r-gray-600': showLastRightBorder,
    }"
  >
    <ValueCellNumberInput
      name="custom_adjusted_value"
      field="value"
      :unitValue="unitValue"
      :rowHeight="rowHeight"
      :value="unitValue.value"
      :originalValue="unitValue.custom_adjusted_value"
      :hasError="errorField === 'value'"
      cls="bg-gray-100"
      :showClear="unitValue.custom_adjusted_value !== null"
      :infoPosition="unitValue.value !== null || props.isAdminMode ? 'left' : 'none'"
      :showUnIgnore="isAdminMode && unitValue.ignored"
      :showIgnore="isAdminMode && !unitValue.ignored"
      :style="`${backgroundColor ? `background-color: ${backgroundColor}` : ''}`"
      :unitValueAggregate="unitValueAggregate"
      @changed="handleNumberInputChanged"
      @focused="emitFocused"
      @blurred="emitBlurred"
      @unIgnoreClicked="
        emit('changed', {
          ...unitValue,
          ignored: false,
        })
      "
      @ignoreClicked="
        emit('changed', {
          ...unitValue,
          ignored: true,
        })
      "
      @navigated="handleNavigated"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import {
  UnitValue,
  UnitValueAggregate,
  UnitValueCellNavigation,
  UnitValueRowTag,
} from "@/types/UnitValue";
import ValueCellNumberInput from "@/views/unit_values/components/ValueCellNumberInput.vue";
import { calculateFields } from "@/views/unit_values/services/unitValues";

const props = defineProps({
  unitValue: {
    type: Object as PropType<UnitValue>,
    required: true,
  },
  tag: {
    type: Object as PropType<UnitValueRowTag>,
    required: true,
  },
  isAdminMode: {
    type: Boolean,
    required: true,
  },
  rowHeight: {
    type: Number,
    required: true,
  },
  errorField: {
    type: String as PropType<string | null>,
    required: false,
  },
  showLastRightBorder: {
    type: Boolean,
    required: true,
  },
  unitValueAggregate: {
    type: Object as PropType<UnitValueAggregate>,
    required: false,
  },
});

const emit = defineEmits(["changed", "focused", "blurred", "navigated"]);

const fields = computed(
  () =>
    [
      "custom_adjusted_duration",
      props.isAdminMode ? "oai_adjusted_workforce" : "custom_adjusted_workforce",
      "quantity",
      "custom_adjusted_value",
    ] as (keyof UnitValue)[],
);

const handleNumberInputChanged = ({ name, value }: { name: string; value: number | null }) => {
  const newUnitValue: UnitValue = {
    ...props.unitValue,
    [name]: value,
  };
  const finalUnitValue: UnitValue = {
    ...newUnitValue,
    ...calculateFields(newUnitValue),
  };
  emit(`changed`, finalUnitValue);
};

const handleNavigated = (unitValueCellNavigation: UnitValueCellNavigation) => {
  const payload: UnitValueCellNavigation = {
    ...unitValueCellNavigation,
    fields: fields.value,
  };
  emit("navigated", payload);
};

const emitFocused = () => {
  emit("focused", props.unitValue);
};

const emitBlurred = () => {
  emit("blurred", props.unitValue);
};

const backgroundColor = computed(() => {
  if (!props.unitValueAggregate || props.unitValue.state !== "finished") {
    return null;
  }
  const { confidence_lower_limit, confidence_upper_limit } = props.unitValueAggregate;
  if (
    confidence_lower_limit === null ||
    confidence_upper_limit === null ||
    props.unitValue.value === null
  ) {
    return null;
  }
  const interval = confidence_upper_limit - confidence_lower_limit;
  const colors = ["#63be7b80", "#84c77c80", "#e2e28280", "#ffe98480", "#fdc57d80", "#f8696b80"];
  const step = interval / colors.length;
  const index = Math.floor((props.unitValue.value - confidence_lower_limit) / step);
  const finalIndex = Math.max(Math.min(index, colors.length - 1), 0);
  return colors[finalIndex];
});
</script>
