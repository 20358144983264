<template>
  <div class="flex">
    <template v-for="tag in tags" :key="tag">
      <HierarchyTagItem
        v-if="tag.name"
        class="m-1 cursor-pointer align-middle text-xs"
        :tag="tag"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import HierarchyTagItem from "shared/components/other/HierarchyTagItem.vue";
import { HierarchyType } from "shared/types/HierarchyTag";

const props = defineProps<{ data: Record<HierarchyType, string> }>();

const tags = computed(() =>
  Object.entries(props.data).map(([type, name]) => ({
    type: type as HierarchyType,
    name,
  })),
);
</script>
