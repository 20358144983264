<template>
  <TransitionRoot as="template" :show="!!confirmationModalArgs">
    <Dialog as="div" class="relative" style="z-index: 999999" @close="setConfirm(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <template v-if="confirmationModalArgsWithDelayedClear">
                <div class="sm:flex sm:items-start">
                  <div
                    :class="[
                      'mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                      colors.bgLight,
                    ]"
                  >
                    <ExclamationTriangleIcon :class="['h-6 w-6', colors.text]" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                      {{ confirmationModalArgsWithDelayedClear.header }}
                    </DialogTitle>
                    <div class="mt-2" v-if="confirmationModalArgsWithDelayedClear.message">
                      <p class="text-sm text-gray-500">
                        {{ confirmationModalArgsWithDelayedClear.message }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    :class="[
                      'inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm',
                      colors.bgMedium,
                      colors.bgHover,
                      colors.ringFocus,
                    ]"
                    @click="setConfirm(true)"
                  >
                    {{ confirmationModalArgsWithDelayedClear.confirmAction }}
                  </button>
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="setConfirm(false)"
                  >
                    {{ confirmationModalArgsWithDelayedClear.cancelAction }}
                  </button>
                </div>
              </template>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot, DialogTitle } from "@headlessui/vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { ConfirmationModalArgs } from "../../types/modals";

const store = useStore();
const confirmationModalArgs = computed(
  () => store.state.confirmationModal?.args || inComponentConfirmationModalArgs.value,
);
const confirmationModalArgsWithDelayedClear = ref<ConfirmationModalArgs | null>(null);
const inComponentConfirmationModalArgs = ref<ConfirmationModalArgs | null>(null);
const resolvePromise = ref<((confirmed: boolean) => void) | null>(null);

defineExpose({
  showConfirmationModal: (args: ConfirmationModalArgs) => {
    inComponentConfirmationModalArgs.value = args;
    return new Promise((resolve) => {
      resolvePromise.value = resolve;
    }).then((confirmed) => {
      inComponentConfirmationModalArgs.value = null;
      resolvePromise.value = null;
      return confirmed;
    });
  },
});

const setConfirm = (confirmed: boolean) => {
  if (!confirmationModalArgs.value) {
    return;
  }

  if (resolvePromise.value) {
    resolvePromise.value(confirmed);
  } else {
    store.commit("setConfirmationModalConfirmed", confirmed);
  }
};

const colors = computed(() => {
  const colorsPresets = {
    red: {
      bgLight: "bg-red-100",
      bgMedium: "bg-red-500",
      bgDark: "bg-red-600",
      bgHover: "hover:bg-red-600",
      ringFocus: "focus:ring-red-600",
      text: "text-red-600",
    },
    yellow: {
      bgLight: "bg-yellow-100",
      bgMedium: "bg-yellow-500",
      bgDark: "bg-yellow-600",
      bgHover: "hover:bg-yellow-600",
      ringFocus: "focus:ring-yellow-600",
      text: "text-yellow-600",
    },
  };

  if (!confirmationModalArgsWithDelayedClear.value?.color) {
    return colorsPresets.yellow;
  }

  return colorsPresets[confirmationModalArgsWithDelayedClear.value.color];
});

watch(
  () => confirmationModalArgs.value,
  () => {
    if (confirmationModalArgs.value) {
      confirmationModalArgsWithDelayedClear.value = confirmationModalArgs.value;
    } else {
      setTimeout(() => {
        confirmationModalArgsWithDelayedClear.value = null;
      }, 300);
    }
  },
);
</script>
