import { AxiosResponse } from "axios";
import type { AxiosRequestConfig } from "axios";
import { parseISO, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { v4 as uuidv4 } from "uuid";
import { apiClient } from "shared/repositories/clients";
import {
  WeatherPlotResponse,
  CustomImageResponse,
  ReportSettings,
  DailyReportResponse,
  CopySectionResponse,
  ReportMeta,
  ReportProcessEntry,
  ReportSection,
  PdfExportResponse,
  OverviewItem,
  OverviewResponse,
} from "@/types/DailyReport";

const parseUtcToHourMinute = (value: string): string =>
  format(toZonedTime(parseISO(value), "UTC"), "HH:mm");

const getDailyReportOverview = (
  customerName: string,
  siteId: string,
  year: number | null,
  month: number | null,
): Promise<OverviewResponse> => {
  const searchParams = new URLSearchParams();
  if (year) {
    searchParams.set("year", String(year));
  }

  if (month) {
    searchParams.set("month", String(month));
  }

  return apiClient
    .get<void, AxiosResponse<OverviewResponse>>(
      `/daily-report/overview/${customerName}/${siteId}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const getDailyReport = (
  customerName: string,
  siteId: string,
  date: string,
): Promise<DailyReportResponse> =>
  apiClient
    .get<void, AxiosResponse<DailyReportResponse>>(
      `/daily-report/single/${customerName}/${siteId}/${date}`,
    )
    .then(
      ({ data }) =>
        ({
          ...data,
          data: {
            ...data?.data,
            site_duration: {
              start_time:
                data.data.site_duration.start_time &&
                parseUtcToHourMinute(data.data.site_duration.start_time),
              end_time:
                data.data.site_duration.end_time &&
                parseUtcToHourMinute(data.data.site_duration.end_time),
            },
            processes: data.data.processes.map((item) => ({
              ...item,
              id: uuidv4(), // Add temporary id for updating fields
              start_time: item.start_time ? parseUtcToHourMinute(item.start_time) : item.start_time,
              end_time: item.end_time ? parseUtcToHourMinute(item.end_time) : item.end_time,
            })),
          },
          oai_processes: data.oai_processes.map((item) => ({
            ...item,
            start_time: parseUtcToHourMinute(item.start_time),
            end_time: parseUtcToHourMinute(item.end_time),
          })),
        } as DailyReportResponse),
    );

const initializeDailyReport = (
  customerName: string,
  siteId: string,
  date: string,
): Promise<OverviewItem["data"]> => {
  return apiClient
    .post<void, AxiosResponse<OverviewItem["data"]>>(
      `/daily-report/single/${customerName}/${siteId}/${date}`,
    )
    .then((response) => response.data);
};

const deleteDailyReport = (customerName: string, siteId: string, date: string): Promise<string> => {
  return apiClient
    .delete<void, AxiosResponse<string>>(`/daily-report/single/${customerName}/${siteId}/${date}`)
    .then((response) => response.data);
};

const updateDailyReport = (
  customerName: string,
  siteId: string,
  date: string,
  payload: unknown,
): Promise<unknown> => {
  return apiClient
    .patch<void, AxiosResponse<unknown>>(
      `/daily-report/single/${customerName}/${siteId}/${date}`,
      payload,
    )
    .then((response) => response);
};

const getDailyReportWeatherPlot = (
  customerName: string,
  siteId: string,
  date: string,
  lang: string,
): Promise<WeatherPlotResponse | null> => {
  const searchParams = new URLSearchParams({ lang });

  return apiClient
    .get<void, AxiosResponse<WeatherPlotResponse>>(
      `/daily-report/single/weather/${customerName}/${siteId}/${date}?${searchParams.toString()}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });
};

const exportDailyReportBulk = (
  customerName: string,
  siteId: string,
  startDate: string,
  endDate: string,
  lang: string,
  export_format: string,
  status_filter: string,
  v: string,
  expectedResponseType?: string,
): Promise<unknown> => {
  const searchParams = new URLSearchParams({ lang, export_format, status_filter, version: v });

  const config: AxiosRequestConfig = {
    responseType: expectedResponseType === "arraybuffer" ? "arraybuffer" : "json",
  };
  return apiClient
    .get<void, AxiosResponse<unknown>>(
      `/daily-report/export/bulk/${customerName}/${siteId}/${startDate}/${endDate}?${searchParams.toString()}`,
      config,
    )
    .then((response) => response);
};

const exportDailyReportSingle = (
  customerName: string,
  siteId: string,
  date: string,
  lang: string,
  v: string,
): Promise<PdfExportResponse> => {
  const searchParams = new URLSearchParams({ lang, version: v });

  return apiClient
    .get<void, AxiosResponse<PdfExportResponse>>(
      `/daily-report/export/single/${customerName}/${siteId}/${date}?${searchParams.toString()}`,
    )
    .then((response) => response.data);
};

const getSectionValueCopy = (
  sectionName: ReportSection,
  customerName: string,
  siteId: string,
  date: string,
): Promise<CopySectionResponse> => {
  return apiClient
    .get<void, AxiosResponse<CopySectionResponse>>(
      `/daily-report/single/copy/${sectionName}/${customerName}/${siteId}/${date}`,
    )
    .then((response) => {
      if (sectionName === "processes") {
        const data = response.data.data as ReportProcessEntry[];
        return {
          data: data.map((item) => ({
            ...item,
            start_time: parseUtcToHourMinute(item.start_time),
            end_time: parseUtcToHourMinute(item.end_time),
          })),
        };
      } else {
        return response.data;
      }
    });
};

const updateCustomField = (
  customerName: string,
  siteId: string,
  action: string,
  payload: unknown,
): Promise<unknown> => {
  return apiClient
    .patch<void, AxiosResponse<unknown>>(
      `/daily-report/custom-fields/${customerName}/${siteId}/${action}`,
      payload,
    )
    .then((response) => response);
};

const getDailyReportSettings = (customerName: string, siteId: string) => {
  return apiClient
    .get<void, AxiosResponse<ReportSettings>>(`/daily-report/settings/${customerName}/${siteId}`)
    .then((response) => response);
};

const updateDailyReportSettings = (
  customerName: string,
  siteId: string,
  payload: unknown,
): Promise<unknown> => {
  return apiClient
    .patch<void, AxiosResponse<unknown>>(
      `/daily-report/settings/${customerName}/${siteId}`,
      payload,
    )
    .then((response) => response);
};

const updateDailyReportStatus = (
  customerName: string,
  siteId: string,
  date: string,
  payload: unknown,
): Promise<ReportMeta> => {
  return apiClient
    .patch<void, AxiosResponse<ReportMeta>>(
      `/daily-report/single/status/${customerName}/${siteId}/${date}`,
      payload,
    )
    .then((response) => response.data);
};

const uploadDailyReportCustomImage = (
  customerName: string,
  siteId: string,
  file_string: string,
): Promise<CustomImageResponse> => {
  return apiClient
    .post<void, AxiosResponse<CustomImageResponse>>(
      `/daily-report/custom-image/${customerName}/${siteId}`,
      {
        file: file_string,
      } as AxiosRequestConfig,
    )
    .then((response) => response.data);
};

export default {
  getDailyReportOverview,
  getDailyReport,
  initializeDailyReport,
  deleteDailyReport,
  updateDailyReport,
  getDailyReportWeatherPlot,
  exportDailyReportBulk,
  exportDailyReportSingle,
  getSectionValueCopy,
  updateCustomField,
  getDailyReportSettings,
  updateDailyReportSettings,
  updateDailyReportStatus,
  uploadDailyReportCustomImage,
};
