<template>
  <BaseWidget :isLoading="isLoading" @delete="emit('delete')" hideDragHandle menuClass="top-4">
    <div class="h-full flex flex-col" ref="containerRef">
      <div
        class="flex items-center pl-4 pr-7 py-4 gap-2"
        :style="{ height: `${liveStreamWidgetHeaderHeight}px` }"
      >
        <DashboardDragHandle class="shrink-0" />
        <RouterLink
          v-if="stream"
          :to="{
            name: 'ProjectTimelapse',
            params: {
              customer_name: stream.customer_name,
              site_id: stream.site_id,
              camera_id: stream.camera_id,
            },
          }"
          @click.prevent="emit('widgetRouteClicked')"
          class="font-semibold text-gray-900 truncate text-lg hover:text-yellow-800"
        >
          {{ stream?.name }}
        </RouterLink>
        <div class="bg-yellow-200 text-yellow-800 px-3 py-0.5 text-xs rounded-xl truncate">
          {{ t("dashboard.stream.archived_stream") }}
        </div>
      </div>
      <div
        class="flex flex-col items-center"
        :style="{ 'background-color': thumbnailUrl ? '#000' : '#f2f5f8' }"
        data-oai-left-over-space-calculator="ignore"
      >
        <img
          :src="thumbnailUrl ?? undefined"
          alt=""
          class="bg-transparent bg-no-repeat bg-center bg-contain object-contain"
          :style="{
            backgroundImage: `url('${defaultProjectThumbnailUrl}')`,
            textIndent: '-1000px',
            width: `${playerDimensions.width}px`,
            height: `${playerDimensions.height}px`,
          }"
        />
      </div>
    </div>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import logger from "shared/services/logger";
import defaultProjectThumbnailUrl from "@/assets/imgs/default-project-thumbnail.jpg";
import { useStreams } from "@/composables/stream";
import StreamRepository from "@/repositories/StreamRepository";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import DashboardDragHandle from "@/views/dashboard/componentsV2/DashboardDragHandle.vue";
import { useVideoPlayerDimensions } from "@/views/dashboard/composables";
import { liveStreamWidgetHeaderHeight } from "@/views/dashboard/services/projectOverviewV2Layout";

const props = defineProps<{ arg: string; width: number; height: number }>();
const emit = defineEmits(["delete", "widgetRouteClicked"]);

const { t } = useI18n();

const isLoading = ref(false);
const thumbnailUrl = ref<string | null>(null);
const containerRef = ref<HTMLDivElement | null>(null);

const playerDimensions = useVideoPlayerDimensions(containerRef);
const { streams } = useStreams();
const stream = computed(() => streams.value.find(({ camera_id }) => camera_id === props.arg));

const loadImage = () => {
  if (!stream.value || !stream.value.thumbnail_url || isLoading.value) {
    return;
  }

  isLoading.value = true;
  StreamRepository.loadThumbnail(stream.value.thumbnail_url)
    .then((thumbnail) => {
      thumbnailUrl.value = thumbnail;
    })
    .catch((error) => {
      thumbnailUrl.value = null;
      logger.error(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

watch(
  () => stream.value,
  () => {
    loadImage();
  },
);

onMounted(() => {
  loadImage();
});
</script>
