<template>
  <ComboboxOption
    as="template"
    :value="typeWithChildren.type"
    v-slot="{ active, selected, disabled }"
    :disabled="existingTypes.includes(typeWithChildren.type)"
  >
    <li
      class="relative cursor-default select-none py-2 pl-5 pr-9 z-20"
      :class="{
        'bg-yellow-600 text-white': active,
        'text-gray-200': !active && disabled,
        'text-gray-900': !active && !disabled,
      }"
    >
      <span
        class="text-xs block truncate"
        :class="
          typeWithChildren.matchType === 'label' || selected ? ['font-semibold'] : ['font-normal']
        "
        >{{ typeWithChildren.label }}</span
      >
      <span
        v-if="selected"
        :class="[
          active ? 'text-white' : 'text-yellow-600',
          'absolute inset-y-0 right-0 flex items-center pr-4',
        ]"
      >
        <CheckIcon class="h-5 w-5" aria-hidden="true" />
      </span>
      <div
        class="absolute border-b top-1/2"
        :class="active ? ['border-yellow-600'] : ['border-gray-600']"
        :style="`${parent ? 'left: -3px; width: 19px' : 'left: 9px; width: 7px'}`"
      />
      <div
        v-if="parent"
        class="absolute border-l border-gray-600 bottom-1/2"
        :style="`left: -3px; height: ${countOfItemsBefore * itemHeight}px`"
      />
    </li>
  </ComboboxOption>
  <div class="ml-3 relative z-10">
    <ColumnModalTypeFieldListOption
      :existingTypes="existingTypes"
      v-for="childType in typeWithChildren.children"
      :key="childType.type"
      :typeWithChildren="childType"
      :parent="typeWithChildren"
      :parentChildren="typeWithChildren.children"
    />
  </div>
</template>

<script lang="ts" setup>
import { ComboboxOption } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/solid";
import { computed, PropType } from "vue";
import { UnitValueTypeWithChildren } from "@/types/UnitValue";
import ColumnModalTypeFieldListOption from "@/views/unit_values/components/ColumnModalTypeFieldListOption.vue";

const itemHeight = 32;

const props = defineProps({
  existingTypes: {
    type: Array as PropType<string[]>,
    required: true,
  },
  typeWithChildren: {
    type: Object as PropType<UnitValueTypeWithChildren>,
    required: true,
  },
  parent: {
    type: Object as PropType<UnitValueTypeWithChildren | null>,
    required: false,
  },
  parentChildren: {
    type: Object as PropType<UnitValueTypeWithChildren[]>,
    required: true,
  },
});

const countItems = <T extends { children: T[] }>(items: T[]) => {
  let count = 0;
  for (const item of items) {
    count += countItems(item.children) + 1;
  }
  return count;
};

const countOfItemsBefore = computed(() => {
  const index = props.parentChildren.indexOf(props.typeWithChildren);
  if (index < 0) {
    return 0;
  }
  return countItems(props.parentChildren.slice(0, index)) + (props.parent ? 1 : 0);
});
</script>
