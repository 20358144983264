<template>
  <CameraItemLayout :onlyLeft="isPublic" :noData="noData && !loading">
    <template #contentLeft>
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto">
          <div class="pr-2">
            <div class="flex justify-center items-center pb-2" style="min-height: 56px">
              <button
                @click="getGalleryImages(data?.previous)"
                type="button"
                :disabled="!(data && data.previous)"
                class="border-transparent inline-flex items-center px-4 py-2 text-sm font-medium"
                v-if="!loading"
              >
                <ChevronLeftIcon
                  class="h-6 w-6 text-oaiGray-300"
                  :class="{ 'text-transparent': !(data && data.previous) }"
                  aria-hidden="true"
                />
              </button>
              <h2
                class="pl-8 pr-8 2xl:pl-12 2xl:pr-12 text-sm 2xl:text-lg font-extrabold text-oaiGray-300"
                :class="isPublic ? ['cursor-pointer'] : []"
                @click="showHeaderDatePicker = !!isPublic"
                v-if="date && !showHeaderDatePicker"
              >
                {{ formatDate(date) }}
              </h2>
              <input
                type="date"
                :value="date"
                v-if="showHeaderDatePicker"
                @change="handleHeaderDatePickerChange"
                :disabled="loading"
                :max="formatDate(maxDate)"
                @keydown.prevent
                class="oai-inputs"
                style="width: min-content !important"
              />
              <button
                @click="getGalleryImages(data?.next)"
                type="button"
                :disabled="!(data && data.next)"
                class="border-transparent inline-flex items-center px-4 py-2 text-sm font-medium"
                v-if="!loading"
              >
                <ChevronRightIcon
                  class="h-6 w-6 text-oaiGray-300"
                  :class="{ 'text-transparent': !(data && data.next) }"
                  aria-hidden="true"
                />
              </button>
            </div>
            <!-- Gallery -->
            <div v-if="loading">
              <div
                class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8"
              >
                <div class="loadingCard is-loading" v-for="i in 4" :key="i">
                  <div
                    class="image group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-50 overflow-hidden"
                  />
                  <h2 class="mt-2 block h-xs font-medium text-gray-900 rounded-xs w-10" />
                </div>
              </div>
            </div>
            <div v-if="!loading && showDefaultImages">
              <div
                class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8 pb-8"
              >
                <div v-for="i in 4" :key="i">
                  <img
                    :src="defaultProjectThumbnailUrl"
                    alt="default image"
                    class="group block w-full rounded-lg bg-gray-50 overflow-hidden"
                  />
                </div>
              </div>
            </div>
            <div
              :id="galleryId"
              v-show="!loading && data"
              class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8"
            >
              <div v-for="(image, key) in data?.items" :key="key">
                <a
                  :href="image.url"
                  :data-pswp-width="image.width"
                  :data-pswp-height="image.height"
                  :data-timestamp="image.timestamp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden"
                  >
                    <img
                      :src="image.url"
                      class="group-hover:opacity-75 object-cover pointer-events-none"
                      alt="gallery image"
                    />
                  </div>
                </a>
                <div class="mt-2 block text-xs font-medium text-gray-900">
                  {{ displayTime(image.timestamp) }}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </template>
    <template #contentRight>
      <div
        class="flex items-center justify-end"
        v-if="!isPublic && hasFilePicker && !isMobileDevice"
      >
        <BasicMenu position="left">
          <template #icon>
            <EllipsisVerticalIcon class="h-6 w-6" aria-hidden="true" />
          </template>
          <template #content="{ item }">
            <component :is="item" v-slot="{ active }" @click="galleryDownloadModalOpen = true">
              <p
                :class="[
                  active ? 'text-yellow-600' : 'text-gray-700',
                  'px-4 py-2 cursor-pointer whitespace-nowrap flex items-center',
                ]"
              >
                <ArrowDownTrayIcon class="h-5 w-5 mr-2" aria-hidden="true" />

                <span> {{ $t("camera.gallery.download.menu_title") }} </span>
              </p>
            </component>
          </template>
        </BasicMenu>
      </div>
      <div class="col-span-4 lg:col-span-2">
        <div class="space-y-4 divide-y divide-gray-200">
          <h3 class="text-md 2xl:text-lg leading-6 font-medium text-gray-900">
            {{ $t("camera.gallery.pick_a_date") }}
          </h3>
          <Form
            @submit="onSubmit"
            :validationSchema="schema"
            class="space-y-3 sm:space-y-5 py-3 sm:py-0"
          >
            <div class="mt-1 pt-4">
              <DateField
                name="galleryDate"
                :label="$t('camera.period.date')"
                :maxDate="maxDate"
                :inlineField="true"
                :date="date"
              />
            </div>

            <div class="flex sm:pt-5">
              <MainButton
                :label="$t('camera.gallery.search')"
                type="submit"
                color="yellow"
                @click="buttonLoad = true"
                class="mx-0 sm:mr-0 mt-0"
              >
                <LoadingSpinner v-if="buttonLoad" size="w-5 h-5" color="white" class="mr-2" />
              </MainButton>
            </div>
          </Form>

          <!-- Weather Container -->
          <WeatherContainer
            ref="weatherContainerComponent"
            :date="dateToString"
            :loadMain="loading"
            v-if="data"
          />
        </div>
      </div>
    </template>
  </CameraItemLayout>
  <GalleryDownload
    v-if="galleryDownloadModalOpen"
    @close="galleryDownloadModalOpen = false"
  ></GalleryDownload>
</template>

<script setup lang="ts">
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
  ArrowDownTrayIcon,
} from "@heroicons/vue/24/solid";
import axios from "axios";
import { format, isValid, parseISO } from "date-fns";
import mobile from "is-mobile";
import { Padding } from "photoswipe";
import "photoswipe/dist/photoswipe.css";
import { PhotoSwipe } from "photoswipe/dist/types/ui/ui-element";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { Form } from "vee-validate";
import { computed, onMounted, onUnmounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import * as yup from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import {
  useCurrentCustomerName,
  useCurrentSiteId,
  useHasPermission,
} from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import defaultProjectThumbnailUrl from "@/assets/imgs/default-project-thumbnail.jpg";
import DateField from "@/components/forms/DateField.vue";
import BasicMenu from "@/components/other/BasicMenu.vue";
import MainButton from "@/components/other/MainButton.vue";
import CameraRepository from "@/repositories/CameraRepository";
import { GalleryImagesPaginatedResponse } from "@/types/Camera";
import CameraItemLayout from "@/views/camera/components/CameraItemLayout.vue";
import GalleryDownload from "@/views/camera/components/GalleryDownload.vue";
import WeatherContainer from "@/views/camera/components/WeatherContainer.vue";

const props = defineProps({
  isPublic: {
    type: Boolean,
    required: false,
  },
});

const isMobileDevice = ref<boolean>(mobile());
const hasFilePicker = !!window.showSaveFilePicker;

const lightbox = ref(null) as Ref<PhotoSwipeLightbox | null>;
const data = ref(null) as Ref<null | GalleryImagesPaginatedResponse>;
const date = ref(null) as Ref<Date | null>;
const searchDate = ref(null) as Ref<null | string>;
const loading = ref(false) as Ref<boolean>;
const buttonLoad = ref(false) as Ref<boolean>;
const showDefaultImages = ref(false) as Ref<boolean>;
const showHeaderDatePicker = ref(false) as Ref<boolean>;
const galleryDownloadModalOpen = ref(false) as Ref<boolean>;
const route = useRoute();
const { t } = useI18n();
const customer = useCurrentCustomerName();
const site = useCurrentSiteId();
const noData = ref(true) as Ref<boolean>;
const toast = useCustomToast();
const hasAdminPermissions = useHasPermission(["app_admin", "pct_admin"]);

const schema = computed(() =>
  yup.object({
    galleryDate: yup
      .date()
      .nullable()
      .required(t("err.required"))
      .max(
        maxDate.value,
        t("err.err_future_date", { maxDate: format(maxDate.value, "yyyy-MM-dd") }),
      ),
  }),
);

onMounted(() => {
  getGalleryImages(dateToString.value);
});

onUnmounted(() => {
  if (lightbox.value) {
    lightbox.value.destroy();
    lightbox.value = null;
  }
});

const galleryId = computed(() => {
  return `gallery-${customer}-${site}-${route.params.camera_id}`;
});
const maxDate = computed(() => {
  return new Date();
});

const dateToString = computed(() => {
  return date.value ? format(date.value, "yyyy-MM-dd") : "";
});

watch(loading, (newLoadingValue) => {
  if (!newLoadingValue) {
    buttonLoad.value = newLoadingValue;
  }
});

watch(searchDate, (newDate) => {
  getGalleryImages(newDate);
});

const mountPhotoSwipe = () => {
  if (!lightbox.value) {
    const lightboxInstance = new PhotoSwipeLightbox({
      gallery: `#${galleryId.value}`,
      children: "a",
      pswpModule: () => import("photoswipe"),
      wheelToZoom: true,
      padding: { top: 10, bottom: 10 } as Padding,
    });
    lightboxInstance.on("uiRegister", () => {
      lightboxInstance.pswp?.ui?.registerElement({
        name: "download-button",
        order: 8,
        isButton: true,
        tagName: "a",

        // SVG with outline
        html: {
          isCustomSVG: true,
          inner:
            '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1-1.5-1.6ZM23 23H9v2h14" id="pswp__icn-download"/>',
          outlineID: "pswp__icn-download",
        },

        onInit: (el: HTMLElement, pswp: PhotoSwipe) => {
          el.setAttribute("download", "");
          el.setAttribute("target", "_blank");
          el.setAttribute("rel", "noopener");

          pswp.on("change", () => {
            el.setAttribute("href", pswp.currSlide?.data.src as string);
          });
        },
      });

      if (hasAdminPermissions) {
        lightboxInstance.pswp?.ui?.registerElement({
          name: "annotation-button",
          order: 7,
          isButton: true,
          tagName: "p",
          className: "!w-auto !flex !items-center",
          html: '<p class="px-3 py-2 rounded-md bg-yellow-600 text-white text-xs whitespace-nowrap">Add for CV Dataset</p>',

          onClick(e, el, pswp) {
            const timestamp = pswp.currSlide?.data?.element?.dataset?.timestamp;

            if (!timestamp) {
              return;
            }
            const formattedTimestamp = formatTimestampForPersonDataset(timestamp);

            CameraRepository.addPersonImageToDatasetBuffer(
              customer as string,
              site as string,
              route.params.camera_id as string,
              formattedTimestamp,
            )
              .then(() => {
                toast.success("Image successfully uploaded");
              })
              .catch((error) => {
                logger.error(error);
                toast.error("Failed to upload image");
              });
          },
        });
      }
    });

    lightboxInstance.init();
    lightbox.value = lightboxInstance;
  }
};
const displayTime = (value: string) => {
  const date = new Date(value);
  return isValid(date) ? format(new Date(value), "HH:mm") : "";
};

const getGalleryImages = async (value: string | null | undefined) => {
  try {
    noData.value = false;

    date.value = value ? parseISO(value) : null;
    loading.value = true;

    const { customer_name, site_id, camera_id } = route.params;

    data.value = props.isPublic
      ? await CameraRepository.getPublicPaginatedGalleryImages(
          customer_name as string,
          site_id as string,
          camera_id as string,
          value || (route.query.date as string) || null,
          route.params.token as string,
        )
      : await CameraRepository.getPaginatedGalleryImages(
          customer_name as string,
          site_id as string,
          camera_id as string,
          value || (route.query.date as string) || null,
        );
    date.value = new Date(data.value.date);
    showDefaultImages.value = false;

    data.value.items.forEach((img) => {
      const imgElement = new Image();
      imgElement.src = img.url;
      imgElement.addEventListener("load", () => {
        img.width = imgElement.naturalWidth;
        img.height = imgElement.naturalHeight;
      });
    });

    loading.value = false;
    if (buttonLoad.value) buttonLoad.value = false;
    mountPhotoSwipe();
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.status !== 404) {
        logger.error(error);
      }
      data.value = null;
      if (error?.response?.data?.code === "NO_DATA_YET") {
        noData.value = true;
      } else {
        toast.error(t("err.no_data_found"));
      }
      showDefaultImages.value = true;
      loading.value = false;
      if (buttonLoad.value) buttonLoad.value = false;
      loading.value = false;
    }
  } finally {
    loading.value = false;
    if (buttonLoad.value) buttonLoad.value = false;
  }
};

const handleHeaderDatePickerChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  showHeaderDatePicker.value = false;
  getGalleryImages(target.value);
};

const formatDate = (dateValue: string | Date) => {
  const dateObject = typeof dateValue === "string" ? new Date(dateValue) : dateValue;
  return format(dateObject, "dd.MM.yyyy");
};

const onSubmit = async (values: Record<string, number | Date>) => {
  const formattedValue = format(values.galleryDate, "yyyy-MM-dd");
  await getGalleryImages(formattedValue);
};

const formatTimestampForPersonDataset = (timestamp: string) => {
  return format(new Date(timestamp), "yyyy-MM-dd_HHmmss");
};
</script>
