import dateService from "../services/dateService";
import { ShortenedProcessWithTags } from "../types/Process";
import { apiClient } from "./clients";

const loadShortenedProcessesWithTags = (
  customerName: string,
  siteId: string,
): Promise<ShortenedProcessWithTags[]> =>
  apiClient
    .get<ShortenedProcessWithTags<string>[]>(
      `/ops-process/shortened-processes-with-tags/${customerName}/${siteId}`,
    )
    .then((response) =>
      response.data.map((process) => ({
        ...process,
        start_time: dateService.parseLocalDate(process.start_time),
        end_time: dateService.parseLocalDate(process.end_time),
        work_intervals: process.work_intervals.map((workInterval) => ({
          ...workInterval,
          start_time: dateService.parseLocalDate(workInterval.start_time),
          end_time: dateService.parseLocalDate(workInterval.end_time),
        })),
      })),
    );

export default {
  loadShortenedProcessesWithTags,
};
