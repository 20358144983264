<template>
  <BaseWidget
    @delete="emit('delete')"
    hideDragHandle
    menuClass="top-4"
    @trackInteraction="emit('trackInteraction')"
  >
    <div class="h-full flex flex-col" ref="containerRef">
      <div :style="{ height: `${liveStreamWidgetHeaderHeight}px` }">
        <div
          class="pl-4 pr-7 flex items-center py-4 gap-2"
          :class="{
            'bg-red-100': routerConnectionLost,
          }"
        >
          <DashboardDragHandle class="shrink-0" />
          <RouterLink
            v-if="stream && !isRenaming"
            :to="{
              name: 'Live',
              params: {
                camera_id: stream.camera_id,
                customer_name: stream.customer_name,
                site_id: stream.site_id,
              },
            }"
            class="font-semibold text-gray-900 truncate text-lg hover:text-yellow-800"
            @click.prevent="emit('widgetRouteClicked')"
          >
            {{ stream?.name }}
          </RouterLink>
          <div v-if="isRenaming" class="flex items-center gap-2">
            <input
              ref="nameEditingInput"
              type="text"
              autocomplete="one-time-code"
              v-model="nameEditingValue"
              @keydown.stop
              @keyup.enter="handleConfirmNameClick"
              @keyup.esc="resetNameEditing"
              @click.stop
              maxlength="1000"
              class="block rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
            />
            <CheckIcon
              class="w-5 h-5 cursor-pointer text-gray-600 hover:text-yellow-600 shrink-0"
              v-if="!isUpdateLoading"
              @click.stop="handleConfirmNameClick"
            />
            <XMarkIcon
              class="w-5 h-5 cursor-pointer text-gray-600 hover:text-yellow-600 shrink-0"
              v-if="!isUpdateLoading"
              @click.stop="resetNameEditing"
            />
            <LoadingSpinner size="w-5 h-5" v-if="isUpdateLoading" />
          </div>
        </div>
      </div>
      <div
        :style="{ height: `${playerDimensions.height}px` }"
        class="bg-oaiGray-900 flex flex-col items-center"
        data-oai-left-over-space-calculator="ignore"
      >
        <div
          :style="{
            width: `${playerDimensions.width}px`,
          }"
          class="overflow-hidden"
        >
          <LiveBase
            :cameraId="arg"
            :addRightSpaceToControlBar="true"
            @videoHasError="routerConnectionLost = true"
          />
        </div>
      </div>
    </div>
    <template #menuItems>
      <MenuItem
        v-if="hasPermission('project_admin') && dashboard?.type === 'project'"
        v-slot="{ active }"
        @click="handleEditNameClick"
      >
        <div
          :class="[
            active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
            'px-4 py-2 text-sm cursor-pointer truncate flex items-center gap-2',
          ]"
        >
          <PencilIcon class="w-4 h-4" />
          {{ $t("camera.dashboard.rename") }}
        </div>
      </MenuItem>
    </template>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { MenuItem } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/outline";
import { PencilIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { computed, nextTick, ref } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useTrackEvent } from "shared/composables/tracking";
import LiveBase from "@/components/live_video/LiveBase.vue";
import { useStreams, useUpdateStreams } from "@/composables/stream";
import { Dashboard } from "@/types/Dashboard";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import DashboardDragHandle from "@/views/dashboard/componentsV2/DashboardDragHandle.vue";
import { useVideoPlayerDimensions } from "@/views/dashboard/composables";
import { liveStreamWidgetHeaderHeight } from "@/views/dashboard/services/projectOverviewV2Layout";

const props = defineProps<{ arg: string; width: number; height: number; dashboard?: Dashboard }>();
const emit = defineEmits(["delete", "widgetRouteClicked", "trackInteraction"]);

const isRenaming = ref(false);
const nameEditingValue = ref("");
const nameEditingInput = ref<HTMLInputElement | null>(null);

const { streams } = useStreams();
const { updateStreams, isUpdateLoading } = useUpdateStreams();

const stream = computed(() => streams.value.find(({ camera_id }) => camera_id === props.arg));

const containerRef = ref<HTMLDivElement | null>(null);

const playerDimensions = useVideoPlayerDimensions(containerRef);

const handleEditNameClick = () => {
  if (!stream.value) {
    return;
  }
  isRenaming.value = true;
  nameEditingValue.value = stream.value.name;
  nextTick(() => {
    if (nameEditingInput.value) {
      nameEditingInput.value?.focus();
    }
  });
};

const trackEvent = useTrackEvent();

const handleConfirmNameClick = () => {
  if (!stream.value) {
    return;
  }
  const updatedStream = {
    _id: stream.value._id,
    name: nameEditingValue.value || stream.value.name,
  };

  trackEvent("dashboard_widget_stream-rename_apply");
  updateStreams([updatedStream]).catch(() => undefined);
};

const resetNameEditing = () => {
  isRenaming.value = false;
  nameEditingValue.value = "";
};
const routerConnectionLost = ref(false);
</script>
