const CACHE_NAME = "oculai-cache";
const EXPIRES_HEADER = "expires";

const clearExpiredCache = async () => {
  const cache = await caches.open(CACHE_NAME);
  const keys = await cache.keys();

  for (const request of keys) {
    const response = await cache.match(request);

    if (!response) {
      return;
    }

    const headers = response.headers;
    const expires = headers.get(EXPIRES_HEADER);
    if (expires && new Date(expires) < new Date()) {
      await cache.delete(request);
    }
  }
};

const cachePlugin = {
  install() {
    const hour = 1000 * 60 * 60;
    setInterval(clearExpiredCache, hour);
    clearExpiredCache();
  },
};

export default {
  cachePlugin,
};
