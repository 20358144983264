import { inject } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { User, UserEveryGroup, UserGroup, UserProjectPermissionGroup } from "../types/User";

export const useCurrentCustomerName = (): string => {
  const { params } = useRoute();
  return (inject<string>("customerName", "") || params.customer_name) as string;
};

export const useCurrentSiteId = (): string => {
  const { params } = useRoute();
  return (inject<string>("siteId", "") || params.site_id) as string;
};

export const useHasPermissionForUser = (
  user: User,
  groups: UserEveryGroup | UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[],
  customerName?: string,
  siteId?: string,
) => {
  const requiredGroups: UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[] = Array.isArray(
    groups,
  )
    ? groups
    : [groups];

  if (
    requiredGroups.some((requiredGroup) =>
      Array.isArray(requiredGroup)
        ? requiredGroup.every((userGroup) => user.groups.includes(userGroup as UserGroup))
        : user.groups.includes(requiredGroup as UserGroup),
    )
  ) {
    return true;
  }

  customerName = customerName || useCurrentCustomerName();
  siteId = siteId || useCurrentSiteId();

  if (customerName && siteId) {
    const project = user.projects.find(
      (project) => project.customer_name === customerName && project.site_id === siteId,
    );

    if (
      requiredGroups.some((requiredGroup) =>
        Array.isArray(requiredGroup)
          ? requiredGroup.every((UserProjectPermissionGroup) =>
              project?.groups.includes(UserProjectPermissionGroup as UserProjectPermissionGroup),
            )
          : project?.groups.includes(requiredGroup as UserProjectPermissionGroup),
      )
    ) {
      return true;
    }
  }

  return false;
};

export const useHasPermission = (
  groups: UserEveryGroup | UserEveryGroup[] | (UserEveryGroup[] | UserEveryGroup)[],
  customerName?: string,
  siteId?: string,
) => {
  const store = useStore();

  const user = store.state.user as User | undefined;
  if (!user) {
    return false;
  }

  return useHasPermissionForUser(user, groups, customerName, siteId);
};
