export const userRoles = [
  "project_management",
  "site_management",
  "foreman",
  "management_board",
  "division_management",
  "bim_management",
  "lean_management",
  "calculation",
  "controlling",
  "procurement",
  "scheduling",
  "business_development",
  "assistance",
  "other",
] as const;

export const companyTypes = [
  "general_contractor",
  "subcontractor",
  "owner",
  "architect",
  "other",
] as const;
