import { computed, Ref } from "vue";
import { useIsPlotTypeEnabled } from "@/composables/report";
import { DashboardGridSize } from "@/types/Dashboard";
import { ReportPlotType } from "@/types/Report";
import {
  calculateRealWidgetWidth,
  calculateRealWidgetHeight,
} from "@/views/dashboard/services/projectOverviewV2Layout";

export const useChartSize = (
  props: Ref<{
    dashboardGridSize?: DashboardGridSize;
    width?: number;
    height?: number;
    spacingX: number;
    spacingY: number;
  }>,
  { minGridColumns, minGridRows }: { minGridColumns: number; minGridRows: number },
) => {
  const headerHeight = 36;
  const filterBadgesHeight = 36;

  const minFullWidth = computed(() => {
    const { dashboardGridSize } = props.value;
    return dashboardGridSize ? calculateRealWidgetWidth(dashboardGridSize, minGridColumns) : 0;
  });

  const minFullHeight = computed(() => {
    const { dashboardGridSize } = props.value;
    return dashboardGridSize ? calculateRealWidgetHeight(dashboardGridSize, minGridRows) : 0;
  });

  const chartHeight = computed(() => {
    const { height, spacingY } = props.value;
    return Math.floor(
      Math.max(height || 0, minFullHeight.value) - headerHeight - filterBadgesHeight - 2 * spacingY,
    );
  });

  const sizeStyles = computed(() => {
    const { spacingX, spacingY, width, height } = props.value;
    return {
      minWidth: `${width && minFullWidth.value > width ? minFullWidth.value : 0}px`,
      minHeight: `${height && minFullHeight.value > height ? minFullHeight.value : 0}px`,
      padding: `${spacingY}px ${spacingX}px`,
    };
  });

  return {
    filterBadgesHeight,
    headerHeight,
    minFullWidth,
    minFullHeight,
    chartHeight,
    sizeStyles,
  };
};

export const usePlotTypes = () => {
  const isPlotTypeEnabled = useIsPlotTypeEnabled();
  return computed(() => {
    const plotTypes: ReportPlotType[] = [
      "cycle_times",
      "working_hour_curve",
      "unit_values",
      "milestone",
      "query_value",
    ];
    return plotTypes.map((type) => ({
      type,
      enabled: isPlotTypeEnabled(type),
    }));
  });
};
