import {
  CriticalPath,
  CriticalPathContext,
  CriticalPathEx,
  CriticalPathNode,
  CriticalPathNodeEx,
  CriticalPathTag,
} from "shared/types/CriticalPath";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { ActualEvent, PlanConfig, PlannedEvent, PlannerItem } from "shared/types/Plan";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { calculateEntireCriticalPathDifferenceByCriticalPath } from "./criticalPathDifference";
import { createCriticalPathEx } from "./criticalPathNode";

export const createBuildingIds = (
  criticalPath: CriticalPath,
  hierarchyTags: HierarchyTagStore[],
) => {
  const nodeBuildingIds = new Set((criticalPath?.nodes || []).map((node) => node.building_id));
  const ids = hierarchyTags
    .filter((tag) => tag.type === "building" && nodeBuildingIds.has(tag._id))
    .map((tag) => tag._id);
  return [...(nodeBuildingIds.has(null) ? [null] : []), ...ids];
};

export const createLevels = (
  criticalPath: CriticalPath,
  hierarchyTags: HierarchyTagStore[],
  buildingIds: (string | null)[],
) => {
  const buildingIdSet = new Set(buildingIds);
  const nodes = criticalPath.nodes.filter((node) => buildingIdSet.has(node.building_id));
  const nodeLevelIds = new Set(nodes.map((node) => node.level_id));
  const buildingIdsByLevel = nodes.reduce((acc, node) => {
    if (!buildingIdSet.has(node.building_id)) {
      return acc;
    }
    if (!acc[node.level_id]) {
      acc[node.level_id] = new Set();
    }
    acc[node.level_id].add(node.building_id);
    return acc;
  }, {} as Record<string, Set<string | null>>);

  const levels = hierarchyTags.filter((tag) => tag.type === "level" && nodeLevelIds.has(tag._id));
  levels.sort((a, b) => b.number - a.number);
  return levels.map((level) => {
    const buildingIdsForLevel = buildingIdsByLevel[level._id];
    return {
      level,
      shared:
        buildingIdsForLevel.size === 1 && buildingIdsForLevel.has(null) && buildingIdSet.size > 1,
    };
  });
};

const traverseEdges = (
  selectedNodeIds: string[],
  context: CriticalPathContext,
  direction: "both" | "up" | "down",
  lastNodeId: string | null,
): { edgeIds: Set<string>; nodeIds: Set<string> } => {
  const { edgesBySourceId, edgesByTargetId } = context;
  const edgeIds = new Set<string>();
  const nodeIds = new Set<string>();
  for (const nodeId of selectedNodeIds) {
    nodeIds.add(nodeId);

    if (direction === "up" || direction === "both") {
      const targetIds = edgesBySourceId[nodeId] || [];
      for (const targetId of targetIds) {
        const traverseResult = traverseEdges([targetId], context, "up", lastNodeId);
        if (!lastNodeId || traverseResult.nodeIds.has(lastNodeId)) {
          traverseResult.nodeIds.forEach((nodeId) => nodeIds.add(nodeId));
          traverseResult.edgeIds.forEach((edgeId) => edgeIds.add(edgeId));
          nodeIds.add(targetId);
          edgeIds.add(`${nodeId}_${targetId}`);
        }
      }
    }
    if (direction === "down" || direction === "both") {
      const sourceIds = edgesByTargetId[nodeId] || [];
      sourceIds.forEach((sourceId) => {
        nodeIds.add(sourceId);
        edgeIds.add(`${sourceId}_${nodeId}`);
      });
      const traverseResult = traverseEdges(sourceIds, context, "down", lastNodeId);
      traverseResult.nodeIds.forEach((nodeId) => nodeIds.add(nodeId));
      traverseResult.edgeIds.forEach((edgeId) => edgeIds.add(edgeId));
    }
  }
  return { edgeIds, nodeIds };
};

export const getCriticalPathEdgeIds = (
  criticalPath: CriticalPath,
  nodeIds: string[],
  lastNodeId: string | null,
  context: CriticalPathContext,
) => {
  return traverseEdges(nodeIds, context, "both", lastNodeId);
};

export const getCriticalPathContext = (
  criticalPath: CriticalPathEx,
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
): CriticalPathContext => {
  const edgesBySourceId = criticalPath.edges.reduce((acc, edge) => {
    if (!acc[edge.source_id]) {
      acc[edge.source_id] = [];
    }
    acc[edge.source_id].push(edge.target_id);
    return acc;
  }, {} as Record<string, string[]>);

  const edgesByTargetId = criticalPath.edges.reduce((acc, edge) => {
    if (!acc[edge.target_id]) {
      acc[edge.target_id] = [];
    }
    acc[edge.target_id].push(edge.source_id);
    return acc;
  }, {} as Record<string, string[]>);

  const tagsById = hierarchyTags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  const tagsByTypeAndSourceId = hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      acc[`${tag.type}_${sourceId}`] = tag;
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore>);

  const plannerItemsById = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem>);

  const plannedEventsBySourceId = planConfig.planned_events.reduce((acc, plannedEvent) => {
    const plannerItem = plannerItemsById[plannedEvent.planner_item_id];
    acc[plannerItem.source_id] = plannedEvent;
    return acc;
  }, {} as Record<string, PlannedEvent>);

  const actualEventsBySourceId = planConfig.actual_events.reduce((acc, actualEvent) => {
    acc[actualEvent.source_id] = actualEvent;
    return acc;
  }, {} as Record<string, ActualEvent>);

  const criticalPathNodesById = criticalPath.nodes.reduce((acc, node) => {
    acc[node._id] = node;
    return acc;
  }, {} as Record<string, CriticalPathNodeEx>);

  const criticalPathTagsById = criticalPath.tags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, CriticalPathTag>);

  const abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const criticalPathTagLetterById = criticalPath.tags.reduce((acc, tag, index) => {
    acc[tag._id] = abc[index % abc.length];
    return acc;
  }, {} as Record<string, string>);

  return {
    projectDurationSettings,
    tagsById,
    tagsByTypeAndSourceId,
    plannerItemsById,
    plannedEventsBySourceId,
    actualEventsBySourceId,
    edgesBySourceId,
    edgesByTargetId,
    criticalPathNodesById,
    criticalPathTagsById,
    criticalPathTagLetterById,
  };
};

export const calculateIsLastInCriticalPath = (
  node: CriticalPathNode,
  context: CriticalPathContext,
) => {
  const { edgesBySourceId } = context;
  const childNodeIds = edgesBySourceId[node._id] || [];
  return childNodeIds.length === 0;
};

export const calculateDelta = (
  criticalPath: CriticalPath,
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
) => {
  const criticalPathEx = createCriticalPathEx(criticalPath, planConfig, hierarchyTags);
  const context = getCriticalPathContext(
    criticalPathEx,
    planConfig,
    hierarchyTags,
    projectDurationSettings,
  );
  const delta = calculateEntireCriticalPathDifferenceByCriticalPath(criticalPathEx, context);
  return delta?.forLastFinished.workingDays ?? null;
};
