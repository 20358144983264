import { useI18n } from "vue-i18n";

type I18nEx = ReturnType<typeof useI18n>["global"] & { localeText: string };

const instance: { global: I18nEx } = { global: null } as { global: I18nEx };

export const setInstance = (instanceToSet: unknown) => {
  const finalInstance = instanceToSet as I18nEx;
  finalInstance.localeText =
    typeof finalInstance.locale === "string" ? finalInstance.locale : finalInstance.locale.value;
  instance.global = finalInstance;
};

export default instance;
