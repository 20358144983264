import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { ProcessClass } from "shared/types/ProcessClass";
import { UnitValue, UnitValueType } from "@/types/UnitValue";
import { getProcessTypeByUnitValueType } from "@/views/unit_values/services/unitValueTypes";

export const getProcesses = (type: UnitValueType, processClasses: ProcessClass[]) => {
  const processClassByDecodedLabel = processClasses.reduce((acc, item) => {
    acc[item.decodedLabel] = item;
    return acc;
  }, {} as Record<string, ProcessClass>);

  const processTypes = getProcessTypeByUnitValueType(type);

  const processes = processTypes.map(
    (processType) => processClassByDecodedLabel[processType].encodedLabel,
  );

  const processElements = processTypes.map(
    (processType) => processClassByDecodedLabel[processType].processElement,
  );

  return { processes, process_types: [...new Set(processElements)] };
};

export const createLink = (
  unitValue: UnitValue,
  hierarchyTags: HierarchyTagStore[],
  processClasses: ProcessClass[],
) => {
  const processesFull = getProcesses(unitValue.type, processClasses);

  const joinToString = <T>(items: T[]) => [...new Set(items)].join(",") || undefined;

  return {
    name: "ProcessesTable",
    query: {
      processes: joinToString(processesFull.processes),
      processTypes: joinToString(processesFull.process_types),
      location: joinToString(
        unitValue.tags
          .flatMap((tag) => [tag.building_id, tag.level_id, tag.section_id])
          .filter((tagId) => tagId),
      ),
    },
  };
};
