<template>
  <button
    :type="type"
    :class="[
      'focus:outline-none relative w-full sm:w-auto inline-flex items-center justify-center rounded-md border mt-2 focus:ring-2 sm:mt-0 px-4 py-2 md:text-sm text-xs shadow-sm font-medium',
      {
        'border-gray-300 border-solid bg-white text-gray-700 hover:bg-gray-50': bordered,
        'text-white': color,
      },
      isDisabled ? 'bg-gray-300' : `bg-${color}-600 hover:bg-${color}-700 focus:ring-transparent`,
      customClass,
    ]"
    :disabled="isDisabled"
    @click="$emit('clicked')"
  >
    <slot></slot>
    {{ label }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainButton",
  props: ["type", "label", "color", "isDisabled", "bordered", "customClass"],
  emits: ["clicked"],
});
</script>
