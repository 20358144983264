<template>
  <Teleport to="#modal">
    <div
      class="fixed bg-white border py-1 z-[200] cursor-default w-[200px] shadow-md overflow-auto max-h-[300px]"
      ref="tagContextMenuContainerRef"
      :style="{ top: `${position.y}px`, left: `${position.x}px` }"
    >
      <div
        v-for="tag in criticalPath.tags"
        :key="tag._id"
        class="px-5 py-2 hover:bg-gray-100 flex gap-2 items-center cursor-pointer"
        @click="handleTagClick(tag)"
      >
        <div class="truncate flex-1">{{ tag.name }}</div>
        <template v-for="nodesForTag of [getNodesForTagId(tag._id)]" :key="nodesForTag">
          <div
            class="text-xs bg-orange-400 rounded text-white w-4 h-4 flex items-center justify-center cursor-pointer"
            v-if="nodesForTag.size > 0 && nodesForTag.size !== selectedNodeIds.length"
          >
            -
          </div>
          <input
            v-else
            type="checkbox"
            :checked="nodesForTag.size > 0 && nodesForTag.size === selectedNodeIds.length"
            class="shrink-0 focus:ring-0 text-orange-400 rounded cursor-pointer"
          />
        </template>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { onClickOutside } from "@vueuse/core";
import { computed, ref } from "vue";
import { CriticalPathEx, CriticalPathNodeEx, CriticalPathTag } from "shared/types/CriticalPath";

const props = defineProps<{
  position: { x: number; y: number };
  criticalPath: CriticalPathEx;
  selectedNodeIds: string[];
}>();

const emit = defineEmits<{
  (eventName: "close"): void;
  (eventName: "change", payload: CriticalPathEx): void;
}>();

const tagContextMenuContainerRef = ref<HTMLDivElement | null>(null);

onClickOutside(tagContextMenuContainerRef, () => {
  emit("close");
});

const selectedNodeIdsSet = computed(() => new Set(props.selectedNodeIds));

const nodesByTagId = computed(() =>
  props.criticalPath.nodes.reduce((acc, node) => {
    if (!selectedNodeIdsSet.value.has(node._id)) {
      return acc;
    }
    for (const tagId of node.tags) {
      if (!acc[tagId]) {
        acc[tagId] = new Set<string>();
      }
      acc[tagId].add(node._id);
    }
    return acc;
  }, {} as Record<string, Set<string>>),
);

const getNodesForTagId = (tagId: string) => nodesByTagId.value[tagId] || new Set<string>();

const addTagToNode = (node: CriticalPathNodeEx, tag: CriticalPathTag, nodesForTag: Set<string>) => {
  if (nodesForTag.has(node._id)) {
    return node;
  }
  return {
    ...node,
    tags: [...node.tags, tag._id],
  };
};

const removeTagFromNode = (node: CriticalPathNodeEx, tag: CriticalPathTag) => ({
  ...node,
  tags: node.tags.filter((tagId) => tagId !== tag._id),
});

const addOrRemoveTagToNode = (
  node: CriticalPathNodeEx,
  tag: CriticalPathTag,
  nodesForTag: Set<string>,
  { shouldAdd }: { shouldAdd: boolean },
) => {
  if (shouldAdd) {
    return addTagToNode(node, tag, nodesForTag);
  }
  return removeTagFromNode(node, tag);
};

const handleTagClick = (tag: CriticalPathTag) => {
  const nodesForTag = getNodesForTagId(tag._id);
  const shouldAdd = nodesForTag.size === 0 || nodesForTag.size !== props.selectedNodeIds.length;
  emit("change", {
    ...props.criticalPath,
    nodes: props.criticalPath.nodes.map((node) =>
      selectedNodeIdsSet.value.has(node._id)
        ? addOrRemoveTagToNode(node, tag, nodesForTag, { shouldAdd })
        : node,
    ),
  });
};
</script>
