<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col gap-1" v-for="(rule, index) in rules" :key="index">
      <QueryValueFormattingRuleField
        :metric="metric"
        :rule="rule"
        @delete="handleDeleteRule(index)"
        @update="handleUpdateRule(index, $event)"
      />
    </div>
    <PlusIcon class="w-5 h-5 cursor-pointer" @click="handleAddRuleClick" />
  </div>
</template>

<script setup lang="ts">
import { PlusIcon } from "@heroicons/vue/24/solid";
import { QueryValueFormattingRule, QueryValueMetric } from "@/types/reports/PlotQueryValue";
import QueryValueFormattingRuleField from "@/views/reports/plots/query_value/QueryValueFormattingRuleField.vue";

const props = defineProps<{ metric: QueryValueMetric; rules: QueryValueFormattingRule[] }>();
const emit = defineEmits<{ (eventName: "update", payload: QueryValueFormattingRule[]): void }>();

const handleAddRuleClick = () => {
  const rule: QueryValueFormattingRule = {
    action: "green_background",
    value: null,
    operator: "greater",
  };
  emit("update", [...props.rules, rule]);
};

const handleDeleteRule = (ruleIndex: number) => {
  emit(
    "update",
    props.rules.filter((rule, index) => index !== ruleIndex),
  );
};

const handleUpdateRule = (ruleIndex: number, updatedRule: QueryValueFormattingRule) => {
  emit(
    "update",
    props.rules.map((rule, index) => (index == ruleIndex ? updatedRule : rule)),
  );
};
</script>
